import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService, User } from 'src/app/services/auth.service';
import { CollectionService } from 'src/app/services/collection.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements AfterViewInit, OnInit {
  displayedColums: string[] = [
    "Email",
    "UserName",
    "Rol",
    "Password",
    "Actions"
  ];
  dataSource: MatTableDataSource<User>;
  dataUsers: any[];
  devenv = environment.fakeData;
  cresults: any;
  dataResults: User[];
  pageSizes = [5,10,15,20,40,50,100,200,500,1000];

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private SCollection: CollectionService, private userAuth: AuthService, private router: Router) {
   
  }
  ngOnInit(): void {
     this.GetResults();
  }
  
  ngAfterViewInit(): void {
    
  }
  GetResults(){
    this.SCollection.GetUsers.then((results) => {
      this.cresults = JSON.parse(JSON.stringify(results));
     this.dataResults = this.cresults.map(dta=> {
           const id = dta.id;
           const email = dta.email;
           const password = dta.password;
           var type = "";
           switch (parseInt(dta.type)) {
                  case 1:
                      type = "Admin"
                    break;
                  case 2:
                      type = "Owner"
                  break;
                  case 3:
                    type = "Doctor/Nutritionist"
                    break;
                  
                  default:
                    break;
           }
           const username = dta.username;  
           return {id, email, password, type, username}
     });     
        console.log("this.dataResults: ", this.dataResults);
        this.SCollection.UserCollection = this.cresults;
        this.dataSource = new MatTableDataSource(this.dataResults);        
        console.log("this.dataSource: ", this.dataSource);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;      
       
        if(!this.devenv && this.userAuth.isLoggedIn()){
         this.SCollection.ColUsers.unsubscribe();                
        //  this.SCollection.FAuth.signOut();  
        }         
   })
  }
  ReloadList(){
    window.location.href= "/users"
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }   
  }
  UpdateUser(id){
    console.log("updated, ", id);
    // this.router.navigateByUrl("/users/edit/:id");
    this.router.navigate(['/users/edit'], {
      queryParams: {
         id: id
      }
   });
  }

  DeleteUser(id){
    console.log("deleted, ", id);
    var answer = window.confirm("Are you sure you want to Delete this User?");
    if (answer) {
      this.SCollection.DeleteUser(id).then(_ => {
        window.location.href = "/users";
      })
    }
    else {
        console.log("abort delete");
    }
   
  }

}
