<div class="container" style="max-width: 70%;">
    <div class="row">
        <div class="col-6" style="padding: 5%;
        position: relative;">
            <button  mat-button routerLink="new" style="left: 1vw; right: auto; top: 7vw">New User</button>
        </div>
        <div class="col-6" style="padding: 5%;
        position: relative;">
            <button  mat-button (click)="ReloadList()" style="left: auto; right: 1vw; top: 7vw">Reload List</button>
        </div>
        
        <div class="col-12">
            <mat-card style="margin-top: 5%;overflow-x: scroll;">
                <mat-card-header>
                    <mat-card-title>User management</mat-card-title>
                    <mat-card-subtitle>Data</mat-card-subtitle>
                  </mat-card-header>
                <mat-card-content>
                    <mat-form-field appearance="standard">
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. gmail" #input>
                    </mat-form-field>
                    <!-- <button  mat-button (click)="exporter.exportTable('csv')" >Export Data</button> -->
                    <div class="mat-elevation-z8">
                    <table mat-table   [dataSource]="dataSource" matSort matTableExporter  #exporter="matTableExporter">
                
                    <ng-container matColumnDef="Email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-20'" > Email </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-20'"> {{row.email}} </td>
                    </ng-container>
                    <ng-container matColumnDef="UserName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-20'"> UserName </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-20'"> {{row.username}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Rol">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-20'"> Rol </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-20'" > {{row.type}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Password">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-20'"> Password </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-20'"> <input type="password" value="{{row.password}}" style="border: 0; text-align: center;"></td>
                    </ng-container>
                    <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-20'"> Actions </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-20'"> 
                            <div class="menu-nav">
                                <div class="menu-item"></div>
                                <div class="dropdown-container" tabindex="-1">
                                  <div class="dots"></div>
                                  <div class="dropdown">
                                    <a (click)= "UpdateUser(row.id)"><div> UPDATE &nbsp;&nbsp;<i class="fas fa-chevron-right"></i></div></a>
                                    <a (click)="DeleteUser(row.id)"><div>DELETE &nbsp;&nbsp;<i class="fas fa-chevron-right"></i></div></a>                                 
                                  </div>
                                </div>
                              </div>    
                        </td>
                    </ng-container>                    
                    <ng-container matColumnDef="Date Finish">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header > Date Finish </th>
                        <td mat-cell *matCellDef="let row" > {{row.date}} </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColums"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColums;"></tr>
            
                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                    </tr>
                    
                    </table>
                
                    <mat-paginator #paginator [pageSizeOptions]="pageSizes"  showFirstLastButtons></mat-paginator>
                    </div>
            </mat-card-content>
            </mat-card>
        </div>
    </div>   
</div>

