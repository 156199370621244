import { stringify } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import { List } from 'linqts';
import { Observable, Subject } from 'rxjs';
import { CollectionScore, CollectionPercentil, CollectionService, CollectionPercentilFactor, CollectionSScoreSmall, Patients, Profile } from './collection.service';
import * as $ from 'jquery'
import * as Chart from 'chart.js';
import { ThrowStmt } from '@angular/compiler';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeneralServicesService {

 //all foods objects
 public ObjFoodShort: any;
 public ObjScoreTable: any;
 public ObjPercentilTable: any;
 public ObjPercentilTableF1: any;
 public ObjPercentilTableF2: any;
 public ObjPercentilTableF3: any;
 public ObjSmallTestTable: any;
 public ObjPatients: any;
 public answerbyfood :  Answer[];
public DatosSujeto = {
  id : "",
  typeTest : "",
  gender : "",
  birthday : "",
  region : 0,
  educationLevel: 0,
  ocupation: "",
  SizeTest: 0,
  Agree:"",
  PKU:0
}
devenv = environment.fakeData;
ELEMENTS_DATA : Array<ResultElement> = []

public AnswerxFood : Array<Answer>
  constructor(private FCollection: CollectionService) {
      this.BarVisibilityChange.subscribe((value)=> {
        this.currentValueBar = value;
      });

      setTimeout(()=>{
        this.FCollection.GetParamScore.then(result => {       
          this.ObjScoreTable = result;
          console.log("this.ObjScoreTable.length > 0", this.ObjScoreTable.length);
          if(this.ObjScoreTable.length == 0){
            var element = document.getElementById("popup1");
            element.style.visibility = 'visible';
            element.style.opacity = '1';
          }
          if(!this.devenv){
          this.FCollection.Score.unsubscribe();
          // this.FCollection.FAuth.signOut();
          }
        }).catch(err => {
           var element = document.getElementById("popup1");
          element.style.visibility = 'visible';
          element.style.opacity = '1';
          console.log("GetParamScore Error -> ", err);
        })
  
        this.FCollection.GetPercentil.then(result => {
          this.ObjPercentilTable = result;
          console.log("this.ObjPercentilTable.length > 0", this.ObjPercentilTable.length);
          if(this.ObjPercentilTable.length == 0){
            var element = document.getElementById("popup1");
            element.style.visibility = 'visible';
            element.style.opacity = '1';
          }
          if(!this.devenv){
          this.FCollection.Percent.unsubscribe();
          // this.FCollection.FAuth.signOut();
          }
        }).catch(err => {
          var element = document.getElementById("popup1");
        element.style.visibility = 'visible';
        element.style.opacity = '1';
          console.log("GetPercentil Error -> ", err);
        })
  
        this.FCollection.GetPercentilF1.then(result => {
          this.ObjPercentilTableF1 = result;
          console.log("this.ObjPercentilTableF1.length > 0", this.ObjPercentilTableF1.length);
          if(this.ObjPercentilTableF1.length == 0){
            var element = document.getElementById("popup1");
            element.style.visibility = 'visible';
            element.style.opacity = '1';
          }
          if(!this.devenv){
          this.FCollection.PercentF1.unsubscribe();
          // this.FCollection.FAuth.signOut();
          }
        }).catch(err => {
          var element = document.getElementById("popup1");
          element.style.visibility = 'visible';
          element.style.opacity = '1';
          console.log("GetPercentilF1 Error -> ", err);
        })
        this.FCollection.GetPercentilF2.then(result => {
          this.ObjPercentilTableF2 = result;
          console.log("this.ObjPercentilTableF2.length > 0", this.ObjPercentilTableF2.length);
          if(this.ObjPercentilTableF2.length == 0){
            var element = document.getElementById("popup1");
            element.style.visibility = 'visible';
            element.style.opacity = '1';
          }
          if(!this.devenv){
          this.FCollection.PercentF2.unsubscribe();
          // this.FCollection.FAuth.signOut();
          }
        }).catch(err => {
          var element = document.getElementById("popup1");
          element.style.visibility = 'visible';
          element.style.opacity = '1';
          console.log("GetPercentilF2 Error -> ", err);
        })
        this.FCollection.GetPercentilF3.then(result => {
          this.ObjPercentilTableF3 = result;
          console.log("this.ObjPercentilTableF3.length > 0", this.ObjPercentilTableF3.length);
          if(this.ObjPercentilTableF3.length == 0){
            var element = document.getElementById("popup1");
            element.style.visibility = 'visible';
            element.style.opacity = '1';
          }
          if(!this.devenv){
          this.FCollection.PercentF3.unsubscribe();
          // this.FCollection.FAuth.signOut();
          }
        }).catch(err => {
          var element = document.getElementById("popup1");
          element.style.visibility = 'visible';
          element.style.opacity = '1';
          console.log("GetPercentilF3 Error -> ", err);
        });
  
  
        this.FCollection.GetSScoreSmallTest.then(result => {
          this.ObjSmallTestTable = result;
          console.log("this.ObjSmallTestTable.length > 0", this.ObjSmallTestTable.length);
          if(this.ObjSmallTestTable.length == 0){
            var element = document.getElementById("popup1");
            element.style.visibility = 'visible';
            element.style.opacity = '1';
          }
          if(!this.devenv){
          this.FCollection.SScore.unsubscribe();
          // this.FCollection.FAuth.signOut();
          }
        }).catch(err => {
          var element = document.getElementById("popup1");
          element.style.visibility = 'visible';
          element.style.opacity = '1';
          console.log("GetSScoreSmallTest Error -> ", err);
        });
      },10000)
     
     
      
   

   }
currentValueBar = 1;
BarVisibilityChange: Subject<number> = new Subject<number>();

AnswerF1 : any;
AnswerF2 : any;
AnswerF3 : any;
AnswerF4 : any;
AnswerF5 : any;
dataFilter : any;
RawScore: number;
PercentTotal: number;
element = new ResultElement;

isExistsCode(idCode){
  
  return new Promise((resolve, reject) => {
    this.FCollection.GetPatients.then((results)=> {
      this.ObjPatients = results;
      console.log("ObjPatients: ", this.ObjPatients);
      var elementPatient = new List<Patients>(this.ObjPatients).Where(f=> f.id == idCode).Select(f=> f.id).SingleOrDefault();
      this.FCollection.GramsFen = new List<Patients>(this.ObjPatients).Where(f=> f.id == idCode).Select(f=> f.Gram).SingleOrDefault();
      console.log("isExistscode GRAM=> ", this.FCollection.GramsFen);
      console.log("GService element id Patient: ", elementPatient);
      if(elementPatient == idCode ){
        resolve(true);
      }else {
        reject(false);
      }
  })
  })
  
}

SaveLocalStorage(id,value, isObject = false){
   if(isObject)
   value = JSON.stringify(value)
   localStorage.setItem(id,value);
 }

GetLocalStorage(id, isObject = false){
   if(isObject){
   var retrievedObject = localStorage.getItem(id);
   return JSON.parse(retrievedObject)
   }
   else
    return localStorage.getItem(id);
}

ProcessResult() {
  var data = this.GetLocalStorage("Answers",true);
  this.SaveAnswer(data);
  this.AnswerF1 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 1)
  .Count();

  this.AnswerF2 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 2)
  .Count();
  
  this.AnswerF3 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 3)
  .Count();

  //Right Answer

 var PointsF1 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 1 && f.allow == f.answer)
  .Count();

  var PointsF2 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 2 && f.allow == f.answer)
  .Count();
  
  var PointsF3 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 3 && f.allow == f.answer)
  .Count();

  var PointsF4 = 0;

  var PointsF5 = 0;
//Wrong Answer
  var WPointsF1 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 1 && f.allow != f.answer)
  .Count();

  var WPointsF2 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 2 && f.allow != f.answer)
  .Count();
  
  var WPointsF3 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 3 && f.allow != f.answer)
  .Count();

  var WPointsF4 = 0;

  var WPointsF5 = 0;
  
  console.log("Total POINTS FACTOR 1: ", PointsF1);
  console.log("Total POINTS FACTOR 2: ", PointsF2);
  console.log("Total POINTS FACTOR 3: ", PointsF3);
  
  console.log("this.AnswerF1 -> ", this.AnswerF1);
  console.log("this.AnswerF2 -> ", this.AnswerF2);
  console.log("this.AnswerF3 -> ", this.AnswerF3);


  var ScoreFactor1 = new List<CollectionScore>(this.ObjScoreTable)
  .Where(f=> f.score == PointsF1.toString() && f.factor == "1")
  .Select(f=> f.value).SingleOrDefault()

  var LevelFactor1 = new List<CollectionScore>(this.ObjScoreTable)
  .Where(f=> f.score == PointsF1.toString() && f.factor == "1")
  .Select(f=> f.level).SingleOrDefault()
  
  var ScoreFactor2 = new List<CollectionScore>(this.ObjScoreTable)
  .Where(f=> f.score == PointsF2.toString() && f.factor == "2")
  .Select(f=> f.value).SingleOrDefault()

  var LevelFactor2 = new List<CollectionScore>(this.ObjScoreTable)
  .Where(f=> f.score == PointsF2.toString() && f.factor == "2")
  .Select(f=> f.level).SingleOrDefault()

  var ScoreFactor3 = new List<CollectionScore>(this.ObjScoreTable)
  .Where(f=> f.score == PointsF3.toString() && f.factor == "3")
  .Select(f=> f.value).SingleOrDefault()

  var LevelFactor3 = new List<CollectionScore>(this.ObjScoreTable)
  .Where(f=> f.score == PointsF3.toString() && f.factor == "3")
  .Select(f=> f.level).SingleOrDefault()

  console.log("LevelFactor1,LevelFactor2 ,LevelFactor3 => ", LevelFactor1,LevelFactor2 ,LevelFactor3);

  switch (LevelFactor1) {
    case "vlow":
      LevelFactor1 = "Very low level of knowledge"
      $('.factor1 .vlow').css('display','flex');
      break;
    case "Llow":
      LevelFactor1 = "Low level of knowledge"
      $('.factor1 .low').css('display','flex');
      break;
    // case "Medium-Low":
    //   LevelFactor1 = "Medium-Low level of knowledge"
    //   break;
    case "Medium":
      LevelFactor1 = "Medium level of knowledge";
      $('.factor1 .average').css('display','flex');
      break;
    case "High":
      LevelFactor1 = "High level of knowledge";
      $('.factor1 .vhigh').css('display','flex');
      break;
  
    default:
      break;
  }

  switch (LevelFactor2) {
    case "vlow":
      LevelFactor2 = "Very low level of knowledge";
      $('.factor2 .vlow').css('display','flex');
      break;
    case "Llow":
      LevelFactor2 = "Low level of knowledge";
      $('.factor2 .low').css('display','flex');
      break;
    // case "Medium-Low":
    //   LevelFactor2 = "Medium-Low level of knowledge"
    //   break;
    case "Medium":
      LevelFactor2 = "Medium level of knowledge";
      $('.factor2 .average').css('display','flex');
      break;
    case "High":
      LevelFactor2 = "High level of knowledge";
      $('.factor2 .vhigh').css('display','flex');
      break;
  
    default:
      break;
  }

  switch (LevelFactor3) {
    case "vlow":
      LevelFactor3 = "Very low level of knowledge";
      $('.factor3 .vlow').css('display','flex');
      break;
    case "Llow":
      LevelFactor3 = "Low level of knowledge";
      $('.factor3 .low').css('display','flex');
      break;
    // case "Medium":
    //   LevelFactor3 = "Medium-Low level of knowledge"
    //   break;
    case "Medium":
      LevelFactor3 = "Medium level of knowledge";
      $('.factor3 .average').css('display','flex');
      break;
    case "High":
      LevelFactor3 = "High level of knowledge";
      $('.factor3 .vhigh').css('display','flex');
      break;
  
    default:
      break;
  }

//SmallTestSScore

var SScore = new List<CollectionSScoreSmall>(this.ObjSmallTestTable)
.Where(f=> f.RawScore ==  (PointsF3 + PointsF2 + PointsF1))
.Select(f=> f.Sscore).SingleOrDefault()

var SScoreLevel = new List<CollectionSScoreSmall>(this.ObjSmallTestTable)
.Where(f=> f.RawScore == (PointsF3 + PointsF2 + PointsF1))
.Select(f=> f.level).SingleOrDefault()

switch (SScoreLevel) {
  case "vlow":
    SScoreLevel = "Very low level of knowledge"
    $('.factorgeneral .vlow').css('display','flex');
    break;
  case "low":
    SScoreLevel = "Low level of knowledge"
    $('.factorgeneral .low').css('display','flex');
    break;
  case "Medium":
    SScoreLevel = "Medium-Low level of knowledge"
    $('.factorgeneral .average').css('display','flex');
    break;
  case "MediumHigh":
    SScoreLevel = "Medium-High level of knowledge"
    $('.factorgeneral .high').css('display','flex');
    break;
  case "VeryHigh":
    SScoreLevel = "Very High level of knowledge"
    $('.factorgeneral .vhigh').css('display','flex');
    break;

  default:
    break;
}


  let _now = new Date().toLocaleString();
 
  var objDataResult = {
    PointsF1: PointsF1,
    PointsF2: PointsF2,
    PointsF3: PointsF3,
    PointsF4: PointsF4,
    PointsF5: PointsF5,
    ScoreF1: parseInt(ScoreFactor1),
    ScoreF2: parseInt(ScoreFactor2),
    ScoreF3: parseInt(ScoreFactor3),
    PercentsF1: 0,
    PercentsF2: 0,
    PercentsF3: 0,
    GRawScore: (PointsF1 + PointsF2 + PointsF3 + PointsF4 + PointsF4),
    GenPercentil: 0,
    date:_now
  }
  
  
  this.SaveResulst(objDataResult);
  

  this.ELEMENTS_DATA = [];
 this.ELEMENTS_DATA.push({ name:"FACTOR  1 Allowed food",
    NumberRight: PointsF1,
    NumberWrong : WPointsF1,
    DiffRW : (PointsF1 - WPointsF1),
    Sscore : ScoreFactor1.toString(),
    ScalePerc : "Not Allowed",
    description : LevelFactor1,
    percentilScale : "-",
    finalresult: LevelFactor1})

  
 this.ELEMENTS_DATA.push({name : "FACTOR 2 Forbidden foods, easy to recognize",
  NumberRight : PointsF2,
  NumberWrong : WPointsF2,
  DiffRW : (PointsF2 - WPointsF2),
  Sscore : ScoreFactor2.toString(),
  ScalePerc : "Not Allowed",
  description : LevelFactor2,
  percentilScale : "-",
  finalresult: LevelFactor2});


  this.ELEMENTS_DATA.push({name : "FACTOR 3 Forbidden foods",
  NumberRight : PointsF3,
  NumberWrong : WPointsF3,
  DiffRW : (PointsF3 - WPointsF3),
  Sscore : ScoreFactor3.toString(),
  ScalePerc : "Not Allowed",
  description : LevelFactor3,
  percentilScale : "-",
  finalresult: LevelFactor3});

  
  this.ELEMENTS_DATA.push({name : "GENERAL KNOWLEDGE PKU DIET",
  NumberRight : (PointsF1 + PointsF2 + PointsF3 + PointsF4 + PointsF4),
  NumberWrong : (WPointsF1 + WPointsF2 + WPointsF3 + WPointsF4 + WPointsF5),
  DiffRW : ((PointsF1 - WPointsF1) + (PointsF2 - WPointsF2) + (PointsF3 - WPointsF3) + (PointsF4 - WPointsF4) + (PointsF5 - WPointsF5)),
  Sscore : SScore.toString(),
  ScalePerc : "Not Allowed",
  description : "No Allowed",
  percentilScale : "-",
  finalresult: SScoreLevel})
  var currentYear :  number = new Date().getFullYear();
  var today = new Date().toLocaleDateString();
  var GenDataResults = this.GetLocalStorage('DataUser',true);
  var Age = currentYear - parseInt(GenDataResults.birthday)
  
  var TypePKU = GenDataResults.PKU;
  switch (parseInt(TypePKU)) {
    case 1:
      $("#defaultresult5").attr('checked', 'checked');
      break;
    case 2:
      $("#defaultresult6").attr('checked', 'checked');
      break;
    case 3:
      console.log("data radio checked automatic")
      $("#defaultresult7").attr('checked', 'checked');
      break;
  
    default:
      break;
  }
  

  $('#defaultresult1').val(Age);
  $('#defaultresult2').val(GenDataResults.gender);
  $('#defaultresult3').val(today);
  $('#defaultresult1').attr('readonly', true);
  $('#defaultresult2').attr('readonly', true);
  $('#defaultresult3').attr('readonly', true);
  $("#defaultresult5").attr('disabled', true);
  $("#defaultresult6").attr('disabled', true);
  $("#defaultresult7").attr('disabled', true);
  return this.ELEMENTS_DATA;
  }
  
ProcessResultExtend() {
  var data = this.GetLocalStorage("Answers",true);
  this.SaveAnswer(data);
  this.AnswerF1 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 1)
  .Count();

  this.AnswerF2 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 2)
  .Count();
  
  this.AnswerF3 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 3)
  .Count();

  this.AnswerF4 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 4)
  .Count();

  this.AnswerF5 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 5)
  .Count();

  //Right Answer

 var PointsF1 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 1 && f.allow == f.answer)
  .Count();

  var PointsF2 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 2 && f.allow == f.answer)
  .Count();
  
  var PointsF3 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 3 && f.allow == f.answer)
  .Count();

  var PointsF4 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 4 && f.allow == f.answer)
  .Count();

  var PointsF5 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 5 && f.allow == f.answer)
  .Count();
//Wrong Answer
  var WPointsF1 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 1 && f.allow != f.answer)
  .Count();

  var WPointsF2 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 2 && f.allow != f.answer)
  .Count();
  
  var WPointsF3 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 3 && f.allow != f.answer)
  .Count();

  var WPointsF4 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 4 && f.allow != f.answer)
  .Count();

  var WPointsF5 = new List<Answer>(this.AnswerxFood)
  .Where(f=>f.factor == 4 && f.allow != f.answer)
  .Count();
  
  console.log("Total POINTS FACTOR 1: ", PointsF1);
  console.log("Total POINTS FACTOR 2: ", PointsF2);
  console.log("Total POINTS FACTOR 3: ", PointsF3);
  console.log("Total POINTS FACTOR 4: ", PointsF4);
  console.log("Total POINTS FACTOR 5: ", PointsF5);

  
  console.log("this.AnswerF1 -> ", this.AnswerF1);
  console.log("this.AnswerF2 -> ", this.AnswerF2);
  console.log("this.AnswerF3 -> ", this.AnswerF3);


  var ScoreFactor1 = new List<CollectionScore>(this.ObjScoreTable)
  .Where(f=> f.score == PointsF1.toString() && f.factor == "1")
  .Select(f=> f.value).SingleOrDefault()

  var LevelFactor1 = new List<CollectionScore>(this.ObjScoreTable)
  .Where(f=> f.score == PointsF1.toString() && f.factor == "1")
  .Select(f=> f.level).SingleOrDefault()
  
  
  var ScoreFactor2 = new List<CollectionScore>(this.ObjScoreTable)
  .Where(f=> f.score == PointsF2.toString() && f.factor == "2")
  .Select(f=> f.value).SingleOrDefault()

  var LevelFactor2 = new List<CollectionScore>(this.ObjScoreTable)
  .Where(f=> f.score == PointsF2.toString() && f.factor == "2")
  .Select(f=> f.level).SingleOrDefault()


  var ScoreFactor3 = new List<CollectionScore>(this.ObjScoreTable)
  .Where(f=> f.score == PointsF3.toString() && f.factor == "3")
  .Select(f=> f.value).SingleOrDefault()

  var LevelFactor3 = new List<CollectionScore>(this.ObjScoreTable)
  .Where(f=> f.score == PointsF3.toString() && f.factor == "3")
  .Select(f=> f.level).SingleOrDefault()


  var PercentFactor;
  // if((PointsF1 + PointsF2 + PointsF3 + PointsF4 + PointsF5) > 73){
  //   PercentFactor = 100;
  // }else {
    PercentFactor = new List<CollectionPercentil>(this.ObjPercentilTable)
    .Where(f=>  f.score == (PointsF1 + PointsF2 + PointsF3 + PointsF4 + PointsF5).toString())
    .Select(f=> f.percent).SingleOrDefault()
    
    var RawScorePercent = PointsF1 + PointsF2 + PointsF3 + PointsF4 + PointsF5;
    
    if(PercentFactor == undefined){      
      RawScorePercent = RawScorePercent - 1;
      while (PercentFactor == undefined) {

          PercentFactor = new List<CollectionPercentil>(this.ObjPercentilTable)
          .Where(f=>  f.score == (RawScorePercent).toString())
          .Select(f=> f.percent).SingleOrDefault();
          if(PercentFactor == undefined)
              RawScorePercent = RawScorePercent - 1;
      }

    }

  // }
  var LevelPercentFactor = new List<CollectionPercentil>(this.ObjPercentilTable)
  .Where(f=>  f.score == (PointsF1 + PointsF2 + PointsF3 + PointsF4 + PointsF5).toString())
  .Select(f=> f.level).SingleOrDefault()

  if(LevelPercentFactor == undefined){
    LevelPercentFactor = new List<CollectionPercentil>(this.ObjPercentilTable)
      .Where(f=>  f.score == (RawScorePercent).toString())
      .Select(f=> f.level).SingleOrDefault()
  }

/**Percentil Factor1 Factor2 Factor3 */
var PercentilF1 = new List<CollectionPercentilFactor>(this.ObjPercentilTableF1)
.Where(f=>  f.RawScore == parseInt(PointsF1.toString()))
.Select(f=> f.Percent).SingleOrDefault()

var PercentilF2 = new List<CollectionPercentilFactor>(this.ObjPercentilTableF2)
.Where(f=>  f.RawScore == parseInt(PointsF2.toString()))
.Select(f=> f.Percent).SingleOrDefault()

var PercentilF3 = new List<CollectionPercentilFactor>(this.ObjPercentilTableF3)
.Where(f=>  f.RawScore == parseInt(PointsF3.toString()))
.Select(f=> f.Percent).SingleOrDefault()

let _now = new Date().toLocaleString();
var objDataResult = {
  PointsF1: PointsF1,
  PointsF2: PointsF2,
  PointsF3: PointsF3,
  PointsF4: PointsF4,
  PointsF5: PointsF5,
  ScoreF1: parseInt(ScoreFactor1),
  ScoreF2: parseInt(ScoreFactor2),
  ScoreF3: parseInt(ScoreFactor3),
  PercentsF1: PercentilF1,
  PercentsF2: PercentilF2,
  PercentsF3: PercentilF3,
  GRawScore: RawScorePercent,
  GenPercentil: parseInt(PercentFactor),
  date:_now
}

this.SaveResulst(objDataResult);

/***********************************/
console.log("PercentilF1,PercentilF2 ,PercentilF3 => ", PercentilF1,PercentilF2 ,PercentilF3);
  console.log("LevelFactor1,LevelFactor2 ,LevelFactor3 => ", LevelFactor1,LevelFactor2 ,LevelFactor3);
  
  console.log("PercentFactor, LevelPercentFactor, Total Points Raw G -> ", PercentFactor, LevelPercentFactor, (PointsF1 + PointsF2 + PointsF3 + PointsF4 + PointsF5));

  switch (LevelFactor1) {
    case "vlow":
      LevelFactor1 = "Very low level of knowledge"
      $('.factor1 .vlow').css('display','flex');
      break;
    case "Llow":
      LevelFactor1 = "Low level of knowledge"
      $('.factor1 .low').css('display','flex'); 
      break;
    // case "Medium-Low":
    //   LevelFactor1 = "Medium-Low level of knowledge"
    //   break;
    case "Medium":
      LevelFactor1 = "Medium level of knowledge"
      $('.factor1 .average').css('display','flex');
      break;
    case "High":
      LevelFactor1 = "High level of knowledge"
      $('.factor1 .vhigh').css('display','flex');
      break;

    default:
      break;
  }

  switch (LevelFactor2) {
    case "vlow":
      LevelFactor2 = "Very low level of knowledge"
      $('.factor2 .vlow').css('display','flex');
      break;
    case "Llow":
      LevelFactor2 = "Low level of knowledge"
      $('.factor2 .low').css('display','flex'); 
      break;
    // case "Medium-Low":
    //   LevelFactor2 = "Medium-Low level of knowledge"
    //   break;
    case "Medium":
      LevelFactor2 = "Medium level of knowledge"
      $('.factor2 .average').css('display','flex');
      break;
    case "High":
      LevelFactor2 = "High level of knowledge"
      $('.factor2 .vhigh').css('display','flex');
      break;
  
    default:
      break;
  }

  switch (LevelFactor3) {
    case "vlow":
      LevelFactor3 = "Very low level of knowledge"
      $('.factor3 .vlow').css('display','flex');
      break;
    case "Llow":
      LevelFactor3 = "Low level of knowledge"
      $('.factor3 .low').css('display','flex'); 
      break;
    // case "Medium-Low":
    //   LevelFactor3 = "Medium-Low level of knowledge"
    //   break;
    case "Medium":
      LevelFactor3 = "Medium level of knowledge"
      $('.factor3 .average').css('display','flex');
      break;
    case "High":
      LevelFactor3 = "High level of knowledge"
      $('.factor3 .vhigh').css('display','flex');
      break;
  
    default:
      break;
  }

  switch (LevelPercentFactor) {
    case "vlow":
      LevelPercentFactor = "Very low level of knowledge"
      $('.factorgeneral .vlow').css('display','flex');
      break;
    case "Llow":
      LevelPercentFactor = "Low level of knowledge"
      $('.factorgeneral .low').css('display','flex');
      break;
    case "Medium-Low":
      LevelPercentFactor = "Medium-Low level of knowledge"
      $('.factorgeneral .average').css('display','flex');
      break;
    case "Medium-High":
      LevelPercentFactor = "Medium-High level of knowledge"
      $('.factorgeneral .high').css('display','flex');
      break;
    case "Very-High":
      $('.factorgeneral .vhigh').css('display','flex');
      LevelPercentFactor = "Very High level of knowledge"
      break;
  
    default:
      break;
  }

//SmallTestSScore

var SScored = new List<CollectionSScoreSmall>(this.ObjSmallTestTable)
.Where(f=> f.RawScore ==  (PointsF1 + PointsF2 + PointsF3 + PointsF4 + PointsF5))
.Select(f=> f.Sscore).SingleOrDefault()


  this.ELEMENTS_DATA = [];
 this.ELEMENTS_DATA.push({ name:"FACTOR  1 Allowed food",
    NumberRight: PointsF1,
    NumberWrong : WPointsF1,
    DiffRW : (PointsF1 - WPointsF1),
    Sscore : ScoreFactor1.toString(),
    ScalePerc : "Not Allowed",
    description : LevelFactor1,
    percentilScale : PercentilF1.toString(),
    finalresult: LevelFactor1})

  
 this.ELEMENTS_DATA.push({name : "FACTOR 2 Forbidden foods, easy to recognize",
  NumberRight : PointsF2,
  NumberWrong : WPointsF2,
  DiffRW : (PointsF2 - WPointsF2),
  Sscore : ScoreFactor2.toString(),
  ScalePerc : "Not Allowed",
  description : LevelFactor2,
  percentilScale : PercentilF2.toString(),
  finalresult: LevelFactor2});


  this.ELEMENTS_DATA.push({name : "FACTOR 3 Forbidden foods",
  NumberRight : PointsF3,
  NumberWrong : WPointsF3,
  DiffRW : (PointsF3 - WPointsF3),
  Sscore : ScoreFactor3.toString(),
  ScalePerc : "Not Allowed",
  description : LevelFactor3,
  percentilScale : PercentilF3.toString(),
  finalresult: LevelFactor3});

  
  this.ELEMENTS_DATA.push({name : "GENERAL KNOWLEDGE PKU DIET",
  NumberRight : (PointsF1 + PointsF2 + PointsF3 + PointsF4 + PointsF5 ),
  NumberWrong : (WPointsF1 + WPointsF2 + WPointsF3 + WPointsF4 + WPointsF5),
  DiffRW : ((PointsF1 - WPointsF1) + (PointsF2 - WPointsF2) + (PointsF3 - WPointsF3) + (PointsF4 - WPointsF4) + (PointsF5 - WPointsF5)),
  Sscore : SScored.toString(),
  ScalePerc : PercentFactor,
  description : LevelPercentFactor,
  percentilScale : PercentFactor,
  finalresult:LevelPercentFactor})

  var currentYear :  number = new Date().getFullYear();
  var today = new Date().toLocaleDateString();
  var GenDataResults = this.GetLocalStorage('DataUser',true);
  var Age = currentYear - parseInt(GenDataResults.birthday)
  
  var TypePKU = GenDataResults.PKU;

switch (parseInt(TypePKU)) {
  case 1:
    $("#defaultresult5").attr('checked', 'checked');
    break;
  case 2:
    $("#defaultresult6").attr('checked', 'checked');
    break;
  case 3:
    $("#defaultresult7").attr('checked', 'checked');
    break;

  default:
    break;
}
  $('#defaultresult1').val(Age);
  $('#defaultresult2').val(GenDataResults.gender);
  $('#defaultresult3').val(today);
  $('#defaultresult1').attr('readonly', true);
  $('#defaultresult2').attr('readonly', true);
  $('#defaultresult3').attr('readonly', true);
  $("#defaultresult5").attr('disabled', true);
  $("#defaultresult6").attr('disabled', true);
  $("#defaultresult7").attr('disabled', true);
this.RawScore = RawScorePercent;
this.PercentTotal = parseInt(PercentFactor);

this.ChartCalculate(this.RawScore , this.PercentTotal);
this.ChartCalculateFactores(PointsF1,PointsF2,PointsF3,PercentilF1,PercentilF2,PercentilF3)

  return this.ELEMENTS_DATA;
    }

/**Results Admin/Nutrisionist */
ProcessAdminResult(answerxfood: Array<Answer>) {
  return new Promise ((resolve, reject)=> {
    try {
      var data = this.GetLocalStorage("Answers",true);
      this.SaveAnswer(data);
      this.AnswerF1 = new List<Answer>(answerxfood)
      .Where(f=>f.factor == 1)
      .Count();
    
      this.AnswerF2 = new List<Answer>(answerxfood)
      .Where(f=>f.factor == 2)
      .Count();
      
      this.AnswerF3 = new List<Answer>(answerxfood)
      .Where(f=>f.factor == 3)
      .Count();
    
      //Right Answer
    
     var PointsF1 = new List<Answer>(answerxfood)
      .Where(f=>f.factor == 1 && f.allow == f.answer)
      .Count();
    
      var PointsF2 = new List<Answer>(answerxfood)
      .Where(f=>f.factor == 2 && f.allow == f.answer)
      .Count();
      
      var PointsF3 = new List<Answer>(answerxfood)
      .Where(f=>f.factor == 3 && f.allow == f.answer)
      .Count();
    
      var PointsF4 = 0;
    
      var PointsF5 = 0;
    //Wrong Answer
      var WPointsF1 = new List<Answer>(answerxfood)
      .Where(f=>f.factor == 1 && f.allow != f.answer)
      .Count();
    
      var WPointsF2 = new List<Answer>(answerxfood)
      .Where(f=>f.factor == 2 && f.allow != f.answer)
      .Count();
      
      var WPointsF3 = new List<Answer>(answerxfood)
      .Where(f=>f.factor == 3 && f.allow != f.answer)
      .Count();
    
      var WPointsF4 = 0;
    
      var WPointsF5 = 0;
      
      console.log("Total POINTS FACTOR 1: ", PointsF1);
      console.log("Total POINTS FACTOR 2: ", PointsF2);
      console.log("Total POINTS FACTOR 3: ", PointsF3);
      
      console.log("this.AnswerF1 -> ", this.AnswerF1);
      console.log("this.AnswerF2 -> ", this.AnswerF2);
      console.log("this.AnswerF3 -> ", this.AnswerF3);
    
    
      var ScoreFactor1 = new List<CollectionScore>(this.ObjScoreTable)
      .Where(f=> f.score == PointsF1.toString() && f.factor == "1")
      .Select(f=> f.value).SingleOrDefault()
    
      var LevelFactor1 = new List<CollectionScore>(this.ObjScoreTable)
      .Where(f=> f.score == PointsF1.toString() && f.factor == "1")
      .Select(f=> f.level).SingleOrDefault()
      
      var ScoreFactor2 = new List<CollectionScore>(this.ObjScoreTable)
      .Where(f=> f.score == PointsF2.toString() && f.factor == "2")
      .Select(f=> f.value).SingleOrDefault()
    
      var LevelFactor2 = new List<CollectionScore>(this.ObjScoreTable)
      .Where(f=> f.score == PointsF2.toString() && f.factor == "2")
      .Select(f=> f.level).SingleOrDefault()
    
      var ScoreFactor3 = new List<CollectionScore>(this.ObjScoreTable)
      .Where(f=> f.score == PointsF3.toString() && f.factor == "3")
      .Select(f=> f.value).SingleOrDefault()
    
      var LevelFactor3 = new List<CollectionScore>(this.ObjScoreTable)
      .Where(f=> f.score == PointsF3.toString() && f.factor == "3")
      .Select(f=> f.level).SingleOrDefault()
    
      console.log("LevelFactor1,LevelFactor2 ,LevelFactor3 => ", LevelFactor1,LevelFactor2 ,LevelFactor3);
    
      switch (LevelFactor1) {
        case "vlow":
          LevelFactor1 = "Very low level of knowledge"
          break;
        case "Llow":
          LevelFactor1 = "Low level of knowledge"
          break;
        // case "Medium-Low":
        //   LevelFactor1 = "Medium-Low level of knowledge"
        //   break;
        case "Medium":
          LevelFactor1 = "Medium level of knowledge"
          break;
        case "High":
          LevelFactor1 = "High level of knowledge"
          break;
      
        default:
          break;
      }
    
      switch (LevelFactor2) {
        case "vlow":
          LevelFactor2 = "Very low level of knowledge"
          break;
        case "Llow":
          LevelFactor2 = "Low level of knowledge"
          break;
        // case "Medium-Low":
        //   LevelFactor2 = "Medium-Low level of knowledge"
        //   break;
        case "Medium":
          LevelFactor2 = "Medium level of knowledge"
          break;
        case "High":
          LevelFactor2 = "High level of knowledge"
          break;
      
        default:
          break;
      }
    
      switch (LevelFactor3) {
        case "vlow":
          LevelFactor3 = "Very low level of knowledge"
          break;
        case "Llow":
          LevelFactor3 = "Low level of knowledge"
          break;
        // case "Medium":
        //   LevelFactor3 = "Medium-Low level of knowledge"
        //   break;
        case "Medium":
          LevelFactor3 = "Medium level of knowledge"
          break;
        case "High":
          LevelFactor3 = "High level of knowledge"
          break;
      
        default:
          break;
      }
    
    //SmallTestSScore
    
    var SScore = new List<CollectionSScoreSmall>(this.ObjSmallTestTable)
    .Where(f=> f.RawScore ==  (PointsF3 + PointsF2 + PointsF1))
    .Select(f=> f.Sscore).SingleOrDefault()
    
    var SScoreLevel = new List<CollectionSScoreSmall>(this.ObjSmallTestTable)
    .Where(f=> f.RawScore == (PointsF3 + PointsF2 + PointsF1))
    .Select(f=> f.level).SingleOrDefault()
    
    switch (SScoreLevel) {
      case "vlow":
        SScoreLevel = "Very low level of knowledge"
        break;
      case "low":
        SScoreLevel = "Low level of knowledge"
        break;
      case "Medium":
        SScoreLevel = "Medium-Low level of knowledge"
        break;
      case "MediumHigh":
        SScoreLevel = "Medium-High level of knowledge"
        break;
      case "VeryHigh":
        SScoreLevel = "Very High level of knowledge"
        break;
    
      default:
        break;
    }
    
    
      let _now = new Date().toLocaleString();
    
      var objDataResult = {
        PointsF1: PointsF1,
        PointsF2: PointsF2,
        PointsF3: PointsF3,
        PointsF4: PointsF4,
        PointsF5: PointsF5,
        ScoreF1: parseInt(ScoreFactor1),
        ScoreF2: parseInt(ScoreFactor2),
        ScoreF3: parseInt(ScoreFactor3),
        PercentsF1: 0,
        PercentsF2: 0,
        PercentsF3: 0,
        GRawScore: (PointsF1 + PointsF2 + PointsF3 + PointsF4 + PointsF4),
        GenPercentil: 0,
        date:_now
      }
      
      
      // this.SaveResulst(objDataResult);
      
    
      this.ELEMENTS_DATA = [];
     this.ELEMENTS_DATA.push({ name:"FACTOR  1 Allowed food",
        NumberRight: PointsF1,
        NumberWrong : WPointsF1,
        DiffRW : (PointsF1 - WPointsF1),
        Sscore : ScoreFactor1.toString(),
        ScalePerc : "Not Allowed",
        description : LevelFactor1,
        percentilScale : "-",
        finalresult: LevelFactor1})
    
      
     this.ELEMENTS_DATA.push({name : "FACTOR 2 Forbidden foods, easy to recognize",
      NumberRight : PointsF2,
      NumberWrong : WPointsF2,
      DiffRW : (PointsF2 - WPointsF2),
      Sscore : ScoreFactor2.toString(),
      ScalePerc : "Not Allowed",
      description : LevelFactor2,
      percentilScale : "-",
      finalresult: LevelFactor2});
    
    
      this.ELEMENTS_DATA.push({name : "FACTOR 3 Forbidden foods",
      NumberRight : PointsF3,
      NumberWrong : WPointsF3,
      DiffRW : (PointsF3 - WPointsF3),
      Sscore : ScoreFactor3.toString(),
      ScalePerc : "Not Allowed",
      description : LevelFactor3,
      percentilScale : "-",
      finalresult: LevelFactor3});
    
      
      this.ELEMENTS_DATA.push({name : "GENERAL KNOWLEDGE PKU DIET",
      NumberRight : (PointsF1 + PointsF2 + PointsF3 + PointsF4 + PointsF4),
      NumberWrong : (WPointsF1 + WPointsF2 + WPointsF3 + WPointsF4 + WPointsF5),
      DiffRW : ((PointsF1 - WPointsF1) + (PointsF2 - WPointsF2) + (PointsF3 - WPointsF3) + (PointsF4 - WPointsF4) + (PointsF5 - WPointsF5)),
      Sscore : SScore.toString(),
      ScalePerc : "Not Allowed",
      description : "No Allowed",
      percentilScale : "-",
      finalresult: SScoreLevel})
      var currentYear :  number = new Date().getFullYear();
      var today = new Date().toLocaleDateString();
      var GenDataResults = this.GetLocalStorage('DataUser',true);
      var Age = currentYear - parseInt(GenDataResults.birthday)
      
      var TypePKU = GenDataResults.PKU;
      switch (parseInt(TypePKU)) {
        case 1:
          $("#defaultresult5-pat").attr('checked', 'checked');
          break;
        case 2:
          $("#defaultresult6-pat").attr('checked', 'checked');
          break;
        case 3:
          console.log("data radio checked automatic")
          $("#defaultresult7-pat").attr('checked', 'checked');
          break;
      
        default:
          break;
      }
      
    
      $('#defaultresult1-pat').val(Age);
      $('#defaultresult2-pat').val(GenDataResults.gender);
      $('#defaultresult3-pat').val(today);
      $('#defaultresult1-pat').attr('readonly', true);
      $('#defaultresult2-pat').attr('readonly', true);
      $('#defaultresult3-pat').attr('readonly', true);
      $("#defaultresult5-pat").attr('disabled', true);
      $("#defaultresult6-pat").attr('disabled', true);
      $("#defaultresult7-pat").attr('disabled', true);
      resolve(this.ELEMENTS_DATA);
    } catch (error) {
      console.log("promise process error patient: ", error);
      reject(false);
    }
    
  }).catch((err)=> {
    console.log("ERROR PROCESS RESULTS PATIENT: ", err);   
  })
  
  }
  
  ProcessResultAdminExtend(answerxfood: Array<Answer>) {
    return new Promise((resolve, reject)=> {
      try {
        var data = this.GetLocalStorage("Answers",true);
        this.SaveAnswer(data);
        this.AnswerF1 = new List<Answer>(answerxfood)
        .Where(f=>f.factor == 1)
        .Count();
      
        this.AnswerF2 = new List<Answer>(answerxfood)
        .Where(f=>f.factor == 2)
        .Count();
        
        this.AnswerF3 = new List<Answer>(answerxfood)
        .Where(f=>f.factor == 3)
        .Count();
      
        this.AnswerF4 = new List<Answer>(answerxfood)
        .Where(f=>f.factor == 4)
        .Count();
      
        this.AnswerF5 = new List<Answer>(answerxfood)
        .Where(f=>f.factor == 5)
        .Count();
      
        //Right Answer
      
       var PointsF1 = new List<Answer>(answerxfood)
        .Where(f=>f.factor == 1 && f.allow == f.answer)
        .Count();
      
        var PointsF2 = new List<Answer>(answerxfood)
        .Where(f=>f.factor == 2 && f.allow == f.answer)
        .Count();
        
        var PointsF3 = new List<Answer>(answerxfood)
        .Where(f=>f.factor == 3 && f.allow == f.answer)
        .Count();
      
        var PointsF4 = new List<Answer>(answerxfood)
        .Where(f=>f.factor == 4 && f.allow == f.answer)
        .Count();
      
        var PointsF5 = new List<Answer>(answerxfood)
        .Where(f=>f.factor == 5 && f.allow == f.answer)
        .Count();
      //Wrong Answer
        var WPointsF1 = new List<Answer>(answerxfood)
        .Where(f=>f.factor == 1 && f.allow != f.answer)
        .Count();
      
        var WPointsF2 = new List<Answer>(answerxfood)
        .Where(f=>f.factor == 2 && f.allow != f.answer)
        .Count();
        
        var WPointsF3 = new List<Answer>(answerxfood)
        .Where(f=>f.factor == 3 && f.allow != f.answer)
        .Count();
      
        var WPointsF4 = new List<Answer>(answerxfood)
        .Where(f=>f.factor == 4 && f.allow != f.answer)
        .Count();
      
        var WPointsF5 = new List<Answer>(answerxfood)
        .Where(f=>f.factor == 4 && f.allow != f.answer)
        .Count();
        
        console.log("Total POINTS FACTOR 1: ", PointsF1);
        console.log("Total POINTS FACTOR 2: ", PointsF2);
        console.log("Total POINTS FACTOR 3: ", PointsF3);
        console.log("Total POINTS FACTOR 4: ", PointsF4);
        console.log("Total POINTS FACTOR 5: ", PointsF5);
      
        
        console.log("this.AnswerF1 -> ", this.AnswerF1);
        console.log("this.AnswerF2 -> ", this.AnswerF2);
        console.log("this.AnswerF3 -> ", this.AnswerF3);
      
      
        var ScoreFactor1 = new List<CollectionScore>(this.ObjScoreTable)
        .Where(f=> f.score == PointsF1.toString() && f.factor == "1")
        .Select(f=> f.value).SingleOrDefault()
      
        var LevelFactor1 = new List<CollectionScore>(this.ObjScoreTable)
        .Where(f=> f.score == PointsF1.toString() && f.factor == "1")
        .Select(f=> f.level).SingleOrDefault()
        
        
        var ScoreFactor2 = new List<CollectionScore>(this.ObjScoreTable)
        .Where(f=> f.score == PointsF2.toString() && f.factor == "2")
        .Select(f=> f.value).SingleOrDefault()
      
        var LevelFactor2 = new List<CollectionScore>(this.ObjScoreTable)
        .Where(f=> f.score == PointsF2.toString() && f.factor == "2")
        .Select(f=> f.level).SingleOrDefault()
      
      
        var ScoreFactor3 = new List<CollectionScore>(this.ObjScoreTable)
        .Where(f=> f.score == PointsF3.toString() && f.factor == "3")
        .Select(f=> f.value).SingleOrDefault()
      
        var LevelFactor3 = new List<CollectionScore>(this.ObjScoreTable)
        .Where(f=> f.score == PointsF3.toString() && f.factor == "3")
        .Select(f=> f.level).SingleOrDefault()
      
      
        var PercentFactor;
        // if((PointsF1 + PointsF2 + PointsF3 + PointsF4 + PointsF5) > 73){
        //   PercentFactor = 100;
        // }else {
          PercentFactor = new List<CollectionPercentil>(this.ObjPercentilTable)
          .Where(f=>  f.score == (PointsF1 + PointsF2 + PointsF3 + PointsF4 + PointsF5).toString())
          .Select(f=> f.percent).SingleOrDefault()
          
          var RawScorePercent = PointsF1 + PointsF2 + PointsF3 + PointsF4 + PointsF5;
          
          if(PercentFactor == undefined){      
            RawScorePercent = RawScorePercent - 1;
            while (PercentFactor == undefined) {
      
                PercentFactor = new List<CollectionPercentil>(this.ObjPercentilTable)
                .Where(f=>  f.score == (RawScorePercent).toString())
                .Select(f=> f.percent).SingleOrDefault();
                if(PercentFactor == undefined)
                    RawScorePercent = RawScorePercent - 1;
            }
      
          }
      
        // }
        var LevelPercentFactor = new List<CollectionPercentil>(this.ObjPercentilTable)
        .Where(f=>  f.score == (PointsF1 + PointsF2 + PointsF3 + PointsF4 + PointsF5).toString())
        .Select(f=> f.level).SingleOrDefault()
      
        if(LevelPercentFactor == undefined){
          LevelPercentFactor = new List<CollectionPercentil>(this.ObjPercentilTable)
            .Where(f=>  f.score == (RawScorePercent).toString())
            .Select(f=> f.level).SingleOrDefault()
        }
      
      /**Percentil Factor1 Factor2 Factor3 */
      var PercentilF1 = new List<CollectionPercentilFactor>(this.ObjPercentilTableF1)
      .Where(f=>  f.RawScore == parseInt(PointsF1.toString()))
      .Select(f=> f.Percent).SingleOrDefault()
      
      var PercentilF2 = new List<CollectionPercentilFactor>(this.ObjPercentilTableF2)
      .Where(f=>  f.RawScore == parseInt(PointsF2.toString()))
      .Select(f=> f.Percent).SingleOrDefault()
      
      var PercentilF3 = new List<CollectionPercentilFactor>(this.ObjPercentilTableF3)
      .Where(f=>  f.RawScore == parseInt(PointsF3.toString()))
      .Select(f=> f.Percent).SingleOrDefault()
      
      let _now = new Date().toLocaleString();
      var objDataResult = {
        PointsF1: PointsF1,
        PointsF2: PointsF2,
        PointsF3: PointsF3,
        PointsF4: PointsF4,
        PointsF5: PointsF5,
        ScoreF1: parseInt(ScoreFactor1),
        ScoreF2: parseInt(ScoreFactor2),
        ScoreF3: parseInt(ScoreFactor3),
        PercentsF1: PercentilF1,
        PercentsF2: PercentilF2,
        PercentsF3: PercentilF3,
        GRawScore: RawScorePercent,
        GenPercentil: parseInt(PercentFactor),
        date:_now
      }
      
      // this.SaveResulst(objDataResult);
      
      /***********************************/
      console.log("PercentilF1,PercentilF2 ,PercentilF3 => ", PercentilF1,PercentilF2 ,PercentilF3);
        console.log("LevelFactor1,LevelFactor2 ,LevelFactor3 => ", LevelFactor1,LevelFactor2 ,LevelFactor3);
        
        console.log("PercentFactor, LevelPercentFactor, Total Points Raw G -> ", PercentFactor, LevelPercentFactor, (PointsF1 + PointsF2 + PointsF3 + PointsF4 + PointsF5));
      
        switch (LevelFactor1) {
          case "vlow":
            LevelFactor1 = "Very low level of knowledge"
            break;
          case "Llow":
            LevelFactor1 = "Low level of knowledge"
            break;
          // case "Medium-Low":
          //   LevelFactor1 = "Medium-Low level of knowledge"
          //   break;
          case "Medium":
            LevelFactor1 = "Medium level of knowledge"
            break;
          case "High":
            LevelFactor1 = "High level of knowledge"
            break;
      
          default:
            break;
        }
      
        switch (LevelFactor2) {
          case "vlow":
            LevelFactor2 = "Very low level of knowledge"
            break;
          case "Llow":
            LevelFactor2 = "Low level of knowledge"
            break;
          // case "Medium-Low":
          //   LevelFactor2 = "Medium-Low level of knowledge"
          //   break;
          case "Medium":
            LevelFactor2 = "Medium level of knowledge"
            break;
          case "High":
            LevelFactor2 = "High level of knowledge"
            break;
        
          default:
            break;
        }
      
        switch (LevelFactor3) {
          case "vlow":
            LevelFactor3 = "Very low level of knowledge"
            break;
          case "Llow":
            LevelFactor3 = "Low level of knowledge"
            break;
          // case "Medium-Low":
          //   LevelFactor3 = "Medium-Low level of knowledge"
          //   break;
          case "Medium":
            LevelFactor3 = "Medium level of knowledge"
            break;
          case "High":
            LevelFactor3 = "High level of knowledge"
            break;
        
          default:
            break;
        }
      
        switch (LevelPercentFactor) {
          case "vlow":
            LevelPercentFactor = "Very low level of knowledge"
            break;
          case "Llow":
            LevelPercentFactor = "Low level of knowledge"
            break;
          case "Medium-Low":
            LevelPercentFactor = "Medium-Low level of knowledge"
            break;
          case "Medium-High":
            LevelPercentFactor = "Medium-High level of knowledge"
            break;
          case "Very-High":
            LevelPercentFactor = "Very High level of knowledge"
            break;
        
          default:
            break;
        }
      
      //SmallTestSScore
      
      var SScored = new List<CollectionSScoreSmall>(this.ObjSmallTestTable)
      .Where(f=> f.RawScore ==  (PointsF1 + PointsF2 + PointsF3 + PointsF4 + PointsF5))
      .Select(f=> f.Sscore).SingleOrDefault()
      
      
        this.ELEMENTS_DATA = [];
       this.ELEMENTS_DATA.push({ name:"FACTOR  1 Allowed food",
          NumberRight: PointsF1,
          NumberWrong : WPointsF1,
          DiffRW : (PointsF1 - WPointsF1),
          Sscore : ScoreFactor1.toString(),
          ScalePerc : "Not Allowed",
          description : LevelFactor1,
          percentilScale : PercentilF1.toString(),
          finalresult: LevelFactor1})
      
        
       this.ELEMENTS_DATA.push({name : "FACTOR 2 Forbidden foods, easy to recognize",
        NumberRight : PointsF2,
        NumberWrong : WPointsF2,
        DiffRW : (PointsF2 - WPointsF2),
        Sscore : ScoreFactor2.toString(),
        ScalePerc : "Not Allowed",
        description : LevelFactor2,
        percentilScale : PercentilF2.toString(),
        finalresult: LevelFactor2});
      
      
        this.ELEMENTS_DATA.push({name : "FACTOR 3 Forbidden foods",
        NumberRight : PointsF3,
        NumberWrong : WPointsF3,
        DiffRW : (PointsF3 - WPointsF3),
        Sscore : ScoreFactor3.toString(),
        ScalePerc : "Not Allowed",
        description : LevelFactor3,
        percentilScale : PercentilF3.toString(),
        finalresult: LevelFactor3});
      
        
        this.ELEMENTS_DATA.push({name : "GENERAL KNOWLEDGE PKU DIET",
        NumberRight : (PointsF1 + PointsF2 + PointsF3 + PointsF4 + PointsF5 ),
        NumberWrong : (WPointsF1 + WPointsF2 + WPointsF3 + WPointsF4 + WPointsF5),
        DiffRW : ((PointsF1 - WPointsF1) + (PointsF2 - WPointsF2) + (PointsF3 - WPointsF3) + (PointsF4 - WPointsF4) + (PointsF5 - WPointsF5)),
        Sscore : SScored.toString(),
        ScalePerc : PercentFactor,
        description : LevelPercentFactor,
        percentilScale : PercentFactor,
        finalresult:LevelPercentFactor})
      
        var currentYear :  number = new Date().getFullYear();
        var today = new Date().toLocaleDateString();
        var GenDataResults = this.GetLocalStorage('DataUser',true);
        console.log("GenDataResults:: ", GenDataResults);
        var Age = currentYear - parseInt(GenDataResults.birthday)
        
        var TypePKU = GenDataResults.PKU;
      
      switch (parseInt(TypePKU)) {
        case 1:
          $("#defaultresult5-pat").attr('checked', 'checked');
          break;
        case 2:
          $("#defaultresult6-pat").attr('checked', 'checked');
          break;
        case 3:
          $("#defaultresult7-pat").attr('checked', 'checked');
          break;
      
        default:
          break;
      }
        $('#defaultresult1-pat').val(Age);
        $('#defaultresult2-pat').val(GenDataResults.gender);
        $('#defaultresult3-pat').val(today);
        $('#defaultresult1-pat').attr('readonly', true);
        $('#defaultresult2-pat').attr('readonly', true);
        $('#defaultresult3-pat').attr('readonly', true);
        $("#defaultresult5-pat").attr('disabled', true);
        $("#defaultresult6-pat").attr('disabled', true);
        $("#defaultresult7-pat").attr('disabled', true);
      this.RawScore = RawScorePercent;
      this.PercentTotal = parseInt(PercentFactor);
      
      this.ChartCalculateAdmin(this.RawScore , this.PercentTotal);
      this.ChartCalculateFactoresAdmin(PointsF1,PointsF2,PointsF3,PercentilF1,PercentilF2,PercentilF3)
      
        resolve(this.ELEMENTS_DATA);
      } catch (error) {
        console.log("ERROR AdminResultExt: ", error);
        reject(false);
      }
    })
    
      }
InitValues(){
  localStorage.removeItem("Answers");
  localStorage.removeItem("currentFood");
  localStorage.removeItem("DataUser");  
  this.AnswerxFood = [];
  this.DatosSujeto.typeTest = "";
  this.DatosSujeto.gender = "";
  this.DatosSujeto.birthday = "";
  this.DatosSujeto.region = 0;
  this.DatosSujeto.educationLevel= 0;
  this.DatosSujeto.ocupation = "";
  this.DatosSujeto.SizeTest= 0;
  this.DatosSujeto.Agree="";
}
toggleBarVisibility() {
  this.BarVisibilityChange.next(this.currentValueBar);
}

GetData(){ return new Promise((resolve, reject) => {
 this.FCollection.GetFoods().then((res)=> {
   try {
    this.ObjFoodShort = res;
    if(this.ObjFoodShort.length == 0){
      var element = document.getElementById("popup1");
      element.style.visibility = 'visible';
      element.style.opacity = '1';
    }
    if(!this.devenv){
      this.FCollection.Ofodds.unsubscribe();                
      // this.FCollection.FAuth.signOut(); 
    }
          if( this.ObjFoodShort.length > 0){     
            resolve( this.ObjFoodShort);
          }else {
            this.ObjFoodShort = [];
            reject( this.ObjFoodShort);
          }
   } catch (error) {
    if(this.ObjFoodShort.length > 0){
      var element = document.getElementById("popup1");
      element.style.visibility = 'visible';
      element.style.opacity = '1';
    }
          console.log("ERROR Serialize -> ", error);
   }
  
   

  }).catch(e => {
    console.log("catch error GS -> ", e);
  })
});
}

// GetCustomData (id) {
//   return new Promise((resolve, reject) => {
//       this.FCollection.GetCustomFoods().then((res)=> {
//         try {
//           console.log("inspect:");
//           console.log("this.ObjFoodShort> ", this.ObjFoodShort);
//           console.log("RES: ", res);
//           this.FCollection.Ofodds2.unsubscribe();
//           resolve(res);
        // this.ObjFoodShort = res;
        // if(this.ObjFoodShort.length == 0){
        //   var element = document.getElementById("popup1");
        //   element.style.visibility = 'visible';
        //   element.style.opacity = '1';
        // }
        // if(!this.devenv){
        //   this.FCollection.Ofodds.unsubscribe();                
        //   // this.FCollection.FAuth.signOut(); 
        // }
        //       if( this.ObjFoodShort.length > 0){     
        //         resolve( this.ObjFoodShort);
        //       }else {
        //         this.ObjFoodShort = [];
        //         reject( this.ObjFoodShort);
        //       }
//         } catch (error) {
//         if(this.ObjFoodShort.length > 0){
//           var element = document.getElementById("popup1");
//           element.style.visibility = 'visible';
//           element.style.opacity = '1';
//           reject(false);
//         }
//               console.log("ERROR Serialize -> ", error);
//         }
      
        
    
//       }).catch(e => {
//         console.log("catch error GS -> ", e);
//       })
//  });
// }

Progress_Val = new Observable((observer) => {
  if(this.currentValueBar){
    observer.next(this.currentValueBar);
  }else{
    return {
      unsubscribe(){ this.currentValueBar }
    }
  }
})


progress_Calculate(newValue,TopValue,percent, first) {
 setTimeout(()=> {
  //  console.log("first -> ", first);
  if(first == 1){
    console.log("Here Progress bar Init");
    $(".progress-left").css('left','0');
    $(".progress-right").css('right','0');

    $("#calcualtedValudProgress").attr('data-value',1);
  }
  $(".progress").each(function() {
    $(this).attr('data-value',percent);
    $("#lastfoodItem").html(`${TopValue} </div><span class="small text-gray">`);
    $("#currentfoodItem").text(newValue);
    $("#progressItemValue").html(`${percent}<sup class="small">%</sup>`);    
    var value = $(this).attr('data-value');
    var left = $(this).find('.progress-left .progress-bar');
    var right = $(this).find('.progress-right .progress-bar');
    if(first == 1) {
      right.css('left','-100%');
      left.css('left','100%');
      left.css('transform','rotate(0deg)');
    }
  //  console.log("value -> ", value);
    if (value > 0) {
      if (value <= 50) {
        right.css('transform', 'rotate(' + percentageToDegrees(value) + 'deg)')
      } else {
        right.css('transform', 'rotate(180deg)')
        left.css('transform', 'rotate(' + percentageToDegrees(value - 50) + 'deg)')
      }
    }

  })

 },1000)
 
  function percentageToDegrees(percentage) {

    return percentage / 100 * 360

  }

};

ChartCalculate(RawScore, Percentil: number){
  var barOptions_stacked = {
    responsive: false,
    tooltips: {
        enabled: true
    },
    hover :{
        animationDuration:0
    },
    scales: {
        xAxes: [{
            ticks: {
                beginAtZero:true,
                fontFamily: "'Open Sans Bold', sans-serif",
                fontSize:16,
                suggestedMin: 0,
                suggestedMax: 100
            },
            scaleLabel:{
                display:false
            },
            gridLines: {
            }, 
            stacked: true
        }],
        yAxes: [{
            gridLines: {
                display:false,
                color: "#000",
                zeroLineColor: "#fff",
                zeroLineWidth: 0
            },
            ticks: {
                fontFamily: "'Open Sans Bold', sans-serif",
                fontSize:16
            },
            stacked: true
        }]
    },
    legend:{
        display:true

    },
    animation: {
        onComplete: function () {
            var chartInstance = this.chart;
            var ctx = chartInstance.ctx;
            ctx.textAlign = "center";
            ctx.font = "1.2vw Open Sans";
            ctx.fillStyle = "#FFFFFF";

            Chart.helpers.each(this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                Chart.helpers.each(meta.data.forEach(function (bar, index) {
                   var data = dataset.data[index];
                    if(i==0){
                        //ctx.fillText(data, 50, bar._model.y+4);
                    } else {
                        //ctx.fillText(data, bar._model.x-25, bar._model.y+4);
                    }
                }),this)
            }),this);
        }
    },
    pointLabelFontFamily : "Quadon Extra Bold",
    scaleFontFamily : "Quadon Extra Bold",
};
console.log("CharCalculate: ", RawScore, Percentil);

var myChart = new Chart('Chart1', {
    type: 'horizontalBar',
    data: {
        labels: [""],        
        datasets: [{
            label:"GENERAL KNOWLEDGE PKU DIET",
            data: [Percentil],
            backgroundColor: "rgba(255,99,132,0.2)",
            hoverBackgroundColor: "#1E66AD"
        }]
    },

    options: barOptions_stacked,
});

$("#text-results").text(`Raw Score = ${RawScore}`);

}

ChartCalculateFactores(RawScoreF1,RawScoreF2,RawScoreF3, Percentil1: number = 0, Percentil2: number = 0, Percentil3: number = 0){
  var barOptions_stacked = {
    responsive: false,
    tooltips: {
        enabled: true
    },
    hover :{
        animationDuration:0
    },
    scales: {
        xAxes: [{
            ticks: {
                beginAtZero:true,
                fontFamily: "'Open Sans Bold', sans-serif",
                fontSize:16,
                suggestedMin: 0,
                suggestedMax: 100
            },
            scaleLabel:{
                display:false
            },
            gridLines: {
            }, 
            stacked: true
        }],
        yAxes: [{
            gridLines: {
                display:false,
                color: "#000",
                zeroLineColor: "#fff",
                zeroLineWidth: 0
            },
            ticks: {
                fontFamily: "'Open Sans Bold', sans-serif",
                fontSize:16
            },
            stacked: true
        }]
    },
    legend:{
        display:true

    },
    animation: {
        onComplete: function () {
            var chartInstance = this.chart;
            var ctx = chartInstance.ctx;
            ctx.textAlign = "center";
            ctx.font = "1.2vw Open Sans";
            ctx.fillStyle = "#FFFFFF";

            Chart.helpers.each(this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                Chart.helpers.each(meta.data.forEach(function (bar, index) {
                   var data = dataset.data[index];
                    if(i==0){
                        //ctx.fillText(data, 50, bar._model.y+4);
                    } else {
                        //ctx.fillText(data, bar._model.x-25, bar._model.y+4);
                    }
                }),this)
            }),this);
        }
    },
    pointLabelFontFamily : "Quadon Extra Bold",
    scaleFontFamily : "Quadon Extra Bold",
};
console.log("ChartCalculateFactores> RawScoreF1,RawScoreF2,RawScoreF3, Percentil1, Percentil2, Percentil3 ", RawScoreF1,RawScoreF2,RawScoreF3, Percentil1, Percentil2, Percentil3);
var barOptions_stacked1 = {
  responsive: false,
  tooltips: {
      enabled: true
  },
  hover :{
      animationDuration:0
  },
  scales: {
      xAxes: [{
          ticks: {
              beginAtZero:true,
              fontFamily: "'Open Sans Bold', sans-serif",
              fontSize:16,
              suggestedMin: 0,
              suggestedMax: 100
          },
          scaleLabel:{
              display:false
          },
          gridLines: {
          }, 
          stacked: true
      }],
      yAxes: [{
          gridLines: {
              display:false,
              color: "#000",
              zeroLineColor: "#fff",
              zeroLineWidth: 0
          },
          ticks: {
              fontFamily: "'Open Sans Bold', sans-serif",
              fontSize:16
          },
          stacked: true
      }]
  },
  legend:{
      display:true

  },
  animation: {
      onComplete: function () {
          var chartInstance = this.chart;
          var ctx = chartInstance.ctx;
          ctx.textAlign = "center";
          ctx.font = "1.2vw Open Sans";
          ctx.fillStyle = "#FFFFFF";

          Chart.helpers.each(this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              Chart.helpers.each(meta.data.forEach(function (bar, index) {
                 var data = dataset.data[index];
                  if(i==0){
                      //ctx.fillText(data, 50, bar._model.y+4);
                  } else {
                      //ctx.fillText(data, bar._model.x-25, bar._model.y+4);
                  }
              }),this)
          }),this);
      }
  },
  pointLabelFontFamily : "Quadon Extra Bold",
  scaleFontFamily : "Quadon Extra Bold",
};

var barOptions_stacked2 = {
  responsive: false,
  tooltips: {
      enabled: true
  },
  hover :{
      animationDuration:0
  },
  scales: {
      xAxes: [{
          ticks: {
              beginAtZero:true,
              fontFamily: "'Open Sans Bold', sans-serif",
              fontSize:16,
              suggestedMin: 0,
              suggestedMax: 85
          },
          scaleLabel:{
              display:false
          },
          gridLines: {
          }, 
          stacked: true
      }],
      yAxes: [{
          gridLines: {
              display:false,
              color: "#000",
              zeroLineColor: "#fff",
              zeroLineWidth: 0
          },
          ticks: {
              fontFamily: "'Open Sans Bold', sans-serif",
              fontSize:16
          },
          stacked: true
      }]
  },
  legend:{
      display:true

  },
  animation: {
      onComplete: function () {
          var chartInstance = this.chart;
          var ctx = chartInstance.ctx;
          ctx.textAlign = "center";
          ctx.font = "1.2vw Open Sans";
          ctx.fillStyle = "#FFFFFF";

          Chart.helpers.each(this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              Chart.helpers.each(meta.data.forEach(function (bar, index) {
                 var data = dataset.data[index];
                  if(i==0){
                      //ctx.fillText(data, 50, bar._model.y+4);
                  } else {
                      //ctx.fillText(data, bar._model.x-25, bar._model.y+4);
                  }
              }),this)
          }),this);
      }
  },
  pointLabelFontFamily : "Quadon Extra Bold",
  scaleFontFamily : "Quadon Extra Bold",
};

var barOptions_stacked3 = {
  responsive: false,
  tooltips: {
      enabled: true
  },
  hover :{
      animationDuration:0
  },
  scales: {
      xAxes: [{
          ticks: {
              beginAtZero:true,
              fontFamily: "'Open Sans Bold', sans-serif",
              fontSize:16,
              suggestedMin: 0,
              suggestedMax: 90
          },
          scaleLabel:{
              display:false
          },
          gridLines: {
          }, 
          stacked: true
      }],
      yAxes: [{
          gridLines: {
              display:false,
              color: "#000",
              zeroLineColor: "#fff",
              zeroLineWidth: 0
          },
          ticks: {
              fontFamily: "'Open Sans Bold', sans-serif",
              fontSize:16
          },
          stacked: true
      }]
  },
  legend:{
      display:true

  },
  animation: {
      onComplete: function () {
          var chartInstance = this.chart;
          var ctx = chartInstance.ctx;
          ctx.textAlign = "center";
          ctx.font = "1.2vw Open Sans";
          ctx.fillStyle = "#FFFFFF";

          Chart.helpers.each(this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              Chart.helpers.each(meta.data.forEach(function (bar, index) {
                 var data = dataset.data[index];
                  if(i==0){
                      //ctx.fillText(data, 50, bar._model.y+4);
                  } else {
                      //ctx.fillText(data, bar._model.x-25, bar._model.y+4);
                  }
              }),this)
          }),this);
      }
  },
  pointLabelFontFamily : "Quadon Extra Bold",
  scaleFontFamily : "Quadon Extra Bold",
};
var myChart = new Chart("Chart2", {
    type: 'horizontalBar',
    data: {
        labels: [["FACTOR 1","Allowed food"]],        
        datasets: [{
            label:`S Score ${Percentil1}`,
            data: [Percentil1],
            backgroundColor: "rgba(255,99,132,0.2)",
            hoverBackgroundColor: "#1E66AD"
        }]
    },

    options: barOptions_stacked1,
});

var myChart = new Chart("Chart3", {
  type: 'horizontalBar',
  data: {
      labels: [["FACTOR 2","Forbidden foods", "easy to recognize"]],        
      datasets: [{
          label:`S Score ${Percentil2}`,
          data: [ Percentil2],
          backgroundColor: "rgba(255,99,132,0.2)",
          hoverBackgroundColor: "#1E66AD"
      }]
  },

  options: barOptions_stacked2,
});


var myChart = new Chart("Chart4", {
  type: 'horizontalBar',
  data: {
      labels: [["FACTOR 3","Forbidden foods","NOT easy to recognize"]],        
      datasets: [{
          label:`S Score ${Percentil3}`,
          data: [Percentil3],
          backgroundColor: "rgba(255,99,132,0.2)",
          hoverBackgroundColor: "#1E66AD"
      }]
  },

  options: barOptions_stacked3,
});

 $("#text-results2").text(`Raw Score = ${RawScoreF1}`);
 $("#text-results3").text(`Raw Score = ${RawScoreF2}`);
 $("#text-results4").text(`Raw Score = ${RawScoreF3}`);

}


ChartCalculateAdmin(RawScore, Percentil: number){
  var barOptions_stacked = {
    responsive: false,
    tooltips: {
        enabled: true
    },
    hover :{
        animationDuration:0
    },
    scales: {
        xAxes: [{
            ticks: {
                beginAtZero:true,
                fontFamily: "'Open Sans Bold', sans-serif",
                fontSize:16,
                suggestedMin: 0,
                suggestedMax: 100
            },
            scaleLabel:{
                display:false
            },
            gridLines: {
            }, 
            stacked: true
        }],
        yAxes: [{
            gridLines: {
                display:false,
                color: "#000",
                zeroLineColor: "#fff",
                zeroLineWidth: 0
            },
            ticks: {
                fontFamily: "'Open Sans Bold', sans-serif",
                fontSize:16
            },
            stacked: true
        }]
    },
    legend:{
        display:true

    },
    animation: {
        onComplete: function () {
            var chartInstance = this.chart;
            var ctx = chartInstance.ctx;
            ctx.textAlign = "center";
            ctx.font = "1.2vw Open Sans";
            ctx.fillStyle = "#FFFFFF";

            Chart.helpers.each(this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                Chart.helpers.each(meta.data.forEach(function (bar, index) {
                   var data = dataset.data[index];
                    if(i==0){
                        //ctx.fillText(data, 50, bar._model.y+4);
                    } else {
                        //ctx.fillText(data, bar._model.x-25, bar._model.y+4);
                    }
                }),this)
            }),this);
        }
    },
    pointLabelFontFamily : "Quadon Extra Bold",
    scaleFontFamily : "Quadon Extra Bold",
};
var myChart0 = new Chart('Chart1', {
    type: 'horizontalBar',
    data: {
        labels: [""],        
        datasets: [{
            label:"GENERAL KNOWLEDGE PKU DIET",
            data: [Percentil],
            backgroundColor: "rgba(255,99,132,0.2)",
            hoverBackgroundColor: "#1E66AD"
        }]
    },

    options: barOptions_stacked,
});

$("#text-results-pat").text(`Raw Score = ${RawScore}`);

}

ChartCalculateFactoresAdmin(RawScoreF1,RawScoreF2,RawScoreF3, Percentil1: number = 0, Percentil2: number = 0, Percentil3: number = 0){
  var barOptions_stacked = {
    responsive: false,
    tooltips: {
        enabled: true
    },
    hover :{
        animationDuration:0
    },
    scales: {
        xAxes: [{
            ticks: {
                beginAtZero:true,
                fontFamily: "'Open Sans Bold', sans-serif",
                fontSize:16,
                suggestedMin: 0,
                suggestedMax: 100
            },
            scaleLabel:{
                display:false
            },
            gridLines: {
            }, 
            stacked: true
        }],
        yAxes: [{
            gridLines: {
                display:false,
                color: "#000",
                zeroLineColor: "#fff",
                zeroLineWidth: 0
            },
            ticks: {
                fontFamily: "'Open Sans Bold', sans-serif",
                fontSize:16
            },
            stacked: true
        }]
    },
    legend:{
        display:true

    },
    animation: {
        onComplete: function () {
            var chartInstance = this.chart;
            var ctx = chartInstance.ctx;
            ctx.textAlign = "center";
            ctx.font = "1.2vw Open Sans";
            ctx.fillStyle = "#FFFFFF";

            Chart.helpers.each(this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                Chart.helpers.each(meta.data.forEach(function (bar, index) {
                   var data = dataset.data[index];
                    if(i==0){
                        //ctx.fillText(data, 50, bar._model.y+4);
                    } else {
                        //ctx.fillText(data, bar._model.x-25, bar._model.y+4);
                    }
                }),this)
            }),this);
        }
    },
    pointLabelFontFamily : "Quadon Extra Bold",
    scaleFontFamily : "Quadon Extra Bold",
};

var barOptions_stacked1 = {
  responsive: false,
  tooltips: {
      enabled: true
  },
  hover :{
      animationDuration:0
  },
  scales: {
      xAxes: [{
          ticks: {
              beginAtZero:true,
              fontFamily: "'Open Sans Bold', sans-serif",
              fontSize:16,
              suggestedMin: 0,
              suggestedMax: 100
          },
          scaleLabel:{
              display:false
          },
          gridLines: {
          }, 
          stacked: true
      }],
      yAxes: [{
          gridLines: {
              display:false,
              color: "#000",
              zeroLineColor: "#fff",
              zeroLineWidth: 0
          },
          ticks: {
              fontFamily: "'Open Sans Bold', sans-serif",
              fontSize:16
          },
          stacked: true
      }]
  },
  legend:{
      display:true

  },
  animation: {
      onComplete: function () {
          var chartInstance = this.chart;
          var ctx = chartInstance.ctx;
          ctx.textAlign = "center";
          ctx.font = "1.2vw Open Sans";
          ctx.fillStyle = "#FFFFFF";

          Chart.helpers.each(this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              Chart.helpers.each(meta.data.forEach(function (bar, index) {
                 var data = dataset.data[index];
                  if(i==0){
                      //ctx.fillText(data, 50, bar._model.y+4);
                  } else {
                      //ctx.fillText(data, bar._model.x-25, bar._model.y+4);
                  }
              }),this)
          }),this);
      }
  },
  pointLabelFontFamily : "Quadon Extra Bold",
  scaleFontFamily : "Quadon Extra Bold",
};

var barOptions_stacked2 = {
  responsive: false,
  tooltips: {
      enabled: true
  },
  hover :{
      animationDuration:0
  },
  scales: {
      xAxes: [{
          ticks: {
              beginAtZero:true,
              fontFamily: "'Open Sans Bold', sans-serif",
              fontSize:16,
              suggestedMin: 0,
              suggestedMax: 85
          },
          scaleLabel:{
              display:false
          },
          gridLines: {
          }, 
          stacked: true
      }],
      yAxes: [{
          gridLines: {
              display:false,
              color: "#000",
              zeroLineColor: "#fff",
              zeroLineWidth: 0
          },
          ticks: {
              fontFamily: "'Open Sans Bold', sans-serif",
              fontSize:16
          },
          stacked: true
      }]
  },
  legend:{
      display:true

  },
  animation: {
      onComplete: function () {
          var chartInstance = this.chart;
          var ctx = chartInstance.ctx;
          ctx.textAlign = "center";
          ctx.font = "1.2vw Open Sans";
          ctx.fillStyle = "#FFFFFF";

          Chart.helpers.each(this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              Chart.helpers.each(meta.data.forEach(function (bar, index) {
                 var data = dataset.data[index];
                  if(i==0){
                      //ctx.fillText(data, 50, bar._model.y+4);
                  } else {
                      //ctx.fillText(data, bar._model.x-25, bar._model.y+4);
                  }
              }),this)
          }),this);
      }
  },
  pointLabelFontFamily : "Quadon Extra Bold",
  scaleFontFamily : "Quadon Extra Bold",
};

var barOptions_stacked3 = {
  responsive: false,
  tooltips: {
      enabled: true
  },
  hover :{
      animationDuration:0
  },
  scales: {
      xAxes: [{
          ticks: {
              beginAtZero:true,
              fontFamily: "'Open Sans Bold', sans-serif",
              fontSize:16,
              suggestedMin: 0,
              suggestedMax: 90
          },
          scaleLabel:{
              display:false
          },
          gridLines: {
          }, 
          stacked: true
      }],
      yAxes: [{
          gridLines: {
              display:false,
              color: "#000",
              zeroLineColor: "#fff",
              zeroLineWidth: 0
          },
          ticks: {
              fontFamily: "'Open Sans Bold', sans-serif",
              fontSize:16
          },
          stacked: true
      }]
  },
  legend:{
      display:true

  },
  animation: {
      onComplete: function () {
          var chartInstance = this.chart;
          var ctx = chartInstance.ctx;
          ctx.textAlign = "center";
          ctx.font = "1.2vw Open Sans";
          ctx.fillStyle = "#FFFFFF";

          Chart.helpers.each(this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              Chart.helpers.each(meta.data.forEach(function (bar, index) {
                 var data = dataset.data[index];
                  if(i==0){
                      //ctx.fillText(data, 50, bar._model.y+4);
                  } else {
                      //ctx.fillText(data, bar._model.x-25, bar._model.y+4);
                  }
              }),this)
          }),this);
      }
  },
  pointLabelFontFamily : "Quadon Extra Bold",
  scaleFontFamily : "Quadon Extra Bold",
};
var myChart1 = new Chart("Chart2", {
    type: 'horizontalBar',
    data: {
        labels: [["FACTOR 1","Allowed food"]],        
        datasets: [{
            label:`S Score ${Percentil1}`,
            data: [Percentil1],
            backgroundColor: "rgba(255,99,132,0.2)",
            hoverBackgroundColor: "#1E66AD"
        }]
    },

    options: barOptions_stacked1,
});

var myChart2 = new Chart("Chart3", {
  type: 'horizontalBar',
  data: {
      labels: [["FACTOR 2","Forbidden foods", "easy to recognize"]],        
      datasets: [{
          label:`S Score ${Percentil2}`,
          data: [ Percentil2],
          backgroundColor: "rgba(255,99,132,0.2)",
          hoverBackgroundColor: "#1E66AD"
      }]
  },

  options: barOptions_stacked2,
});


var myChart3 = new Chart("Chart4", {
  type: 'horizontalBar',
  data: {
      labels: [["FACTOR 3","Forbidden foods","NOT easy to recognize"]],        
      datasets: [{
          label:`S Score ${Percentil3}`,
          data: [Percentil3],
          backgroundColor: "rgba(255,99,132,0.2)",
          hoverBackgroundColor: "#1E66AD"
      }]
  },

  options: barOptions_stacked3,
});

 $("#text-results2-pat").text(`Raw Score = ${RawScoreF1}`);
 $("#text-results3-pat").text(`Raw Score = ${RawScoreF2}`);
 $("#text-results4-pat").text(`Raw Score = ${RawScoreF3}`);

}


saveUser(profile) {
  var resultsprofile = this.FCollection.SaveProfile(profile);
  console.log("SaveProfile => ", resultsprofile);
  // this.FCollection.FAuth.signOut();
}
SaveAnswer(data, id = this.DatosSujeto.id){
  var saveanswer = this.FCollection.SaveAnswer(data, id);
  console.log("saveAnswer => ", saveanswer);
  // this.FCollection.FAuth.signOut();
}
SaveResulst(dataResult, id = this.DatosSujeto.id){
  var saveresults = this.FCollection.SaveResults(dataResult, id);
  console.log("saveresults => ", saveresults);
  // this.FCollection.FAuth.signOut();
}

}



export class Answer{
  foodNumber: number;
  answer: boolean;
  food: number;
  factor: number;
  allow: boolean;
}

export class ResultElement {
  name: string;
  NumberRight: number;
  NumberWrong: number;
  DiffRW: number;
  Sscore: string;
  ScalePerc: string;
  description: string;
  percentilScale: string;
  finalresult: string;
}
export class Foods{
  allow: boolean;
  class: string;
  factor: number;
  id: number;
  name: string;
  type: string;
}