
<div class="container rounded  mt-5">
    <div class="row">
        <mat-card style="margin-top: 5%;overflow-x: scroll;">
            <mat-card-header>
                <mat-card-title>Add Food</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="col-md-12">
                    <div class="p-3 py-5">                        
                        <div class="row mt-2">
                            <div class="col-md-6 form-group">
                                <label for="foodname-new">Food Name</label>
                                <input type="text" class="form-control" placeholder="First Name" id="foodname-new" value="">
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="classifi-new">Classification</label>
                                <input type="text" class="form-control" value="custom" placeholder="" id="classifi-new" disabled>
                            </div>
                        </div>                       
                        <div class="row mt-3">
                            <div class="col-md-6 form-group">
                                <select class="form-select food-new-select" (change)="selectChange()">
                                    <option selected value="0">Select type of Food</option>
                                    <option value="1">Allowed Food</option>
                                    <option value="2">Forbidden Food</option>
                                    <!-- <option value="3">Nutritionist</option> -->
                                  </select>                                
                            </div>
                        </div>                       
                        <div class="mt-5 text-right"><button class="btn btn-primary profile-button" type="button" (click)="SaveFood()">Save Food</button></div>
                    </div>
                </div>
             </mat-card-content>
        </mat-card>
    </div>
    <div class="row">
        <div class="col-6" style="padding: 5%;
        position: relative;">
        <div class="form-group">
            <button class="button-list"  mat-button (click)="ReloadList()" style="left: auto; right: 1vw; top: 7vw">Reload Foods</button>
        </div>
           
        </div>
        <mat-card style="margin-top: 5%;overflow-x: scroll;">
            <mat-card-header>
                <mat-card-title>Food List</mat-card-title>
                <mat-card-subtitle>Data</mat-card-subtitle>
              </mat-card-header>
            <mat-card-content>
                <mat-form-field appearance="standard">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Eggs" #input>
                </mat-form-field>
                <div class="mat-elevation-z8">
                <table mat-table   [dataSource]="dataSource" matSort matTableExporter  #exporter="matTableExporter">
            
                <ng-container matColumnDef="Code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header > Code </th>
                    <td mat-cell *matCellDef="let row" > {{row.id}} </td>
                </ng-container>
                <ng-container matColumnDef="Food Name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header > Food Name </th>
                    <td mat-cell *matCellDef="let row" > {{row.name}} </td>
                </ng-container>
                <ng-container matColumnDef="Classification">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header > Classification </th>
                    <td mat-cell *matCellDef="let row"  > {{row.type}} </td>
                </ng-container>
                <ng-container matColumnDef="Type of Food">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Type of Food </th>
                    <td mat-cell *matCellDef="let row" > {{row.allow}}</td>
                </ng-container>
                <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-20'"> Actions </th>
                    <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-20'"> 
                        <div class="menu-nav">
                            <div class="menu-item"></div>
                            <div class="dropdown-container" tabindex="-1">
                              <div class="dots"></div>
                              <div class="dropdown">
                                <!-- <a (click)="DeleteFood(row.id)"><div>RESULTS &nbsp;&nbsp;<i class="fas fa-chevron-right"></i></div></a>     -->
                                <a (click)="DeleteFood(row.idg)"><div>DELETE &nbsp;&nbsp;<i class="fas fa-chevron-right"></i></div></a>                              
                              </div>
                            </div>
                          </div>    
                    </td>
                </ng-container>                    
                <tr mat-header-row *matHeaderRowDef="displayedColums"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColums;"></tr>
        
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>
                
                </table>
            
                <mat-paginator #paginator [pageSizeOptions]="pageSizes"  showFirstLastButtons></mat-paginator>
                </div>
        </mat-card-content>
        </mat-card>
    </div>
</div>
