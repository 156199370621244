import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'
import { AnimationsService } from '../../services/animations.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  constructor(private animation: AnimationsService) { }

  ngOnInit(): void {
  }
  reload() {
    window.location.href="/";
  }
  closepopup() {   
      this.animation.toHome("#food-block");
      this.animation.fadeOut("#credit-test");
      this.animation.fadeOut("#credit-test-result");
      this.animation.fadeIn("#container-btn-r");
      this.animation.fadeOut("#table-result");
      $(".valitb-btn").css('position','absolute');
      $(".valitb-btn").css('top','50%');
      $(".valitb-btn").css('right','50%');
      $(".valitb-btn").css('transform','translateY(-50%) translateX(50%)');
      this.animation.fadeOut("#downloadpdf");
      this.animation.fadeOut("#sendEmailpdf"); 
  }
}
