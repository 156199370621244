// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  firebase: {
    projectId: 'cuestionario-pku',
    appId: '1:290418611022:web:318effe6f20b986110041f',
    databaseURL: 'https://cuestionario-pku-default-rtdb.europe-west1.firebasedatabase.app',
    storageBucket: 'cuestionario-pku.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyD1ZU9gw2bCxP2z0FDrka7yttqIHFrvRwI',
    authDomain: 'cuestionario-pku.firebaseapp.com',
    messagingSenderId: '290418611022',
    measurementId: 'G-TB00RK4PH0',
  },
  AuthFireStore: {
    email:"pku.admin.4NW4iCg@gmail.com",
    passw:",z/v–y–ffHOJ<&4NW4$iCg}{z.ibAz"
  },
  fakeData: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
