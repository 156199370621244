import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';     
import { fromEvent, Subscription } from 'rxjs';
import { AnimationsService } from 'src/app/services/animations.service';
import { GeneralServicesService } from 'src/app/services/general-services.service';
import { Answer } from 'src/app/services/general-services.service';
import * as $ from 'jquery'
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { ThrowStmt } from '@angular/compiler';
import { v4 as uuidv4 } from 'uuid';
import { List } from 'linqts';

@Component({
  selector: 'app-food',
  templateUrl: './food.component.html',
  styleUrls: ['./food.component.css']
})

export class FoodComponent implements OnInit {
  // @ViewChild('TestCard') d1:ElementRef;
  favoriteSeason: string;
  options: string[] = ['Child / Adult with PKU', 'Adult with relative diagnosed with PKU', 'Physician, nutritionist, nurse, Health Professional', 'Student or any other case'];
  color: ThemePalette = "primary";
  options_selected: string;
  indexFood: number;
  public htmlToAdd;
  clickedElement: Subscription = new Subscription();
  dataFoods: any;
  dataFoodsCustom: any;
  years = [];
  foodsCustom : any[];
  dataFilter : any;
  constructor(public GService: GeneralServicesService, private animation: AnimationsService) { 

   }

  ngOnInit(): void {
    this.animation.fadeIn("#buttoms-type");    
    this.indexFood = 0;
    let yearPivot = new Date().getFullYear();
    yearPivot = Number(yearPivot) - 80;
    this.Putyear(yearPivot);
  }
  ChangeOption(tradio1selection, type) {    
    switch (type) {
      case "typeTest":
        this.GService.InitValues();
        this.options_selected = tradio1selection;  
        break;
      case "Gender":
        this.GService.DatosSujeto.gender = tradio1selection;  
        break;
      case "Ocupation":
        this.GService.DatosSujeto.ocupation = tradio1selection;  
        break;
    
      default:
        break;
    }
      
  }

  selectChange(event, type){
    switch (type) {
      case "region":      
        this.GService.DatosSujeto.region = event.target.value.substring(event.target.selectionStart, event.target.selectionEnd); 
        break;
      case "education":
        this.GService.DatosSujeto.educationLevel = event.target.value.substring(event.target.selectionStart, event.target.selectionEnd);  
        break;
      case "SizeTest":
        this.GService.DatosSujeto.SizeTest = event.target.value.substring(event.target.selectionStart, event.target.selectionEnd);  
        break;
       case "Byear":
          this.GService.DatosSujeto.birthday = event.target.value.substring(event.target.selectionStart, event.target.selectionEnd);  
         break;
      case "PKU":
        this.GService.DatosSujeto.PKU = event.target.value.substring(event.target.selectionStart, event.target.selectionEnd);  
        break;
    
      default:
        break;
    }

  }
  
  OnDateChange(event){
    this.GService.DatosSujeto.birthday = event.toDateString();
  }
 
  SaveOption(){
    this.GService.DatosSujeto.typeTest = this.options_selected;
    this.GService.SaveLocalStorage("DataUser",this.GService.DatosSujeto, true);    
    
    this.animation.MovetoLeftOpacity("#buttoms-type","#GeneralData", ()=> {
     document.getElementById("step-1").classList.remove('current');
      document.getElementById('step-1').classList.add('success');
      document.getElementById('step-2').classList.remove('pending');
      document.getElementById('step-2').classList.add('current');
      setTimeout(()=> {
        this.animation.fadeIn("#overlay2");
        this.animation.fadeIn("#section-code-nutri0");
      },1500)      
        
     
    });
  }
  goHome(){
    this.GService.DatosSujeto.Agree = "No";
    this.GService.SaveLocalStorage("DataUser",this.GService.DatosSujeto,true); 
    this.animation.toHome("#food-block");
    this.animation.fadeOut("#credit-test");
    this.animation.fadeOut("#credit-test-result");
    this.animation.fadeIn("#container-btn-r");
    this.animation.fadeOut("#table-result");
    $(".valitb-btn").css('position','absolute');
    $(".valitb-btn").css('top','50%');
    $(".valitb-btn").css('right','50%');
    $(".valitb-btn").css('transform','translateY(-50%) translateX(50%)');
    this.animation.fadeOut("#downloadpdf");
    this.animation.fadeOut("#sendEmailpdf");
    this.animation.fadeOut("#ViewAnswers");
      $('.factor2 .vlow').css('display','none');  
      $('.factor2 .low').css('display','none'); 
      $('.factor2 .average').css('display','none');
      $('.factor2 .vhigh').css('display','none');

      $('.factor1 .vlow').css('display','none');  
      $('.factor1 .low').css('display','none'); 
      $('.factor1 .average').css('display','none');
      $('.factor1 .vhigh').css('display','none');

      $('.factor3 .vlow').css('display','none');  
      $('.factor3 .low').css('display','none'); 
      $('.factor3 .average').css('display','none');
      $('.factor3 .vhigh').css('display','none');
     
      $('.factorgeneral .vlow').css('display','none');  
      $('.factorgeneral .low').css('display','none'); 
      $('.factorgeneral .average').css('display','none');
      $('.factorgeneral .high').css('display','none');
      $('.factorgeneral .vhigh').css('display','none');
    


  }
  goTest(){
    this.GService.DatosSujeto.Agree = "Yes";
    if(this.GService.DatosSujeto.id == ""){
        this.GService.DatosSujeto.id = uuidv4();
        this.GService.SaveLocalStorage("DataUser",this.GService.DatosSujeto,true);  
          this.indexFood = 0;
          localStorage.removeItem('Answers');
          this.GService.AnswerxFood = [];
          this.animation.MovetoLeftOpacity("#disclaimer","#Test-card",()=> {
            document.getElementById("step-3").classList.remove('current');
            document.getElementById('step-3').classList.add('success');
            document.getElementById('step-4').classList.remove('pending');
            document.getElementById('step-4').classList.add('current');
            this.animation.fadeIn("#Progress-bar");
            this.animation.fadeIn("#credit-test");
            $("#disclaimer").css('transform','translate(0px)');
          });
          this.GService.saveUser(this.GService.DatosSujeto);
          this.ChangeFood(-1,true);
    }else {
      this.GService.isExistsCode(this.GService.DatosSujeto.id).then((r) => {
        console.log("r: ", r);
        if(r == true){
          this.GService.SaveLocalStorage("DataUser",this.GService.DatosSujeto,true);  
          this.indexFood = 0;
          localStorage.removeItem('Answers');
          this.GService.AnswerxFood = [];
          this.animation.MovetoLeftOpacity("#disclaimer","#Test-card",()=> {
            document.getElementById("step-3").classList.remove('current');
            document.getElementById('step-3').classList.add('success');
            document.getElementById('step-4').classList.remove('pending');
            document.getElementById('step-4').classList.add('current');
            this.animation.fadeIn("#Progress-bar");
            this.animation.fadeIn("#credit-test");
            $("#disclaimer").css('transform','translate(0px)');
          });
          this.GService.saveUser(this.GService.DatosSujeto);
          this.ChangeFood(-1,true);
        }else {
          var element = document.getElementById("popup1");
          element.style.visibility = 'visible';
          element.style.opacity = '1';
        }
      }).catch((err) => {
        console.log("Error verify code> ", err);
        var element = document.getElementById("popup1");
        element.style.visibility = 'visible';
        element.style.opacity = '1';
      })
    }
    
  }
  toAgreement(){   
 
    this.GService.SaveLocalStorage("DataUser",this.GService.DatosSujeto,true);  
    this.animation.MovetoLeftOpacity("#GeneralData","#disclaimer", ()=> {
      document.getElementById("step-2").classList.remove('current');
      document.getElementById('step-2').classList.add('success');
      document.getElementById('step-3').classList.remove('pending');
      document.getElementById('step-3').classList.add('current');
    });
    let idNutri = $("#CodefromNutri").val();
    if(idNutri != ""){
       idNutri = idNutri.toString().replace("N-TEST-","")
       idNutri = idNutri.toString().substring(0,idNutri.toString().indexOf('-')); 
       console.log("idNutri: ", idNutri);
    }
    this.GetDataFoods(idNutri);
  }
 ChangeFood(current: number, next = false, idClick=""){  
    if(current == -1){  
       var TopValue = Number(this.GService.GetLocalStorage("NumberItems"));
       var percent = (1 * 100) / TopValue;
       this.GService.currentValueBar = Math.round(percent);    
       this.GService.progress_Calculate(1,TopValue,this.GService.currentValueBar,1)
      this.GService.SaveLocalStorage("currentFood",0);     
        let CurrentValue = this.dataFoods[0].class;
        this.animation.NextFood(0,CurrentValue,true);
        this.animation.fadeIn("#Test-Card");
    }else{             
        var TopValue = Number(this.GService.GetLocalStorage("NumberItems"));
        var percent = ((current == 0 ? 2: current + 2) * 100) / TopValue;
        this.GService.currentValueBar = Math.round(percent);
        if(next){
          console.log("NEXT current: ", current);
          if(current + 1  < (parseInt(this.GService.GetLocalStorage("NumberItems"))) ){
            this.GService.progress_Calculate( (current == 0 ? 2: current + 2),TopValue,this.GService.currentValueBar,2);
            let NextValue = this.foodsCustom[current + 1].class;  
            let CurrentValue = this.foodsCustom[current].class;
            var  BackValue;
            if(current > 0)  BackValue = this.foodsCustom[current - 1].class;
            else BackValue = 0;

          let currentLocal = parseInt(this.GService.GetLocalStorage("currentFood"));
          this.GService.SaveLocalStorage("currentFood",currentLocal + 1); 
          console.log("CurrentValue,NextValue: ", CurrentValue,NextValue);
          this.animation.NextFood(CurrentValue,NextValue);
          setTimeout(function(){
            document.getElementById(idClick).style.pointerEvents = "auto";
          },2000)
            }else {
              console.log("FINISCH LARGE current, localStorega -> ", current, parseInt(this.GService.GetLocalStorage("NumberItems")));
              this.FinishFood();
            }
        }else {
          let NextValue = this.dataFoods[current + 1].class;  
          let CurrentValue = this.dataFoods[current].class;
          var  BackValue;
          if(current > 0)  BackValue = this.dataFoods[current - 1].class;
          else BackValue = 0;

          let currentLocal = parseInt(this.GService.GetLocalStorage("currentFood")) - 1;
          if(current > 0) this.GService.SaveLocalStorage("currentFood",currentLocal - 1); 
          else this.GService.SaveLocalStorage("currentFood",currentLocal); 
          this.animation.NextFood(CurrentValue,BackValue);
          setTimeout(function(){
             document.getElementById(idClick).style.pointerEvents = "auto";
          },2000)
        }  
    }
    
  }
  
  FinishFood(){
    console.log("FINISH FOOD");
  
    this.animation.fadeOut("#credit-test");
    this.animation.fadeIn("#credit-test-result");
    if(parseInt(this.GService.DatosSujeto.SizeTest.toString()) == 1){
      this.animation.fadeOut("#chart-container-0");
      this.animation.fadeOut("#chart-container-1");
      this.animation.fadeOut("#chart-container-2");
      this.animation.fadeOut("#chart-container-3");
      this.GService.ProcessResult();
     
    }
    else {
      this.animation.fadeIn("#chart-container-0");
      this.animation.fadeIn("#chart-container-1");
      this.animation.fadeIn("#chart-container-2");
      this.animation.fadeIn("#chart-container-3");
      this.GService.ProcessResultExtend();
    }

    this.animation.fadeOut("#Test-Card");
    this.animation.fadeOut("#Progress-bar");
    this.animation.fadeIn("#results");
    document.getElementById("step-4").classList.remove('current');
    document.getElementById('step-4').classList.add('success');
    document.getElementById('step-5').classList.remove('pending');
    document.getElementById('step-5').classList.add('current');
  }

 async GetDataFoods(id){   

     
        this.GService.GetData().then((res)=> {
        this.dataFoods = [];
        console.log("res datafoods => ", res);
        if(id != "" && id != undefined){
          this.dataFilter = res;
          this.dataFoodsCustom = new List<any>(this.dataFilter).Where(f =>  f.idN == id).ToArray();
          console.log("this.dataFoodsCustom: ", this.dataFoodsCustom);
         
          this.dataFoods = new List<any>(this.dataFilter).Where(f => f.type != "custom").ToArray();
          for (let index = 0; index < this.dataFoodsCustom.length; index++) {
            const element = this.dataFoodsCustom[index];
            this.dataFoods.push(element);            
          }
        }else {
           this.dataFilter = res;
          this.dataFoods = new List<any>(this.dataFilter).Where(f => f.type != "custom").ToArray();
        }
        
        console.log("FOODSHORT -> ", this.dataFoods);  
        if(this.dataFoods.length > 0){   
         
          let styles = `
                        <style>
                        .fa-thumbs-up::before {
                          content: "\\f164";
                          color: var(--green);
                          font-size: 3vw;
                          }
                      
                      .fa-thumbs-down::before {
                          content: "\\f165";
                          color: #e14747;
                          font-size: 3vw;   
                          }
                      
                      .fa-thumbs-up:hover {
                          cursor: pointer;
                          color: #0d940d;                         
                      }
                      .fa-thumbs-down:hover {
                          cursor: pointer;
                          color: #992828;                         
                      }
                      .up {
                          padding-right: 5%;
                      }
                      .down {
                          padding-left: 5%;
                      }
                      .line {
                          display: block;
                          max-width: 30%;
                          margin: 0 auto;
                          margin-top: 1vw;
                          color: #316af0;
                          padding: 0.2vw;
                      }
                      #bnt-to-select {
                        margin-top: 2%;
                      }           
                     .current-food {
                       font-size:2.6vw !important;
                       font-family:'ProximaNova-Light' !important;
                     }
                     .icons {
                      position: relative;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin: 0 2.3rem 0 0;
                      width: 1.25rem;
                      height: 2.6rem;
                    }
                    .icons i {
                      position: absolute;                    
                      display: block;
                    }
                     .icon-default {
                      transition: opacity 0.3s, transform 0.3s;
                    }               
                    .icon-default:hover {
                      -webkit-transform: rotate(15deg); /* WebKit */
                      -moz-transform: rotate(15deg); /* Mozilla */
                      -o-transform: rotate(15deg); /* Opera */
                      -ms-transform: rotate(15deg); /* Internet Explorer */
                      transform: rotate(15deg); /* CSS3 */                       
                    }
                    @media only screen and (max-width: 811px) and (orientation:portrait) {
                          .fa-thumbs-up::before {
                            content: "\f164";
                            color: var(--green);
                            font-size: 4vw;
                        }
                        .fa-thumbs-down::before {
                            content: "\f165";
                            color: #e14747;
                            font-size: 4vw;
                        }
                        .yes-no {
                            font-size: 2.2vw !important;
                        }     
                        .current-food {
                          font-size: 3vw !important;
                        }                
                    }
                    @media only screen and (max-width: 768px) {
                      .title-foods-section {
                        font-size: 5vw;
                      }
                      .current-food {
                        font-size: 7vw !important;                      
                      }
                      .line {
                        height: 4px !important;
                        max-width: 50% !important;
                      }
                      .fa-thumbs-up::before {
                        content: "\f164";
                        color: var(--green);
                        font-size: 9vw;
                       }
                       .fa-thumbs-down::before {
                          content: "\f165";
                          color: #e14747;
                          font-size: 9vw;
                        }
                       .yes-no {
                         font-size: 4.2vw !important;
                       }
                       .only-desktop {
                         display:none !important;
                       }
                       #bnt-to-select {
                        flex-direction: row;
                      }
                      .up {
                        width: 50% !important;
                      }
                      .down {
                        width: 50% !important;
                      }
                    }
                    </style>
                        `;
          this.PopulateData();
          $('#Test-Card').append(styles);
        }          
      }).catch( e => {
        console.log("Catch error Foodcompnent -> ", e);
      })
    
      
  }
  
async PopulateData(){
  try {
    let elements;
    var StorageData = this.GService.GetLocalStorage("DataUser",true);
    $('.food-elemts').empty();
    $('.food-elemts').append()
    var items = 1; 
    this.foodsCustom = [];
    for(let i=0; i < this.dataFoods.length ; i ++){ 
      if(this.dataFoods[i] == undefined) continue;        
      if(parseInt(StorageData.SizeTest) == 1){
          if(this.dataFoods[i].type=="brief" || this.dataFoods[i].type=="custom"){             
              elements = ` 
                            <div id="${this.dataFoods[i].class}" style="display:none;opacity:0;">                    
                            <h1 class="current-food">${this.dataFoods[i].name}</h1>
                            <hr class="line"> 
                            <div class="row flex" id="bnt-to-select"> 
                              <span class="up col-lg-6 col-md-6 col-sm-6 col-xs-12  justify-content-end" >
                                  <span style="width:100%;" class="icons">
                                  <i class="fas fa-thumbs-up  icon-default" id="Next-${this.dataFoods[i].class}" ></i>                                 
                                  </span>
                                  <br class="only-desktop">
                                  <span class="yes-no"  style="width:100%;line-height: 3; font-size: 1.2vw; font-family: 'ProximaNova-Light'; color: var(--gray);">Yes</span>
                              </span>
                              <span class="down col-lg-6 col-md-6 col-sm-6 col-xs-12  justify-content-start">
                                  <span style="width:100%;" class="icons"> 
                                  <i class="fas fa-thumbs-down icon-default" id="Back-${this.dataFoods[i].class}"></i>
                                  </span>
                                  <br class="only-desktop">
                                  <span class="yes-no" style="width:100%;line-height: 3; font-size: 1.2vw; font-family: 'ProximaNova-Light'; color: var(--gray);">No</span>
                              </span> 
                            </div>
                          </div> `;  
              $('.food-elemts').append(elements);
              this.foodsCustom.push(this.dataFoods[i]);
              $("#Next-"+this.dataFoods[i].class).click(()=> {            
                
                var answer = new Answer ;        
                answer.foodNumber = this.indexFood;
                answer.answer = true;
                answer.food = this.dataFoods.find(x => x.id === this.indexFood + 1 ).id;
                answer.factor = this.dataFoods.find(x => x.id === this.indexFood + 1 ).factor;
                answer.allow = this.dataFoods.find(x => x.id === this.indexFood + 1 ).allow; 
                
                if(this.indexFood  < 35 || this.indexFood  <= 79 ){
                  this.GService.AnswerxFood.push(answer);
                  this.GService.SaveLocalStorage("Answers",this.GService.AnswerxFood,true);
                  document.getElementById("Next-"+this.dataFoods[i].class).style.pointerEvents = "none";
                  this.ChangeFood(i,true,"Next-"+this.dataFoods[i].class);
                }
                else 
                  this.FinishFood();
                this.indexFood = this.indexFood + 1;
              });
              
              $("#Back-"+this.dataFoods[i].class).click(() => {            
                var answer2 = new Answer ;        
                answer2.foodNumber = this.indexFood;
                answer2.answer = false;
                answer2.food = this.dataFoods.find(x => x.id === this.indexFood + 1 ).id;
                answer2.factor = this.dataFoods.find(x => x.id === this.indexFood + 1 ).factor;
                answer2.allow = this.dataFoods.find(x => x.id === this.indexFood + 1 ).allow;
                if(this.indexFood  < 35 || this.indexFood  < 79 ){
                  this.GService.AnswerxFood.push(answer2);
                  this.GService.SaveLocalStorage("Answers",this.GService.AnswerxFood,true);
                  document.getElementById("Back-"+this.dataFoods[i].class).style.pointerEvents = "none";
                  this.ChangeFood(i,true,"Back-"+this.dataFoods[i].class);
                }
                else 
                  this.FinishFood();
                this.indexFood = this.indexFood + 1;
              });

              items++;
              // if(items == 37){
                  this.GService.SaveLocalStorage("NumberItems",items - 1 );
                  this.GService.SaveLocalStorage("foodsCustom",this.foodsCustom,true);
              // }
          }
      }else {               
              elements = ` 
                            <div id="${this.dataFoods[i].class}" style="display:none;opacity:0;">                    
                            <h1 class="current-food">${this.dataFoods[i].name}</h1>
                            <hr class="line"> 
                            <div class="row flex" id="bnt-to-select"> 
                              <span class="up col-lg-6 col-md-6 col-sm-6 col-xs-12  justify-content-end" >
                                  <span style="width:100%;"><i class="fas fa-thumbs-up icon-default" id="Next-${this.dataFoods[i].class}" ></i></span><br class="only-desktop">
                                  <span style="width:100%;line-height: 3; font-size: 1.2vw; font-family: 'ProximaNova-Light'; color: var(--gray);">Yes</span>
                              </span>
                              <span class="down col-lg-6 col-md-6 col-sm-6 col-xs-12  justify-content-start">
                                  <span style="width:100%;"> <i class="fas fa-thumbs-down icon-default" id="Back-${this.dataFoods[i].class}"></i></span><br class="only-desktop">
                                  <span style="width:100%;line-height: 3; font-size: 1.2vw; font-family: 'ProximaNova-Light'; color: var(--gray);">No</span>
                              </span> 
                            </div>
                          </div> `;  
              $('.food-elemts').append(elements);
              this.foodsCustom.push(this.dataFoods[i]);          
              $("#Next-"+this.dataFoods[i].class).click(()=> {            
                
                var answer = new Answer ;        
                answer.foodNumber = this.indexFood;
                answer.answer = true;
                answer.food = this.dataFoods.find(x => x.id === this.indexFood + 1 ).id;
                answer.factor = this.dataFoods.find(x => x.id === this.indexFood + 1 ).factor;
                answer.allow = this.dataFoods.find(x => x.id === this.indexFood + 1 ).allow;
                
                console.log("this.indexFood NEXT-> ", this.indexFood);
                if(this.indexFood  <= 79){
                  this.GService.AnswerxFood.push(answer);
                  this.GService.SaveLocalStorage("Answers",this.GService.AnswerxFood,true);
                  document.getElementById("Next-"+this.dataFoods[i].class).style.pointerEvents = "none";
                  this.ChangeFood(parseInt(this.GService.GetLocalStorage("currentFood")),true,"Next-"+this.dataFoods[i].class);
                }
                else 
                  this.FinishFood();
                this.indexFood = this.indexFood + 1;
              });
              
              $("#Back-"+this.dataFoods[i].class).click(() => {            
                var answer2 = new Answer ;        
                answer2.foodNumber = this.indexFood;
                answer2.answer = false;
                answer2.food = this.dataFoods.find(x => x.id === this.indexFood + 1 ).id;
                answer2.factor = this.dataFoods.find(x => x.id === this.indexFood + 1 ).factor;
                answer2.allow = this.dataFoods.find(x => x.id === this.indexFood + 1 ).allow;
                console.log("this.indexFood BACK-> ", this.indexFood);

                if(this.indexFood  <= 79){
                  this.GService.AnswerxFood.push(answer2);
                  this.GService.SaveLocalStorage("Answers",this.GService.AnswerxFood,true);
                  document.getElementById("Back-"+this.dataFoods[i].class).style.pointerEvents = "none";
                  this.ChangeFood(parseInt(this.GService.GetLocalStorage("currentFood")),true,"Back-"+this.dataFoods[i].class);
                }
                else 
                  this.FinishFood();
                this.indexFood = this.indexFood + 1;
              });
              items++;
              if(items == this.dataFoods.length){
                  this.GService.SaveLocalStorage("NumberItems",items);
                  this.GService.SaveLocalStorage("foodsCustom",this.foodsCustom,true);
      }
      } 

   } 
  } catch (error) {
    console.log("Error PopulateDATA -> ", error);
  }
  
}

closenutri() {
  let idcodeNutri =  $("#CodefromNutri").val();
  console.log("idNuri=> ", idcodeNutri);
  if(idcodeNutri != "" && idcodeNutri != undefined){
    this.GService.DatosSujeto.id = idcodeNutri;
    this.GService.SaveLocalStorage("DataUser",this.GService.DatosSujeto,true);
    this.GService.isExistsCode(this.GService.DatosSujeto.id).then((d) => {console.log("d=>", d)});  
  } 
  this.animation.fadeOut("#section-code-nutri");
  this.animation.fadeOut("#overlay2");
}
codesyes(){
  this.animation.fadeOut("#section-code-nutri0");
  this.animation.fadeIn("#section-code-nutri");
}
codesno(){
  this.animation.fadeOut("#section-code-nutri0");
  this.animation.fadeOut("#overlay2");
}
Putyear = (startYear) => {
    var currentYear = new Date().getFullYear();
    startYear = startYear || 1970;  
    while ( currentYear >= startYear ) {
        this.years.push(currentYear);
        currentYear--;
    }   
  }

}
