import { Component,  AfterViewInit, ViewChild, OnInit } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { MatTableExporterModule } from 'mat-table-exporter';
import { CollectionAnswers, CollectionResults, CollectionService, Profile } from 'src/app/services/collection.service';
import { List } from 'linqts';
import { environment } from '../../../../environments/environment';
import { conditionallyCreateMapObjectLiteral } from '@angular/compiler/src/render3/view/util';
import { Answer } from 'src/app/services/general-services.service';
import { AuthGuard } from '../../../guard/auth.guard';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.css']
})
export class DataComponent implements AfterViewInit, OnInit   {
  displayedColumnsAnswers: string[] = [
    "ID",
    "FOODS",
    "Answer",
    "Allowed"
  ];
  displayedColumnsProfile: string[] = [ "id",
                                        "Agreement",
                                        "PKU Type",
                                        "Questionnarie",
                                        "Age",
                                        "Education Level",
                                        "Gender",
                                        "Ocupation",
                                        "Region",
                                        "Start at",
                                        "Profile", //typeTest 
                                        "Raw Score", 
                                        "General Percentil",  
                                        "Percentil Factor 1",
                                        "Percentil Factor 2",
                                        "Percentil Factor 3",
                                        "Points Factor 1",
                                        "Points Factor 2",
                                        "Points Factor 3",
                                        "Points Factor 4",
                                        "Points Factor 5",                                        
                                        "Date Finish" ]

  dataSource: MatTableDataSource<profileByResults>;
  dataSource2: MatTableDataSource<answerResults>;
  dataProfiles: Profile[];
  dataAnswers: any[];
  dataResults : CollectionResults[];
  ColAnswersResults : any[];
  superResults: Array<profileByResults> = [];
  superAnswer: Array<answerResults> = [];
  objectJoin : profileByResults;
  datasr: any;
  cresults: any;
  datafoods: any;
  datanswers: any;
  pageSizes = [5,10,15,20,40,50,100,200,500,1000];

  devenv = environment.fakeData;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private SCollection: CollectionService, private userAuth: AuthService) { 
    
  }
  ngOnInit(): void {
    this.DataProfiles(); 
    
  }

  ngAfterViewInit(): void {
    this.DataAnswers();
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }   
  }

  applyFilter2(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();

    if (this.dataSource2.paginator) {
      this.dataSource2.paginator.firstPage();
    }
    
  }

  GetResults(profilesdata: Profile[]){
    this.SCollection.GetResultsProfiles.then((results) => {
      this.cresults = JSON.parse(JSON.stringify(results));
     this.dataResults = this.cresults.map(dta=> {
           const id = dta.id;
           const GRawScore = dta.dataResult.GRawScore;
           const GenPercentil = dta.dataResult.GenPercentil;
           const PointsF1 = dta.dataResult.PointsF1;
           const PointsF2 = dta.dataResult.PointsF2;
           const PointsF3 = dta.dataResult.PointsF3;
           const PointsF4 = dta.dataResult.PointsF4;
           const PointsF5 = dta.dataResult.PointsF5;           
           const PercentsF1 = dta.dataResult.PercentsF1;
           const PercentsF2 = dta.dataResult.PercentsF2;
           const PercentsF3 = dta.dataResult.PercentsF3;           
           const Date = dta.dataResult.date;

           return {id,GRawScore,GenPercentil, PointsF1, PointsF2, PointsF3, PointsF4, PointsF5, PercentsF1, PercentsF2, PercentsF3, Date}


     });
      console.log("this.dataResults => ",this.dataResults);
      let index2temp = 0;
     for (let index1 = 0; index1 < this.dataResults.length; index1++) {
       for (let index2 = 0; index2 < profilesdata.length; index2++) {
         if(this.dataResults[index1].id == profilesdata[index2].id){
           this.objectJoin = new profileByResults;          
           this.objectJoin.id = profilesdata[index2].id;
           this.objectJoin.Agree = profilesdata[index2].Agree;
           this.objectJoin.PKU = profilesdata[index2].PKU;
           this.objectJoin.SizeTest = profilesdata[index2].SizeTest;
           this.objectJoin.birthday = profilesdata[index2].birthday;
           this.objectJoin.educationLevel = profilesdata[index2].educationLevel;
           this.objectJoin.gender = profilesdata[index2].gender;
           this.objectJoin.ocupation = profilesdata[index2].ocupation;
           this.objectJoin.region = profilesdata[index2].region;
           this.objectJoin.startAt = profilesdata[index2].startAt;
           this.objectJoin.typeTest = profilesdata[index2].typeTest;
           this.objectJoin.GRawScore = this.dataResults[index1].GRawScore;
           this.objectJoin.GenPercentil = this.dataResults[index1].GenPercentil;
           this.objectJoin.PercentsF1 = this.dataResults[index1].PercentsF1;
           this.objectJoin.PercentsF2 = this.dataResults[index1].PercentsF2;
           this.objectJoin.PercentsF3 = this.dataResults[index1].PercentsF3;
           this.objectJoin.PointsF1 = this.dataResults[index1].PointsF1;
           this.objectJoin.PointsF2 = this.dataResults[index1].PointsF2;
           this.objectJoin.PointsF3 = this.dataResults[index1].PointsF3;
           this.objectJoin.PointsF4 = this.dataResults[index1].PointsF4;
           this.objectJoin.PointsF5 = this.dataResults[index1].PointsF5;
           this.objectJoin.date = this.dataResults[index1].Date;
           this.superResults.push(this.objectJoin);           
         }
         index2temp = index2;
        
       }
       if(index1 == this.dataResults.length - 1 && index2temp == profilesdata.length - 1) {
            console.log("this.superResults: ", this.superResults);
        this.dataSource = new MatTableDataSource(this.superResults);
        console.log("this.dataSource: ", this.dataSource);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;        
       }
     }        
        if(!this.devenv && this.userAuth.isLoggedIn()){
         this.SCollection.CResults.unsubscribe();                
        //  this.SCollection.FAuth.signOut();  
        }         
   })
  }
   
  GetAnswers(foods: any[]){
      this.SCollection.GetAnswers.then((results) => {
        this.datanswers = JSON.parse(JSON.stringify(results));
        console.log("this.datanswers: ", this.datanswers);
      this.ColAnswersResults = this.datanswers.map(dta=> {
          const id = dta.id;
          const answer = dta.Answer          
          return { id, answer };        
    });
    console.log("this.ColAnswersResults-> ", this.ColAnswersResults);
    if(!this.devenv && this.userAuth.isLoggedIn()){
      this.SCollection.ColAnswers.unsubscribe();                
      // this.SCollection.FAuth.signOut();  
      } 
      this.superAnswer = [];
      let nutriId = sessionStorage.getItem('_89imn7yrtgsf94');
      let typesession = sessionStorage.getItem('_894784yrtgsf52');
    for (let index = 0; index < this.ColAnswersResults.length; index++) {     
      let AnswerNutriid = this.ColAnswersResults[index].id;
      let nutri = AnswerNutriid.toString().includes(nutriId) ? true : false
     console.log("nutriId, AnswerNutriid, nutri,typesession, anserw: ",nutriId, AnswerNutriid, nutri,typesession, this.ColAnswersResults[index].answer)
     let  indexelement = index;
      if(this.ColAnswersResults[index].answer != null  && nutri == true && parseInt(typesession) == 3){
      const element = this.ColAnswersResults[index].answer;
      const elementID = this.ColAnswersResults[index].id;
         
        for (let index = 0; index < element.length; index++) {
            var objAnswerFake = new answerResults();
            objAnswerFake.ID = elementID;
            // console.log("element: ", element);
            objAnswerFake.FOODS = new List<any>(foods)
            .Where(f=>  f.id == element[index].food)
            .Select(f=> f.name).SingleOrDefault()
            objAnswerFake.Answer = element[index].answer == element[index].allow ? "Yes" : "No";
            objAnswerFake.Alloweds = element[index].allow == true ? "Yes" : "No";
          //  console.log("objAnswerFake-> ", objAnswerFake);
          this.superAnswer.push(objAnswerFake);          
        }

        // if(indexelement == this.ColAnswersResults.length - 1){
        //    console.log("this.superAnswer: ", this.superAnswer);
        //   this.dataSource2 = new MatTableDataSource(this.superAnswer);
        //   this.dataSource2.paginator = this.paginator2;
        //   this.dataSource2.sort = this.sort; 
        //   console.log("this.dataSource2-> ", this.dataSource2);
         
        // }
      
     }else if(this.ColAnswersResults[index].answer != null && parseInt(typesession) < 3){
      const element = this.ColAnswersResults[index].answer;
      const elementID = this.ColAnswersResults[index].id;
      //  indexelement = index;    
        for (let index = 0; index < element.length; index++) {
            var objAnswerFake = new answerResults();
            objAnswerFake.ID = elementID;
            // console.log("element: ", element);
            objAnswerFake.FOODS = new List<any>(foods)
            .Where(f=>  f.id == element[index].food)
            .Select(f=> f.name).SingleOrDefault()
            objAnswerFake.Answer = element[index].answer == element[index].allow ? "Yes" : "No";
            objAnswerFake.Alloweds = element[index].allow == true ? "Yes" : "No";
          //  console.log("objAnswerFake-> ", objAnswerFake);
          this.superAnswer.push(objAnswerFake);          
        }
       
     }
     if(indexelement == this.ColAnswersResults.length - 1){
      console.log("this.superAnswer: ", this.superAnswer);
     this.dataSource2 = new MatTableDataSource(this.superAnswer);
     this.dataSource2.paginator = this.paginator2;
     this.dataSource2.sort = this.sort; 
     console.log("this.dataSource2-> ", this.dataSource2);
    
   }
    }
   
    })

  }

  DataProfiles(){
    if(this.userAuth.isLoggedIn()){
      this.SCollection.GetProfiles.then((results) => {
        this.datasr = JSON.parse(JSON.stringify(results));
        console.log("datasr", this.datasr);
        this.dataProfiles = this.datasr.map(dta=> {
              const id = dta.id;
              const  Agree = dta.Agree;
              var  PKU = dta.PKU;
              switch (parseInt(PKU)) {
                case 1:
                    PKU = "Mild";
                  break;
                case 2:
                  PKU = "Moderate";
                  break;
                case 3:
                  PKU = "Classic";
                  break;
                default:
                  break;
              }

              var  SizeTest = dta.SizeTest;
              switch (parseInt(SizeTest)) {
                case 1:
                  SizeTest = "Brief questionnaire";
                  break;
                case 2:
                  SizeTest = "Full questionnaire";
                  break;
                case 3:
                  SizeTest = "Custom questionnaire";
                  break;
                default:
                  break;
              }
              var currentYear :  number = new Date().getFullYear();
              var  birthday  =   currentYear - parseInt(dta.birthday)

              var  educationLevel = dta.educationLevel;
              switch (parseInt(educationLevel)) {
                case 1:
                  educationLevel = "Primary School";
                  break;
                case 2:
                  educationLevel = "High School or similar";
                  break;
                case 3:
                  educationLevel = "Bachelor's Degree";
                case 4:
                  educationLevel = "Master's Degree or Ph.D.";
                break;
                default:
                  break;
              }
              const  gender = dta.gender;
              const ocupation = dta.ocupation;

              var  region = dta.region;
              switch (parseInt(region)) {
                case 1:
                  region = "Asia";
                    break;
                case 2:
                  region = "Africa";
                    break;
                case 3:
                  region = "Europe";
                    break;
                case 4:
                  region = "Oceania";
                    break;

                case 5:
                  region = "Antartica";
                    break;

                case 6:
                  region = "North America";
                    break;

                case 7:
                  region = "South America";
                    break;
                default:
                  break;
              }
              const  startAt = dta.startAt;
              const  typeTest = dta.typeTest;

              return {id,Agree, PKU, SizeTest, birthday, educationLevel, gender, ocupation, region, startAt, typeTest}


        })
        var datafilter;
        if(parseInt(sessionStorage.getItem("_894784yrtgsf52")) == 3 ) {
          var idNutri = sessionStorage.getItem("_89imn7yrtgsf94");
         datafilter = new List<Profile>(this.dataProfiles)
                      .Where(f => f.id.toString().includes(idNutri)).ToArray();
        } else{
          datafilter = this.dataProfiles;
        }
          console.log("this.dataProfiles=> ", datafilter);
          this.GetResults(datafilter);               
            if(!this.devenv && this.userAuth.isLoggedIn()){
            this.SCollection.GProfile.unsubscribe();                
            // this.SCollection.FAuth.signOut();
            }   
      });
  }else {
    console.log("user is not authenticated")
  }
  }
  DataAnswers(){
          if(this.userAuth.isLoggedIn()){
            this.SCollection.GetFoods().then((results)=>{
              this.datafoods = JSON.parse(JSON.stringify(results));
              console.log("this.datafoodsTABLE: ", this.datafoods);
              this.GetAnswers(this.datafoods);          
              if(!this.devenv && this.userAuth.isLoggedIn()){
                this.SCollection.Ofodds.unsubscribe();                
                // this.SCollection.FAuth.signOut();
                } 
              
          })
      }else {
        console.log("user is not authenticated")
      }
  }

  Reload(){
    console.log("Reloading");
    window.location.href= "/data"
   
  }
}


export class profileByResults {  
    id: string
    Agree: string
    PKU : string
    SizeTest : string
    birthday : string
    educationLevel : string
    gender : string
    ocupation : string
    region : string
    startAt : string
    typeTest : string    
    GRawScore: number​​​
    GenPercentil: number​
    PercentsF1: number​​
    PercentsF2: number​
    PercentsF3: number​
    PointsF1: number​
    PointsF2: number​
    PointsF3: number​
    PointsF4: number​
    PointsF5: number​
    date: string
    
}

export interface ColAnswer {  
  allow: boolean,
  answer: boolean,
  factor : number,
  food : number,
  foodNumber: number
}

export enum ExportType {
  XLS = 'xls',
     XLSX = 'xlsx',
     CSV = 'csv',
     TXT = 'txt',
     JSON = 'json',
     OTHER = 'other'
}

export class answerResults {
     ID: string
    FOODS: string
    Answer: string
    Alloweds: string
};

