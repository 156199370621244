import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AnimationsService } from 'src/app/services/animations.service';
import { GeneralServicesService } from 'src/app/services/general-services.service';
import * as $ from 'jquery'
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import html2canvas from 'html2canvas'
import htmlToPdfmake from 'html-to-pdfmake';
import { Label } from 'ng2-charts';
import { CollectionService } from 'src/app/services/collection.service';
@Component({
  selector: 'app-result-patients',
  templateUrl: './result-patients.component.html',
  styleUrls: ['./result-patients.component.css']
})
export class ResultPatientsComponent implements OnInit {
  title = 'htmltopdf';
  @ViewChild('pdfTable') pdfTable: ElementRef;
  displayedColumns: string[] = ['name', 'NumberRight', 'NumberWrong', 'DiffRW', 'Sscore', 'ScalePerc', 'description'];
  dataSource : any;
  public typeTest;
  constructor(private GService: GeneralServicesService, private animation: AnimationsService, private SCollection: CollectionService) { 

  }

  ngOnInit(): void {  
    this.typeTest = this.GService.GetLocalStorage("DataUser",true);
    if(this.typeTest.SizeTest == 1){
      this.GService.ProcessAdminResult(this.GService.answerbyfood).then(_ => { 
        this.dataResult(); 
        this.SCollection.GSProfile.unsubscribe();
      })
    }else {
      this.GService.ProcessResultAdminExtend(this.GService.answerbyfood).then(_ => {  
        this.dataResult();  
        this.SCollection.GSProfile.unsubscribe();
      })  
    }
   
    
  }
  dataResult() {
    // $("#downloadpdf").css('pointer-events','auto');
    this.typeTest = this.GService.GetLocalStorage("DataUser",true);
     if(this.typeTest.SizeTest == 1){
       this.dataSource = this.GService.ELEMENTS_DATA;
      //  this.animation.fadeOut("#container-btn-r-pat");
        this.animation.fadeIn("#table-result-pat");
        this.GData();
        // document.getElementById("step-5").classList.remove('current');
        // document.getElementById('step-5').classList.add('success');
        setTimeout(() => {
          this.animation.fadeIn("#downloadpdf-pat");
          // this.animation.fadeIn("#sendEmailpdf");    
        }, 1700);
     }
     else { 
       this.dataSource = this.GService.ELEMENTS_DATA;
      //  this.animation.fadeOut("#container-btn-r-pat");
        this.animation.fadeIn("#table-result-pat");
        this.GDataExtend();
        // document.getElementById("step-5").classList.remove('current');
        // document.getElementById('step-5').classList.add('success');
        setTimeout(() => {
          this.animation.fadeIn("#downloadpdf-pat");
          // this.animation.fadeIn("#sendEmailpdf");    
        }, 1700);
     }
     
    }
    
    GData(){
  
      
  
              $('#no-more-tables-pat').empty();
              let headTable =  `
                                <table class="table" style="border-top:0;">
                                <thead class="cf" style="border:0;">
                                <tr style="border:0;">
                                <th class="title-table text-center" style="border:0;" >                                     
                                </th>
                                  <th class="title-table text-center" colspan="2" style="border:0;font-family: 'ProximaNova_Semibold' !important;" >
                                  RAW Score                      
                                  </th>
                                  <th class="title-table text-center" colspan="2" style="border:0;font-family: 'ProximaNova_Semibold' !important;" >    
                                  Assessment                  
                                  </th>
                                  </tr>
                                  <tr>
                                    <th class="captions-table  caption1" style="background:#FFFFFF;color:trasparent;">name</th>
                                    <th  class="captions-table  caption2" style="font-family: 'ProximaNova_Semibold' !important;">Number of Right Items</th>
                                    <th  class="captions-table  caption2" style="font-family: 'ProximaNova_Semibold' !important;">Number of Wrong Items</th>
                                    <!--<th  class="captions-table  caption3" >Hits less mistakes</th>-->
                                    <th   class="captions-table  caption4" style="background:#FFFFFF;font-family: 'ProximaNova_Semibold' !important;">S Score</th>
                                    <!--<th   class="captions-table  caption5" style="background:#FFFFFF;font-family: 'ProximaNova_Semibold' !important;">Percentil Scale</th>-->
                                    <th   class="captions-table  caption6" style="background:#FFFFFF;font-family: 'ProximaNova_Semibold' !important;">Very Low / Low / Medium / High</th>
                                  </tr>
                                </thead>
                                <tbody id="data-body-pat">`
              $('#no-more-tables-pat').append(headTable);           
  
            for (let index = 0; index < this.dataSource.length; index++) {
            const element = this.dataSource[index];
            let dataBinding;
            if(index % 2 == 0){
              dataBinding = `
              <tr>
                  <td data-label="name" style="background-color: #1E66AD;color:#FFFFFF;font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].name}</td>
                  <td data-label="Number of Right Items" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].NumberRight}</td>
                  <td data-label="Number of Wrong Items" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].NumberWrong}</td>
                <!-- <td data-label="Hits less mistakes" >${this.dataSource[index].DiffRW}</td>-->
                  <td data-label="S Score" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].Sscore}</td>
                  <!--<td data-label="Percentil Scale" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].percentilScale}</td>-->
                  <td data-label="Assesment Very Low / Low / Medium / High" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].finalresult}</td>
              </tr>`;
            }else {
              
              dataBinding = `
                      <tr class="color-back-table">
                          <td data-label="name" style="background-color: #1E66AD;color:#FFFFFF;font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].name}</td>
                          <td data-label="Number of Right Items" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].NumberRight}</td>
                          <td data-label="Number of Wrong Items" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].NumberWrong}</td>
                          <!-- <td data-label="Hits less mistakes">${this.dataSource[index].DiffRW}</td>-->
                          <td data-label="S Score" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].Sscore}</td>
                          <!-- <td data-label="Percentil Scale" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].percentilScale}</td>-->
                          <td data-label="Assesment Very Low / Low / Medium / High" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].finalresult}</td>
                      </tr>`;
  }
  
  
             
  $('#data-body-pat').append(dataBinding);
  } 
  
  let footerTable = `</tbody></table>`;
  $('#no-more-tables-pat').append(footerTable);
  console.log("THIS.TypeTest => ", this.typeTest.SizeTest);
  // if(this.typeTest.SizeTest == 2){
      $("#charts").css('display','block');
  // }  
  }
  GDataExtend(){
  
      
  
    $('#no-more-tables-pat').empty();
    let headTable =  `
                      <table class="table" style="border-top:0;">
                      <thead class="cf" style="border:0;">
                      <tr style="border:0;">
                      <th class="title-table text-center" style="border:0;" >                                     
                      </th>
                        <th class="title-table text-center" colspan="2" style="border:0;font-family: 'ProximaNova_Semibold' !important;" >
                        RAW Score                      
                        </th>
                        <th class="title-table text-center" colspan="3" style="border:0;font-family: 'ProximaNova_Semibold' !important;" >    
                        Assessment                  
                        </th>
                        </tr>
                        <tr>
                          <th class="captions-table  caption1" style="background:#FFFFFF;color:trasparent;">name</th>
                          <th  class="captions-table  caption2" style="font-family: 'ProximaNova_Semibold' !important;">Number of Right Items</th>
                          <th  class="captions-table  caption2" style="font-family: 'ProximaNova_Semibold' !important;">Number of Wrong Items</th>
                          <!--<th  class="captions-table  caption3">Hits less mistakes</th>-->
                          <th   class="captions-table  caption4" style="background:#FFFFFF;font-family: 'ProximaNova_Semibold' !important;">S Score</th>
                          <th   class="captions-table  caption5" style="background:#FFFFFF;font-family: 'ProximaNova_Semibold' !important;">Percentil Scale</th>
                          <th   class="captions-table  caption6" style="background:#FFFFFF;font-family: 'ProximaNova_Semibold' !important;">Very Low / Low / Medium / High</th>
                        </tr>
                      </thead>
                      <tbody id="data-body-pat">`
    $('#no-more-tables-pat').append(headTable);           
  
  for (let index = 0; index < this.dataSource.length; index++) {
  const element = this.dataSource[index];
  let dataBinding;
  if(index % 2 == 0){
    dataBinding = `
    <tr>
        <td data-label="name" style="background-color: #1E66AD;color:#FFFFFF;font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].name}</td>
        <td data-label="Number of Right Items" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].NumberRight}</td>
        <td data-label="Number of Wrong Items " style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].NumberWrong}</td>
      <!-- <td data-label="Hits less mistakes " >${this.dataSource[index].DiffRW}</td>-->
        <td data-label="S Score" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].Sscore}</td>
        <td data-label="Percentil Scale" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].percentilScale}</td>
        <td data-label="Assesment Very Low / Low / Medium / High" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].finalresult}</td>
    </tr>`;
  }else {
    
    dataBinding = `
            <tr class="color-back-table">
                <td data-label="name" style="background-color: #1E66AD;color:#FFFFFF;font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].name}</td>
                <td data-label="Number of Right Items" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].NumberRight}</td>
                <td data-label="Number of Wrong Items" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].NumberWrong}</td>
                <!-- <td data-label="Hits less mistakes">${this.dataSource[index].DiffRW}</td>-->
                <td data-label="S Score" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].Sscore}</td>
                <td data-label="Percentil Scale" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].percentilScale}</td>
                <td data-label="Assesment Very Low / Low / Medium / High" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].finalresult}</td>
            </tr>`;
  }
  
  
   
  $('#data-body-pat').append(dataBinding);
  } 
  
  let footerTable = `</tbody></table>`;
  $('#no-more-tables-pat').append(footerTable);
  console.log("THIS.TypeTest => ", this.typeTest.SizeTest);
  // if(this.typeTest.SizeTest == 2){
  $("#charts").css('display','block');
  // }  
  }
  public async downloadAsPDF() {
   html2canvas(document.querySelector("#table-result-pat")).then(canvas => {
    var data =  canvas.toDataURL();
          var docDefinition = {
            pageSize: {
              width: 595.28,
              height: 'auto'
            },
              content: [
                { image: data, width: 500, } 
              ]
          };
          pdfMake.createPdf(docDefinition).download("PKU_Knowledge_of_the_Low_Phe_Diet.pdf");
      })
  
  }
  
  // public async SendEmailPDF() {
     
  
  
  // html2canvas(document.querySelector("#table-result-pat")).then(canvas => {
  //   var w = $("#table-result-pat").width();
  //   var h = $("#table-result-pat").height();
    
  //   // Set the width of canvas to twice the width of the container
  //   //var canvas = document.createElement("canvas");
  //   canvas.width = w * 2;
  //   canvas.height = h * 2;
  //   canvas.style.width = w + "px";
  //   canvas.style.height = h + "px";
  //   var context = canvas.getContext("2d");
  //   // Then zoom the canvas and double the image onto the canvas.
  //   context.scale(2,2);
  
  //   var data =  canvas.toDataURL("image/png");
  //         var docDefinition = {
  //           pageSize: {
  //             width: 595.28,
  //             height: 'auto'
  //           },
  //             content: [
  //               { image: data, width: 500, } 
  //             ]
  //         };
  //       var pdf =  pdfMake.createPdf(docDefinition);
  //         console.log("Send by email");
  //         this.animation.fadeIn("#form-email");
  //         this.animation.fadeOut(".branding21");
      
  //     })
  
  // }
  
}
