import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators,ReactiveFormsModule } from '@angular/forms';
import * as $ from 'jquery'

import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm : FormGroup;
                            
  constructor(private authenticationService: AuthService,
    private router: Router) { 
      // this. loginForm = new FormGroup({
      //   username: new FormControl('', Validators.required),
      //   password: new FormControl(['', Validators.required, Validators.minLength(5)])
      // });
    }

  ngOnInit(): void {
    if(this.authenticationService.isLoggedIn()){
      this.router.navigateByUrl("/data");
    }
  }
  
  login() {
    console.log("login");
    let username = $('#floatingInput').val();
    let password = $('#floatingPassword').val();
     this.authenticationService.checkCredentials(username, password).then((res)=> {
      if(res){
        this.authenticationService.login(username, password).subscribe((response) =>{
               console.log("respondes loging = ", response)
               if(response.status == 200){
                    this.authenticationService.SaveSession().then((results) => {
                      console.log("session recorded: ", results);
                    // this.authenticationService.FAuth.signOut();
                      }).catch((err) => {
                        // this.authenticationService.FAuth.signOut();
                        console.log("Error Login", err);
                      })
                        this.router.navigateByUrl("/data");
               }else {
                 alert("Loging Failed!");
               }
              
            });         
      }
    })
    

    
  }
}
