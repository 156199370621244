<div style="display: flex;flex-direction: column; margin-top: 1.5vw;padding-bottom: 12vw;">
   <div class="graph_container" id="chart-container-0">
    <canvas id="Chart1" style="height:20vh; width:45vw;"></canvas>
    <span class="text-results" id="text-results"></span> 
  </div>
  <div class="graph_container" id="chart-container-1">
    <canvas id="Chart2" style="height:20vh; width:45vw;"></canvas>
    <span class="text-results" id="text-results2"></span> 
  </div> 
  <div class="graph_container" id="chart-container-2">
    <canvas id="Chart3" style="height:20vh; width:45vw;"></canvas>
    <span class="text-results" id="text-results3"></span> 
  </div> 
  <div class="graph_container" id="chart-container-3">
    <canvas id="Chart4" style="height:20vh; width:45vw;"></canvas>
    <span class="text-results" id="text-results4"></span> 
  </div> 
 
</div>