<div class="page-inner full-height">
    <div class="container align-center detail-info">
      <div class="row">
        <div data-ng-controller="ContactController" class="panel-body">
          <h3 class="col-lg-12 col-xs-9 title-contact" data-ng-bind="formData.inputName||'Hello..'">{{'Contact-Section-title' | translate}}</h3>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-9">
            <form  name="contactform" method="post"
              class="form-horizontal" role="form">
              <div class="form-group input--hoshi">
                <div class="input-wrap">
                  <input data-ng-model="formData.inputName" type="text"
                    class="form-control input__field input input__field--hoshi" id="inputName" name="inputName"
                    placeholder="Name" required>
                  <label
                    class="input__label input__label input__label--hoshi input__label--hoshi-color-1 input__label--hoshi input__label--hoshi-color-1"></label>
                </div>
              </div>
              <div class="form-group  input--hoshi">
                <div class="input-wrap">
                  <input data-ng-model="formData.inputEmail" type="email"
                    class="form-control input input__field input__field--hoshi" placeholder="Your Email"
                    id="inputEmail" name="inputEmail" required>
                  <label class="input__label input__label--hoshi input__label--hoshi-color-1"></label>
                </div>
              </div>
              <div class="form-group  input--hoshi">
                <div class="input-wrap">
                  <textarea data-ng-model="formData.inputMessage"
                    class="form-control input input__field input__field--hoshi" rows="4" id="inputMessage"
                    name="inputMessage" placeholder="message" required></textarea>
                  <label class="input__label input__label--hoshi input__label--hoshi-color-1"></label>
                </div>
              </div>
              <div class="form-group">
                <div class="align-center">
                  <button type="submit" class="btn btn-default" (click)="submitContact()">
                    <span><span class="arrow-wrap"><span class="arrow"></span></span></span>
                  </button>
                </div>
              </div>
            </form>
            <!-- <p data-ng-class="result">Email Send Successfuly</p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="trans-overlay-dark-contact"></div>
  </div>
