<div class="page-inner full-height">
  <div class="container align-center detail-info detail-info-section">
    <span class="closed" (click)="goHome()">
      &#10006;
    </span>
    <div class="row h-99 content-test">
      <div class="col-lg-3 col-md-3 col-sm-3 sidebar-left-test">
        <app-side-menu></app-side-menu>
      </div>
      <div class="col-lg-9 col-md-8 col-sm-8 col-xs-9 p-relative principal-container-food" style=" overflow-y: auto; overflow-x: hidden; height: 85vh;">
             <div id="overlay" style="position: -webkit-sticky;position: sticky; left: 0; width: 100%; height: 100%;top:0;background-color: rgba(2, 2, 2, 0.4); z-index: 99; display: none; opacity: 0;"></div>
              <div class="radios-buttom" id="buttoms-type">
                <form id="formulario-options">
                  <label id="select-radio-group-label">{{'RadioButtom-test-Title' | translate}}</label>
                  <mat-radio-group class="select-radio-group" #tradio1selection aria-label="Select an option">
                    <mat-radio-button class="item-radio-button" [color]="color" *ngFor="let option of options"  [value]="option" (change)="ChangeOption(option, 'typeTest')">
                      {{option}}
                    </mat-radio-button>
                  </mat-radio-group>           
                    <!-- <button mat-raised-button class="btn btn-large" type="submit" color="primary" (click)="SaveOption()">{{'RadioButtom-test-buttom' | translate}}</button>            -->
                    <button mdbBtn color="info" outline="true" rounded="true" block="true" class="z-depth-0 my-4 waves-effect" mdbWavesEffect
                    type="submit" style="font-family: 'ProximaNova_SemiBold';" (click)="SaveOption()">{{'RadioButtom-test-buttom' | translate}}</button>
              </form>  
              <!-- <app-credits style="position: relative;display: block;width: 100%;margin: 0 auto;margin-top: 3%;"></app-credits>              -->
              </div>
            
              <div id="GeneralData">
                  <!-- Material form contact -->
                      <mdb-card class="valign">

                        <mdb-card-header class="info-color white-text text-center py-1" style="background-color: var(--blue);">
                          <h5 style="color: #FFFFFF; font-size: 1vw;font-family: 'ProximaNova-Light';">
                            Please, Enter the requested data
                          </h5>
                        </mdb-card-header>


                        <!--Card content-->
                        <mdb-card-body class="pt-0 p-card-food">
                          <div id="overlay2" style="position: -webkit-sticky;position: absolute; left: 0; width: 100%; height: 100%;top:0;background-color: rgba(2, 2, 2, 0.4); z-index: 98; display: none; opacity: 0;"></div>
                          <div class="md-form col-xs-12 col-sm-12 col-md-6 col-lg-6 text-left" id="section-code-nutri0">
                            <div class="col-md-12 form-group">                              
                              <label  style="margin-bottom: 0.5vw;">Do you have an access code given by your Doctor / nutritionist?</label>
                              <button type="button" class="btn btn-large btn-success"  id="CodefromNutriYes" (click)="codesyes()">YES</button> 
                              <button type="button" class="btn btn-large btn-info"  id="CodefromNutriYes" (click)="codesno()">NO</button>
                           </div>
                           </div>
                          <div class="md-form col-xs-12 col-sm-12 col-md-6 col-lg-6 text-left" id="section-code-nutri">
                            <div class="col-md-12 form-group">
                              <span class="closed" style="color:#FFFFFF !important;" (click)="closenutri()">
                                &#10006;
                              </span>
                              <label for="CodefromNutri" style="margin-bottom: 0.5vw;">Doctor / Nutritionist's Code</label>
                              <input type="text" class="form-control" placeholder="Enter Doctor / Nutritionist's Code" id="CodefromNutri" value="">
                           </div>
                           </div>
                          <!-- Form -->
                          <form class="text-center" style="color: #757575;">

                            <!-- Gender -->
                            <div class="md-form mt-3">
                              <h6 class="title-section-desc text-left">Gender:</h6>
                              <!-- Default inline 1-->
                              <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="defaultInline1" name="inlineDefaultRadiosExample" mdbInput (change)="ChangeOption('Female', 'Gender')">
                                <label class="custom-control-label" for="defaultInline1">Female</label>
                              </div>                        
                              <!-- Default inline 2-->
                              <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="defaultInline2" name="inlineDefaultRadiosExample" mdbInput (change)="ChangeOption('Male', 'Gender')">
                                <label class="custom-control-label" for="defaultInline2">Male</label>
                              </div>
                              <!-- Default inline 3-->
                              <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="defaultInline3" name="inlineDefaultRadiosExample" mdbInput (change)="ChangeOption('Other', 'Gender')">
                                <label class="custom-control-label" for="defaultInline3">Other</label>
                              </div>
                            </div>
                            <hr>

                            <!--Age -->
                            <div class="md-form text-center">                        
                              <div class="md-form " >
                                <div class="row">                                  
                                  <div class="md-form col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                    <h4 class="title-section-desc" style="display: inline-block;">Choose your Birthday Year: </h4>
                                    <mat-form-field appearance="fill" style="max-width: 100%;position: relative;">
                                      <mat-label>What is your year of birth?</mat-label>
                                      <select matNativeControl class="custom-control-label" style="border-bottom:1px solid #4a4a4a;" (change)="selectChange($event, 'Byear')">
                                        <option value="0">Choose...</option>
                                        <option *ngFor="let year of years" [value]="year">{{year}}</option>
                                      </select>                                  
                                    </mat-form-field>
                                  </div>
                                  
                                </div>
                                
                            
                                
                              </div>
                            </div>
                            <hr>
                            <div class="row">
                            <!-- Region -->
                              <div class="md-form col-xs-12 col-sm-12 col-md-6 col-lg-6 text-center">
                                <h4 class="title-section-desc">Choose a Region: </h4>
                                <mat-form-field appearance="fill" style="max-width: 100%;position: relative;">
                                  <mat-label>Where do yo live?</mat-label>
                                  <select id="RegionSelect" matNativeControl class="custom-control-label" style="border-bottom:1px solid #4a4a4a;" (change)="selectChange($event, 'region')">
                                    <option value="0">Choose...</option>
                                    <option value="1">Asia</option>
                                    <option value="2" >Africa</option>
                                    <option value="3" >Europe</option>
                                    <option value="4" >Oceania</option>
                                    <option value="5" >Antartica</option>
                                    <option value="6" >North America</option>
                                    <option value="7" >South America</option>
                                  </select>                                  
                                </mat-form-field>
                              </div>                      
                              <!-- <hr> -->

                              <!--Message-->
                              <div class="md-form col-xs-12 col-sm-12 col-md-6 col-lg-6 text-center">
                                <h4 class="title-section-desc">Choose a Educational Level: </h4>
                                <mat-form-field appearance="fill"  style="max-width: 100%;position: relative;">
                                  <mat-label>Studies Level</mat-label>
                                  <select matNativeControl class="custom-control-label" style="border-bottom:1px solid #4a4a4a;"  (change)="selectChange($event, 'education')">
                                    <option value="0">Choose...</option>
                                    <option value="1">Primary School</option>
                                    <option value="2">High School or similar</option>
                                    <option value="3">Bachelor's Degree</option>
                                    <option value="4">Master's Degree or Ph.D.</option>                           
                                  </select>                                  
                                </mat-form-field>
                              </div>
                          </div>
                          <hr>
                            <!-- Ocupation -->
                          
                            <div class="md-form">
                              <h6 class="title-section-desc text-left">Current Occupation:</h6>
                              <!-- Default inline 1-->
                              <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="defaultInline4" name="inlineDefaultRadiosExample2" mdbInput (change)="ChangeOption('Unemployed', 'Ocupation')">
                                <label class="custom-control-label" for="defaultInline4">Unemployed</label>
                              </div>                        
                              <!-- Default inline 2-->
                              <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="defaultInline5" name="inlineDefaultRadiosExample2" mdbInput (change)="ChangeOption('Employed', 'Ocupation')">
                                <label class="custom-control-label" for="defaultInline5">Employed</label>
                              </div>
                              <!-- Default inline 3-->
                              <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="defaultInline6" name="inlineDefaultRadiosExample2" mdbInput (change)="ChangeOption('Student', 'Ocupation')">
                                <label class="custom-control-label" for="defaultInline6">Student</label>
                              </div>
                            </div>
                            <hr>
                            <!--type of test-->
                            <div class="md-form ">
                              <div class="row">
                                <div class="md-form col-xs-12 col-sm-12 col-md-6 col-lg-6 text-center">
                                  <h4 class="title-section-desc" style="display: inline-block;">Choose a type of Test: </h4><br>
                                  <mat-form-field appearance="fill" style="max-width: 50%;position: relative;">
                                    <mat-label>What Test do You Prefer?</mat-label>
                                    <select matNativeControl class="custom-control-label" style="border-bottom:1px solid #4a4a4a;" (change)="selectChange($event, 'SizeTest')">
                                      <option value="0">Choose...</option>
                                      <option value="1">Brief questionnaire (3-5 minutes)</option>
                                      <option value="2">Full questionnaire (7-10 minutes)</option>                              
                                    </select>                                  
                                  </mat-form-field>
                                </div>
                                <div class="md-form col-xs-12 col-sm-12 col-md-6 col-lg-6 text-center">
                                  <h4 class="title-section-desc" style="display: inline-block;">Please choose the type of PKU: </h4><br>
                                  <mat-form-field appearance="fill" style="max-width: 50%;position: relative;">
                                    <mat-label>Type of PKU condition?</mat-label>
                                    <select matNativeControl class="custom-control-label" style="border-bottom:1px solid #4a4a4a;" (change)="selectChange($event, 'PKU')">
                                      <option value="0">Choose...</option>
                                      <option value="1">Mild</option>
                                      <option value="2">Moderate</option> 
                                      <option value="3">Classic</option>                              
                                    </select>                                  
                                  </mat-form-field>
                                </div>
                              </div>                        
                                
                            </div>
                          
                            <hr>
                            <!-- Send button -->
                            <button mdbBtn color="info" outline="true" rounded="true" block="true" class="z-depth-0 my-4 waves-effect" mdbWavesEffect
                              type="submit" style="font-family: 'ProximaNova_SemiBold';" (click)="toAgreement()">Send</button>

                          </form>
                          <!-- Form -->

                        </mdb-card-body>

                      </mdb-card>
                      <!-- Material form contact -->
                    <!-- <app-credits style="position: relative;display: block;width: 100%;margin: 0 auto;margin-top: 3%;"></app-credits> -->
              </div>
              
              <div id="disclaimer" style="display: none;">
                    <p>
                      {{'Test-disclaimer1' | translate}}
                    </p>
                    <p>
                      {{'Test-disclaimer2' | translate}}
                    </p>
                    <p>
                      {{'Test-disclaimer3' | translate}}
                    </p>
                    <div class="row flex section-buttons-disclaimer">
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex justify-content-end btn-mobile-diclaimer">
                        <button mdbBtn color="secondary" outline="true" rounded="true" block="true" class="z-depth-0 my-4 waves-effect" mdbWavesEffect
                        type="submit" style="font-family: 'ProximaNova_SemiBold';" (click)="goHome()">{{'Test-disclaimer-btn1' | translate}}</button>
                      </div>
                      
                      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex justify-content-start btn-mobile-diclaimer">
                        <button mdbBtn color="info" outline="true" rounded="true" block="true" class="z-depth-0 my-4 waves-effect" mdbWavesEffect
                        type="submit" style="font-family: 'ProximaNova_SemiBold';" (click)="goTest()">{{'Test-disclaimer-btn2' | translate}}</button>           
                      </div>
                      
                    </div>
                    <!-- <app-credits style="position: relative;display: block;width: 100%;margin: 0 auto;margin-top: 3%;"></app-credits> -->
              </div>
              <div class="row" id="Test-Card-row">
                  <div id="Test-Card" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 Test-Card">
                    <div id="title-explication"> 
                      <h2 class="title-foods-section">{{'Test-Header' | translate}}</h2>
                    </div>
                    <div class="food-elemts"></div>            
                  </div>
                  <div id="Progress-bar" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <app-progress-bar class="circle-bar"></app-progress-bar>                    
                  </div>
                  <div class="row results" id="results">
                    <app-results></app-results>
                    
                  </div>
                  
              </div>
              <app-credits id="credit-test" style="position: absolute;display: none;width: 100%;margin-top: 3%;bottom:0;top:auto;"></app-credits>
            
  </div>
  </div>
  <app-alert></app-alert>
</div>