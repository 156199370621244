import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';
import { map, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import exportFromJSON from 'export-from-json'
@Injectable({
  providedIn: 'root'
})
export class CollectionService {

  //firebase related
  foods: AngularFirestoreCollection<any>
  foods2: AngularFirestoreCollection<any>
  score: AngularFirestoreCollection<CollectionScore>
  percentil: AngularFirestoreCollection<CollectionPercentil>
  percentilF1: AngularFirestoreCollection<CollectionPercentilFactor>
  percentilF2: AngularFirestoreCollection<CollectionPercentilFactor>
  percentilF3: AngularFirestoreCollection<CollectionPercentilFactor>
  SScoreSmall: AngularFirestoreCollection<CollectionSScoreSmall>
  Profiles: AngularFirestoreCollection<Profile>
  SProfiles: AngularFirestoreCollection<Profile>
  Results: AngularFirestoreCollection<CollectionResults>
  Answers: AngularFirestoreCollection<CollectionAnswers>
  AnswersR: any;
  SAnswers: AngularFirestoreCollection<CollectionAnswers>
  Users: AngularFirestoreCollection<CollectionUsers>
  patients: AngularFirestoreCollection<Patients>
  Ofodds: any
  Ofodds2: any
  Score: any
  Percent: any
  PercentF1: any
  PercentF2: any
  PercentF3: any
  SScore: any
  GProfile: any
  GSProfile: any
  CResults: any
  ColAnswers: any
  ColSAnswers: any
  ColSCustmonfood: any
  ColUsers: any
  ColPatents: any
  user = null
  UserCollection: any;
  PatientCollection: any;
  FUser= environment.AuthFireStore.email;
  FPsw = environment.AuthFireStore.passw;
  fakeData = environment.fakeData;
  TypeFood = JSON.parse(localStorage.getItem('DataUser'));
  Ofood = [];
  DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm'; 
  TestUID: string;
  GramsFen: string = '0';

  constructor(public FAuth: AngularFireAuth, private FireS: AngularFirestore, private userauth: AuthService) {
    setTimeout(()=>{
      this.SetAuth();
    },5000)
      
   }

   SetAuth(){
      return this.FAuth.setPersistence(firebase.default.auth.Auth.Persistence.SESSION).then(_ => {
        return this.FAuth.signInWithEmailAndPassword(this.FUser,this.FPsw);
      }).catch(err=> {
        console.log("ERROR AUTH: " , err);
        
      })
   }

   GetFoods (){ return  new Promise( (resolve, reject) => {
     try {
      if (this.fakeData){
        resolve(fakedataFood);
       }else {
         console.log("this.GramsFen=> ", this.GramsFen);
       let CollectionFoodsPatients = parseInt(this.GramsFen) == 100 ? 'list100' : 'list';
     
            this.foods = this.FireS.collection(
              `DataCollection/foods/${CollectionFoodsPatients}`,
              ref => ref.orderBy('id')
            );
       
          this.Ofodds = this.foods.snapshotChanges().pipe(
            map(actions => 
              actions.map(a => {
                const data = a.payload.doc.data();
                const idg = a.payload.doc.id;
                return {idg,...data}
              })
              )
          ).subscribe( data => {
            console.log("data collection => ", data);
            resolve(data);
          })       
          
      //    }).catch(e => {
      //     reject([]);
      //     console.log("Error found-> ", e);
      // });
    }
       
     } catch (error) {
       console.log("ERROR GETFOODS: ", error);
       var element = document.getElementById("popup1");
        element.style.visibility = 'visible';
        element.style.opacity = '1';
     }
   
   })
  }

   //Score Table

   GetParamScore = new Promise( (resolve, reject) => {
    if (this.fakeData){
      resolve(fackeParamScore);
     }else {
 
          this.score = this.FireS.collection(
            `DataCollection/foods/Score`,
            ref => ref.orderBy('factor')
          );
     
        this.Score = this.score.snapshotChanges().pipe(
          map(actions => 
            actions.map(a => {
              const data = a.payload.doc.data();
              const id = a.payload.doc.id;
              return {id,...data}
            })
            )
        ).subscribe( data => {
          console.log("GetParamScore collection => ", data);
          resolve(data);
        })         
 
    }
   });

   //Percentile Table
   GetPercentil = new Promise( (resolve, reject) => {
    if (this.fakeData){
      resolve(fakePercentilCollection);
     }else {
    // this.FAuth.signInWithEmailAndPassword(this.FUser,this.FPsw).then(res => {
   
          this.percentil = this.FireS.collection(
            `DataCollection/foods/Percentil`,
            ref => ref.orderBy('score')
          );
     
        this.Percent = this.percentil.snapshotChanges().pipe(
          map(actions => 
            actions.map(a => {
              const data = a.payload.doc.data();
              const id = a.payload.doc.id;
              return {id,...data}
            })
            )
        ).subscribe( data => {
          console.log("GetPercentil collection => ", data);
          resolve(data);
        })           
    //    }).catch(e => {
    //     reject([]);
    //     console.log("Error found-> ", e);
    // });
  }
   })
   
    //Percentile Table
    GetPercentilF1 = new Promise( (resolve, reject) => {
      if (this.fakeData){
        resolve(fakePercentilF1);
       }else {
      // this.FAuth.signInWithEmailAndPassword(this.FUser,this.FPsw).then(res => {
     
            this.percentilF1 = this.FireS.collection(
              `DataCollection/foods/PercentilFactor1`,
              ref => ref.orderBy('RawScore')
            );
       
          this.PercentF1 = this.percentilF1.snapshotChanges().pipe(
            map(actions => 
              actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return {id,...data}
              })
              )
          ).subscribe( data => {
            console.log("GetPercentilF1 collection => ", data);
            resolve(data);
          })              
      //    }).catch(e => {
      //     reject([]);
      //     console.log("Error found-> ", e);
      // });
    }
     });
  //Percentile Table
  GetPercentilF2 = new Promise( (resolve, reject) => {
    if (this.fakeData){
      resolve(fakePercentilF2);
     }else {
    // this.FAuth.signInWithEmailAndPassword(this.FUser,this.FPsw).then(res => {
   
          this.percentilF2 = this.FireS.collection(
            `DataCollection/foods/PercentilFactor2`,
            ref => ref.orderBy('RawScore')
          );
     
        this.PercentF2 = this.percentilF2.snapshotChanges().pipe(
          map(actions => 
            actions.map(a => {
              const data = a.payload.doc.data();
              const id = a.payload.doc.id;
              return {id,...data}
            })
            )
        ).subscribe( data => {
          console.log("GetPercentilF2 collection => ", data);
          resolve(data);
        })           
    //    }).catch(e => {
    //      reject([])
    //     console.log("Error found-> ", e);
    // });
  }
   });

     //Percentile Table
     GetPercentilF3 = new Promise( (resolve, reject) => {
      if (this.fakeData){
        resolve(fakePercentilF3);
       }else {
    //  this.FAuth.signInWithEmailAndPassword(this.FUser,this.FPsw).then(res => {
     
            this.percentilF3 = this.FireS.collection(
              `DataCollection/foods/PercentilFactor3`,
              ref => ref.orderBy('RawScore')
            );
       
          this.PercentF3 = this.percentilF3.snapshotChanges().pipe(
            map(actions => 
              actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return {id,...data}
              })
              )
          ).subscribe( data => {
            console.log("GetPercentilF3 collection => ", data);
            resolve(data);
          })              
      //    }).catch(e => {
      //     reject([]);
      //     console.log("Error found-> ", e);
      // });
    }
     });
    //Get S-Score Small Test Table
    GetSScoreSmallTest = new Promise( (resolve, reject) => {
      if (this.fakeData){
        resolve(fakeSScoreSmallCol);
       }else {
    // this.FAuth.signInWithEmailAndPassword(this.FUser,this.FPsw).then(res => {
    
          this.SScoreSmall = this.FireS.collection(
            `DataCollection/foods/SScoreTestSmall`,
            ref => ref.orderBy('RawScore')
          );
      
        this.SScore = this.SScoreSmall.snapshotChanges().pipe(
          map(actions => 
            actions.map(a => {
              const data = a.payload.doc.data();
              const id = a.payload.doc.id;
              return {id,...data}
            })
            )
        ).subscribe( data => {
          console.log("SScoreSmall collection => ", data);
          resolve(data);
        })              
    //     }).catch(e => {
    //     reject([]);
    //     console.log("Error found-> ", e);
    // });
  }
    });

    //Get Profiles Table
    GetProfiles = new Promise( (resolve, reject) => {
      if (this.fakeData && this.userauth.isLoggedIn()){
        resolve(fakeGetProfiles);
       }else if(this.userauth.isLoggedIn()){      
      // this.FAuth.signInWithEmailAndPassword(this.FUser,this.FPsw).then(res => {
      
            this.Profiles = this.FireS.collection(
              `profiles`,
              ref => ref.orderBy('startAt')
            );
        
          this.GProfile = this.Profiles.snapshotChanges().pipe(
            map(actions => 
              actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return {id,...data}
              })
              )
          ).subscribe( data => {
            // console.log("GProfile collection => ", data);
            resolve(data);
          })              
      //     }).catch(e => {
      //     reject([]);
      //     console.log("Error found-> ", e);
      // });
    }else {
      resolve([]);
    }
      });

        //Get Profiles Table
    GetResultsProfiles = new Promise( (resolve, reject) => {
      if (this.fakeData && this.userauth.isLoggedIn()){
        resolve(fakeGetResultsProfiles);
       }else if(this.userauth.isLoggedIn()){
      // resolve(fakedtaResult);
      // this.FAuth.signInWithEmailAndPassword(this.FUser,this.FPsw).then(res => {
      
            this.Results = this.FireS.collection(
              `results`,
              ref => ref.orderBy('dataResult')
            );
        
          this.CResults = this.Results.snapshotChanges().pipe(
            map(actions => 
              actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return {id,...data}
              })
              )
          ).subscribe( data => {
            // console.log("GetResultsProfiles collection => ", data);
            resolve(data);
          })              
      //     }).catch(e => {
      //     reject([]);
      //     console.log("Error found-> ", e);
      // });
    }else {
      resolve([]);
    }
      });

      //Get Answers Table
    GetAnswers =  new Promise((resolve, reject) => {
            // if (this.fakeData && this.userauth.isLoggedIn()){
            //   resolve(fakeAnswers);
            // }else if(this.userauth.isLoggedIn()) {          
            // this.FAuth.signInWithEmailAndPassword(this.FUser,this.FPsw).then(res => {
            
                  this.Answers = this.FireS.collection(
                    `answers`,
                    ref => ref.orderBy('Answer')
                  );
              
                this.ColAnswers = this.Answers.snapshotChanges().pipe(
                  map(actions => 
                    actions.map(a => {
                      const data = a.payload.doc.data();
                      const id = a.payload.doc.id;
                      return {id,...data}
                    })
                    )
                ).subscribe( data => {
                  // console.log("GetAnswers collection => ", data);
                  resolve(data);
                })              
            //     }).catch(e => {
            //     reject([]);
            //     console.log("Error found-> ", e);
            // });
          // }else {
          //   resolve([]);
          // }
    })
    GetAnswersResult(id) { return new Promise((resolve, reject) => {    
      
            this.AnswersR = this.FireS.collection<AngularFirestoreCollection<CollectionAnswers>>(`answers`).doc(id).snapshotChanges().pipe(
              map(actions => {
                const data = actions.payload.data();
                const id = actions.payload.id;                                                               
                 return {id,...data}                             
              })
            ).subscribe(data => {
              console.log("GetAnswersResult: ", data);
              resolve(data);
          });  
  
      })
    }
       //Get users Table
    GetUsers = new Promise((resolve, reject) => {
      if (this.fakeData && this.userauth.isLoggedIn()){
        resolve([]);
      }else if(this.userauth.isLoggedIn()) { 
            this.Users = this.FireS.collection(
              `users`,
              ref => ref.orderBy('username')
            );
        
          this.ColUsers = this.Users.snapshotChanges().pipe(
            map(actions => 
              actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return {id,...data}
              })
              )
          ).subscribe( data => {
            // console.log("GetUsers collection => ", data);
            resolve(data);
          })   
        }           
      //     }).catch(e => {
      //     reject([]);
      //     console.log("Error found-> ", e);
      // });
    // }else {
    //   resolve([]);
    // }
    })

  SaveProfile = function(profile){
        return  new Promise( (resolve, reject) => {
            console.log("profile => ", profile);
            // this.FAuth.signInWithEmailAndPassword(this.FUser,this.FPsw).then(res => {
              //let _now: Moment;
             let _now = new Date().toLocaleString();
              console.log("moment => ", _now);
              this.FireS.collection("profiles").doc(profile.id).set({                
                          Agree : profile.Agree,
                          PKU : profile.PKU,
                          SizeTest: profile.SizeTest,
                          birthday: profile.birthday,
                          educationLevel: profile.educationLevel,
                          gender: profile.gender,
                          ocupation: profile.ocupation,
                          region: profile.region,
                          startAt: _now,
                          typeTest: profile.typeTest 
                        }).then(res => {
                          console.log("res => ", res);
                          resolve("ok");
                        }).catch(err => {
                          reject(err);
                        })
            // });
   
        });
    }

  SaveAnswer = function(Answer, id){
      return  new Promise( (resolve, reject) => {
          console.log("Answer => ", Answer);
          // this.FAuth.signInWithEmailAndPassword(this.FUser,this.FPsw).then(res => {
            //let _now: Moment;
           let _now = new Date().toLocaleString();
            console.log("moment => ", _now);
            this.FireS.collection("answers").doc(id).set({  Answer }).then(res => {
                        console.log("res => ", res);
                        resolve("ok");
                      }).catch(err => {
                        reject(err);
                      })
          // });
 
      });
  }

  SaveResults = function(dataResult, id){
    return  new Promise( (resolve, reject) => {
        console.log("dataResult => ", dataResult);
        // this.FAuth.signInWithEmailAndPassword(this.FUser,this.FPsw).then(res => {
          //let _now: Moment;
          this.FireS.collection("results").doc(id).set({  dataResult }).then(res => {
                      console.log("res => ", res);
                      resolve("ok");
                    }).catch(err => {
                      reject(err);
                    })
        // });

    });
}
    
  SaveUSerEx(id, UserEdit){
    return  new Promise( (resolve, reject) => {
      console.log("saving in firebase");   

        this.FireS.collection("users").doc(id).update({ email: UserEdit.email, password: UserEdit.password, type: UserEdit.type, username: UserEdit.username }).then(res => {
                    console.log("SaveUserEx - res => ", res);
                    resolve("ok");
                  }).catch(err => {
                    console.log("SaveUserEx - Reject => ", err);
                    reject(err);
                  });
  });
  }
  SaveNewUSer( NewUser){
    return  new Promise( (resolve, reject) => {
      console.log("saving newUser in firebase");   

        this.FireS.collection("users").doc().set({ email: NewUser.email, password: NewUser.password, type: NewUser.type, username: NewUser.username }).then(res => {
                    console.log("SaveUserEx - res => ", res);
                    resolve("ok");
                  }).catch(err => {
                    console.log("SaveUserEx - Reject => ", err);
                    reject(err);
                  });
  });
  }
  SaveNewFood( NewFood){
    return  new Promise( (resolve, reject) => {
      console.log("saving newUser in firebase");   
        this.FireS.collection("DataCollection/foods/list").doc().set({ id: NewFood.id, class: NewFood._class, type: NewFood.type, allow: NewFood.allow, name: NewFood.name, factor: NewFood.factor, idN: NewFood.idN }).then(res => {
                    console.log("SaveNewFood - res => ", res);
                    resolve("ok");
                  }).catch(err => {
                    console.log("SaveNewFood - Reject => ", err);
                    reject(err);
                  });
  });
  }

  DeleteUser(id) {
    return  new Promise( (resolve, reject) => {
      console.log("saving newUser in firebase");   

        this.FireS.collection("users").doc(id).delete().then(res => {
                    console.log("DeleteUser - res => ", res);
                    resolve("ok");
                  }).catch(err => {
                    console.log("DeleteUser - Reject => ", err);
                    reject(err);
                  });
  });
  }
  DeleteFood(id) {
    return  new Promise( (resolve, reject) => {
      console.log("delete food in firebase");   

        this.FireS.collection("DataCollection/foods/list").doc(id).delete().then(res => {
                    console.log("DeleteFood - res => ", res);
                    resolve("ok");
                  }).catch(err => {
                    console.log("DeleteFood - Reject => ", err);
                    reject(err);
                  });
  });
  }

  GetSAnswer(id: string){
    return new Promise((resolve, reject) => {
      console.log("GesSAnswer id: ", id);
      if (this.fakeData && this.userauth.isLoggedIn()){
        resolve([]);
      }else if(this.userauth.isLoggedIn()) { 
            this.ColSAnswers = this.FireS.collection<AngularFirestoreCollection<CollectionAnswers>>(`answers`).doc(id).snapshotChanges().pipe(
                      map(actions => {
                        const data = actions.payload.data();
                        const id = actions.payload.id;                                                               
                         return {id,...data}                             
                      })
                    ).subscribe(data => {
                      console.log("GesSAnswer: ", data);
                      resolve(data);
                  }); 
                
                  // this.ColSAnswers = this.SAnswers.snapshotChanges().pipe(
                  //   map(actions => 
                  //     actions.map(a => {
                  //       const data = a.payload.doc.data();
                  //       const id = a.payload.doc.id;
                  //       return {id,...data}
                  //     })
                  //     )
                  // ).subscribe( data => {
                  //   console.log("GetSAnswer collection => ", data);
                  //   resolve(data);
                  // })   
                }     
    })
  }

     //Get Profiles Table
     GetSProfiles (idProf){
       return new Promise( (resolve, reject) => {                    
        console.log("GetSProfiles id: ", idProf);
                        this.GSProfile  = this.FireS.collection<AngularFirestoreCollection<Profile>>(`profiles`).doc(idProf).snapshotChanges().pipe(
                                  map(actions => {
                                    const data = actions.payload.data();
                                    const id = actions.payload.id;                                                               
                                     return {id,...data}                             
                                  })
                        ).subscribe(data => {
                            // console.log("GetSProfiles: ", data);
                            resolve(data);
                        });                
        
        });
    }

/**PAteines */
  SavePatient(patient: Patients) {
    return  new Promise( (resolve, reject) => {
      console.log("saving Patient in firebase");   
        this.FireS.collection("patients").doc(patient.id).set({ email: patient.email, firstname: patient.firstname, lastname: patient.lastname, id: patient.id, Gram: patient.Gram }).then(res => {
                    console.log("SavePatient - res => ", res);
                    resolve("ok");
                  }).catch(err => {
                    console.log("SavePatient - Reject => ", err);
                    reject(err);
                  });
  });
  }
 
  GetPatients = new Promise((resolve, reject) => {
          this.patients = this.FireS.collection(
            `patients`,
            ref => ref.orderBy('id')
          );
      
        this.ColPatents = this.patients.snapshotChanges().pipe(
          map(actions => 
            actions.map(a => {
              const data = a.payload.doc.data();
              const id = a.payload.doc.id;
              return {...data}
            })
            )
        ).subscribe( data => {
          //console.log("GetPatients collection => ", data);
          resolve(data);
        })       
  })
  

  GetFoodsByType(Id: number){

    
    try {
            console.log("Services: ", Id);
            if (this.fakeData){       

            }else {    
              let typelist = "";
                  switch (Id) {
                    case 50:
                      typelist = "list"
                      break;
                    case 100:
                      typelist = "list100"
                      break;
                  
                    default:
                      typelist = "list"
                      break;
                  }
                  console.log("typelist: ", typelist);
                  this.foods = this.FireS.collection(
                    `DataCollection/foods/${typelist}`,
                    ref => ref.orderBy('id')
                  );
            
                this.Ofodds = this.foods.snapshotChanges().pipe(
                  map(actions => 
                    actions.map(a => {
                      const data = a.payload.doc.data();
                      const idg = a.payload.doc.id;
                      return {idg,...data}
                    })
                    )
                ).subscribe( data => {
                  console.log("data collection from Nutritionist=> ", data);
                     data = data.map(dta=> {
                      const Food = dta.name;
                      const Allow = dta.allow  == true ? 'Allowed' : 'Forbidden';   
                      return { Food, Allow };
                    });
                    console.log("data collection from Formatter=> ", data);
                    const fileName = `Foods_PKU_${Id}%_phenylalanine`;
                    const exportType =  exportFromJSON.types.csv

                    exportFromJSON({ data, fileName, exportType })
                })     
            } 
      } catch (error) {
          console.log("ERROR GETFOODS Nutritionist: ", error);
          
        }
  
  
  }

}
  const fakedata = [{
    "id": "N-TEST-vE88nqrJ1oBvuac3kYLe-0d8a5e4f-5866-4840-b1a6-5b2606bc5d45",
    "SizeTest": "2",
    "startAt": "12/14/2021, 7:37:13 PM",
    "PKU": "3",
    "birthday": "1949",
    "gender": "Other",
    "ocupation": "Employed",
    "typeTest": "Physician, nutritionist, nurse, Health Professional",
    "region": "6",
    "educationLevel": "1",
    "Agree": "Yes"
  },
  {
    "id": "449b3663-b9a3-4b08-9410-317432400aaf",
    "Agree": "Yes",
    "startAt": "12/15/2021, 4:57:00 AM",
    "SizeTest": "1",
    "birthday": "1955",
    "region": "7",
    "ocupation": "Student",
    "typeTest": "Physician, nutritionist, nurse, Health Professional",
    "PKU": "2",
    "gender": "Other",
    "educationLevel": "1"
  }​,
  {
    "id": "460f6fe6-6c9a-4f5a-8ebf-cfa686e39690",
    "gender": "Other",
    "SizeTest": 2,
    "birthday": "1998",
    "Agree": "Yes",
    "typeTest": "Student or any other case",
    "region": 3,
    "ocupation": "Employed",
    "PKU": "2",
    "educationLevel": 2,
    "startAt": "12/15/2021, 5:04:12 AM"
  }​,
  {
    "id": "1f229f50-b398-4c3d-b7ba-95e204a74637",
    "birthday": "2001",
    "region": "5",
    "startAt": "12/16/2021, 4:50:48 AM",
    "gender": "Female",
    "Agree": "Yes",
    "SizeTest": "1",
    "PKU": "2",
    "educationLevel": "1",
    "ocupation": "Student",
    "typeTest": "Student or any other case"
  }​,
  {
    "id": "c2ea7e31-71e5-4d34-9340-c086a7c13755",
    "Agree": "Yes",
    "startAt": "12/16/2021, 5:02:34 AM",
    "region": "7",
    "ocupation": "Student",
    "educationLevel": "1",
    "SizeTest": "1",
    "gender": "Female",
    "typeTest": "Physician, nutritionist, nurse, Health Professional",
    "PKU": "2",
    "birthday": "1996"
  }​,
  {
    "id": "3a773e92-de88-4fe5-9050-4fa495891dfe",
    "PKU": "3",
    "educationLevel": "1",
    "startAt": "12/16/2021, 5:17:59 AM",
    "SizeTest": "2",
    "gender": "Male",
    "typeTest": "Adult with relative diagnosed with PKU",
    "region": "5",
    "ocupation": "Unemployed",
    "Agree": "Yes",
    "birthday": "1989"
  }​,
  {
    "id": "90ebe5f6-fa97-47c2-9919-8e2ff59b5a3a",
    "typeTest": "Physician, nutritionist, nurse, Health Professional",
    "region": "6",
    "educationLevel": "1",
    "PKU": "3",
    "birthday": "1949",
    "ocupation": "Employed",
    "SizeTest": "2",
    "gender": "Other",
    "startAt": "12/16/2021, 6:12:21 PM",
    "Agree": "Yes"
  }​,
  {
    "id": "3ff3a424-d4a5-4bf9-931a-6829f61e9a1e",
    "region": "4",
    "PKU": "1",
    "ocupation": "Student",
    "SizeTest": "1",
    "Agree": "Yes",
    "birthday": "2005",
    "typeTest": "Student or any other case",
    "startAt": "15/12/2021 21:45:09",
    "educationLevel": "1",
    "gender": "Female"
  }​,
  {
    "id": "3b423a2d-d6d3-449b-b3fc-2ba8802e05a9",
    "educationLevel": "1",
    "typeTest": "Student or any other case",
    "birthday": "2005",
    "startAt": "15/12/2021 21:52:57",
    "gender": "Female",
    "ocupation": "Student",
    "SizeTest": "1",
    "Agree": "Yes",
    "PKU": "1",
    "region": "4"
  }​,
  {
    "id": "065e6a9b-48ec-4b47-930f-7eb68dc6750c",
    "gender": "Female",
    "region": "4",
    "SizeTest": "1",
    "ocupation": "Student",
    "educationLevel": "2",
    "startAt": "2021-12-15, 9:01:37 p.m.",
    "Agree": "Yes",
    "typeTest": "Adult with relative diagnosed with PKU",
    "birthday": "2008",
    "PKU": "2"
  }​,
  {
    "id": "c74f04a7-12f4-4b5f-a5e3-9cbc940bafe3",
    "SizeTest": "1",
    "gender": "Other",
    "birthday": "2008",
    "Agree": "No",
    "PKU": "1",
    "educationLevel": "1",
    "startAt": "2021-12-15, 9:19:25 p.m.",
    "ocupation": "Student",
    "region": "5",
    "typeTest": "Adult with relative diagnosed with PKU"
  }​,
  {
    "id": "7c7c5cd6-e609-41fc-a967-3383521f0763",
    "Agree": "Yes",
    "startAt": "2021-12-15, 9:56:40 p.m.",
    "educationLevel": "1",
    "birthday": "2009",
    "PKU": "2",
    "gender": "Male",
    "region": "3",
    "ocupation": "Student",
    "typeTest": "Physician, nutritionist, nurse, Health Professional",
    "SizeTest": "1"
  }]

const fakedtaResult = [
  {"id":"N-TEST-vE88nqrJ1oBvuac3kYLe-0d8a5e4f-5866-4840-b1a6-5b2606bc5d45",
  "dataResult": {
               
                "GRawScore": 32,
                "GenPercentil": 4,
                "PercentsF1": 35,
                "PercentsF2" :30,
                "PercentsF3": 30,
                "PointsF1": 8,
                "PointsF2": 5,
                "PointsF3": 6,
                "PointsF4": 4,
                "PointsF5": 9,
                "ScoreF1" :70,
                "ScoreF2" :30,
                "ScoreF3": 55,
                "date": "2021-12-26, 10:33:06 a.m." 
              }},
  {"id":"449b3663-b9a3-4b08-9410-317432400aaf",
  "dataResult": {
    
            "GRawScore" : 15,
            "GenPercentil": 0,
            "PercentsF1": 0,
            "PercentsF2": 0,
            "PercentsF3": 0,
            "PointsF1": 7,
            "PointsF2": 3,
            "PointsF3": 5,
            "PointsF4": 0,
            "PointsF5": 0,
            "ScoreF1": 65,
            "ScoreF2": 10,
            "ScoreF3": 50,
            "date": "2021-12-26, 1:27:34 p.m." 
  }},
  {"id":"460f6fe6-6c9a-4f5a-8ebf-cfa686e39690",
  "dataResult": {
    
            "GRawScore":33,
            "GenPercentil": 10,
            "PercentsF1": 30,
            "PercentsF2": 35,
            "PercentsF3": 50,
            "PointsF1": 7,
            "PointsF2": 6,
            "PointsF3": 7,
            "PointsF4": 5,
            "PointsF5": 8,
            "ScoreF1" :65,
            "ScoreF2" :40,
            "ScoreF3" :60,
            "date": "2021-12-26, 1:30:08 p.m." 
  }},
  {"id":"af04b295-6fd8-48a8-9829-f51e2bf8898c",
  "dataResult": {
      
        "GRawScore": 25,
        "GenPercentil": 0,
        "PercentsF1": 0,
        "PercentsF2": 0,
        "PercentsF3": 0,
        "PointsF1": 12,
        "PointsF2": 6,
        "PointsF3": 7,
        "PointsF4": 0,
        "PointsF5": 0,
        "ScoreF1": 90,
        "ScoreF2": 40,
        "ScoreF3": 60,
        "date": "2021-12-26, 12:30:13 p.m." 
}},
{"id":"c2ea7e31-71e5-4d34-9340-c086a7c13755",
"dataResult": {
          
        "GRawScore": 15,
        "GenPercentil": 0,
        "PercentsF1": 0,
        "PercentsF2": 0,
        "PercentsF3": 0,
        "PointsF1": 7,
        "PointsF2": 3,
        "PointsF3": 5,
        "PointsF4": 0,
        "PointsF5": 0,
        "ScoreF1": 65,
        "ScoreF2": 10,
        "ScoreF3": 50,
        "date": "2021-12-26, 10:30:24 a.m." 
}}

]

const fakedataFood = [
  {
    "id": 1,
    "type": "brief",
    "class": "number1",
    "allow": true,
    "name": "Pepper",
    "factor": 1
  },
  {
    "id": 2,
    "class": "number2",
    "factor": 1,
    "allow": true,
    "name": "Pineapple",
    "type": "brief"
  },
  {
    "id": 3,
    "class": "number3",
    "allow": true,
    "factor": 2,
    "type": "brief",
    "name": "Diet coke"
  },
  {
    "id": 4,
    "type": "brief",
    "name": "Broccoli",
    "allow": true,
    "class": "number4",
    "factor": 3
  },
  {
    "id": 5,
    "type": "brief",
    "class": "number5",
    "factor": 1,
    "name": "Canned fruit",
    "allow": true
  },
  {
    "id": 6,
    "factor": 2,
    "class": "number6",
    "name": "Nachos",
    "allow": false,
    "type": "brief"
  },
  {
    "id": 7,
    "class": "number7",
    "type": "brief",
    "factor": 3,
    "allow": false,
    "name": "Rice"
  },
  {
    "id": 8,
    "name": "French fries",
    "type": "brief",
    "factor": 3,
    "class": "number8",
    "allow": "number8"
  },
  {
    "id": 9,
    "name": "Roasted Potatoes",
    "factor": 3,
    "class": "number9",
    "allow": false,
    "type": "brief"
  },
  {
    "id": 10,
    "type": "brief",
    "allow": false,
    "factor": 2,
    "class": "number10",
    "name": "Flour"
  },
  {
    "id": 11,
    "allow": true,
    "factor": 1,
    "class": "number11",
    "type": "brief",
    "name": "Ketchup"
  },
  {
    "id": 12,
    "class": "number12",
    "type": "brief",
    "allow": true,
    "name": "Onion",
    "factor": 1
  },
  {
    "id": 13,
    "name": "Lettuce",
    "class": "number13",
    "allow": true,
    "factor": 1,
    "type": "brief"
  },
  {
    "id": 14,
    "allow": false,
    "factor": 2,
    "class": "number14",
    "type": "brief",
    "name": "Beans"
  },
  {
    "id": 15,
    "factor": 1,
    "type": "brief",
    "allow": true,
    "class": "number15",
    "name": "Syrup"
  },
  {
    "id": 16,
    "type": "brief",
    "name": "Coconut",
    "class": "number16",
    "allow": false,
    "factor": 3
  },
  {
    "id": 17,
    "allow": false,
    "class": "number17",
    "factor": 3,
    "type": "brief",
    "name": "Green peas"
  },
  {
    "id": 18,
    "class": "number18",
    "type": "brief",
    "factor": 3,
    "name": "Avocado",
    "allow": false
  },
  {
    "id": 19,
    "factor": 2,
    "allow": false,
    "class": "number19",
    "type": "brief",
    "name": "Sunflower seeds"
  },
  {
    "id": 20,
    "class": "number20",
    "type": "brief",
    "allow": true,
    "name": "Pickles",
    "factor": 1
  },
  {
    "id": 21,
    "factor": 2,
    "class": "number21",
    "name": "Granola type cereal",
    "type": "brief",
    "allow": false
  },
  {
    "id": 22,
    "factor": 2,
    "class": "number22",
    "name": "Hot chocolate mix",
    "allow": false,
    "type": "brief"
  },
  {
    "id": 23,
    "type": "brief",
    "class": "number23",
    "allow": true,
    "name": "Orange",
    "factor": 1
  },
  {
    "id": 24,
    "name": "Watermelon",
    "factor": 1,
    "allow": true,
    "class": "number24",
    "type": "brief"
  },
  {
    "id": 25,
    "class": "number25",
    "factor": 3,
    "type": "brief",
    "allow": false,
    "name": "Pumpkin"
  },
  {
    "id": 26,
    "allow": false,
    "factor": 2,
    "class": "number26",
    "name": "Hazelnuts",
    "type": "brief"
  },
  {
    "id": 27,
    "name": "Fresh orange juice",
    "allow": true,
    "type": "brief",
    "factor": 1,
    "class": "number27"
  },
  {
    "id": 28,
    "type": "brief",
    "factor": 2,
    "name": "Beef Bouillon",
    "class": "number28",
    "allow": false
  },
  {
    "id": 29,
    "type": "brief",
    "class": "number29",
    "name": "Melon",
    "allow": false,
    "factor": 1
  },
  {
    "id": 30,
    "factor": 3,
    "allow": false,
    "type": "brief",
    "name": "Artichoke",
    "class": "number30"
  },
  {
    "id": 31,
    "class": "number31",
    "name": "Egg White",
    "type": "brief",
    "allow": false,
    "factor": 2
  },
  {
    "id": 32,
    "type": "brief",
    "factor": 1,
    "name": "Kiwi",
    "allow": true,
    "class": "number32"
  },
  {
    "id": 33,
    "name": "Dark Chocolate",
    "allow": false,
    "class": "number33",
    "type": "brief",
    "factor": 3
  },
  {
    "id": 34,
    "class": "number34",
    "allow": false,
    "type": "brief",
    "factor": 3,
    "name": "Banana"
  },
  {
    "id": 35,
    "factor": 1,
    "type": "brief",
    "class": "number35",
    "allow": true,
    "name": "Olives"
  },
  {
    "id": 36,
    "name": "Plum",
    "type": "brief",
    "class": "number36",
    "factor": 1,
    "allow": true
  },
  {
    "id": 37,
    "factor": 5,
    "allow": false,
    "type": "large",
    "name": "Mustard",
    "class": "number37"
  },
  {
    "id": 38,
    "factor": 4,
    "allow": true,
    "type": "large",
    "name": "Cornstarch",
    "class": "number38"
  },
  {
    "id": 39,
    "class": "number39",
    "name": "Margarine",
    "type": "large",
    "allow": true,
    "factor": 4
  },
  {
    "id": 40,
    "type": "large",
    "factor": 5,
    "allow": false,
    "class": "number40",
    "name": "Herring"
  },
  {
    "id": 41,
    "name": "Milk",
    "factor": 5,
    "allow": false,
    "class": "number41",
    "type": "large"
  },
  {
    "id": 42,
    "name": "Shrimp",
    "allow": false,
    "type": "large",
    "factor": 5,
    "class": "number42"
  },
  {
    "id": 43,
    "type": "large",
    "factor": 5,
    "name": "Almonds",
    "allow": false,
    "class": "number43"
  },
  {
    "id": 44,
    "class": "number44",
    "allow": true,
    "factor": 4,
    "type": "large",
    "name": "Butter"
  },
  {
    "id": 45,
    "name": "Olive Oil",
    "class": "number45",
    "type": "large",
    "factor": 4,
    "allow": true
  },
  {
    "id": 46,
    "name": "Sugar",
    "type": "large",
    "allow": true,
    "class": "number46",
    "factor": 4
  },
  {
    "id": 47,
    "type": "large",
    "name": "Cashews",
    "class": "number47",
    "factor": 5,
    "allow": false
  },
  {
    "id": 48,
    "name": "Vinegar",
    "class": "number48",
    "type": "large",
    "factor": 4,
    "allow": true
  },
  {
    "id": 49,
    "class": "number49",
    "factor": 5,
    "name": "Honey",
    "type": "large",
    "allow": true
  },
  {
    "id": 50,
    "factor": 2,
    "type": "large",
    "allow": false,
    "class": "number50",
    "name": "Cheese"
  },
  {
    "id": 51,
    "factor": 4,
    "type": "large",
    "class": "number51",
    "allow": true,
    "name": "Coke"
  },
  {
    "id": 52,
    "factor": 5,
    "allow": false,
    "type": "large",
    "class": "number52",
    "name": "Yogurt"
  },
  {
    "id": 53,
    "allow": false,
    "type": "large",
    "factor": 5,
    "name": "Canned tuna",
    "class": "number53"
  },
  {
    "id": 54,
    "type": "large",
    "factor": 4,
    "name": "Sugary beverages",
    "allow": true,
    "class": "number54"
  },
  {
    "id": 55,
    "class": "number55",
    "factor": 4,
    "name": "Baking powder",
    "allow": true,
    "type": "large"
  },
  {
    "id": 56,
    "type": "large",
    "name": "Saccharin",
    "allow": true,
    "class": "number56",
    "factor": 4
  },
  {
    "id": 57,
    "class": "number57",
    "name": "Cocoa butter",
    "type": "large",
    "allow": true,
    "factor": 4
  },
  {
    "id": 58,
    "factor": 5,
    "allow": false,
    "type": "large",
    "class": "number58",
    "name": "Egg"
  },
  {
    "id": 59,
    "factor": 5,
    "class": "number59",
    "allow": false,
    "name": "Sugarless gum",
    "type": "large"
  },
  {
    "id": 60,
    "allow": false,
    "class": "number60",
    "name": "Walnut",
    "type": "large",
    "factor": 5
  },
  {
    "id": 61,
    "type": "large",
    "factor": 5,
    "name": "Sausage",
    "class": "number61",
    "allow": false
  },
  {
    "id": 62,
    "allow": false,
    "type": "large",
    "name": "Soybean",
    "class": "number62",
    "factor": 5
  },
  {
    "id": 63,
    "type": "large",
    "allow": true,
    "factor": 4,
    "name": "Eggplant",
    "class": "number63"
  },
  {
    "id": 64,
    "factor": 5,
    "class": "number64",
    "allow": false,
    "name": "Surimi, crab meat",
    "type": "large"
  },
  {
    "id": 65,
    "allow": false,
    "class": "number65",
    "factor": 1,
    "type": "large",
    "name": "Mushrooms, white"
  },
  {
    "id": 66,
    "type": "large",
    "factor": 3,
    "name": "Chamomile tea",
    "class": "number66",
    "allow": true
  },
  {
    "id": 67,
    "allow": true,
    "class": "number67",
    "factor": 2,
    "type": "large",
    "name": "Pistachios"
  },
  {
    "id": 68,
    "class": "number68",
    "factor": 1,
    "name": "Mayonnaise no egg",
    "type": "large",
    "allow": true
  },
  {
    "id": 69,
    "allow": true,
    "class": "number69",
    "name": "Cinnamon",
    "factor": 3,
    "type": "large"
  },
  {
    "id": 70,
    "type": "large",
    "factor": 5,
    "name": "Shrimp",
    "allow": false,
    "class": "number70"
  },
  {
    "id": 71,
    "name": "Peanuts",
    "allow": false,
    "class": "number71",
    "factor": 1,
    "type": "large"
  },
  {
    "id": 72,
    "type": "large",
    "factor": 5,
    "allow": false,
    "name": "Mussels",
    "class": "number72"
  },
  {
    "id": 73,
    "allow": false,
    "name": "Boiled chicken",
    "class": "number73",
    "type": "large",
    "factor": 5
  },
  {
    "id": 74,
    "allow": false,
    "factor": 5,
    "class": "number74",
    "type": "large",
    "name": "Chips"
  },
  {
    "id": 75,
    "type": "large",
    "class": "number75",
    "name": "Sparkling water",
    "factor": 4,
    "allow": true
  },
  {
    "id": 76,
    "type": "large",
    "factor": 5,
    "class": "number76",
    "allow": false,
    "name": "Chickpeas"
  },
  {
    "id": 77,
    "class": "number77",
    "factor": 5,
    "type": "large",
    "name": "Whole-wheat bread",
    "allow": false
  },
  {
    "id": 78,
    "factor": 4,
    "class": "number78",
    "type": "large",
    "name": "Zucchini",
    "allow": true
  },
  {
    "id": 79,
    "type": "large",
    "allow": false,
    "name": "Lobster",
    "factor": 5,
    "class": "number79"
  },
  {
    "id": 80,
    "class": "number80",
    "type": "large",
    "factor": 4,
    "name": "Tea",
    "allow": true
  }
]

const fakeAnswers = [
  {
    "id": "61d383cb-7185-42df-9af0-b88448182439",
    "Answer": [
      {
        "factor": 1,
        "allow": true,
        "food": 1,
        "answer": false,
        "foodNumber": 0
      },
      {
        "food": 2,
        "answer": false,
        "factor": 1,
        "allow": true,
        "foodNumber": 1
      },
      {
        "allow": true,
        "factor": 2,
        "foodNumber": 2,
        "food": 3,
        "answer": false
      },
      {
        "factor": 3,
        "food": 4,
        "foodNumber": 3,
        "allow": true,
        "answer": false
      },
      {
        "answer": false,
        "foodNumber": 4,
        "allow": true,
        "food": 5,
        "factor": 1
      },
      {
        "answer": false,
        "factor": 2,
        "allow": false,
        "food": 6,
        "foodNumber": 5
      },
      {
        "allow": false,
        "food": 7,
        "factor": 3,
        "answer": false,
        "foodNumber": 6
      },
      {
        "factor": 3,
        "allow": "number8",
        "food": 8,
        "foodNumber": 7,
        "answer": false
      },
      {
        "foodNumber": 8,
        "food": 9,
        "factor": 3,
        "answer": false,
        "allow": false
      },
      {
        "food": 10,
        "answer": false,
        "foodNumber": 9,
        "allow": false,
        "factor": 2
      },
      {
        "allow": true,
        "foodNumber": 10,
        "answer": false,
        "food": 11,
        "factor": 1
      },
      {
        "foodNumber": 11,
        "allow": true,
        "food": 12,
        "answer": false,
        "factor": 1
      },
      {
        "answer": false,
        "food": 13,
        "allow": true,
        "factor": 1,
        "foodNumber": 12
      },
      {
        "answer": false,
        "factor": 2,
        "allow": false,
        "food": 14,
        "foodNumber": 13
      },
      {
        "foodNumber": 14,
        "food": 15,
        "factor": 1,
        "allow": true,
        "answer": false
      },
      {
        "foodNumber": 15,
        "food": 16,
        "answer": false,
        "factor": 3,
        "allow": false
      },
      {
        "foodNumber": 16,
        "allow": false,
        "answer": false,
        "factor": 3,
        "food": 17
      },
      {
        "answer": false,
        "foodNumber": 17,
        "food": 18,
        "factor": 3,
        "allow": false
      },
      {
        "food": 19,
        "factor": 2,
        "foodNumber": 18,
        "allow": false,
        "answer": false
      },
      {
        "food": 20,
        "foodNumber": 19,
        "allow": true,
        "factor": 1,
        "answer": false
      },
      {
        "food": 21,
        "allow": false,
        "answer": false,
        "foodNumber": 20,
        "factor": 2
      },
      {
        "answer": false,
        "foodNumber": 21,
        "food": 22,
        "factor": 2,
        "allow": false
      },
      {
        "factor": 1,
        "allow": true,
        "answer": false,
        "food": 23,
        "foodNumber": 22
      },
      {
        "allow": true,
        "factor": 1,
        "food": 24,
        "foodNumber": 23,
        "answer": false
      },
      {
        "food": 25,
        "foodNumber": 24,
        "answer": false,
        "allow": false,
        "factor": 3
      },
      {
        "factor": 2,
        "allow": false,
        "foodNumber": 25,
        "food": 26,
        "answer": false
      },
      {
        "answer": false,
        "factor": 1,
        "food": 27,
        "foodNumber": 26,
        "allow": true
      },
      {
        "food": 28,
        "allow": false,
        "foodNumber": 27,
        "answer": false,
        "factor": 2
      },
      {
        "foodNumber": 28,
        "allow": false,
        "answer": false,
        "food": 29,
        "factor": 1
      },
      {
        "answer": false,
        "allow": false,
        "factor": 3,
        "foodNumber": 29,
        "food": 30
      },
      {
        "food": 31,
        "foodNumber": 30,
        "allow": false,
        "factor": 2,
        "answer": false
      },
      {
        "allow": true,
        "answer": false,
        "food": 32,
        "foodNumber": 31,
        "factor": 1
      },
      {
        "foodNumber": 32,
        "answer": false,
        "factor": 3,
        "allow": false,
        "food": 33
      },
      {
        "answer": false,
        "factor": 3,
        "foodNumber": 33,
        "food": 34,
        "allow": false
      },
      {
        "food": 35,
        "factor": 1,
        "allow": true,
        "foodNumber": 34,
        "answer": false
      },
      {
        "answer": false,
        "factor": 1,
        "allow": true,
        "food": 36,
        "foodNumber": 35
      },
      {
        "food": 37,
        "allow": false,
        "foodNumber": 36,
        "answer": false,
        "factor": 5
      },
      {
        "allow": true,
        "factor": 4,
        "foodNumber": 37,
        "food": 38,
        "answer": false
      },
      {
        "allow": true,
        "factor": 4,
        "answer": true,
        "foodNumber": 38,
        "food": 39
      },
      {
        "foodNumber": 39,
        "answer": true,
        "food": 40,
        "factor": 5,
        "allow": false
      },
      {
        "allow": false,
        "food": 41,
        "foodNumber": 40,
        "factor": 5,
        "answer": true
      },
      {
        "factor": 5,
        "answer": true,
        "foodNumber": 41,
        "allow": false,
        "food": 42
      },
      {
        "allow": false,
        "answer": true,
        "factor": 5,
        "food": 43,
        "foodNumber": 42
      },
      {
        "foodNumber": 43,
        "answer": true,
        "factor": 4,
        "food": 44,
        "allow": true
      },
      {
        "food": 45,
        "factor": 4,
        "allow": true,
        "answer": true,
        "foodNumber": 44
      },
      {
        "food": 46,
        "factor": 4,
        "allow": true,
        "foodNumber": 45,
        "answer": true
      },
      {
        "factor": 5,
        "foodNumber": 46,
        "allow": false,
        "answer": true,
        "food": 47
      },
      {
        "allow": true,
        "answer": true,
        "factor": 4,
        "food": 48,
        "foodNumber": 47
      },
      {
        "foodNumber": 48,
        "allow": true,
        "factor": 5,
        "answer": true,
        "food": 49
      },
      {
        "food": 50,
        "factor": 2,
        "allow": false,
        "foodNumber": 49,
        "answer": true
      },
      {
        "factor": 4,
        "food": 51,
        "allow": true,
        "foodNumber": 50,
        "answer": true
      },
      {
        "factor": 5,
        "food": 52,
        "allow": false,
        "foodNumber": 51,
        "answer": false
      },
      {
        "factor": 5,
        "food": 53,
        "answer": false,
        "foodNumber": 52,
        "allow": false
      },
      {
        "factor": 4,
        "food": 54,
        "allow": true,
        "foodNumber": 53,
        "answer": false
      },
      {
        "answer": false,
        "food": 55,
        "allow": true,
        "foodNumber": 54,
        "factor": 4
      },
      {
        "allow": true,
        "answer": false,
        "foodNumber": 55,
        "factor": 4,
        "food": 56
      },
      {
        "food": 57,
        "factor": 4,
        "answer": true,
        "foodNumber": 56,
        "allow": true
      },
      {
        "answer": true,
        "allow": false,
        "food": 58,
        "foodNumber": 57,
        "factor": 5
      },
      {
        "foodNumber": 58,
        "factor": 5,
        "food": 59,
        "answer": true,
        "allow": false
      },
      {
        "answer": true,
        "food": 60,
        "factor": 5,
        "foodNumber": 59,
        "allow": false
      },
      {
        "answer": true,
        "allow": false,
        "foodNumber": 60,
        "factor": 5,
        "food": 61
      },
      {
        "food": 62,
        "foodNumber": 61,
        "factor": 5,
        "answer": true,
        "allow": false
      },
      {
        "answer": true,
        "food": 63,
        "factor": 4,
        "allow": true,
        "foodNumber": 62
      },
      {
        "factor": 5,
        "answer": true,
        "foodNumber": 63,
        "allow": false,
        "food": 64
      },
      {
        "foodNumber": 64,
        "answer": true,
        "food": 65,
        "factor": 1,
        "allow": false
      },
      {
        "allow": true,
        "answer": true,
        "food": 66,
        "foodNumber": 65,
        "factor": 3
      },
      {
        "allow": true,
        "answer": true,
        "factor": 2,
        "food": 67,
        "foodNumber": 66
      },
      {
        "answer": true,
        "food": 68,
        "allow": true,
        "factor": 1,
        "foodNumber": 67
      },
      {
        "answer": true,
        "foodNumber": 68,
        "factor": 3,
        "allow": true,
        "food": 69
      },
      {
        "food": 70,
        "answer": true,
        "factor": 5,
        "foodNumber": 69,
        "allow": false
      },
      {
        "allow": false,
        "factor": 1,
        "foodNumber": 70,
        "food": 71,
        "answer": true
      },
      {
        "answer": true,
        "factor": 5,
        "allow": false,
        "food": 72,
        "foodNumber": 71
      },
      {
        "answer": true,
        "foodNumber": 72,
        "factor": 5,
        "food": 73,
        "allow": false
      },
      {
        "foodNumber": 73,
        "food": 74,
        "factor": 5,
        "answer": true,
        "allow": false
      },
      {
        "foodNumber": 74,
        "allow": true,
        "answer": true,
        "factor": 4,
        "food": 75
      },
      {
        "allow": false,
        "factor": 5,
        "answer": true,
        "foodNumber": 75,
        "food": 76
      },
      {
        "food": 77,
        "factor": 5,
        "foodNumber": 76,
        "allow": false,
        "answer": true
      },
      {
        "answer": true,
        "foodNumber": 77,
        "food": 78,
        "factor": 4,
        "allow": true
      },
      {
        "answer": true,
        "factor": 5,
        "foodNumber": 78,
        "allow": false,
        "food": 79
      }
    ]
  },
  {
    "id": "N-TEST-vE88nqrJ1oBvuac3kYLe-3d7604be-db31-46c7-a59b-0f8020dd5edd",
    "Answer": [
      {
        "allow": true,
        "food": 1,
        "factor": 1,
        "answer": false,
        "foodNumber": 0
      },
      {
        "food": 2,
        "allow": true,
        "answer": false,
        "factor": 1,
        "foodNumber": 1
      },
      {
        "allow": true,
        "answer": false,
        "food": 3,
        "foodNumber": 2,
        "factor": 2
      },
      {
        "foodNumber": 3,
        "food": 4,
        "factor": 3,
        "answer": false,
        "allow": true
      },
      {
        "factor": 1,
        "food": 5,
        "allow": true,
        "answer": false,
        "foodNumber": 4
      },
      {
        "answer": false,
        "allow": false,
        "food": 6,
        "foodNumber": 5,
        "factor": 2
      },
      {
        "food": 7,
        "factor": 3,
        "answer": false,
        "allow": false,
        "foodNumber": 6
      },
      {
        "allow": "number8",
        "factor": 3,
        "foodNumber": 7,
        "answer": false,
        "food": 8
      },
      {
        "factor": 3,
        "foodNumber": 8,
        "food": 9,
        "answer": false,
        "allow": false
      },
      {
        "food": 10,
        "foodNumber": 9,
        "factor": 2,
        "allow": false,
        "answer": false
      },
      {
        "foodNumber": 10,
        "factor": 1,
        "allow": true,
        "food": 11,
        "answer": false
      },
      {
        "allow": true,
        "food": 12,
        "answer": false,
        "foodNumber": 11,
        "factor": 1
      },
      {
        "food": 13,
        "allow": true,
        "factor": 1,
        "foodNumber": 12,
        "answer": false
      },
      {
        "allow": false,
        "foodNumber": 13,
        "answer": false,
        "food": 14,
        "factor": 2
      },
      {
        "allow": true,
        "foodNumber": 14,
        "answer": false,
        "factor": 1,
        "food": 15
      },
      {
        "factor": 3,
        "foodNumber": 15,
        "allow": false,
        "answer": false,
        "food": 16
      },
      {
        "answer": false,
        "food": 17,
        "foodNumber": 16,
        "allow": false,
        "factor": 3
      },
      {
        "factor": 3,
        "answer": false,
        "foodNumber": 17,
        "allow": false,
        "food": 18
      },
      {
        "factor": 2,
        "food": 19,
        "foodNumber": 18,
        "allow": false,
        "answer": false
      },
      {
        "factor": 1,
        "food": 20,
        "foodNumber": 19,
        "answer": false,
        "allow": true
      },
      {
        "food": 21,
        "answer": true,
        "allow": false,
        "factor": 2,
        "foodNumber": 20
      },
      {
        "food": 22,
        "foodNumber": 21,
        "allow": false,
        "answer": true,
        "factor": 2
      },
      {
        "foodNumber": 22,
        "allow": true,
        "food": 23,
        "answer": true,
        "factor": 1
      },
      {
        "food": 24,
        "answer": true,
        "factor": 1,
        "foodNumber": 23,
        "allow": true
      },
      {
        "allow": false,
        "factor": 3,
        "food": 25,
        "foodNumber": 24,
        "answer": true
      },
      {
        "food": 26,
        "foodNumber": 25,
        "answer": true,
        "allow": false,
        "factor": 2
      },
      {
        "allow": true,
        "answer": true,
        "food": 27,
        "foodNumber": 26,
        "factor": 1
      },
      {
        "answer": true,
        "allow": false,
        "foodNumber": 27,
        "food": 28,
        "factor": 2
      },
      {
        "food": 29,
        "foodNumber": 28,
        "answer": true,
        "factor": 1,
        "allow": false
      },
      {
        "answer": true,
        "foodNumber": 29,
        "allow": false,
        "factor": 3,
        "food": 30
      },
      {
        "food": 31,
        "allow": false,
        "answer": true,
        "foodNumber": 30,
        "factor": 2
      },
      {
        "food": 32,
        "factor": 1,
        "allow": true,
        "foodNumber": 31,
        "answer": true
      },
      {
        "factor": 3,
        "food": 33,
        "answer": true,
        "allow": false,
        "foodNumber": 32
      },
      {
        "food": 34,
        "answer": true,
        "allow": false,
        "factor": 3,
        "foodNumber": 33
      },
      {
        "food": 35,
        "factor": 1,
        "answer": true,
        "allow": true,
        "foodNumber": 34
      },
      {
        "factor": 1,
        "food": 36,
        "allow": true,
        "answer": true,
        "foodNumber": 35
      },
      {
        "allow": false,
        "factor": 5,
        "foodNumber": 36,
        "answer": true,
        "food": 37
      },
      {
        "foodNumber": 37,
        "factor": 4,
        "food": 38,
        "answer": true,
        "allow": true
      },
      {
        "factor": 4,
        "foodNumber": 38,
        "answer": true,
        "allow": true,
        "food": 39
      },
      {
        "factor": 5,
        "allow": false,
        "answer": false,
        "foodNumber": 39,
        "food": 40
      },
      {
        "foodNumber": 40,
        "food": 41,
        "answer": false,
        "allow": false,
        "factor": 5
      },
      {
        "allow": false,
        "foodNumber": 41,
        "factor": 5,
        "answer": false,
        "food": 42
      },
      {
        "food": 43,
        "foodNumber": 42,
        "factor": 5,
        "allow": false,
        "answer": false
      },
      {
        "answer": false,
        "food": 44,
        "allow": true,
        "factor": 4,
        "foodNumber": 43
      },
      {
        "answer": false,
        "allow": true,
        "food": 45,
        "foodNumber": 44,
        "factor": 4
      },
      {
        "food": 46,
        "foodNumber": 45,
        "answer": false,
        "factor": 4,
        "allow": true
      },
      {
        "allow": false,
        "food": 47,
        "factor": 5,
        "foodNumber": 46,
        "answer": false
      },
      {
        "foodNumber": 47,
        "factor": 4,
        "allow": true,
        "food": 48,
        "answer": false
      },
      {
        "allow": true,
        "answer": false,
        "factor": 5,
        "food": 49,
        "foodNumber": 48
      },
      {
        "allow": false,
        "food": 50,
        "answer": false,
        "foodNumber": 49,
        "factor": 2
      },
      {
        "factor": 4,
        "allow": true,
        "answer": false,
        "foodNumber": 50,
        "food": 51
      },
      {
        "factor": 5,
        "food": 52,
        "allow": false,
        "foodNumber": 51,
        "answer": false
      },
      {
        "answer": false,
        "food": 53,
        "allow": false,
        "foodNumber": 52,
        "factor": 5
      },
      {
        "food": 54,
        "foodNumber": 53,
        "allow": true,
        "answer": false,
        "factor": 4
      },
      {
        "answer": false,
        "food": 55,
        "foodNumber": 54,
        "factor": 4,
        "allow": true
      },
      {
        "answer": false,
        "allow": true,
        "foodNumber": 55,
        "factor": 4,
        "food": 56
      },
      {
        "answer": false,
        "food": 57,
        "allow": true,
        "factor": 4,
        "foodNumber": 56
      },
      {
        "food": 58,
        "foodNumber": 57,
        "allow": false,
        "answer": false,
        "factor": 5
      },
      {
        "food": 59,
        "allow": false,
        "answer": false,
        "foodNumber": 58,
        "factor": 5
      },
      {
        "answer": false,
        "foodNumber": 59,
        "factor": 5,
        "food": 60,
        "allow": false
      },
      {
        "factor": 5,
        "allow": false,
        "foodNumber": 60,
        "answer": true,
        "food": 61
      },
      {
        "factor": 5,
        "answer": true,
        "allow": false,
        "food": 62,
        "foodNumber": 61
      },
      {
        "foodNumber": 62,
        "food": 63,
        "factor": 4,
        "answer": true,
        "allow": true
      },
      {
        "answer": true,
        "allow": false,
        "foodNumber": 63,
        "food": 64,
        "factor": 5
      },
      {
        "factor": 1,
        "answer": true,
        "foodNumber": 64,
        "allow": false,
        "food": 65
      },
      {
        "food": 66,
        "foodNumber": 65,
        "allow": true,
        "answer": true,
        "factor": 3
      },
      {
        "foodNumber": 66,
        "food": 67,
        "allow": true,
        "factor": 2,
        "answer": true
      },
      {
        "food": 68,
        "answer": true,
        "allow": true,
        "factor": 1,
        "foodNumber": 67
      },
      {
        "allow": true,
        "foodNumber": 68,
        "food": 69,
        "answer": true,
        "factor": 3
      },
      {
        "food": 70,
        "factor": 5,
        "answer": true,
        "foodNumber": 69,
        "allow": false
      },
      {
        "food": 71,
        "answer": true,
        "allow": false,
        "factor": 1,
        "foodNumber": 70
      },
      {
        "food": 72,
        "allow": false,
        "foodNumber": 71,
        "factor": 5,
        "answer": true
      },
      {
        "answer": true,
        "factor": 5,
        "allow": false,
        "foodNumber": 72,
        "food": 73
      },
      {
        "foodNumber": 73,
        "factor": 5,
        "food": 74,
        "answer": true,
        "allow": false
      },
      {
        "factor": 4,
        "food": 75,
        "answer": true,
        "foodNumber": 74,
        "allow": true
      },
      {
        "food": 76,
        "factor": 5,
        "allow": false,
        "answer": true,
        "foodNumber": 75
      },
      {
        "answer": true,
        "foodNumber": 76,
        "factor": 5,
        "food": 77,
        "allow": false
      },
      {
        "answer": true,
        "foodNumber": 77,
        "food": 78,
        "allow": true,
        "factor": 4
      },
      {
        "food": 79,
        "allow": false,
        "foodNumber": 78,
        "factor": 5,
        "answer": true
      },
      {
        "factor": 4,
        "foodNumber": 79,
        "answer": true,
        "allow": true,
        "food": 80
      }
    ]
  },
  {
    "id": "3a773e92-de88-4fe5-9050-4fa495891dfe",
    "Answer": [
      {
        "allow": true,
        "answer": false,
        "food": 1,
        "foodNumber": 0,
        "factor": 1
      },
      {
        "foodNumber": 1,
        "factor": 1,
        "allow": true,
        "answer": false,
        "food": 2
      },
      {
        "food": 3,
        "answer": false,
        "factor": 2,
        "foodNumber": 2,
        "allow": true
      },
      {
        "factor": 3,
        "allow": true,
        "food": 4,
        "answer": false,
        "foodNumber": 3
      },
      {
        "allow": true,
        "foodNumber": 4,
        "food": 5,
        "factor": 1,
        "answer": false
      },
      {
        "factor": 2,
        "foodNumber": 5,
        "allow": false,
        "answer": false,
        "food": 6
      },
      {
        "factor": 3,
        "answer": false,
        "allow": false,
        "foodNumber": 6,
        "food": 7
      },
      {
        "food": 8,
        "foodNumber": 7,
        "factor": 3,
        "answer": false,
        "allow": "number8"
      },
      {
        "foodNumber": 8,
        "answer": false,
        "factor": 3,
        "allow": false,
        "food": 9
      },
      {
        "foodNumber": 9,
        "answer": false,
        "allow": false,
        "food": 10,
        "factor": 2
      },
      {
        "factor": 1,
        "food": 11,
        "allow": true,
        "foodNumber": 10,
        "answer": false
      },
      {
        "answer": false,
        "factor": 1,
        "foodNumber": 11,
        "allow": true,
        "food": 12
      },
      {
        "food": 13,
        "answer": false,
        "allow": true,
        "foodNumber": 12,
        "factor": 1
      },
      {
        "allow": false,
        "answer": false,
        "foodNumber": 13,
        "food": 14,
        "factor": 2
      },
      {
        "food": 15,
        "allow": true,
        "foodNumber": 14,
        "factor": 1,
        "answer": false
      },
      {
        "answer": false,
        "allow": false,
        "factor": 3,
        "food": 16,
        "foodNumber": 15
      },
      {
        "allow": false,
        "food": 17,
        "answer": false,
        "foodNumber": 16,
        "factor": 3
      },
      {
        "food": 18,
        "allow": false,
        "foodNumber": 17,
        "factor": 3,
        "answer": false
      },
      {
        "food": 19,
        "answer": false,
        "factor": 2,
        "foodNumber": 18,
        "allow": false
      },
      {
        "factor": 1,
        "answer": false,
        "food": 20,
        "foodNumber": 19,
        "allow": true
      },
      {
        "foodNumber": 20,
        "answer": true,
        "factor": 2,
        "allow": false,
        "food": 21
      },
      {
        "foodNumber": 21,
        "allow": false,
        "answer": true,
        "food": 22,
        "factor": 2
      },
      {
        "foodNumber": 22,
        "factor": 1,
        "allow": true,
        "food": 23,
        "answer": true
      },
      {
        "food": 24,
        "allow": true,
        "factor": 1,
        "foodNumber": 23,
        "answer": true
      },
      {
        "answer": true,
        "food": 25,
        "factor": 3,
        "foodNumber": 24,
        "allow": false
      },
      {
        "foodNumber": 25,
        "allow": false,
        "answer": true,
        "food": 26,
        "factor": 2
      },
      {
        "food": 27,
        "factor": 1,
        "foodNumber": 26,
        "answer": true,
        "allow": true
      },
      {
        "food": 28,
        "foodNumber": 27,
        "answer": true,
        "allow": false,
        "factor": 2
      },
      {
        "answer": true,
        "allow": false,
        "foodNumber": 28,
        "factor": 1,
        "food": 29
      },
      {
        "answer": true,
        "factor": 3,
        "foodNumber": 29,
        "food": 30,
        "allow": false
      },
      {
        "answer": true,
        "factor": 2,
        "foodNumber": 30,
        "allow": false,
        "food": 31
      },
      {
        "factor": 1,
        "answer": true,
        "allow": true,
        "foodNumber": 31,
        "food": 32
      },
      {
        "food": 33,
        "answer": true,
        "factor": 3,
        "foodNumber": 32,
        "allow": false
      },
      {
        "allow": false,
        "factor": 3,
        "foodNumber": 33,
        "answer": true,
        "food": 34
      },
      {
        "answer": true,
        "factor": 1,
        "allow": true,
        "foodNumber": 34,
        "food": 35
      },
      {
        "answer": true,
        "foodNumber": 35,
        "allow": true,
        "food": 36,
        "factor": 1
      },
      {
        "foodNumber": 36,
        "factor": 5,
        "answer": true,
        "allow": false,
        "food": 37
      },
      {
        "factor": 4,
        "foodNumber": 37,
        "food": 38,
        "answer": true,
        "allow": true
      },
      {
        "foodNumber": 38,
        "allow": true,
        "answer": true,
        "factor": 4,
        "food": 39
      },
      {
        "foodNumber": 39,
        "answer": false,
        "factor": 5,
        "allow": false,
        "food": 40
      },
      {
        "food": 41,
        "answer": false,
        "factor": 5,
        "allow": false,
        "foodNumber": 40
      },
      {
        "allow": false,
        "food": 42,
        "answer": false,
        "factor": 5,
        "foodNumber": 41
      },
      {
        "food": 43,
        "foodNumber": 42,
        "allow": false,
        "answer": false,
        "factor": 5
      },
      {
        "allow": true,
        "factor": 4,
        "food": 44,
        "foodNumber": 43,
        "answer": false
      },
      {
        "answer": false,
        "food": 45,
        "foodNumber": 44,
        "allow": true,
        "factor": 4
      },
      {
        "answer": false,
        "foodNumber": 45,
        "factor": 4,
        "allow": true,
        "food": 46
      },
      {
        "food": 47,
        "allow": false,
        "answer": false,
        "foodNumber": 46,
        "factor": 5
      },
      {
        "answer": false,
        "allow": true,
        "foodNumber": 47,
        "factor": 4,
        "food": 48
      },
      {
        "answer": false,
        "foodNumber": 48,
        "allow": true,
        "food": 49,
        "factor": 5
      },
      {
        "answer": false,
        "food": 50,
        "foodNumber": 49,
        "factor": 2,
        "allow": false
      },
      {
        "answer": false,
        "foodNumber": 50,
        "food": 51,
        "factor": 4,
        "allow": true
      },
      {
        "food": 52,
        "foodNumber": 51,
        "answer": false,
        "factor": 5,
        "allow": false
      },
      {
        "food": 53,
        "allow": false,
        "factor": 5,
        "answer": false,
        "foodNumber": 52
      },
      {
        "answer": false,
        "factor": 4,
        "food": 54,
        "allow": true,
        "foodNumber": 53
      },
      {
        "answer": false,
        "allow": true,
        "factor": 4,
        "foodNumber": 54,
        "food": 55
      },
      {
        "foodNumber": 55,
        "answer": false,
        "allow": true,
        "factor": 4,
        "food": 56
      },
      {
        "answer": false,
        "foodNumber": 56,
        "allow": true,
        "food": 57,
        "factor": 4
      },
      {
        "answer": false,
        "foodNumber": 57,
        "allow": false,
        "food": 58,
        "factor": 5
      },
      {
        "foodNumber": 58,
        "answer": false,
        "allow": false,
        "factor": 5,
        "food": 59
      },
      {
        "food": 60,
        "foodNumber": 59,
        "factor": 5,
        "allow": false,
        "answer": true
      },
      {
        "foodNumber": 60,
        "allow": false,
        "answer": true,
        "factor": 5,
        "food": 61
      },
      {
        "answer": true,
        "food": 62,
        "factor": 5,
        "foodNumber": 61,
        "allow": false
      },
      {
        "answer": true,
        "food": 63,
        "factor": 4,
        "foodNumber": 62,
        "allow": true
      },
      {
        "answer": true,
        "food": 64,
        "foodNumber": 63,
        "allow": false,
        "factor": 5
      },
      {
        "allow": false,
        "factor": 1,
        "answer": true,
        "food": 65,
        "foodNumber": 64
      },
      {
        "factor": 3,
        "food": 66,
        "allow": true,
        "answer": true,
        "foodNumber": 65
      },
      {
        "factor": 2,
        "answer": true,
        "foodNumber": 66,
        "allow": true,
        "food": 67
      },
      {
        "answer": true,
        "allow": true,
        "foodNumber": 67,
        "factor": 1,
        "food": 68
      },
      {
        "food": 69,
        "allow": true,
        "factor": 3,
        "foodNumber": 68,
        "answer": true
      },
      {
        "answer": true,
        "food": 70,
        "foodNumber": 69,
        "factor": 5,
        "allow": false
      },
      {
        "answer": true,
        "foodNumber": 70,
        "factor": 1,
        "food": 71,
        "allow": false
      },
      {
        "foodNumber": 71,
        "factor": 5,
        "allow": false,
        "food": 72,
        "answer": true
      },
      {
        "food": 73,
        "foodNumber": 72,
        "answer": true,
        "factor": 5,
        "allow": false
      },
      {
        "foodNumber": 73,
        "factor": 5,
        "allow": false,
        "food": 74,
        "answer": true
      },
      {
        "factor": 4,
        "foodNumber": 74,
        "food": 75,
        "answer": true,
        "allow": true
      },
      {
        "foodNumber": 75,
        "allow": false,
        "factor": 5,
        "food": 76,
        "answer": true
      },
      {
        "answer": true,
        "allow": false,
        "foodNumber": 76,
        "food": 77,
        "factor": 5
      },
      {
        "answer": true,
        "factor": 4,
        "allow": true,
        "foodNumber": 77,
        "food": 78
      },
      {
        "allow": false,
        "foodNumber": 78,
        "food": 79,
        "factor": 5,
        "answer": true
      }
    ]
  },
  {
    "id": "460f6fe6-6c9a-4f5a-8ebf-cfa686e39690",
    "Answer": [
      {
        "allow": true,
        "food": 1,
        "factor": 1,
        "foodNumber": 0,
        "answer": false
      },
      {
        "food": 2,
        "factor": 1,
        "foodNumber": 1,
        "answer": false,
        "allow": true
      },
      {
        "foodNumber": 2,
        "allow": true,
        "factor": 2,
        "answer": false,
        "food": 3
      },
      {
        "allow": true,
        "factor": 3,
        "foodNumber": 3,
        "answer": false,
        "food": 4
      },
      {
        "allow": true,
        "factor": 1,
        "foodNumber": 4,
        "food": 5,
        "answer": false
      },
      {
        "foodNumber": 5,
        "food": 6,
        "answer": false,
        "factor": 2,
        "allow": false
      },
      {
        "foodNumber": 6,
        "food": 7,
        "allow": false,
        "answer": false,
        "factor": 3
      },
      {
        "allow": "number8",
        "factor": 3,
        "foodNumber": 7,
        "answer": false,
        "food": 8
      },
      {
        "food": 9,
        "factor": 3,
        "foodNumber": 8,
        "allow": false,
        "answer": false
      },
      {
        "factor": 2,
        "food": 10,
        "allow": false,
        "answer": false,
        "foodNumber": 9
      },
      {
        "foodNumber": 10,
        "food": 11,
        "allow": true,
        "answer": false,
        "factor": 1
      },
      {
        "foodNumber": 11,
        "food": 12,
        "factor": 1,
        "answer": false,
        "allow": true
      },
      {
        "answer": false,
        "factor": 1,
        "food": 13,
        "allow": true,
        "foodNumber": 12
      },
      {
        "allow": false,
        "factor": 2,
        "answer": false,
        "food": 14,
        "foodNumber": 13
      },
      {
        "answer": false,
        "foodNumber": 14,
        "allow": true,
        "food": 15,
        "factor": 1
      },
      {
        "food": 16,
        "foodNumber": 15,
        "answer": false,
        "factor": 3,
        "allow": false
      },
      {
        "allow": false,
        "factor": 3,
        "foodNumber": 16,
        "food": 17,
        "answer": false
      },
      {
        "factor": 3,
        "foodNumber": 17,
        "food": 18,
        "answer": false,
        "allow": false
      },
      {
        "factor": 2,
        "answer": false,
        "food": 19,
        "allow": false,
        "foodNumber": 18
      },
      {
        "factor": 1,
        "food": 20,
        "foodNumber": 19,
        "allow": true,
        "answer": false
      },
      {
        "factor": 2,
        "foodNumber": 20,
        "food": 21,
        "allow": false,
        "answer": true
      },
      {
        "answer": true,
        "factor": 2,
        "food": 22,
        "allow": false,
        "foodNumber": 21
      },
      {
        "food": 23,
        "allow": true,
        "factor": 1,
        "foodNumber": 22,
        "answer": true
      },
      {
        "allow": true,
        "foodNumber": 23,
        "food": 24,
        "answer": true,
        "factor": 1
      },
      {
        "factor": 3,
        "answer": true,
        "allow": false,
        "food": 25,
        "foodNumber": 24
      },
      {
        "foodNumber": 25,
        "allow": false,
        "food": 26,
        "factor": 2,
        "answer": true
      },
      {
        "answer": true,
        "allow": true,
        "foodNumber": 26,
        "factor": 1,
        "food": 27
      },
      {
        "factor": 2,
        "answer": true,
        "food": 28,
        "allow": false,
        "foodNumber": 27
      },
      {
        "food": 29,
        "allow": false,
        "factor": 1,
        "answer": true,
        "foodNumber": 28
      },
      {
        "foodNumber": 29,
        "factor": 3,
        "food": 30,
        "allow": false,
        "answer": true
      },
      {
        "allow": false,
        "foodNumber": 30,
        "answer": true,
        "food": 31,
        "factor": 2
      },
      {
        "answer": true,
        "food": 32,
        "factor": 1,
        "foodNumber": 31,
        "allow": true
      },
      {
        "answer": true,
        "foodNumber": 32,
        "allow": false,
        "food": 33,
        "factor": 3
      },
      {
        "food": 34,
        "foodNumber": 33,
        "allow": false,
        "factor": 3,
        "answer": true
      },
      {
        "allow": true,
        "foodNumber": 34,
        "food": 35,
        "factor": 1,
        "answer": true
      },
      {
        "factor": 1,
        "foodNumber": 35,
        "food": 36,
        "answer": true,
        "allow": true
      },
      {
        "foodNumber": 36,
        "answer": true,
        "factor": 5,
        "food": 37,
        "allow": false
      },
      {
        "allow": true,
        "answer": true,
        "factor": 4,
        "foodNumber": 37,
        "food": 38
      },
      {
        "answer": true,
        "factor": 4,
        "allow": true,
        "foodNumber": 38,
        "food": 39
      },
      {
        "food": 40,
        "answer": true,
        "allow": false,
        "factor": 5,
        "foodNumber": 39
      },
      {
        "foodNumber": 40,
        "allow": false,
        "food": 41,
        "answer": false,
        "factor": 5
      },
      {
        "foodNumber": 41,
        "food": 42,
        "answer": false,
        "factor": 5,
        "allow": false
      },
      {
        "food": 43,
        "answer": false,
        "allow": false,
        "factor": 5,
        "foodNumber": 42
      },
      {
        "foodNumber": 43,
        "answer": false,
        "food": 44,
        "factor": 4,
        "allow": true
      },
      {
        "factor": 4,
        "foodNumber": 44,
        "food": 45,
        "allow": true,
        "answer": false
      },
      {
        "factor": 4,
        "answer": false,
        "food": 46,
        "foodNumber": 45,
        "allow": true
      },
      {
        "food": 47,
        "answer": false,
        "foodNumber": 46,
        "factor": 5,
        "allow": false
      },
      {
        "food": 48,
        "answer": false,
        "allow": true,
        "factor": 4,
        "foodNumber": 47
      },
      {
        "food": 49,
        "foodNumber": 48,
        "allow": true,
        "answer": false,
        "factor": 5
      },
      {
        "food": 50,
        "foodNumber": 49,
        "allow": false,
        "answer": false,
        "factor": 2
      },
      {
        "allow": true,
        "factor": 4,
        "foodNumber": 50,
        "answer": false,
        "food": 51
      },
      {
        "allow": false,
        "factor": 5,
        "food": 52,
        "foodNumber": 51,
        "answer": false
      },
      {
        "answer": false,
        "factor": 5,
        "allow": false,
        "foodNumber": 52,
        "food": 53
      },
      {
        "food": 54,
        "foodNumber": 53,
        "factor": 4,
        "answer": false,
        "allow": true
      },
      {
        "answer": false,
        "allow": true,
        "food": 55,
        "foodNumber": 54,
        "factor": 4
      },
      {
        "factor": 4,
        "allow": true,
        "food": 56,
        "answer": false,
        "foodNumber": 55
      },
      {
        "foodNumber": 56,
        "allow": true,
        "factor": 4,
        "answer": false,
        "food": 57
      },
      {
        "allow": false,
        "answer": false,
        "foodNumber": 57,
        "factor": 5,
        "food": 58
      },
      {
        "allow": false,
        "answer": false,
        "food": 59,
        "factor": 5,
        "foodNumber": 58
      },
      {
        "foodNumber": 59,
        "food": 60,
        "answer": true,
        "factor": 5,
        "allow": false
      },
      {
        "foodNumber": 60,
        "answer": true,
        "factor": 5,
        "allow": false,
        "food": 61
      },
      {
        "food": 62,
        "foodNumber": 61,
        "answer": true,
        "factor": 5,
        "allow": false
      },
      {
        "food": 63,
        "allow": true,
        "factor": 4,
        "answer": true,
        "foodNumber": 62
      },
      {
        "food": 64,
        "allow": false,
        "answer": true,
        "factor": 5,
        "foodNumber": 63
      },
      {
        "food": 65,
        "foodNumber": 64,
        "allow": false,
        "factor": 1,
        "answer": true
      },
      {
        "allow": true,
        "food": 66,
        "factor": 3,
        "foodNumber": 65,
        "answer": true
      },
      {
        "answer": true,
        "foodNumber": 66,
        "food": 67,
        "factor": 2,
        "allow": true
      },
      {
        "answer": true,
        "factor": 1,
        "allow": true,
        "food": 68,
        "foodNumber": 67
      },
      {
        "allow": true,
        "factor": 3,
        "foodNumber": 68,
        "answer": true,
        "food": 69
      },
      {
        "answer": true,
        "food": 70,
        "foodNumber": 69,
        "factor": 5,
        "allow": false
      },
      {
        "factor": 1,
        "foodNumber": 70,
        "allow": false,
        "food": 71,
        "answer": true
      },
      {
        "allow": false,
        "food": 72,
        "answer": true,
        "foodNumber": 71,
        "factor": 5
      },
      {
        "factor": 5,
        "food": 73,
        "foodNumber": 72,
        "allow": false,
        "answer": true
      },
      {
        "foodNumber": 73,
        "allow": false,
        "food": 74,
        "factor": 5,
        "answer": true
      },
      {
        "factor": 4,
        "answer": true,
        "allow": true,
        "food": 75,
        "foodNumber": 74
      },
      {
        "answer": true,
        "foodNumber": 75,
        "factor": 5,
        "allow": false,
        "food": 76
      },
      {
        "factor": 5,
        "foodNumber": 76,
        "food": 77,
        "allow": false,
        "answer": true
      },
      {
        "answer": true,
        "foodNumber": 77,
        "food": 78,
        "allow": true,
        "factor": 4
      },
      {
        "allow": false,
        "factor": 5,
        "answer": true,
        "food": 79,
        "foodNumber": 78
      }
    ]
  },
  {
    "id": "449b3663-b9a3-4b08-9410-317432400aaf",
    "Answer": [
      {
        "answer": false,
        "foodNumber": 0,
        "allow": true,
        "factor": 1,
        "food": 1
      },
      {
        "foodNumber": 1,
        "factor": 1,
        "answer": false,
        "allow": true,
        "food": 2
      },
      {
        "allow": true,
        "answer": false,
        "factor": 2,
        "food": 3,
        "foodNumber": 2
      },
      {
        "allow": true,
        "food": 4,
        "answer": false,
        "foodNumber": 3,
        "factor": 3
      },
      {
        "foodNumber": 4,
        "allow": true,
        "answer": false,
        "food": 5,
        "factor": 1
      },
      {
        "food": 6,
        "factor": 2,
        "allow": false,
        "foodNumber": 5,
        "answer": false
      },
      {
        "answer": false,
        "factor": 3,
        "allow": false,
        "food": 7,
        "foodNumber": 6
      },
      {
        "factor": 3,
        "food": 8,
        "foodNumber": 7,
        "answer": false,
        "allow": "number8"
      },
      {
        "foodNumber": 8,
        "answer": false,
        "allow": false,
        "food": 9,
        "factor": 3
      },
      {
        "food": 10,
        "answer": false,
        "foodNumber": 9,
        "factor": 2,
        "allow": false
      },
      {
        "answer": false,
        "food": 11,
        "factor": 1,
        "foodNumber": 10,
        "allow": true
      },
      {
        "allow": true,
        "answer": false,
        "foodNumber": 11,
        "factor": 1,
        "food": 12
      },
      {
        "foodNumber": 12,
        "food": 13,
        "allow": true,
        "factor": 1,
        "answer": false
      },
      {
        "factor": 2,
        "food": 14,
        "foodNumber": 13,
        "answer": false,
        "allow": false
      },
      {
        "foodNumber": 14,
        "answer": false,
        "food": 15,
        "factor": 1,
        "allow": true
      },
      {
        "allow": false,
        "foodNumber": 15,
        "food": 16,
        "factor": 3,
        "answer": false
      },
      {
        "factor": 3,
        "foodNumber": 16,
        "answer": false,
        "food": 17,
        "allow": false
      },
      {
        "factor": 3,
        "foodNumber": 17,
        "food": 18,
        "allow": false,
        "answer": false
      },
      {
        "answer": true,
        "foodNumber": 18,
        "factor": 2,
        "allow": false,
        "food": 19
      },
      {
        "factor": 1,
        "food": 20,
        "answer": true,
        "foodNumber": 19,
        "allow": true
      },
      {
        "answer": true,
        "factor": 2,
        "allow": false,
        "food": 21,
        "foodNumber": 20
      },
      {
        "allow": false,
        "answer": true,
        "factor": 2,
        "food": 22,
        "foodNumber": 21
      },
      {
        "food": 23,
        "foodNumber": 22,
        "allow": true,
        "factor": 1,
        "answer": true
      },
      {
        "foodNumber": 23,
        "answer": true,
        "allow": true,
        "factor": 1,
        "food": 24
      },
      {
        "food": 25,
        "factor": 3,
        "answer": true,
        "allow": false,
        "foodNumber": 24
      },
      {
        "answer": true,
        "allow": false,
        "food": 26,
        "factor": 2,
        "foodNumber": 25
      },
      {
        "allow": true,
        "foodNumber": 26,
        "factor": 1,
        "answer": true,
        "food": 27
      },
      {
        "factor": 2,
        "allow": false,
        "answer": true,
        "food": 28,
        "foodNumber": 27
      },
      {
        "food": 29,
        "allow": false,
        "answer": true,
        "foodNumber": 28,
        "factor": 1
      },
      {
        "food": 30,
        "answer": true,
        "foodNumber": 29,
        "factor": 3,
        "allow": false
      },
      {
        "answer": true,
        "factor": 2,
        "allow": false,
        "foodNumber": 30,
        "food": 31
      },
      {
        "factor": 1,
        "answer": true,
        "foodNumber": 31,
        "allow": true,
        "food": 32
      },
      {
        "factor": 3,
        "food": 33,
        "allow": false,
        "answer": true,
        "foodNumber": 32
      },
      {
        "answer": true,
        "food": 34,
        "allow": false,
        "foodNumber": 33,
        "factor": 3
      },
      {
        "factor": 1,
        "answer": true,
        "foodNumber": 34,
        "food": 35,
        "allow": true
      },
      {
        "allow": true,
        "answer": true,
        "foodNumber": 35,
        "food": 36,
        "factor": 1
      }
    ]
  },
  {
    "id": "N-TEST-vE88nqrJ1oBvuac3kYLe-0d8a5e4f-5866-4840-b1a6-5b2606bc5d45",
    "Answer": [
      {
        "foodNumber": 0,
        "answer": false,
        "food": 1,
        "allow": true,
        "factor": 1
      },
      {
        "factor": 1,
        "food": 2,
        "allow": true,
        "answer": false,
        "foodNumber": 1
      },
      {
        "answer": false,
        "foodNumber": 2,
        "allow": true,
        "factor": 2,
        "food": 3
      },
      {
        "food": 4,
        "factor": 3,
        "allow": true,
        "answer": false,
        "foodNumber": 3
      },
      {
        "foodNumber": 4,
        "answer": false,
        "factor": 1,
        "food": 5,
        "allow": true
      },
      {
        "allow": false,
        "foodNumber": 5,
        "answer": false,
        "food": 6,
        "factor": 2
      },
      {
        "food": 7,
        "foodNumber": 6,
        "factor": 3,
        "allow": false,
        "answer": false
      },
      {
        "foodNumber": 7,
        "allow": "number8",
        "factor": 3,
        "answer": false,
        "food": 8
      },
      {
        "allow": false,
        "foodNumber": 8,
        "factor": 3,
        "answer": false,
        "food": 9
      },
      {
        "allow": false,
        "food": 10,
        "answer": false,
        "foodNumber": 9,
        "factor": 2
      },
      {
        "allow": true,
        "answer": false,
        "factor": 1,
        "foodNumber": 10,
        "food": 11
      },
      {
        "factor": 1,
        "answer": false,
        "food": 12,
        "foodNumber": 11,
        "allow": true
      },
      {
        "answer": false,
        "allow": true,
        "foodNumber": 12,
        "food": 13,
        "factor": 1
      },
      {
        "allow": false,
        "food": 14,
        "factor": 2,
        "foodNumber": 13,
        "answer": false
      },
      {
        "allow": true,
        "factor": 1,
        "answer": false,
        "food": 15,
        "foodNumber": 14
      },
      {
        "food": 16,
        "factor": 3,
        "answer": false,
        "foodNumber": 15,
        "allow": false
      },
      {
        "food": 17,
        "factor": 3,
        "answer": false,
        "foodNumber": 16,
        "allow": false
      },
      {
        "factor": 3,
        "food": 18,
        "answer": false,
        "foodNumber": 17,
        "allow": false
      },
      {
        "answer": true,
        "foodNumber": 18,
        "allow": false,
        "food": 19,
        "factor": 2
      },
      {
        "allow": true,
        "food": 20,
        "factor": 1,
        "answer": true,
        "foodNumber": 19
      },
      {
        "factor": 2,
        "allow": false,
        "food": 21,
        "answer": true,
        "foodNumber": 20
      },
      {
        "factor": 2,
        "food": 22,
        "answer": true,
        "allow": false,
        "foodNumber": 21
      },
      {
        "allow": true,
        "food": 23,
        "foodNumber": 22,
        "factor": 1,
        "answer": true
      },
      {
        "foodNumber": 23,
        "food": 24,
        "factor": 1,
        "answer": true,
        "allow": true
      },
      {
        "food": 25,
        "answer": true,
        "foodNumber": 24,
        "allow": false,
        "factor": 3
      },
      {
        "food": 26,
        "answer": true,
        "foodNumber": 25,
        "factor": 2,
        "allow": false
      },
      {
        "allow": true,
        "answer": true,
        "foodNumber": 26,
        "food": 27,
        "factor": 1
      },
      {
        "allow": false,
        "foodNumber": 27,
        "answer": true,
        "food": 28,
        "factor": 2
      },
      {
        "factor": 1,
        "foodNumber": 28,
        "answer": true,
        "food": 29,
        "allow": false
      },
      {
        "foodNumber": 29,
        "factor": 3,
        "answer": true,
        "food": 30,
        "allow": false
      },
      {
        "allow": false,
        "foodNumber": 30,
        "food": 31,
        "answer": true,
        "factor": 2
      },
      {
        "factor": 1,
        "answer": true,
        "food": 32,
        "allow": true,
        "foodNumber": 31
      },
      {
        "answer": true,
        "foodNumber": 32,
        "food": 33,
        "allow": false,
        "factor": 3
      },
      {
        "allow": false,
        "food": 34,
        "answer": true,
        "foodNumber": 33,
        "factor": 3
      },
      {
        "food": 35,
        "factor": 1,
        "allow": true,
        "answer": true,
        "foodNumber": 34
      },
      {
        "food": 36,
        "foodNumber": 35,
        "allow": true,
        "answer": true,
        "factor": 1
      }
    ]
  },
  {
    "id": "c2ea7e31-71e5-4d34-9340-c086a7c13755",
    "Answer": [
      {
        "food": 1,
        "allow": true,
        "factor": 1,
        "answer": false,
        "foodNumber": 0
      },
      {
        "answer": false,
        "food": 2,
        "foodNumber": 1,
        "allow": true,
        "factor": 1
      },
      {
        "factor": 2,
        "foodNumber": 2,
        "answer": false,
        "allow": true,
        "food": 3
      },
      {
        "food": 4,
        "foodNumber": 3,
        "allow": true,
        "answer": false,
        "factor": 3
      },
      {
        "allow": true,
        "answer": false,
        "food": 5,
        "foodNumber": 4,
        "factor": 1
      },
      {
        "food": 6,
        "allow": false,
        "answer": false,
        "factor": 2,
        "foodNumber": 5
      },
      {
        "allow": false,
        "food": 7,
        "answer": false,
        "foodNumber": 6,
        "factor": 3
      },
      {
        "food": 8,
        "answer": false,
        "allow": "number8",
        "foodNumber": 7,
        "factor": 3
      },
      {
        "food": 9,
        "allow": false,
        "answer": false,
        "foodNumber": 8,
        "factor": 3
      },
      {
        "foodNumber": 9,
        "answer": false,
        "factor": 2,
        "food": 10,
        "allow": false
      },
      {
        "factor": 1,
        "foodNumber": 10,
        "answer": false,
        "allow": true,
        "food": 11
      },
      {
        "allow": true,
        "foodNumber": 11,
        "answer": false,
        "factor": 1,
        "food": 12
      },
      {
        "answer": false,
        "factor": 1,
        "allow": true,
        "food": 13,
        "foodNumber": 12
      },
      {
        "answer": false,
        "allow": false,
        "food": 14,
        "foodNumber": 13,
        "factor": 2
      },
      {
        "answer": false,
        "foodNumber": 14,
        "allow": true,
        "food": 15,
        "factor": 1
      },
      {
        "answer": false,
        "foodNumber": 15,
        "food": 16,
        "factor": 3,
        "allow": false
      },
      {
        "allow": false,
        "factor": 3,
        "answer": false,
        "food": 17,
        "foodNumber": 16
      },
      {
        "answer": false,
        "allow": false,
        "food": 18,
        "factor": 3,
        "foodNumber": 17
      },
      {
        "food": 19,
        "factor": 2,
        "allow": false,
        "foodNumber": 18,
        "answer": true
      },
      {
        "allow": true,
        "food": 20,
        "answer": true,
        "factor": 1,
        "foodNumber": 19
      },
      {
        "food": 21,
        "allow": false,
        "factor": 2,
        "foodNumber": 20,
        "answer": true
      },
      {
        "answer": true,
        "food": 22,
        "allow": false,
        "foodNumber": 21,
        "factor": 2
      },
      {
        "allow": true,
        "food": 23,
        "answer": true,
        "factor": 1,
        "foodNumber": 22
      },
      {
        "food": 24,
        "answer": true,
        "factor": 1,
        "foodNumber": 23,
        "allow": true
      },
      {
        "allow": false,
        "answer": true,
        "factor": 3,
        "foodNumber": 24,
        "food": 25
      },
      {
        "factor": 2,
        "answer": true,
        "food": 26,
        "foodNumber": 25,
        "allow": false
      },
      {
        "allow": true,
        "answer": true,
        "foodNumber": 26,
        "food": 27,
        "factor": 1
      },
      {
        "food": 28,
        "answer": true,
        "allow": false,
        "foodNumber": 27,
        "factor": 2
      },
      {
        "factor": 1,
        "answer": true,
        "foodNumber": 28,
        "food": 29,
        "allow": false
      },
      {
        "answer": true,
        "foodNumber": 29,
        "allow": false,
        "food": 30,
        "factor": 3
      },
      {
        "food": 31,
        "answer": true,
        "foodNumber": 30,
        "allow": false,
        "factor": 2
      },
      {
        "factor": 1,
        "allow": true,
        "foodNumber": 31,
        "answer": true,
        "food": 32
      },
      {
        "answer": true,
        "allow": false,
        "factor": 3,
        "food": 33,
        "foodNumber": 32
      },
      {
        "factor": 3,
        "answer": true,
        "food": 34,
        "allow": false,
        "foodNumber": 33
      },
      {
        "food": 35,
        "answer": true,
        "foodNumber": 34,
        "allow": true,
        "factor": 1
      },
      {
        "answer": true,
        "foodNumber": 35,
        "food": 36,
        "factor": 1,
        "allow": true
      }
    ]
  },
  {
    "id": "c9dc3f80-f129-483c-8c3a-ae62d51ef08f",
    "Answer": [
      {
        "allow": true,
        "answer": false,
        "factor": 1,
        "foodNumber": 0,
        "food": 1
      },
      {
        "factor": 1,
        "foodNumber": 1,
        "allow": true,
        "food": 2,
        "answer": false
      },
      {
        "allow": true,
        "foodNumber": 2,
        "answer": false,
        "factor": 2,
        "food": 3
      },
      {
        "answer": false,
        "foodNumber": 3,
        "allow": true,
        "food": 4,
        "factor": 3
      },
      {
        "answer": false,
        "food": 5,
        "factor": 1,
        "allow": true,
        "foodNumber": 4
      },
      {
        "foodNumber": 5,
        "food": 6,
        "answer": false,
        "factor": 2,
        "allow": false
      },
      {
        "factor": 3,
        "food": 7,
        "foodNumber": 6,
        "allow": false,
        "answer": false
      },
      {
        "factor": 3,
        "food": 8,
        "foodNumber": 7,
        "answer": false,
        "allow": "number8"
      },
      {
        "foodNumber": 8,
        "food": 9,
        "factor": 3,
        "allow": false,
        "answer": false
      },
      {
        "factor": 2,
        "foodNumber": 9,
        "food": 10,
        "answer": false,
        "allow": false
      },
      {
        "allow": true,
        "food": 11,
        "answer": false,
        "factor": 1,
        "foodNumber": 10
      },
      {
        "answer": false,
        "allow": true,
        "food": 12,
        "factor": 1,
        "foodNumber": 11
      },
      {
        "allow": true,
        "food": 13,
        "factor": 1,
        "foodNumber": 12,
        "answer": false
      },
      {
        "foodNumber": 13,
        "allow": false,
        "food": 14,
        "factor": 2,
        "answer": false
      },
      {
        "answer": false,
        "allow": true,
        "foodNumber": 14,
        "factor": 1,
        "food": 15
      },
      {
        "factor": 3,
        "allow": false,
        "foodNumber": 15,
        "food": 16,
        "answer": false
      },
      {
        "factor": 3,
        "allow": false,
        "answer": false,
        "food": 17,
        "foodNumber": 16
      },
      {
        "food": 18,
        "foodNumber": 17,
        "answer": false,
        "factor": 3,
        "allow": false
      },
      {
        "factor": 2,
        "answer": true,
        "allow": false,
        "foodNumber": 18,
        "food": 19
      },
      {
        "allow": true,
        "answer": true,
        "food": 20,
        "foodNumber": 19,
        "factor": 1
      },
      {
        "answer": true,
        "factor": 2,
        "allow": false,
        "foodNumber": 20,
        "food": 21
      },
      {
        "foodNumber": 21,
        "allow": false,
        "food": 22,
        "answer": true,
        "factor": 2
      },
      {
        "allow": true,
        "answer": true,
        "food": 23,
        "factor": 1,
        "foodNumber": 22
      },
      {
        "food": 24,
        "factor": 1,
        "foodNumber": 23,
        "answer": true,
        "allow": true
      },
      {
        "food": 25,
        "foodNumber": 24,
        "factor": 3,
        "answer": true,
        "allow": false
      },
      {
        "answer": true,
        "foodNumber": 25,
        "food": 26,
        "allow": false,
        "factor": 2
      },
      {
        "answer": true,
        "factor": 1,
        "food": 27,
        "foodNumber": 26,
        "allow": true
      },
      {
        "answer": true,
        "factor": 2,
        "food": 28,
        "foodNumber": 27,
        "allow": false
      },
      {
        "foodNumber": 28,
        "factor": 1,
        "answer": true,
        "allow": false,
        "food": 29
      },
      {
        "factor": 3,
        "allow": false,
        "food": 30,
        "answer": true,
        "foodNumber": 29
      },
      {
        "allow": false,
        "foodNumber": 30,
        "food": 31,
        "answer": true,
        "factor": 2
      },
      {
        "allow": true,
        "foodNumber": 31,
        "answer": true,
        "food": 32,
        "factor": 1
      },
      {
        "allow": false,
        "answer": true,
        "foodNumber": 32,
        "food": 33,
        "factor": 3
      },
      {
        "allow": false,
        "factor": 3,
        "food": 34,
        "answer": true,
        "foodNumber": 33
      },
      {
        "foodNumber": 34,
        "allow": true,
        "food": 35,
        "answer": true,
        "factor": 1
      },
      {
        "answer": true,
        "food": 36,
        "foodNumber": 35,
        "allow": true,
        "factor": 1
      }
    ]
  },
  {
    "id": "03ee2595-94f0-4983-b93e-2d8a50837331",
    "Answer": [
      {
        "food": 1,
        "foodNumber": 0,
        "factor": 1,
        "answer": false,
        "allow": true
      },
      {
        "foodNumber": 1,
        "allow": true,
        "answer": false,
        "food": 2,
        "factor": 1
      },
      {
        "factor": 2,
        "answer": false,
        "allow": true,
        "foodNumber": 2,
        "food": 3
      },
      {
        "foodNumber": 3,
        "factor": 3,
        "answer": false,
        "allow": true,
        "food": 4
      },
      {
        "factor": 1,
        "answer": false,
        "food": 5,
        "foodNumber": 4,
        "allow": true
      },
      {
        "answer": false,
        "allow": false,
        "foodNumber": 5,
        "food": 6,
        "factor": 2
      },
      {
        "food": 7,
        "factor": 3,
        "foodNumber": 6,
        "answer": false,
        "allow": false
      },
      {
        "factor": 3,
        "foodNumber": 7,
        "answer": false,
        "allow": "number8",
        "food": 8
      },
      {
        "food": 9,
        "allow": false,
        "factor": 3,
        "foodNumber": 8,
        "answer": false
      },
      {
        "allow": false,
        "food": 10,
        "factor": 2,
        "answer": false,
        "foodNumber": 9
      },
      {
        "food": 11,
        "foodNumber": 10,
        "allow": true,
        "factor": 1,
        "answer": false
      },
      {
        "food": 12,
        "foodNumber": 11,
        "answer": false,
        "allow": true,
        "factor": 1
      },
      {
        "factor": 1,
        "allow": true,
        "food": 13,
        "answer": false,
        "foodNumber": 12
      },
      {
        "answer": false,
        "food": 14,
        "allow": false,
        "foodNumber": 13,
        "factor": 2
      },
      {
        "allow": true,
        "food": 15,
        "answer": false,
        "factor": 1,
        "foodNumber": 14
      },
      {
        "answer": false,
        "allow": false,
        "foodNumber": 15,
        "food": 16,
        "factor": 3
      },
      {
        "foodNumber": 16,
        "food": 17,
        "allow": false,
        "answer": false,
        "factor": 3
      },
      {
        "foodNumber": 17,
        "allow": false,
        "answer": true,
        "factor": 3,
        "food": 18
      },
      {
        "answer": true,
        "factor": 2,
        "food": 19,
        "allow": false,
        "foodNumber": 18
      },
      {
        "answer": true,
        "foodNumber": 19,
        "allow": true,
        "food": 20,
        "factor": 1
      },
      {
        "food": 21,
        "allow": false,
        "foodNumber": 20,
        "factor": 2,
        "answer": true
      },
      {
        "foodNumber": 21,
        "factor": 2,
        "food": 22,
        "allow": false,
        "answer": true
      },
      {
        "allow": true,
        "foodNumber": 22,
        "food": 23,
        "factor": 1,
        "answer": true
      },
      {
        "answer": true,
        "factor": 1,
        "food": 24,
        "allow": true,
        "foodNumber": 23
      },
      {
        "foodNumber": 24,
        "answer": true,
        "food": 25,
        "factor": 3,
        "allow": false
      },
      {
        "answer": true,
        "food": 26,
        "allow": false,
        "factor": 2,
        "foodNumber": 25
      },
      {
        "food": 27,
        "foodNumber": 26,
        "allow": true,
        "answer": true,
        "factor": 1
      },
      {
        "food": 28,
        "answer": true,
        "foodNumber": 27,
        "allow": false,
        "factor": 2
      },
      {
        "food": 29,
        "answer": true,
        "allow": false,
        "factor": 1,
        "foodNumber": 28
      },
      {
        "answer": true,
        "foodNumber": 29,
        "factor": 3,
        "allow": false,
        "food": 30
      },
      {
        "foodNumber": 30,
        "factor": 2,
        "food": 31,
        "answer": true,
        "allow": false
      },
      {
        "allow": true,
        "factor": 1,
        "answer": true,
        "foodNumber": 31,
        "food": 32
      },
      {
        "foodNumber": 32,
        "allow": false,
        "food": 33,
        "factor": 3,
        "answer": true
      },
      {
        "foodNumber": 33,
        "answer": true,
        "allow": false,
        "factor": 3,
        "food": 34
      },
      {
        "allow": true,
        "answer": true,
        "food": 35,
        "factor": 1,
        "foodNumber": 34
      },
      {
        "allow": true,
        "answer": true,
        "food": 36,
        "foodNumber": 35,
        "factor": 1
      }
    ]
  },
  {
    "id": "3d59bdd1-5098-4f6d-99d1-fc4298f38a12",
    "Answer": [
      {
        "food": 1,
        "factor": 1,
        "answer": false,
        "foodNumber": 0,
        "allow": true
      },
      {
        "foodNumber": 1,
        "factor": 1,
        "allow": true,
        "answer": false,
        "food": 2
      },
      {
        "answer": false,
        "allow": true,
        "factor": 2,
        "food": 3,
        "foodNumber": 2
      },
      {
        "factor": 3,
        "foodNumber": 3,
        "food": 4,
        "allow": true,
        "answer": false
      },
      {
        "allow": true,
        "food": 5,
        "answer": false,
        "foodNumber": 4,
        "factor": 1
      },
      {
        "foodNumber": 5,
        "factor": 2,
        "allow": false,
        "answer": false,
        "food": 6
      },
      {
        "food": 7,
        "answer": false,
        "factor": 3,
        "foodNumber": 6,
        "allow": false
      },
      {
        "food": 8,
        "answer": false,
        "factor": 3,
        "foodNumber": 7,
        "allow": "number8"
      },
      {
        "allow": false,
        "factor": 3,
        "food": 9,
        "foodNumber": 8,
        "answer": false
      },
      {
        "foodNumber": 9,
        "factor": 2,
        "allow": false,
        "food": 10,
        "answer": false
      },
      {
        "food": 11,
        "allow": true,
        "factor": 1,
        "foodNumber": 10,
        "answer": false
      },
      {
        "foodNumber": 11,
        "answer": false,
        "allow": true,
        "food": 12,
        "factor": 1
      },
      {
        "food": 13,
        "factor": 1,
        "foodNumber": 12,
        "answer": false,
        "allow": true
      },
      {
        "foodNumber": 13,
        "allow": false,
        "answer": false,
        "food": 14,
        "factor": 2
      },
      {
        "foodNumber": 14,
        "allow": true,
        "answer": false,
        "factor": 1,
        "food": 15
      },
      {
        "foodNumber": 15,
        "answer": false,
        "food": 16,
        "allow": false,
        "factor": 3
      },
      {
        "allow": false,
        "factor": 3,
        "food": 17,
        "foodNumber": 16,
        "answer": false
      },
      {
        "allow": false,
        "answer": true,
        "food": 18,
        "factor": 3,
        "foodNumber": 17
      },
      {
        "factor": 2,
        "food": 19,
        "foodNumber": 18,
        "allow": false,
        "answer": true
      },
      {
        "food": 20,
        "allow": true,
        "answer": true,
        "factor": 1,
        "foodNumber": 19
      },
      {
        "answer": true,
        "foodNumber": 20,
        "food": 21,
        "allow": false,
        "factor": 2
      },
      {
        "answer": true,
        "foodNumber": 21,
        "factor": 2,
        "food": 22,
        "allow": false
      },
      {
        "food": 23,
        "factor": 1,
        "allow": true,
        "foodNumber": 22,
        "answer": true
      },
      {
        "allow": true,
        "food": 24,
        "answer": true,
        "factor": 1,
        "foodNumber": 23
      },
      {
        "factor": 3,
        "allow": false,
        "foodNumber": 24,
        "food": 25,
        "answer": true
      },
      {
        "foodNumber": 25,
        "allow": false,
        "answer": true,
        "food": 26,
        "factor": 2
      },
      {
        "factor": 1,
        "allow": true,
        "foodNumber": 26,
        "food": 27,
        "answer": true
      },
      {
        "food": 28,
        "allow": false,
        "answer": true,
        "factor": 2,
        "foodNumber": 27
      },
      {
        "foodNumber": 28,
        "answer": true,
        "allow": false,
        "food": 29,
        "factor": 1
      },
      {
        "allow": false,
        "food": 30,
        "foodNumber": 29,
        "factor": 3,
        "answer": true
      },
      {
        "allow": false,
        "food": 31,
        "answer": true,
        "factor": 2,
        "foodNumber": 30
      },
      {
        "foodNumber": 31,
        "food": 32,
        "answer": true,
        "allow": true,
        "factor": 1
      },
      {
        "foodNumber": 32,
        "answer": true,
        "food": 33,
        "factor": 3,
        "allow": false
      },
      {
        "answer": true,
        "food": 34,
        "factor": 3,
        "foodNumber": 33,
        "allow": false
      },
      {
        "answer": true,
        "allow": true,
        "foodNumber": 34,
        "factor": 1,
        "food": 35
      },
      {
        "allow": true,
        "answer": true,
        "food": 36,
        "factor": 1,
        "foodNumber": 35
      }
    ]
  },
  {
    "id": "aa27d3c4-a864-47d9-87dd-e9ed8820071c",
    "Answer": [
      {
        "allow": true,
        "food": 1,
        "foodNumber": 0,
        "answer": false,
        "factor": 1
      },
      {
        "foodNumber": 1,
        "answer": false,
        "factor": 1,
        "allow": true,
        "food": 2
      },
      {
        "foodNumber": 2,
        "answer": false,
        "food": 3,
        "allow": true,
        "factor": 2
      },
      {
        "factor": 3,
        "foodNumber": 3,
        "answer": false,
        "allow": true,
        "food": 4
      },
      {
        "foodNumber": 4,
        "factor": 1,
        "allow": true,
        "answer": false,
        "food": 5
      },
      {
        "allow": false,
        "food": 6,
        "factor": 2,
        "foodNumber": 5,
        "answer": false
      },
      {
        "allow": false,
        "factor": 3,
        "answer": false,
        "foodNumber": 6,
        "food": 7
      },
      {
        "allow": "number8",
        "factor": 3,
        "foodNumber": 7,
        "food": 8,
        "answer": false
      },
      {
        "foodNumber": 8,
        "food": 9,
        "factor": 3,
        "allow": false,
        "answer": false
      },
      {
        "foodNumber": 9,
        "factor": 2,
        "food": 10,
        "answer": false,
        "allow": false
      },
      {
        "answer": false,
        "foodNumber": 10,
        "food": 11,
        "allow": true,
        "factor": 1
      },
      {
        "food": 12,
        "factor": 1,
        "answer": false,
        "allow": true,
        "foodNumber": 11
      },
      {
        "factor": 1,
        "foodNumber": 12,
        "food": 13,
        "allow": true,
        "answer": false
      },
      {
        "answer": false,
        "allow": false,
        "factor": 2,
        "foodNumber": 13,
        "food": 14
      },
      {
        "allow": true,
        "food": 15,
        "answer": false,
        "factor": 1,
        "foodNumber": 14
      },
      {
        "factor": 3,
        "food": 16,
        "foodNumber": 15,
        "answer": false,
        "allow": false
      },
      {
        "allow": false,
        "factor": 3,
        "foodNumber": 16,
        "food": 17,
        "answer": false
      },
      {
        "foodNumber": 17,
        "allow": false,
        "food": 18,
        "factor": 3,
        "answer": true
      },
      {
        "foodNumber": 18,
        "food": 19,
        "factor": 2,
        "answer": true,
        "allow": false
      },
      {
        "foodNumber": 19,
        "factor": 1,
        "allow": true,
        "answer": true,
        "food": 20
      },
      {
        "factor": 2,
        "food": 21,
        "foodNumber": 20,
        "answer": true,
        "allow": false
      },
      {
        "food": 22,
        "factor": 2,
        "allow": false,
        "foodNumber": 21,
        "answer": true
      },
      {
        "allow": true,
        "food": 23,
        "factor": 1,
        "foodNumber": 22,
        "answer": true
      },
      {
        "allow": true,
        "food": 24,
        "foodNumber": 23,
        "factor": 1,
        "answer": true
      },
      {
        "food": 25,
        "allow": false,
        "factor": 3,
        "foodNumber": 24,
        "answer": true
      },
      {
        "factor": 2,
        "allow": false,
        "food": 26,
        "foodNumber": 25,
        "answer": true
      },
      {
        "allow": true,
        "food": 27,
        "factor": 1,
        "foodNumber": 26,
        "answer": true
      },
      {
        "answer": true,
        "factor": 2,
        "food": 28,
        "allow": false,
        "foodNumber": 27
      },
      {
        "foodNumber": 28,
        "food": 29,
        "answer": true,
        "factor": 1,
        "allow": false
      },
      {
        "foodNumber": 29,
        "allow": false,
        "factor": 3,
        "food": 30,
        "answer": true
      },
      {
        "allow": false,
        "answer": true,
        "foodNumber": 30,
        "food": 31,
        "factor": 2
      },
      {
        "answer": true,
        "factor": 1,
        "allow": true,
        "foodNumber": 31,
        "food": 32
      },
      {
        "factor": 3,
        "foodNumber": 32,
        "food": 33,
        "answer": true,
        "allow": false
      },
      {
        "factor": 3,
        "food": 34,
        "foodNumber": 33,
        "answer": true,
        "allow": false
      },
      {
        "factor": 1,
        "allow": true,
        "foodNumber": 34,
        "food": 35,
        "answer": true
      },
      {
        "answer": true,
        "allow": true,
        "factor": 1,
        "food": 36,
        "foodNumber": 35
      }
    ]
  },
  {
    "id": "329d9706-fa33-41d7-a6f9-b003719c4f70",
    "Answer": [
      {
        "foodNumber": 0,
        "allow": true,
        "answer": false,
        "food": 1,
        "factor": 1
      },
      {
        "answer": false,
        "food": 2,
        "foodNumber": 1,
        "factor": 1,
        "allow": true
      },
      {
        "allow": true,
        "factor": 2,
        "food": 3,
        "foodNumber": 2,
        "answer": false
      },
      {
        "foodNumber": 3,
        "factor": 3,
        "allow": true,
        "food": 4,
        "answer": false
      },
      {
        "food": 5,
        "allow": true,
        "factor": 1,
        "foodNumber": 4,
        "answer": false
      },
      {
        "allow": false,
        "answer": false,
        "food": 6,
        "factor": 2,
        "foodNumber": 5
      },
      {
        "food": 7,
        "foodNumber": 6,
        "answer": false,
        "factor": 3,
        "allow": false
      },
      {
        "factor": 3,
        "foodNumber": 7,
        "food": 8,
        "allow": "number8",
        "answer": false
      },
      {
        "factor": 3,
        "allow": false,
        "food": 9,
        "answer": false,
        "foodNumber": 8
      },
      {
        "food": 10,
        "foodNumber": 9,
        "allow": false,
        "factor": 2,
        "answer": false
      },
      {
        "foodNumber": 10,
        "answer": false,
        "food": 11,
        "factor": 1,
        "allow": true
      },
      {
        "factor": 1,
        "answer": false,
        "food": 12,
        "allow": true,
        "foodNumber": 11
      },
      {
        "factor": 1,
        "answer": false,
        "food": 13,
        "allow": true,
        "foodNumber": 12
      },
      {
        "foodNumber": 13,
        "food": 14,
        "allow": false,
        "factor": 2,
        "answer": false
      },
      {
        "factor": 1,
        "foodNumber": 14,
        "answer": false,
        "food": 15,
        "allow": true
      },
      {
        "answer": false,
        "factor": 3,
        "allow": false,
        "foodNumber": 15,
        "food": 16
      },
      {
        "answer": false,
        "allow": false,
        "food": 17,
        "factor": 3,
        "foodNumber": 16
      },
      {
        "food": 18,
        "foodNumber": 17,
        "allow": false,
        "factor": 3,
        "answer": true
      },
      {
        "food": 19,
        "allow": false,
        "answer": true,
        "factor": 2,
        "foodNumber": 18
      },
      {
        "factor": 1,
        "food": 20,
        "foodNumber": 19,
        "answer": true,
        "allow": true
      },
      {
        "food": 21,
        "answer": true,
        "factor": 2,
        "foodNumber": 20,
        "allow": false
      },
      {
        "foodNumber": 21,
        "food": 22,
        "allow": false,
        "factor": 2,
        "answer": true
      },
      {
        "food": 23,
        "allow": true,
        "foodNumber": 22,
        "factor": 1,
        "answer": true
      },
      {
        "factor": 1,
        "allow": true,
        "answer": true,
        "foodNumber": 23,
        "food": 24
      },
      {
        "food": 25,
        "foodNumber": 24,
        "allow": false,
        "factor": 3,
        "answer": true
      },
      {
        "allow": false,
        "foodNumber": 25,
        "factor": 2,
        "answer": true,
        "food": 26
      },
      {
        "foodNumber": 26,
        "answer": true,
        "factor": 1,
        "food": 27,
        "allow": true
      },
      {
        "allow": false,
        "answer": true,
        "food": 28,
        "foodNumber": 27,
        "factor": 2
      },
      {
        "allow": false,
        "food": 29,
        "factor": 1,
        "answer": true,
        "foodNumber": 28
      },
      {
        "factor": 3,
        "foodNumber": 29,
        "food": 30,
        "answer": true,
        "allow": false
      },
      {
        "allow": false,
        "answer": true,
        "factor": 2,
        "food": 31,
        "foodNumber": 30
      },
      {
        "answer": true,
        "allow": true,
        "food": 32,
        "factor": 1,
        "foodNumber": 31
      },
      {
        "answer": true,
        "foodNumber": 32,
        "factor": 3,
        "food": 33,
        "allow": false
      },
      {
        "answer": true,
        "foodNumber": 33,
        "food": 34,
        "allow": false,
        "factor": 3
      },
      {
        "factor": 1,
        "answer": true,
        "allow": true,
        "foodNumber": 34,
        "food": 35
      },
      {
        "food": 36,
        "foodNumber": 35,
        "answer": true,
        "allow": true,
        "factor": 1
      },
      {
        "factor": 5,
        "foodNumber": 36,
        "answer": true,
        "food": 37,
        "allow": false
      },
      {
        "food": 38,
        "answer": true,
        "factor": 4,
        "allow": true,
        "foodNumber": 37
      },
      {
        "factor": 4,
        "foodNumber": 38,
        "allow": true,
        "food": 39,
        "answer": false
      },
      {
        "factor": 5,
        "answer": false,
        "allow": false,
        "foodNumber": 39,
        "food": 40
      },
      {
        "foodNumber": 40,
        "food": 41,
        "factor": 5,
        "answer": false,
        "allow": false
      },
      {
        "foodNumber": 41,
        "allow": false,
        "answer": false,
        "factor": 5,
        "food": 42
      },
      {
        "food": 43,
        "factor": 5,
        "allow": false,
        "answer": false,
        "foodNumber": 42
      },
      {
        "answer": false,
        "food": 44,
        "factor": 4,
        "foodNumber": 43,
        "allow": true
      },
      {
        "foodNumber": 44,
        "factor": 4,
        "food": 45,
        "answer": false,
        "allow": true
      },
      {
        "allow": true,
        "factor": 4,
        "answer": false,
        "foodNumber": 45,
        "food": 46
      },
      {
        "answer": false,
        "allow": false,
        "factor": 5,
        "foodNumber": 46,
        "food": 47
      },
      {
        "food": 48,
        "answer": false,
        "foodNumber": 47,
        "factor": 4,
        "allow": true
      },
      {
        "allow": true,
        "food": 49,
        "factor": 5,
        "answer": false,
        "foodNumber": 48
      },
      {
        "answer": false,
        "factor": 2,
        "foodNumber": 49,
        "allow": false,
        "food": 50
      },
      {
        "factor": 4,
        "answer": false,
        "foodNumber": 50,
        "food": 51,
        "allow": true
      },
      {
        "factor": 5,
        "foodNumber": 51,
        "food": 52,
        "answer": false,
        "allow": false
      },
      {
        "foodNumber": 52,
        "food": 53,
        "allow": false,
        "factor": 5,
        "answer": false
      },
      {
        "food": 54,
        "factor": 4,
        "allow": true,
        "foodNumber": 53,
        "answer": false
      },
      {
        "factor": 4,
        "foodNumber": 54,
        "answer": false,
        "allow": true,
        "food": 55
      },
      {
        "foodNumber": 55,
        "answer": false,
        "factor": 4,
        "allow": true,
        "food": 56
      },
      {
        "factor": 4,
        "food": 57,
        "answer": false,
        "foodNumber": 56,
        "allow": true
      },
      {
        "allow": false,
        "answer": false,
        "factor": 5,
        "food": 58,
        "foodNumber": 57
      },
      {
        "food": 59,
        "foodNumber": 58,
        "factor": 5,
        "answer": false,
        "allow": false
      },
      {
        "foodNumber": 59,
        "food": 60,
        "factor": 5,
        "allow": false,
        "answer": true
      },
      {
        "factor": 5,
        "foodNumber": 60,
        "answer": true,
        "food": 61,
        "allow": false
      },
      {
        "allow": false,
        "foodNumber": 61,
        "factor": 5,
        "food": 62,
        "answer": true
      },
      {
        "food": 63,
        "allow": true,
        "foodNumber": 62,
        "factor": 4,
        "answer": true
      },
      {
        "foodNumber": 63,
        "allow": false,
        "factor": 5,
        "answer": true,
        "food": 64
      },
      {
        "foodNumber": 64,
        "factor": 1,
        "answer": true,
        "allow": false,
        "food": 65
      },
      {
        "food": 66,
        "answer": true,
        "allow": true,
        "factor": 3,
        "foodNumber": 65
      },
      {
        "allow": true,
        "food": 67,
        "foodNumber": 66,
        "answer": true,
        "factor": 2
      },
      {
        "factor": 1,
        "food": 68,
        "answer": true,
        "allow": true,
        "foodNumber": 67
      },
      {
        "factor": 3,
        "answer": true,
        "food": 69,
        "allow": true,
        "foodNumber": 68
      },
      {
        "factor": 5,
        "food": 70,
        "foodNumber": 69,
        "answer": true,
        "allow": false
      },
      {
        "allow": false,
        "answer": true,
        "factor": 1,
        "foodNumber": 70,
        "food": 71
      },
      {
        "factor": 5,
        "allow": false,
        "answer": true,
        "food": 72,
        "foodNumber": 71
      },
      {
        "factor": 5,
        "answer": true,
        "food": 73,
        "foodNumber": 72,
        "allow": false
      },
      {
        "allow": false,
        "foodNumber": 73,
        "answer": true,
        "factor": 5,
        "food": 74
      },
      {
        "factor": 4,
        "foodNumber": 74,
        "food": 75,
        "answer": true,
        "allow": true
      },
      {
        "factor": 5,
        "allow": false,
        "food": 76,
        "foodNumber": 75,
        "answer": true
      },
      {
        "food": 77,
        "foodNumber": 76,
        "allow": false,
        "factor": 5,
        "answer": true
      },
      {
        "food": 78,
        "factor": 4,
        "foodNumber": 77,
        "allow": true,
        "answer": true
      },
      {
        "allow": false,
        "foodNumber": 78,
        "factor": 5,
        "food": 79,
        "answer": true
      }
    ]
  },
  {
    "id": "0f4fdfb3-ddf4-46b2-b27d-f66c9b803a4a",
    "Answer": [
      {
        "foodNumber": 0,
        "allow": true,
        "factor": 1,
        "food": 1,
        "answer": false
      },
      {
        "answer": false,
        "food": 2,
        "foodNumber": 1,
        "allow": true,
        "factor": 1
      },
      {
        "factor": 2,
        "answer": false,
        "allow": true,
        "food": 3,
        "foodNumber": 2
      },
      {
        "answer": false,
        "foodNumber": 3,
        "allow": true,
        "factor": 3,
        "food": 4
      },
      {
        "answer": true,
        "allow": true,
        "food": 5,
        "factor": 1,
        "foodNumber": 4
      },
      {
        "allow": false,
        "answer": true,
        "foodNumber": 5,
        "factor": 2,
        "food": 6
      },
      {
        "factor": 3,
        "foodNumber": 6,
        "food": 7,
        "allow": false,
        "answer": true
      },
      {
        "foodNumber": 7,
        "answer": false,
        "food": 8,
        "factor": 3,
        "allow": "number8"
      },
      {
        "allow": false,
        "food": 9,
        "factor": 3,
        "foodNumber": 8,
        "answer": false
      },
      {
        "factor": 2,
        "foodNumber": 9,
        "answer": true,
        "food": 10,
        "allow": false
      },
      {
        "factor": 1,
        "food": 11,
        "foodNumber": 10,
        "answer": true,
        "allow": true
      },
      {
        "food": 12,
        "factor": 1,
        "foodNumber": 11,
        "answer": false,
        "allow": true
      },
      {
        "food": 13,
        "answer": false,
        "factor": 1,
        "foodNumber": 12,
        "allow": true
      },
      {
        "allow": false,
        "factor": 2,
        "answer": false,
        "foodNumber": 13,
        "food": 14
      },
      {
        "allow": true,
        "foodNumber": 14,
        "food": 15,
        "answer": true,
        "factor": 1
      },
      {
        "factor": 3,
        "allow": false,
        "answer": false,
        "foodNumber": 15,
        "food": 16
      },
      {
        "factor": 3,
        "food": 17,
        "answer": false,
        "allow": false,
        "foodNumber": 16
      },
      {
        "food": 18,
        "answer": false,
        "allow": false,
        "foodNumber": 17,
        "factor": 3
      },
      {
        "foodNumber": 18,
        "factor": 2,
        "answer": true,
        "allow": false,
        "food": 19
      },
      {
        "foodNumber": 19,
        "factor": 1,
        "food": 20,
        "allow": true,
        "answer": true
      },
      {
        "food": 21,
        "answer": false,
        "foodNumber": 20,
        "factor": 2,
        "allow": false
      },
      {
        "answer": false,
        "food": 22,
        "factor": 2,
        "allow": false,
        "foodNumber": 21
      },
      {
        "allow": true,
        "factor": 1,
        "foodNumber": 22,
        "answer": true,
        "food": 23
      },
      {
        "answer": true,
        "foodNumber": 23,
        "allow": true,
        "factor": 1,
        "food": 24
      },
      {
        "foodNumber": 24,
        "answer": true,
        "allow": false,
        "food": 25,
        "factor": 3
      },
      {
        "foodNumber": 25,
        "allow": false,
        "factor": 2,
        "answer": false,
        "food": 26
      },
      {
        "factor": 1,
        "food": 27,
        "allow": true,
        "foodNumber": 26,
        "answer": true
      },
      {
        "answer": true,
        "factor": 2,
        "food": 28,
        "foodNumber": 27,
        "allow": false
      },
      {
        "foodNumber": 28,
        "allow": false,
        "factor": 1,
        "answer": true,
        "food": 29
      },
      {
        "foodNumber": 29,
        "food": 30,
        "allow": false,
        "factor": 3,
        "answer": false
      },
      {
        "food": 31,
        "allow": false,
        "answer": false,
        "factor": 2,
        "foodNumber": 30
      },
      {
        "foodNumber": 31,
        "factor": 1,
        "answer": true,
        "allow": true,
        "food": 32
      },
      {
        "food": 33,
        "allow": false,
        "answer": false,
        "foodNumber": 32,
        "factor": 3
      },
      {
        "allow": false,
        "factor": 3,
        "answer": false,
        "food": 34,
        "foodNumber": 33
      },
      {
        "foodNumber": 34,
        "factor": 1,
        "answer": true,
        "food": 35,
        "allow": true
      },
      {
        "factor": 1,
        "allow": true,
        "food": 36,
        "answer": true,
        "foodNumber": 35
      }
    ]
  },
  {
    "id": "af04b295-6fd8-48a8-9829-f51e2bf8898c",
    "Answer": [
      {
        "foodNumber": 0,
        "food": 1,
        "allow": true,
        "factor": 1,
        "answer": false
      },
      {
        "answer": true,
        "allow": true,
        "food": 2,
        "foodNumber": 1,
        "factor": 1
      },
      {
        "factor": 2,
        "allow": true,
        "food": 3,
        "answer": false,
        "foodNumber": 2
      },
      {
        "allow": true,
        "answer": false,
        "foodNumber": 3,
        "food": 4,
        "factor": 3
      },
      {
        "foodNumber": 4,
        "factor": 1,
        "food": 5,
        "answer": true,
        "allow": true
      },
      {
        "allow": false,
        "answer": true,
        "foodNumber": 5,
        "food": 6,
        "factor": 2
      },
      {
        "foodNumber": 6,
        "answer": true,
        "factor": 3,
        "allow": false,
        "food": 7
      },
      {
        "foodNumber": 7,
        "factor": 3,
        "answer": false,
        "allow": "number8",
        "food": 8
      },
      {
        "answer": false,
        "allow": false,
        "factor": 3,
        "food": 9,
        "foodNumber": 8
      },
      {
        "foodNumber": 9,
        "factor": 2,
        "allow": false,
        "food": 10,
        "answer": true
      },
      {
        "answer": true,
        "factor": 1,
        "foodNumber": 10,
        "food": 11,
        "allow": true
      },
      {
        "food": 12,
        "factor": 1,
        "allow": true,
        "answer": false,
        "foodNumber": 11
      },
      {
        "allow": true,
        "foodNumber": 12,
        "factor": 1,
        "answer": true,
        "food": 13
      },
      {
        "foodNumber": 13,
        "food": 14,
        "factor": 2,
        "answer": false,
        "allow": false
      },
      {
        "foodNumber": 14,
        "factor": 1,
        "allow": true,
        "answer": true,
        "food": 15
      },
      {
        "answer": false,
        "factor": 3,
        "food": 16,
        "foodNumber": 15,
        "allow": false
      },
      {
        "factor": 3,
        "allow": false,
        "answer": true,
        "food": 17,
        "foodNumber": 16
      },
      {
        "factor": 3,
        "answer": false,
        "food": 18,
        "foodNumber": 17,
        "allow": false
      },
      {
        "foodNumber": 18,
        "allow": false,
        "answer": false,
        "food": 19,
        "factor": 2
      },
      {
        "food": 20,
        "answer": true,
        "allow": true,
        "foodNumber": 19,
        "factor": 1
      },
      {
        "answer": false,
        "allow": false,
        "food": 21,
        "foodNumber": 20,
        "factor": 2
      },
      {
        "answer": false,
        "foodNumber": 21,
        "factor": 2,
        "food": 22,
        "allow": false
      },
      {
        "allow": true,
        "foodNumber": 22,
        "factor": 1,
        "food": 23,
        "answer": true
      },
      {
        "food": 24,
        "answer": true,
        "factor": 1,
        "allow": true,
        "foodNumber": 23
      },
      {
        "answer": false,
        "food": 25,
        "allow": false,
        "foodNumber": 24,
        "factor": 3
      },
      {
        "factor": 2,
        "answer": false,
        "allow": false,
        "foodNumber": 25,
        "food": 26
      },
      {
        "food": 27,
        "factor": 1,
        "answer": true,
        "foodNumber": 26,
        "allow": true
      },
      {
        "foodNumber": 27,
        "factor": 2,
        "allow": false,
        "food": 28,
        "answer": true
      },
      {
        "allow": false,
        "answer": true,
        "factor": 1,
        "foodNumber": 28,
        "food": 29
      },
      {
        "answer": false,
        "foodNumber": 29,
        "factor": 3,
        "food": 30,
        "allow": false
      },
      {
        "factor": 2,
        "answer": false,
        "allow": false,
        "foodNumber": 30,
        "food": 31
      },
      {
        "foodNumber": 31,
        "food": 32,
        "answer": true,
        "allow": true,
        "factor": 1
      },
      {
        "answer": false,
        "factor": 3,
        "food": 33,
        "allow": false,
        "foodNumber": 32
      },
      {
        "food": 34,
        "allow": false,
        "answer": false,
        "foodNumber": 33,
        "factor": 3
      },
      {
        "allow": true,
        "answer": true,
        "factor": 1,
        "food": 35,
        "foodNumber": 34
      },
      {
        "answer": true,
        "factor": 1,
        "allow": true,
        "food": 36,
        "foodNumber": 35
      }
    ]
  },
  {
    "id": "90ebe5f6-fa97-47c2-9919-8e2ff59b5a3a",
    "Answer": [
      {
        "food": 1,
        "answer": true,
        "factor": 1,
        "allow": true,
        "foodNumber": 0
      },
      {
        "food": 2,
        "answer": true,
        "foodNumber": 1,
        "allow": true,
        "factor": 1
      },
      {
        "answer": false,
        "allow": true,
        "foodNumber": 2,
        "food": 3,
        "factor": 2
      },
      {
        "factor": 3,
        "allow": true,
        "foodNumber": 3,
        "answer": true,
        "food": 4
      },
      {
        "food": 5,
        "foodNumber": 4,
        "answer": false,
        "allow": true,
        "factor": 1
      },
      {
        "allow": false,
        "foodNumber": 5,
        "factor": 2,
        "food": 6,
        "answer": false
      },
      {
        "answer": true,
        "factor": 3,
        "foodNumber": 6,
        "food": 7,
        "allow": false
      },
      {
        "answer": false,
        "food": 8,
        "allow": "number8",
        "factor": 3,
        "foodNumber": 7
      },
      {
        "food": 9,
        "allow": false,
        "foodNumber": 8,
        "factor": 3,
        "answer": true
      },
      {
        "factor": 2,
        "allow": false,
        "foodNumber": 9,
        "food": 10,
        "answer": true
      },
      {
        "foodNumber": 10,
        "factor": 1,
        "allow": true,
        "food": 11,
        "answer": true
      },
      {
        "answer": true,
        "allow": true,
        "foodNumber": 11,
        "factor": 1,
        "food": 12
      },
      {
        "allow": true,
        "factor": 1,
        "food": 13,
        "answer": true,
        "foodNumber": 12
      },
      {
        "food": 14,
        "factor": 2,
        "foodNumber": 13,
        "allow": false,
        "answer": false
      },
      {
        "factor": 1,
        "allow": true,
        "foodNumber": 14,
        "food": 15,
        "answer": true
      },
      {
        "food": 16,
        "answer": false,
        "factor": 3,
        "foodNumber": 15,
        "allow": false
      },
      {
        "factor": 3,
        "allow": false,
        "answer": true,
        "foodNumber": 16,
        "food": 17
      },
      {
        "factor": 3,
        "foodNumber": 17,
        "food": 18,
        "allow": false,
        "answer": true
      },
      {
        "food": 19,
        "foodNumber": 18,
        "answer": false,
        "allow": false,
        "factor": 2
      },
      {
        "factor": 1,
        "food": 20,
        "foodNumber": 19,
        "allow": true,
        "answer": true
      },
      {
        "answer": false,
        "allow": false,
        "factor": 2,
        "foodNumber": 20,
        "food": 21
      },
      {
        "allow": false,
        "food": 22,
        "foodNumber": 21,
        "factor": 2,
        "answer": false
      },
      {
        "factor": 1,
        "allow": true,
        "foodNumber": 22,
        "answer": true,
        "food": 23
      },
      {
        "answer": true,
        "allow": true,
        "factor": 1,
        "foodNumber": 23,
        "food": 24
      },
      {
        "answer": true,
        "allow": false,
        "foodNumber": 24,
        "factor": 3,
        "food": 25
      },
      {
        "food": 26,
        "foodNumber": 25,
        "answer": false,
        "allow": false,
        "factor": 2
      },
      {
        "foodNumber": 26,
        "allow": true,
        "factor": 1,
        "answer": true,
        "food": 27
      },
      {
        "foodNumber": 27,
        "factor": 2,
        "answer": false,
        "allow": false,
        "food": 28
      },
      {
        "food": 29,
        "allow": false,
        "answer": true,
        "foodNumber": 28,
        "factor": 1
      },
      {
        "factor": 3,
        "food": 30,
        "foodNumber": 29,
        "allow": false,
        "answer": true
      },
      {
        "allow": false,
        "factor": 2,
        "food": 31,
        "foodNumber": 30,
        "answer": false
      },
      {
        "foodNumber": 31,
        "answer": true,
        "factor": 1,
        "food": 32,
        "allow": true
      },
      {
        "food": 33,
        "answer": true,
        "foodNumber": 32,
        "allow": false,
        "factor": 3
      },
      {
        "food": 34,
        "answer": true,
        "factor": 3,
        "foodNumber": 33,
        "allow": false
      },
      {
        "allow": true,
        "factor": 1,
        "answer": true,
        "foodNumber": 34,
        "food": 35
      },
      {
        "factor": 1,
        "answer": true,
        "allow": true,
        "food": 36,
        "foodNumber": 35
      },
      {
        "allow": false,
        "food": 37,
        "factor": 5,
        "answer": true,
        "foodNumber": 36
      },
      {
        "allow": true,
        "answer": true,
        "foodNumber": 37,
        "food": 38,
        "factor": 4
      },
      {
        "factor": 4,
        "foodNumber": 38,
        "food": 39,
        "allow": true,
        "answer": false
      },
      {
        "allow": false,
        "food": 40,
        "foodNumber": 39,
        "factor": 5,
        "answer": false
      },
      {
        "allow": false,
        "foodNumber": 40,
        "factor": 5,
        "answer": false,
        "food": 41
      },
      {
        "allow": false,
        "food": 42,
        "foodNumber": 41,
        "factor": 5,
        "answer": false
      },
      {
        "foodNumber": 42,
        "food": 43,
        "allow": false,
        "answer": false,
        "factor": 5
      },
      {
        "food": 44,
        "foodNumber": 43,
        "factor": 4,
        "answer": false,
        "allow": true
      },
      {
        "factor": 4,
        "allow": true,
        "food": 45,
        "foodNumber": 44,
        "answer": true
      },
      {
        "foodNumber": 45,
        "allow": true,
        "answer": true,
        "food": 46,
        "factor": 4
      },
      {
        "answer": false,
        "foodNumber": 46,
        "allow": false,
        "food": 47,
        "factor": 5
      },
      {
        "food": 48,
        "foodNumber": 47,
        "factor": 4,
        "answer": true,
        "allow": true
      },
      {
        "factor": 5,
        "food": 49,
        "foodNumber": 48,
        "allow": true,
        "answer": true
      },
      {
        "answer": false,
        "allow": false,
        "food": 50,
        "factor": 2,
        "foodNumber": 49
      },
      {
        "food": 51,
        "foodNumber": 50,
        "factor": 4,
        "allow": true,
        "answer": true
      },
      {
        "answer": false,
        "allow": false,
        "factor": 5,
        "foodNumber": 51,
        "food": 52
      },
      {
        "foodNumber": 52,
        "allow": false,
        "answer": false,
        "food": 53,
        "factor": 5
      },
      {
        "factor": 4,
        "allow": true,
        "foodNumber": 53,
        "answer": true,
        "food": 54
      },
      {
        "foodNumber": 54,
        "factor": 4,
        "answer": true,
        "allow": true,
        "food": 55
      },
      {
        "food": 56,
        "foodNumber": 55,
        "answer": true,
        "allow": true,
        "factor": 4
      },
      {
        "food": 57,
        "factor": 4,
        "allow": true,
        "foodNumber": 56,
        "answer": true
      },
      {
        "foodNumber": 57,
        "allow": false,
        "factor": 5,
        "answer": false,
        "food": 58
      },
      {
        "factor": 5,
        "answer": false,
        "foodNumber": 58,
        "allow": false,
        "food": 59
      },
      {
        "factor": 5,
        "foodNumber": 59,
        "allow": false,
        "answer": false,
        "food": 60
      },
      {
        "food": 61,
        "allow": false,
        "factor": 5,
        "foodNumber": 60,
        "answer": false
      },
      {
        "factor": 5,
        "foodNumber": 61,
        "answer": false,
        "allow": false,
        "food": 62
      },
      {
        "food": 63,
        "factor": 4,
        "foodNumber": 62,
        "answer": false,
        "allow": true
      },
      {
        "factor": 5,
        "allow": false,
        "foodNumber": 63,
        "food": 64,
        "answer": false
      },
      {
        "answer": true,
        "allow": false,
        "factor": 1,
        "foodNumber": 64,
        "food": 65
      },
      {
        "food": 66,
        "factor": 3,
        "allow": true,
        "answer": true,
        "foodNumber": 65
      },
      {
        "factor": 2,
        "food": 67,
        "foodNumber": 66,
        "answer": false,
        "allow": true
      },
      {
        "allow": true,
        "answer": true,
        "food": 68,
        "foodNumber": 67,
        "factor": 1
      },
      {
        "answer": true,
        "foodNumber": 68,
        "factor": 3,
        "allow": true,
        "food": 69
      },
      {
        "allow": false,
        "food": 70,
        "factor": 5,
        "foodNumber": 69,
        "answer": false
      },
      {
        "food": 71,
        "factor": 1,
        "foodNumber": 70,
        "allow": false,
        "answer": false
      },
      {
        "foodNumber": 71,
        "food": 72,
        "factor": 5,
        "answer": false,
        "allow": false
      },
      {
        "foodNumber": 72,
        "answer": false,
        "allow": false,
        "food": 73,
        "factor": 5
      },
      {
        "factor": 5,
        "food": 74,
        "answer": true,
        "foodNumber": 73,
        "allow": false
      },
      {
        "answer": true,
        "food": 75,
        "foodNumber": 74,
        "factor": 4,
        "allow": true
      },
      {
        "allow": false,
        "foodNumber": 75,
        "answer": false,
        "factor": 5,
        "food": 76
      },
      {
        "foodNumber": 76,
        "answer": false,
        "food": 77,
        "allow": false,
        "factor": 5
      },
      {
        "answer": true,
        "food": 78,
        "foodNumber": 77,
        "allow": true,
        "factor": 4
      },
      {
        "allow": false,
        "answer": false,
        "foodNumber": 78,
        "factor": 5,
        "food": 79
      }
    ]
  }
];

const fakeUsers = [
  {
    "id": "yuz0e3EZc84rfBbW99BO",
    "email": "pku.world@gmail.com",
    "username": "RRamos",
    "password": "admin2022",
    "type": "2"
  },
  {
    "id": "578Ogeek0HGWFCm1DC8q",
    "username": "admin",
    "password": "testing2021",
    "email": "ing.lvillega@gmail.com",
    "type": 1
  },
  {
    "id": "vE88nqrJ1oBvuac3kYLe",
    "type": "3",
    "email": "nutritionist1@gmail.com",
    "password": "test2022",
    "username": "nutritionist"
  }
];
 const fackeParamScore = [
  {
    "id": "8DxmN9aTWIZ9NRWF3EYe",
    "value": "70",
    "level": "Llow",
    "score": "8",
    "factor": "1"
  },
  {
    "id": "8svEO67NJIREAZT5Ms2k",
    "score": "4",
    "factor": "1",
    "value": "50",
    "level": "vlow"
  },
  {
    "id": "HqfxDoz5ornHewS51YhS",
    "level": "Medium",
    "score": "10",
    "factor": "1",
    "value": "80"
  },
  {
    "id": "J5I3MrdDDfXCVdIL8G2f",
    "score": "15",
    "factor": "1",
    "level": "High",
    "value": "100"
  },
  {
    "id": "Muiz0uG6LtTqPJEwOX3R",
    "score": "5",
    "value": "55",
    "factor": "1",
    "level": "Llow"
  },
  {
    "id": "PygDPkqnYbrimueGIsQ7",
    "score": "12",
    "factor": "1",
    "value": "90",
    "level": "High"
  },
  {
    "id": "SBEgIvl80y8le6sXfm3r",
    "level": "Llow",
    "value": "65",
    "factor": "1",
    "score": "7"
  },
  {
    "id": "SN8dZ0vzlDck32vaHi5o",
    "score": "1",
    "value": "35",
    "factor": "1",
    "level": "vlow"
  },
  {
    "id": "XCXDOCXaFY7N4i8vS0Xr",
    "value": "30",
    "score": "0",
    "level": "vlow",
    "factor": "1"
  },
  {
    "id": "YY0FwQD8Btlo5w24Nfud",
    "value": "97",
    "factor": "1",
    "score": "14",
    "level": "High"
  },
  {
    "id": "aEQUpevRJ40PRb7EuJyv",
    "value": "60",
    "level": "Llow",
    "score": "6",
    "factor": "1"
  },
  {
    "id": "az439y9OfzwQfIvC0vIF",
    "value": "93",
    "score": "13",
    "level": "High",
    "factor": "1"
  },
  {
    "id": "grPuWslY6XOoz6fvyd6T",
    "score": "9",
    "level": "Medium",
    "value": "75",
    "factor": "1"
  },
  {
    "id": "k2fGxQhPgMeOLbHlP4cp",
    "factor": "1",
    "score": "2",
    "value": "40",
    "level": "vlow"
  },
  {
    "id": "vzSkBYCdUhKBTh6Y8X8Q",
    "factor": "1",
    "value": "83",
    "level": "Medium",
    "score": "11"
  },
  {
    "id": "yrK7NutsZKsP1iVZlGoX",
    "score": "3",
    "level": "vlow",
    "value": "45",
    "factor": "1"
  },
  {
    "id": "2LwgtQKnIZu2fPDInbKI",
    "score": "3",
    "value": "10",
    "factor": "2",
    "level": "vlow"
  },
  {
    "id": "EdyO7fXRV7MyetBVDAAG",
    "level": "Llow",
    "score": "4",
    "value": "20",
    "factor": "2"
  },
  {
    "id": "NRZ1i8PtUMCJlWGuUi38",
    "factor": "2",
    "level": "vlow",
    "score": "1",
    "value": "0"
  },
  {
    "id": "NSZzOIlTCwg0QAaS87su",
    "level": "Medium",
    "score": "8",
    "factor": "2",
    "value": "60"
  },
  {
    "id": "THOPVXkCkhMQNczKgcMT",
    "value": "40",
    "score": "6",
    "level": "Medium",
    "factor": "2"
  },
  {
    "id": "UUcjb7XJ96JQKS0x8yrz",
    "level": "vlow",
    "value": "0",
    "factor": "2",
    "score": "2"
  },
  {
    "id": "Y3viIsfWTUaJjPgVlFMP",
    "score": "0",
    "value": "0",
    "level": "vlow",
    "factor": "2"
  },
  {
    "id": "ZcVtOlbmfj1IILyvJc2I",
    "value": "85",
    "score": "10",
    "level": "High",
    "factor": "2"
  },
  {
    "id": "jzbwSe0r5wbmtP0B10xS",
    "level": "High",
    "score": "9",
    "value": "75",
    "factor": "2"
  },
  {
    "id": "kt13gpzvFAqAreVMovNo",
    "score": "5",
    "level": "Llow",
    "value": "30",
    "factor": "2"
  },
  {
    "id": "sAtk1MAbTmtpxNT0ILeV",
    "value": "50",
    "level": "Medium",
    "factor": "2",
    "score": "7"
  },
  {
    "id": "1vX0UR1ly3ObEMHxLIwE",
    "value": "15",
    "score": "0",
    "level": "vlow",
    "factor": "3"
  },
  {
    "id": "2PklobzNWMxs05WkKACp",
    "level": "Medium",
    "score": "7",
    "value": "60",
    "factor": "3"
  },
  {
    "id": "6WukbMaBnUDxngTClBLn",
    "factor": "3",
    "score": "8",
    "value": "65",
    "level": "Medium"
  },
  {
    "id": "Bh7ETHnFr1AIoYFljp2t",
    "value": "35",
    "score": "3",
    "factor": "3",
    "level": "vlow"
  },
  {
    "id": "DCIzjwqHTSzwcbo1xW0Z",
    "factor": "3",
    "score": "2",
    "value": "30",
    "level": "vlow"
  },
  {
    "id": "IKJxX9v2t3ypBLm5JRFj",
    "level": "High",
    "value": "70",
    "score": "9",
    "factor": "3"
  },
  {
    "id": "TSHC8ZK9ArEuRWmTg5fq",
    "score": "10",
    "factor": "3",
    "value": "80",
    "level": "High"
  },
  {
    "id": "XV43MW5AKNg8eVHqN558",
    "score": "6",
    "value": "55",
    "factor": "3",
    "level": "Llow"
  },
  {
    "id": "aFxdc1DhKtj3WhnqEAdj",
    "value": "50",
    "factor": "3",
    "score": "5",
    "level": "Llow"
  },
  {
    "id": "di3mMbHZQrTqpHWhz9pH",
    "value": "90",
    "factor": "3",
    "score": "11",
    "level": "High"
  },
  {
    "id": "iZMZsuQLi3oyMlHQei1e",
    "factor": "3",
    "value": "20",
    "level": "vlow",
    "score": "1"
  },
  {
    "id": "m27wJLjbr8pj29118eYa",
    "value": "40",
    "level": "Llow",
    "score": "4",
    "factor": "3"
  }
];
const fakePercentilCollection = [
  {
    "id": "X5492oLaXap4rP4bVFjq",
    "score": "0",
    "level": "vlow",
    "percent": "0"
  },
  {
    "id": "rW2A1vYU321qb0E0HXCu",
    "percent": "0",
    "score": "1",
    "level": "vlow"
  },
  {
    "id": "luOyPmSTsKTTkUjaoNWx",
    "score": "10",
    "percent": "0",
    "level": "vlow"
  },
  {
    "id": "a1IHjUMMTwzeY6SaQyaI",
    "percent": "0",
    "score": "11",
    "level": "vlow"
  },
  {
    "id": "xY8HbVhajALMhWNyHAl7",
    "level": "vlow",
    "score": "12",
    "percent": "0"
  },
  {
    "id": "mjjW08Ly7qbLfN1zcgch",
    "score": "13",
    "percent": "0",
    "level": "vlow"
  },
  {
    "id": "V7zmOG4I6TpnLLE99jbP",
    "level": "vlow",
    "percent": "0",
    "score": "14"
  },
  {
    "id": "GgyhZZT6fcu4N4XjXt05",
    "level": "vlow",
    "score": "15",
    "percent": "0"
  },
  {
    "id": "RcRrVU7PzKfI4esavRhE",
    "level": "vlow",
    "percent": "0",
    "score": "16"
  },
  {
    "id": "nrY4NnZECbVS0PaY06mA",
    "level": "vlow",
    "percent": "0",
    "score": "17"
  },
  {
    "id": "yzbRq8gHZSQQusPWlR9l",
    "score": "18",
    "percent": "0",
    "level": "vlow"
  },
  {
    "id": "JeP1kNFNP4prP0bwW3z2",
    "level": "vlow",
    "percent": "0",
    "score": "19"
  },
  {
    "id": "q3Z3jsQVLZvCXKj9HpW7",
    "level": "vlow",
    "percent": "0",
    "score": "2"
  },
  {
    "id": "Hb3UDQvXeZFb9x5H0yQO",
    "percent": "0",
    "level": "vlow",
    "score": "20"
  },
  {
    "id": "FQIbDQYTf2x0UpwFW0Cj",
    "percent": "0",
    "level": "vlow",
    "score": "21"
  },
  {
    "id": "CS9eqaPPBFTEtLlkBfKe",
    "percent": "0",
    "score": "22",
    "level": "vlow"
  },
  {
    "id": "uU9UFo6PpjlYons1luzm",
    "score": "23",
    "level": "vlow",
    "percent": "0"
  },
  {
    "id": "Br4lGkNwRmze2uMCFwSj",
    "score": "24",
    "level": "vlow",
    "percent": "0"
  },
  {
    "id": "hyayKZIjGsf4BOpNWrKw",
    "percent": "0",
    "level": "vlow",
    "score": "25"
  },
  {
    "id": "X5Mvd2qg7cyZ0PgPCDay",
    "level": "vlow",
    "score": "26",
    "percent": "0"
  },
  {
    "id": "Fa4xPleiydvsByIjgU8V",
    "score": "27",
    "level": "vlow",
    "percent": "1"
  },
  {
    "id": "nc9LW3tbwk4rhV5Jukzn",
    "score": "28",
    "percent": "1",
    "level": "vlow"
  },
  {
    "id": "chSnrJCAgMuPVZnfC4HC",
    "score": "29",
    "level": "vlow",
    "percent": "3"
  },
  {
    "id": "MFygYMAspB5hl6t37Jff",
    "level": "vlow",
    "percent": "0",
    "score": "3"
  },
  {
    "id": "lPI5MzrJ0NbeM6uDJKnU",
    "percent": "5",
    "level": "vlow",
    "score": "30"
  },
  {
    "id": "wVJipwqxbDTCaInZaEyg",
    "level": "vlow",
    "percent": "7",
    "score": "31"
  },
  {
    "id": "DH3Ta9EoHzZu2t1LmUmS",
    "percent": "8",
    "score": "32",
    "level": "vlow"
  },
  {
    "id": "hv2Q6LcWDZfD6d6hnv0x",
    "percent": "10",
    "level": "vlow",
    "score": "33"
  },
  {
    "id": "B7m9dqUPhaSgRF6eQ3oj",
    "percent": "12",
    "level": "vlow",
    "score": "34"
  },
  {
    "id": "2YGa35CdjckZhQyEegCs",
    "score": "35",
    "percent": "14",
    "level": "vlow"
  },
  {
    "id": "oOqsnXYR7C7DLUws8II9",
    "score": "36",
    "percent": "15",
    "level": "vlow"
  },
  {
    "id": "5bb34zsYaQZdOmWSbe3Z",
    "score": "37",
    "percent": "15",
    "level": "vlow"
  },
  {
    "id": "l9ornVsBExE43JckuG10",
    "percent": "19",
    "level": "vlow",
    "score": "38"
  },
  {
    "id": "jnBq1vhsWCfu6tCiqbNV",
    "score": "39",
    "percent": "19",
    "level": "vlow"
  },
  {
    "id": "8DlbeKV3ftx2Djoz5kvH",
    "percent": "0",
    "score": "4",
    "level": "vlow"
  },
  {
    "id": "i3iGzL44niX7VAdi1QSE",
    "level": "Llow",
    "percent": "23",
    "score": "40"
  },
  {
    "id": "Pp0F3WEN1Ev1aAm8GbUu",
    "score": "41",
    "percent": "23",
    "level": "Llow"
  },
  {
    "id": "ysllJfmPslOH8KWmgrHa",
    "level": "Llow",
    "percent": "26",
    "score": "42"
  },
  {
    "id": "j8jtrsHnWuzEYHQTEH8q",
    "percent": "26",
    "score": "43",
    "level": "Llow"
  },
  {
    "id": "AItGC9O5N961IgDzLaKj",
    "percent": "30",
    "score": "44",
    "level": "Llow"
  },
  {
    "id": "Nz3jw9OZHP1ZEShfP9Wn",
    "percent": "30",
    "score": "45",
    "level": "Llow"
  },
  {
    "id": "2y1EPWrXQ4tFZ06nJ7xn",
    "percent": "34",
    "level": "Llow",
    "score": "46"
  },
  {
    "id": "CCkBTbZE25bIIlEqEDyO",
    "percent": "34",
    "level": "Llow",
    "score": "47"
  },
  {
    "id": "2nhewMpQWcg0kwnxlXg5",
    "percent": "37",
    "level": "Llow",
    "score": "48"
  },
  {
    "id": "OHKrF9ZCTiHXFcdI2wYG",
    "level": "Llow",
    "percent": "37",
    "score": "49"
  },
  {
    "id": "PvgRZpN7y1TP5ioRs6wu",
    "level": "vlow",
    "percent": "0",
    "score": "5"
  },
  {
    "id": "PwBQ93zOBdc2tPjX0qOP",
    "score": "50",
    "percent": "41",
    "level": "Medium-Low"
  },
  {
    "id": "I7QHbuMVShWtgFzS3CdK",
    "level": "Medium-Low",
    "percent": "41",
    "score": "51"
  },
  {
    "id": "e0pE9ozWAzbEnuYVaJmw",
    "score": "52",
    "level": "Medium-Low",
    "percent": "44"
  },
  {
    "id": "riKEbkzd9kVbd4MyB81i",
    "level": "Medium-Low",
    "percent": "44",
    "score": "53"
  },
  {
    "id": "0RICxIhf6HDuW8qWava4",
    "level": "Llow",
    "score": "54",
    "percent": "48"
  },
  {
    "id": "hrYZi7owuBnmcRkpO3vT",
    "level": "Medium-Low",
    "percent": "48",
    "score": "55"
  },
  {
    "id": "gyHnIGpMeb7GQgVisM5H",
    "level": "Medium-Low",
    "score": "56",
    "percente": "52"
  },
  {
    "id": "OreofXaGuxzUlgR3dWkv",
    "level": "Medium-Low",
    "score": "57",
    "percent": "52"
  },
  {
    "id": "1ih4cOqhL557t6w31Hqr",
    "score": "58",
    "level": "Medium-Low",
    "percent": "55"
  },
  {
    "id": "NgpWzTu2F1Kdnd7zepvW",
    "percent": "55",
    "level": "Medium-Low",
    "score": "59"
  },
  {
    "id": "zp4VAEQpYDKyvFLiKiuU",
    "level": "vlow",
    "percent": "0",
    "score": "6"
  },
  {
    "id": "lFzhbWLLHGrppqAHhh9h",
    "percent": "59",
    "score": "60",
    "level": "Medium-Low"
  },
  {
    "id": "7DB29qBzKcaR38Lxb7yl",
    "percent": "59",
    "score": "61",
    "level": "Medium-Low"
  },
  {
    "id": "yqdoxxMy0e0M2csYfynW",
    "score": "62",
    "percent": "62",
    "level": "Medium-High"
  },
  {
    "id": "pjEZMsP5D66srZ2VyhVv",
    "score": "63",
    "percent": "62",
    "level": "Medium-High"
  },
  {
    "id": "y228TJy00agvgmsTsyh5",
    "level": "Medium-High",
    "percent": "66",
    "score": "64"
  },
  {
    "id": "4UQluxcF6RyGSAtgW7lY",
    "level": "Medium-High",
    "percent": "66",
    "score": "65"
  },
  {
    "id": "RHByRdDxdfwaEoc4GXR3",
    "level": "Medium-High",
    "percent": "70",
    "score": "66"
  },
  {
    "id": "VsNW8tCXOWHbVgq6fNZS",
    "score": "67",
    "level": "Medium-High",
    "percent": "70"
  },
  {
    "id": "cRNdmA3IgwzuhvT53ufI",
    "percent": "73",
    "score": "68",
    "level": "Medium-High"
  },
  {
    "id": "GEeBlEsAeUAFpFnyT4zb",
    "score": "69",
    "level": "Medium-High",
    "percent": "73"
  },
  {
    "id": "wwXa5WCufLT2y5FK3vDC",
    "level": "vlow",
    "score": "7",
    "percent": "0"
  },
  {
    "id": "MVXjrnt4E1rOQeK3zinY",
    "score": "70",
    "percent": "77",
    "level": "Medium-High"
  },
  {
    "id": "6zr35EOPo1wbME9gLdmz",
    "level": "Medium-High",
    "percent": "78",
    "score": "71"
  },
  {
    "id": "rPZ6cEH5zzLz09ThZN1R",
    "level": "Very-High",
    "score": "72",
    "percent": "80"
  },
  {
    "id": "IkDOL7qXzyFPD7xyx0Ux",
    "percent": "85",
    "level": "Very-High",
    "score": "73"
  },
  {
    "id": "dru0cPfU9RCAMF6MKVAt",
    "level": "Very-High",
    "score": "74",
    "percent": "90"
  },
  {
    "id": "GPnEpL5y06IIF8j7cuOr",
    "percent": "95",
    "level": "Very-High",
    "score": "75"
  },
  {
    "id": "jfU8gQbcYNemoWmamSyF",
    "score": "76",
    "percent": "96",
    "level": "Very-High"
  },
  {
    "id": "vr4a3EwNEd2Vz9pfvylt",
    "level": "Very-High",
    "score": "77",
    "percent": "97"
  },
  {
    "id": "Hg28EgnDLfHq15G6oYXW",
    "level": "Very-High",
    "score": "78",
    "percent": "98"
  },
  {
    "id": "Mgl4wFtxwKO0WFGBwRKK",
    "score": "79",
    "percent": "99",
    "level": "Very-High"
  },
  {
    "id": "ioRdlveAyRQ4ztj6elFc",
    "level": "vlow",
    "score": "8",
    "percent": "0"
  },
  {
    "id": "KEOrICUJbYz4GQheCvmb",
    "percent": "100",
    "level": "Very-High",
    "score": "80"
  },
  {
    "id": "NbtrrnB3PrgWzsJoz6Hj",
    "score": "9",
    "percent": "0",
    "level": "vlow"
  }
]
const fakePercentilF1 = [
  {
    "id": "xDlWHWI49kerHIyvBwFz",
    "RawScore": 0,
    "Percent": 1
  },
  {
    "id": "BKVBjSufmCeno0LksT6D",
    "RawScore": 1,
    "Percent": 3
  },
  {
    "id": "GK2DbLpqxyBe0urfWEDV",
    "RawScore": 2,
    "Percent": 7
  },
  {
    "id": "53eJwWsr4u1JQ5GOs0SM",
    "Percent": 10,
    "RawScore": 3
  },
  {
    "id": "sYdjGxVSh3eu3ZTCO9T6",
    "Percent": 15,
    "RawScore": 4
  },
  {
    "id": "A7CKUigcGiUx4luR7TG2",
    "RawScore": 5,
    "Percent": 20
  },
  {
    "id": "wrqXE0ns6o6X2YcWAoDR",
    "Percent": 25,
    "RawScore": 6
  },
  {
    "id": "HrrEPrTaSTtGlpFBPOvX",
    "RawScore": 7,
    "Percent": 30
  },
  {
    "id": "ARaJNnyxrcTsmpqKqhC0",
    "RawScore": 8,
    "Percent": 35
  },
  {
    "id": "pIhYZnxMH5Nhlh9sbfVb",
    "RawScore": 9,
    "Percent": 40
  },
  {
    "id": "5WJA2LZBM3Tkydagx6GC",
    "Percent": 45,
    "RawScore": 10
  },
  {
    "id": "zc7eZPZCMz4yJwYFV4aM",
    "Percent": 50,
    "RawScore": 11
  },
  {
    "id": "bmAXtHZ0fYmDpdyVjmkX",
    "RawScore": 12,
    "Percent": 60
  },
  {
    "id": "r9hYuC0QFp8LnlLp4Cpk",
    "RawScore": 13,
    "Percent": 70
  },
  {
    "id": "w2f8E0JzDMg0pkZbL0yd",
    "Percent": 80,
    "RawScore": 14
  },
  {
    "id": "AabgPUyutqeWZwouZz9n",
    "RawScore": 15,
    "Percent": 90
  }
];
const fakePercentilF2 = [
  {
    "id": "2nmjTZQltlnmwHAHtXRy",
    "RawScore": 0,
    "Percent": 1
  },
  {
    "id": "gdNDs6H6oWob977wi3GF",
    "Percent": 3,
    "RawScore": 1
  },
  {
    "id": "HF31hqQeSVRxp2h8GygZ",
    "RawScore": 2,
    "Percent": 10
  },
  {
    "id": "K21XuwvpDIHxs7Mank73",
    "RawScore": 3,
    "Percent": 20
  },
  {
    "id": "hlTVuPLgT7tSqZVNVYc2",
    "Percent": 25,
    "RawScore": 4
  },
  {
    "id": "sNLSNix7onyvWUw0k0W8",
    "Percent": 30,
    "RawScore": 5
  },
  {
    "id": "VLpBwrBoX7YiJg8nCO1U",
    "Percent": 35,
    "RawScore": 6
  },
  {
    "id": "4q0o89KfEQWTzt5hV1WI",
    "RawScore": 7,
    "Percent": 40
  },
  {
    "id": "4L6GOnwBBeIX0O141TVL",
    "RawScore": 8,
    "Percent": 50
  },
  {
    "id": "ywZynP2smRWtOQmlyAHI",
    "RawScore": 9,
    "Percent": 75
  },
  {
    "id": "Pt0HMPQa73YyLozyANNT",
    "Percent": 90,
    "RawScore": 10
  }
];
const fakePercentilF3 = [
  {
    "id": "XBuqCcuddlWPoAZtcsej",
    "RawScore": 0,
    "Percent": 1
  },
  {
    "id": "ANVkmH5BDwUKPwQ6xNUL",
    "RawScore": 1,
    "Percent": 3
  },
  {
    "id": "f2AQWRTDYyad40TZOaIt",
    "Percent": 5,
    "RawScore": 2
  },
  {
    "id": "W70FAnSzGoFprAIOnGcB",
    "RawScore": 3,
    "Percent": 10
  },
  {
    "id": "b8xNFhgfdjPTogUJqLW1",
    "RawScore": 4,
    "Percent": 15
  },
  {
    "id": "24MucBSQr7Z2nZYvlvJI",
    "Percent": 25,
    "RawScore": 5
  },
  {
    "id": "3Pb94qDU7fv7cTspSBQp",
    "RawScore": 6,
    "Percent": 30
  },
  {
    "id": "CURePLN34Cphk9S76S9i",
    "Percent": 50,
    "RawScore": 7
  },
  {
    "id": "QOjoqQuqxzT7qq9GJyW1",
    "RawScore": 8,
    "Percent": 60
  },
  {
    "id": "xAkEdN76pgCSSwr4XDay",
    "Percent": 75,
    "RawScore": 9
  },
  {
    "id": "kW9RvMSWPU9n2I9DQ5ZP",
    "Percent": 90,
    "RawScore": 10
  },
  {
    "id": "iTq4d46WxivZdXmkcZ0C",
    "Percent": 90,
    "RawScore": 11
  }
];
const fakeSScoreSmallCol = [
  {
    "id": "Fp1ldv96R0zWW9ldqplU",
    "RawScore": 0,
    "level": "vlow",
    "Sscore": 0
  },
  {
    "id": "oMBPTs1TqaWCKhnHSvRa",
    "Sscore": 0,
    "level": "vlow",
    "RawScore": 0
  },
  {
    "id": "3HtmUXudHEHM6wyfAGsC",
    "level": "vlow",
    "Sscore": 0,
    "RawScore": 1
  },
  {
    "id": "IUooZzo9RrOcH9vy8D8C",
    "Sscore": 0,
    "level": "vlow",
    "RawScore": 2
  },
  {
    "id": "u2UkronLZUyvnDdkLQtB",
    "Sscore": 0,
    "level": "vlow",
    "RawScore": 3
  },
  {
    "id": "arZE5c2XLr5QvD059YDz",
    "Sscore": 0,
    "level": "vlow",
    "RawScore": 4
  },
  {
    "id": "BMbNOqf6jdgtzisyaG6Q",
    "RawScore": 5,
    "Sscore": 0,
    "level": "vlow"
  },
  {
    "id": "aOAlAbl41iogtCeXiCBe",
    "RawScore": 6,
    "Sscore": 0,
    "level": "vlow"
  },
  {
    "id": "pfwL8VXU2I7TGyjsZrUG",
    "level": "vlow",
    "Sscore": 0,
    "RawScore": 7
  },
  {
    "id": "tJ48tN4AgEiMpm6QgleP",
    "level": "vlow",
    "RawScore": 8,
    "Sscore": 0
  },
  {
    "id": "kWOHSFVrHAuRHa7eK0Xo",
    "RawScore": 9,
    "Sscore": 0,
    "level": "vlow"
  },
  {
    "id": "acxiIJJAUFmMihzs21P6",
    "Sscore": 0,
    "RawScore": 10,
    "level": "vlow"
  },
  {
    "id": "wS8OdU5gCveByNVtGM5Y",
    "RawScore": 11,
    "level": "vlow",
    "Sscore": 0
  },
  {
    "id": "Bt22KVnA1bxOhbBIpeYe",
    "RawScore": 12,
    "level": "vlow",
    "Sscore": 0
  },
  {
    "id": "FOERqEw1hY93qNqnNppQ",
    "RawScore": 13,
    "Sscore": 0,
    "level": "vlow"
  },
  {
    "id": "ykjykmTUsL6otqSQhodt",
    "level": "vlow",
    "Sscore": 0,
    "RawScore": 14
  },
  {
    "id": "QeeSA28hPAV7aSNo3N0R",
    "RawScore": 15,
    "Sscore": 0,
    "level": "vlow"
  },
  {
    "id": "V5DxpdSxogBNn9MY54Th",
    "level": "vlow",
    "Sscore": 0,
    "RawScore": 16
  },
  {
    "id": "3vYDfVxSs3YFXsuq4rae",
    "level": "vlow",
    "RawScore": 17,
    "Sscore": 0
  },
  {
    "id": "2gqzDusdTMGjQBIxgpjW",
    "level": "vlow",
    "RawScore": 18,
    "Sscore": 0
  },
  {
    "id": "OTd4vRbrB4lg2Vj6L8iJ",
    "Sscore": 0,
    "RawScore": 19,
    "level": "vlow"
  },
  {
    "id": "lO35rDCMqnRHMoxBrV4S",
    "RawScore": 20,
    "Sscore": 0,
    "level": "vlow"
  },
  {
    "id": "BNG3Z1VYdOfdPlGnpV9r",
    "RawScore": 21,
    "Sscore": 0,
    "level": "vlow"
  },
  {
    "id": "6tEQWorxFJz4D9pGGWSl",
    "RawScore": 22,
    "Sscore": 0,
    "level": "vlow"
  },
  {
    "id": "jrDK6y0CSIBCKMmMVz5c",
    "Sscore": 0,
    "RawScore": 23,
    "level": "vlow"
  },
  {
    "id": "Y0O2Xt8anQ6LEfjGr5aC",
    "Sscore": 0,
    "RawScore": 24,
    "level": "vlow"
  },
  {
    "id": "MuBRsd0DOp8NwHIiZI17",
    "level": "vlow",
    "RawScore": 25,
    "Sscore": 0
  },
  {
    "id": "VYdJ0A0NteWdWynTkYoD",
    "RawScore": 26,
    "Sscore": 0,
    "level": "vlow"
  },
  {
    "id": "NDaoNfLm6zJe6yAgT7ss",
    "Sscore": 0,
    "level": "vlow",
    "RawScore": 27
  },
  {
    "id": "KIzwIzDYtLVltoxX9vhr",
    "level": "vlow",
    "Sscore": 1,
    "RawScore": 28
  },
  {
    "id": "OCdsbtYnYBIMFg7OQVkJ",
    "Sscore": 3,
    "level": "vlow",
    "RawScore": 29
  },
  {
    "id": "XMz5iMf3TYlR0F5Hg4E3",
    "RawScore": 30,
    "level": "vlow",
    "Sscore": 5
  },
  {
    "id": "awwjWjpAQjDAKbgDZdKH",
    "level": "vlow",
    "Sscore": 7,
    "RawScore": 31
  },
  {
    "id": "aQyxFGPRmKx2ikZB1bKU",
    "level": "vlow",
    "Sscore": 8,
    "RawScore": 32
  },
  {
    "id": "ef5XxkqaxB09mkVCoTwS",
    "level": "vlow",
    "Sscore": 10,
    "RawScore": 33
  },
  {
    "id": "NEidW0OksnQa6G4scYH9",
    "RawScore": 34,
    "level": "vlow",
    "Sscore": 12
  },
  {
    "id": "zMJR19sf8cdYFe1EWgQH",
    "level": "vlow",
    "RawScore": 35,
    "Sscore": 14
  },
  {
    "id": "9he5UVD87gZrKysvoa4z",
    "Sscore": 15,
    "RawScore": 36,
    "level": "vlow"
  },
  {
    "id": "0tCGs5N0mgpbKdnrjZQj",
    "level": "vlow",
    "Sscore": 15,
    "RawScore": 37
  },
  {
    "id": "n3UWt0hqB37c2Cl9appp",
    "Sscore": 19,
    "level": "vlow",
    "RawScore": 38
  },
  {
    "id": "9gBxPCfdtHPWKWbgNjqb",
    "level": "vlow",
    "RawScore": 39,
    "Sscore": 19
  },
  {
    "id": "axcChCo7ZJMzS4Ere0J7",
    "Sscore": 23,
    "RawScore": 40,
    "level": "low"
  },
  {
    "id": "149ER7cKRKdfnQgJQ282",
    "RawScore": 41,
    "Sscore": 23,
    "level": "low"
  },
  {
    "id": "UDDlNqErjkeKrPPZwaus",
    "RawScore": 42,
    "Sscore": 26,
    "level": "low"
  },
  {
    "id": "xdei0NQJ0WRr1c75QQzP",
    "level": "low",
    "RawScore": 43,
    "Sscore": 26
  },
  {
    "id": "55RXRfygh8lWAQaRmy8x",
    "RawScore": 44,
    "Sscore": 30,
    "level": "low"
  },
  {
    "id": "2W0taGie5ElhWVDIqz8x",
    "RawScore": 45,
    "level": "low",
    "Sscore": 30
  },
  {
    "id": "MT2xoEsS2l5ddJjYvnqF",
    "Sscore": 34,
    "RawScore": 46,
    "level": "low"
  },
  {
    "id": "D7cRkkj4k8XhGgk2DIhL",
    "RawScore": 47,
    "level": "low",
    "Sscore": 34
  },
  {
    "id": "sHvoPFELWpLMwpQj2xiL",
    "Sscore": 37,
    "level": "low",
    "RawScore": 48
  },
  {
    "id": "UuanlPCPYRRQh0OCbUyP",
    "Sscore": 37,
    "level": "low",
    "RawScore": 49
  },
  {
    "id": "5a5lc5OGcPzxHRk1rXHN",
    "Sscore": 41,
    "RawScore": 50,
    "level": "Medium"
  },
  {
    "id": "6fB5XMYNbFIX7IRmwn2S",
    "Sscore": 41,
    "level": "Medium",
    "RawScore": 51
  },
  {
    "id": "yA66zQLJ8seYHjbCMREp",
    "Sscore": 44,
    "RawScore": 52,
    "level": "Medium"
  },
  {
    "id": "ac6ka7oBHPBFGGGaYyHE",
    "level": "Medium",
    "RawScore": 53,
    "Sscore": 44
  },
  {
    "id": "cjFRdlxoJPaUm94JFfQN",
    "level": "Medium",
    "Sscore": 48,
    "RawScore": 54
  },
  {
    "id": "zpV3oeWMWPvFA0yMVlpF",
    "RawScore": 55,
    "level": "Medium",
    "Sscore": 48
  },
  {
    "id": "CnfAFN6ioGGiywYGFLIH",
    "RawScore": 56,
    "Sscore": 52,
    "level": "Medium"
  },
  {
    "id": "UGgG1ZZCFhvUnsLRa6Z3",
    "Sscore": 52,
    "RawScore": 57,
    "level": "Medium"
  },
  {
    "id": "PH7GHrNENfySjfhwiaXT",
    "Sscore": 55,
    "RawScore": 58,
    "level": "Medium"
  },
  {
    "id": "gufZGC4LtOBS6gw66lGe",
    "Sscore": 55,
    "level": "Medium",
    "RawScore": 59
  },
  {
    "id": "5CqovKIssQU66ypGXinB",
    "RawScore": 60,
    "Sscore": 59,
    "level": "Medium"
  },
  {
    "id": "w1UPpdfL0d1PCDY6kArP",
    "RawScore": 61,
    "Sscore": 59,
    "level": "Medium"
  },
  {
    "id": "MuYxINDsJcddIB6UPlIQ",
    "Sscore": 62,
    "RawScore": 62,
    "level": "MediumHigh"
  },
  {
    "id": "TNQ1q1uq0R197c4gSd6e",
    "Sscore": 62,
    "RawScore": 63,
    "level": "MediumHigh"
  },
  {
    "id": "RhIqG85TVWL4tjg0M7oE",
    "RawScore": 64,
    "level": "MediumHigh",
    "Sscore": 66
  },
  {
    "id": "3QxwAA9iL1KvRTz5VlCd",
    "level": "MediumHigh",
    "Sscore": 66,
    "RawScore": 65
  },
  {
    "id": "9lM98EJtAW7ZTg983mxG",
    "Sscore": 70,
    "level": "MediumHigh",
    "RawScore": 66
  },
  {
    "id": "hcM14tx7qxChSVKESl1v",
    "Sscore": 70,
    "level": "MediumHigh",
    "RawScore": 67
  },
  {
    "id": "sTpSI8mTAezd64mGcT78",
    "Sscore": 73,
    "RawScore": 68,
    "level": "MediumHigh"
  },
  {
    "id": "dzXpWeux2m2FGr5Nv41V",
    "Sscore": 73,
    "level": "MediumHigh",
    "RawScore": 69
  },
  {
    "id": "xRamC2Mx6h42pYTLP5nj",
    "RawScore": 70,
    "level": "MediumHigh",
    "Sscore": 77
  },
  {
    "id": "CtkP80Ki1oniHLGScIJi",
    "RawScore": 71,
    "Sscore": 78,
    "level": "VeryHigh"
  },
  {
    "id": "GSUhaQWDrJMa23Pwq9H1",
    "Sscore": 80,
    "level": "VeryHigh",
    "RawScore": 72
  },
  {
    "id": "ZASpBUN5vC3paNoLrvxt",
    "level": "VeryHigh",
    "RawScore": 73,
    "Sscore": 85
  },
  {
    "id": "JPXymDB0uH1vir1N2rfo",
    "level": "VeryHigh",
    "RawScore": 74,
    "Sscore": 90
  },
  {
    "id": "44prXKwd7ESOGjIMJOv8",
    "RawScore": 75,
    "Sscore": 95,
    "level": "VeryHigh"
  },
  {
    "id": "EbC0XpkbV8iwtH0rSKz5",
    "Sscore": 96,
    "level": "VeryHigh",
    "RawScore": 76
  },
  {
    "id": "91sEF1IdI5G35anuglVW",
    "Sscore": 97,
    "level": "VeryHigh",
    "RawScore": 77
  },
  {
    "id": "JVmsbZvnDHmtC5v0eiYg",
    "RawScore": 78,
    "level": "VeryHigh",
    "Sscore": 98
  },
  {
    "id": "sPo3GQFB30a8wrWOk5wi",
    "level": "VeryHigh",
    "RawScore": 79,
    "Sscore": 99
  },
  {
    "id": "hELQ9qFn9txtp0x3p4Jl",
    "level": "VeryHigh",
    "Sscore": 100,
    "RawScore": 80
  }
];
const fakeGetProfiles = [
  {
    "id": "c2ea7e31-71e5-4d34-9340-c086a7c13755",
    "educationLevel": "3",
    "Agree": "Yes",
    "startAt": "2021-12-26, 10:29:35 a.m.",
    "typeTest": "Student or any other case",
    "SizeTest": "1",
    "PKU": "2",
    "birthday": "2009",
    "ocupation": "Unemployed",
    "gender": "Other",
    "region": "3"
  },
  {
    "id": "329d9706-fa33-41d7-a6f9-b003719c4f70",
    "Agree": "Yes",
    "PKU": "2",
    "educationLevel": "3",
    "typeTest": "Student or any other case",
    "SizeTest": "2",
    "birthday": "2009",
    "ocupation": "Unemployed",
    "region": "3",
    "gender": "Other",
    "startAt": "2021-12-26, 10:31:15 a.m."
  },
  {
    "id": "af04b295-6fd8-48a8-9829-f51e2bf8898c",
    "PKU": "2",
    "Agree": "Yes",
    "birthday": "2005",
    "educationLevel": "2",
    "ocupation": "Unemployed",
    "startAt": "2021-12-26, 12:28:49 p.m.",
    "region": "5",
    "typeTest": "Child / Adult with PKU",
    "gender": "Female",
    "SizeTest": "1"
  },
  {
    "id": "449b3663-b9a3-4b08-9410-317432400aaf",
    "SizeTest": "1",
    "Agree": "Yes",
    "region": "2",
    "typeTest": "Adult with relative diagnosed with PKU",
    "birthday": "2008",
    "gender": "Male",
    "startAt": "2021-12-26, 1:26:44 p.m.",
    "educationLevel": "2",
    "PKU": "3",
    "ocupation": "Employed"
  },
  {
    "id": "460f6fe6-6c9a-4f5a-8ebf-cfa686e39690",
    "gender": "Other",
    "birthday": "2008",
    "ocupation": "Employed",
    "educationLevel": "2",
    "region": "2",
    "PKU": "3",
    "startAt": "2021-12-26, 1:28:05 p.m.",
    "typeTest": "Adult with relative diagnosed with PKU",
    "Agree": "Yes",
    "SizeTest": "2"
  },
  {
    "id": "aa27d3c4-a864-47d9-87dd-e9ed8820071c",
    "educationLevel": "2",
    "PKU": "2",
    "Agree": "Yes",
    "ocupation": "Student",
    "startAt": "2021-12-28, 9:47:40 p.m.",
    "typeTest": "Child / Adult with PKU",
    "region": "3",
    "birthday": "2014",
    "gender": "Male",
    "SizeTest": "1"
  },
  {
    "id": "N-TEST-vE88nqrJ1oBvuac3kYLe-3d7604be-db31-46c7-a59b-0f8020dd5edd",
    "PKU": "1",
    "educationLevel": "3",
    "birthday": "1982",
    "typeTest": "Student or any other case",
    "region": "5",
    "ocupation": "Employed",
    "startAt": "2021-12-31, 1:19:30 p.m.",
    "SizeTest": "2",
    "gender": "Male",
    "Agree": "Yes"
  },
  {
    "id": "7262f61c-8454-4adb-8457-cb7aea5d5c27",
    "ocupation": "Employed",
    "gender": "Male",
    "region": "4",
    "PKU": "1",
    "SizeTest": "1",
    "Agree": "Yes",
    "typeTest": "Physician, nutritionist, nurse, Health Professional",
    "educationLevel": "1",
    "startAt": "2021-12-31, 8:32:12 a.m.",
    "birthday": "2012"
  },
  {
    "id": "N-TEST-vE88nqrJ1oBvuac3kYLe-0d8a5e4f-5866-4840-b1a6-5b2606bc5d45",
    "gender": "Other",
    "typeTest": "Physician, nutritionist, nurse, Health Professional",
    "SizeTest": "1",
    "Agree": "Yes",
    "startAt": "2021-12-31, 9:33:10 a.m.",
    "educationLevel": "2",
    "region": "4",
    "PKU": "2",
    "ocupation": "Unemployed",
    "birthday": "2015"
  }
];
const fakeGetResultsProfiles = [
  {
    "id": "N-TEST-vE88nqrJ1oBvuac3kYLe-0d8a5e4f-5866-4840-b1a6-5b2606bc5d45",
    "dataResult": {
      "ScoreF2": null,
      "ScoreF1": null,
      "PointsF1": 0,
      "PointsF5": 0,
      "PercentsF3": 0,
      "GRawScore": 0,
      "PointsF4": 0,
      "date": "2021-12-31, 12:06:42 p.m.",
      "GenPercentil": 0,
      "PointsF3": 0,
      "PercentsF2": 0,
      "ScoreF3": null,
      "PointsF2": 0,
      "PercentsF1": 0
    }
  },
  {
    "id": "aa27d3c4-a864-47d9-87dd-e9ed8820071c",
    "dataResult": {
      "ScoreF2": 10,
      "PercentsF1": 0,
      "PointsF2": 3,
      "PointsF5": 0,
      "PointsF4": 0,
      "GRawScore": 14,
      "ScoreF1": 65,
      "PercentsF3": 0,
      "PercentsF2": 0,
      "GenPercentil": 0,
      "PointsF1": 7,
      "ScoreF3": 40,
      "date": "2021-12-28, 9:49:25 p.m.",
      "PointsF3": 4
    }
  },
  {
    "id": "c2ea7e31-71e5-4d34-9340-c086a7c13755",
    "dataResult": {
      "GenPercentil": 0,
      "PointsF5": 0,
      "ScoreF1": 65,
      "date": "2021-12-26, 10:30:24 a.m.",
      "PointsF3": 5,
      "GRawScore": 15,
      "PointsF2": 3,
      "ScoreF3": 50,
      "ScoreF2": 10,
      "PercentsF3": 0,
      "PointsF1": 7,
      "PercentsF1": 0,
      "PointsF4": 0,
      "PercentsF2": 0
    }
  },
  {
    "id": "449b3663-b9a3-4b08-9410-317432400aaf",
    "dataResult": {
      "ScoreF1": 65,
      "PointsF2": 3,
      "GRawScore": 15,
      "PercentsF2": 0,
      "PointsF1": 7,
      "PointsF3": 5,
      "date": "2021-12-26, 1:27:34 p.m.",
      "PointsF4": 0,
      "GenPercentil": 0,
      "PointsF5": 0,
      "PercentsF1": 0,
      "ScoreF2": 10,
      "ScoreF3": 50,
      "PercentsF3": 0
    }
  },
  {
    "id": "af04b295-6fd8-48a8-9829-f51e2bf8898c",
    "dataResult": {
      "PointsF4": 0,
      "PointsF3": 7,
      "PointsF2": 6,
      "PercentsF3": 0,
      "ScoreF3": 60,
      "date": "2021-12-26, 12:30:13 p.m.",
      "ScoreF2": 40,
      "GRawScore": 25,
      "PercentsF1": 0,
      "PercentsF2": 0,
      "ScoreF1": 90,
      "GenPercentil": 0,
      "PointsF5": 0,
      "PointsF1": 12
    }
  },
  {
    "id": "329d9706-fa33-41d7-a6f9-b003719c4f70",
    "dataResult": {
      "PercentsF2": 30,
      "date": "2021-12-26, 10:33:06 a.m.",
      "ScoreF2": 30,
      "ScoreF1": 70,
      "PointsF4": 4,
      "GRawScore": 32,
      "PercentsF1": 35,
      "PointsF2": 5,
      "PointsF3": 6,
      "PercentsF3": 30,
      "PointsF5": 9,
      "PointsF1": 8,
      "GenPercentil": 4,
      "ScoreF3": 55
    }
  },
  {
    "id": "460f6fe6-6c9a-4f5a-8ebf-cfa686e39690",
    "dataResult": {
      "PointsF2": 6,
      "PointsF3": 7,
      "ScoreF1": 65,
      "ScoreF2": 40,
      "ScoreF3": 60,
      "date": "2021-12-26, 1:30:08 p.m.",
      "PercentsF3": 50,
      "PercentsF2": 35,
      "PercentsF1": 30,
      "PointsF1": 7,
      "PointsF5": 8,
      "GRawScore": 33,
      "GenPercentil": 10,
      "PointsF4": 5
    }
  },
  {
    "id": "N-TEST-vE88nqrJ1oBvuac3kYLe-3d7604be-db31-46c7-a59b-0f8020dd5edd",
    "dataResult": {
      "PointsF1": 7,
      "GenPercentil": 15,
      "ScoreF1": 65,
      "PercentsF1": 30,
      "ScoreF2": 40,
      "ScoreF3": 60,
      "PointsF2": 6,
      "PointsF4": 6,
      "GRawScore": 36,
      "PercentsF3": 50,
      "PointsF5": 10,
      "PercentsF2": 35,
      "date": "2021-12-31, 1:53:44 p.m.",
      "PointsF3": 7
    }
  }
];
  export class CollectionScore {
    id: string
    level: string
    factor: string
    score: string
    value: string
  }

  export class CollectionPercentil {
    id: string
    level: string
    percent: string
    score: string
  }

  export class CollectionPercentilFactor {
    id: string
    Percent: number
    RawScore: number
  }
  
  export class CollectionSScoreSmall {    
    RawScore: number
    Sscore : number
    level : string 
  }
  export class Profile { 
    id: string
    Agree: string
    PKU : string
    SizeTest : string
    birthday : string
    educationLevel : string
    gender : string
    ocupation : string
    region : string
    startAt : string
    typeTest : string
  }
  export class CollectionResults {
        id: string
        GRawScore : number
        GenPercentil : number
        PercentsF1 : number
        PercentsF2 : number
        PercentsF3 : number
        PointsF1 : number
        PointsF2 : number
        PointsF3 : number
        PointsF4 : number
        PointsF5 : number
        Date : string 
  }

  export class CollectionAnswers {
    id: any
}

export class CollectionUsers {
  id: string
  email: string
  password: string
  type: string
  username: string
}

export class Patients {
  id: string
  firstname: string
  lastname: string
  email: string
  Gram: string
}