import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  UsernameLogin: string = "username";
  UserPassword: string = "password";
  FUser= environment.AuthFireStore.email;
  FPsw = environment.AuthFireStore.passw;
  GetUser: AngularFirestoreCollection<User>
  Snapuser: any
  UserCollection: any


  constructor(public FAuth: AngularFireAuth, private FireS: AngularFirestore) { }
  isLoggedIn() {
    if(sessionStorage.getItem("token") != null)
      return true
    else
      return false

  }
  isAdmin(){
    if(sessionStorage.getItem("token") != null && (parseInt(sessionStorage.getItem("_894784yrtgsf52")) == 1 || parseInt(sessionStorage.getItem("_894784yrtgsf52")) == 2 ))
        return true
      else
        return false

  }
  login(username: string, password: string): Observable<any> {    
    // console.log("username, this.UserCollection.username, password, this.UserCollection.password", username, this.UserCollection[0].username, password, this.UserCollection[0].password)
     if (username == this.UserCollection[0].username && password == this.UserCollection[0].password) {
       sessionStorage.setItem("token", uuidv4());
       sessionStorage.setItem("_894784yrtgsf52", this.UserCollection[0].type);
       sessionStorage.setItem("_89imn7yrtgsf94", this.UserCollection[0].id);       
        return of(new HttpResponse({ status: 200 }));
      } else {
        return of(new HttpResponse({ status: 401 }));
      }

   
  }

  logout(): void {
    localStorage.removeItem("token");
  }


  checkCredentials(username: string, password: string){
    return new Promise((resolve, reject)=> {
      this.UsernameLogin = username;
      this.UserPassword = password;
      this.GetUserCollection().then((results) => {
        this.UserCollection = results;
        if(this.UserCollection.length == 0){
          this.Snapuser.unsubscribe();
          // this.FAuth.signOut();
          reject(false);
        }
        else{
          this.Snapuser.unsubscribe();
          // this.FAuth.signOut();
          resolve(true);
        }

        
      }).catch((err)=> {
        console.log("check-credencial error: ", err);
        reject(false);
      })
    })
   
  }

  GetUserCollection  (){
        return new Promise( (resolve, reject) => {
        //resolve([]);
        this.FAuth.signInWithEmailAndPassword(this.FUser,this.FPsw).then(res => {
        
              this.GetUser = this.FireS.collection(`users`,
                ref => ref.where('username',"==",this.UsernameLogin)
              );
          
            this.Snapuser = this.GetUser.snapshotChanges().pipe(
              map(actions => 
                actions.map(a => {
                  const data = a.payload.doc.data();
                  const id = a.payload.doc.id;
                  return {id,...data}
                })
                )
            ).subscribe( data => {
              console.log("Snapuser collection => ", data);
              resolve(data);
            })              
            }).catch(e => {
            reject([]);
            console.log("Error found-> ", e);
        });
        });
  }

  SaveSession = function(){
    var user = {
      id : this.UserCollection[0].id,
      token : sessionStorage.getItem("token"),
      username : this.UserCollection[0].username,
      Timespan : new Date().toLocaleString(),
  }
  console.log("USER+ ", user);
    return  new Promise( (resolve, reject) => {
        console.log("usersessions, id => ", user);
        this.FAuth.signInWithEmailAndPassword(this.FUser,this.FPsw).then(res => {
          //let _now: Moment;
          this.FireS.collection("usersessions").add({  user }).then(res => {
                      console.log("res => ", res);
                      resolve("ok");
                    }).catch(err => {
                      console.log("Error usersessions= ", err);
                      reject(false);
                    })
        });

    });
}
  
}


export class User {    
  email : string
  password : string
  type : number
  username : string
}

export class UserSessions {  
  Timespan : string
  id : string
  token : string
  username : string
}



