import { Component, OnInit } from '@angular/core';
import { CollectionService } from 'src/app/services/collection.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit {

  constructor(private SCollection: CollectionService) { }

  ngOnInit(): void {
  }
  SaveUser() {
    console.log("saving");

    console.log("saving");
     let email = $("#emial-new").val();
     let username = $("#username-new").val();
     let pasw =  $("#password-new").val();
     let pasw2 = $("#rpassword-new").val();
     let id =    $("#id-user-head").text();
     let rol = $(".user-new-select").val();

     console.log("email, username, pasw, pasw2, id, rol: ", email, username, pasw, pasw2,  rol);
      if(email == "" || email == undefined){
        alert("Email is required!");
        return 0;
      }
      if(username == "" || username == undefined){
        alert("Username is required!");
        return 0;
      }
      if(pasw == "" || pasw == undefined){
        alert("Password is required!");
        return 0;
      }
      if(pasw2 == "" || pasw2 == undefined){
        alert("Repeat Password is required!");
        return 0;
      }
      if(pasw != pasw2){
        alert("The passwords are not equal!");
        return 0;
      }
     
      if(rol < 1 ){
        alert("Rol is required!");
        return 0;
      }

   this.SCollection.SaveNewUSer({"email": email,"username": username, "password": pasw, "type": rol}).then(_ => {
         alert("User Saved!")
      }).catch((err)=> {
        console.log("ERR: ", err);
        alert("Something was wrong, Try later!")
      })
  }
}
