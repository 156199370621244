<div class="container rounded  mt-5">
    <div class="row">
        <mat-card style="margin-top: 5%;overflow-x: scroll;">
            <mat-card-header>
                <mat-card-title>New Patient</mat-card-title>
                <!-- <mat-card-subtitle id="id-user-head">Data</mat-card-subtitle> -->
              </mat-card-header>
              <mat-card-content>
                <div class="col-md-12">
                    <div class="p-3 py-5">                        
                        <div class="row mt-2">
                            <div class="col-md-6 form-group">
                                <label for="firstname-new">First Name</label>
                                <input type="text" class="form-control" placeholder="First Name" id="firstname-new" value="">
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="lastname-new"> Last Name</label>
                                <input type="text" class="form-control" value="" placeholder="Last Name" id="lastname-new">
                            </div>

                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6 form-group">
                                <label for="emial-new"> Email</label>
                                <input type="text" class="form-control" placeholder="Email" id="emial-new" value="">
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="codetest-new"> Code for Test</label>
                                <input type="text" class="form-control" value="" placeholder="" id="codetest-new" disabled>
                            </div>
                        </div>                         
                        <div class="row mt-3">
                            <div class="col-md-6 form-group">
                                <select class="form-select patient-new-select" (change)="selectChange()">
                                    <option selected value="0"> Generate Code for Test ?</option>
                                    <option value="1">Yes</option>
                                    <option value="2">No</option>
                                    <!-- <option value="3">Nutritionist</option> -->
                                  </select>                                
                            </div>                      
                            <div class="col-md-6 form-group">
                                <select class="form-select patient-new-select-grm" (change)="selectChangeGRM()">
                                    <option selected value="0">Select the list of foods according to grams of phenylalanine</option>
                                    <option value="50">Foods with 50 milligrams of phenylalanine</option>
                                    <option value="100">Foods with 100 milligrams of phenylalanine</option>
                                    <!-- <option value="3">Nutritionist</option> -->
                                    </select>   
                                    <div class="form-group" style="padding-top: 2vw;">
                                        <label style="font-size: 1.2vw;margin-bottom: 1.2vw; width: 100%;text-decoration:underline;">Download Food List:</label>
                                        <input type="radio" id="down-50-mil" name="docmilligrams" value="50" style="width: 1vw;height: 1vw;" (click)="DownList(50)"><label for="docmilligrams" style="margin-left: 1vw;font-size: 0.9vw;">Download Food list of 50 milligrams of phenylalanine</label><br><br>
                                        <input type="radio" id="down-100-mil" name="docmilligrams" value="100" style="width: 1vw;height: 1vw;" (click)="DownList(100)"><label for="docmilligrams" style="margin-left: 1vw;font-size: 0.9vw;" >Download Food list of 100 milligrams of phenylalanine</label>
                                    </div>
                            </div>
                           
                        </div>                     
                        <div class="mt-5 text-right"><button class="btn btn-primary profile-button" type="button" (click)="SavePatient()">Save User</button></div>
                    </div>
                </div>
             </mat-card-content>
        </mat-card>
    </div>
</div>
