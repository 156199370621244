    <div class="bg-white" style="color:#000 !important;">
      <h2 class="h6 font-weight-bold text-center mb-4" style="font-family: 'ProximaNova_SemiBold';font-size: 1.5vw;">Overall progress</h2>

      <!-- Progress bar 1 -->
      <div class="progress mx-auto" id="calcualtedValudProgress" data-value='1'>
        <span class="progress-left">
                      <span class="progress-bar border-primary"></span>
        </span>
        <span class="progress-right">
                      <span class="progress-bar border-primary"></span>
        </span>
        <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
          <div class="h2 font-weight-bold" id="progressItemValue">1<sup class="small">%</sup></div>
        </div>
      </div>
      <!-- END -->

      <!-- Demo info -->
      <div class="row text-center mt-4">
        <div class="col-6 border-right">
          <div class="h4 font-weight-bold mb-0 text-food-prog" id="currentfoodItem">1</div><span class="small text-gray text-food-prog">Current Food</span>
        </div>
        <div class="col-6">
          <div class="h4 font-weight-bold mb-0 text-food-prog" id="lastfoodItem">80</div><span class="small text-gray text-food-prog">Last Food</span>
        </div>
      </div>
      <!-- END -->
    </div>
