<div class="row form-email" id="form-email">
    <div class="col-xs-12" style="background-color: #1E66AD;padding: 3em;position: relative;">
      <span class="closed" (click)="closeMail()">&#10006;</span>
              <div class="form-group input--hoshi">
                <div class="input-wrap">
                  <input data-ng-model="formData.inputName" type="text"
                    class="form-control input__field input-results input input__field--hoshi" id="inputNameEmail" name="inputNameEmail"
                    placeholder="Name" required style="background: #FFF;">                
                </div>
              </div>
              <div class="form-group  input--hoshi" style="margin-top: 3%;margin-bottom: 3%;">
                <div class="input-wrap">
                  <input data-ng-model="formData.inputEmail" type="email"
                    class="form-control input input-results input__field input__field--hoshi" placeholder="Your Email"
                    id="inputEmailSend" name="inputEmailSend" required style="background: #FFF;">                 
                </div>
              </div>           
              <div class="form-group" style="margin-bottom: 3%;">
                <div class="align-center">
                  <button type="submit" class="btn btn-default bnt-send-email" (click)="SendEmailPDF()" style="color: #FFF; font-family: 'ProximaNova_SemiBold';font-size: 1vw;width: fit-content;padding-bottom: 2em;">
                    Send Email
                  </button>
                </div>
              </div>
              <div class="form-group">
                <div class="align-center">
                  <span style="font-family: 'ProximaNova-Light';font-size: 1vw;color:#fff;" class="disclaimer-email">
                    The data provided in this section are not stored by us, they are indicated only for the purpose of sending results by mail.
                  </span>
                </div>
              </div>
    </div>
</div>
