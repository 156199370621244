<div class="container rounded  mt-5">
    <div class="row">
        <mat-card style="margin-top: 5%;overflow-x: scroll;">
            <mat-card-header>
                <mat-card-title>Edit User</mat-card-title>
                <mat-card-subtitle id="id-user-head">Data</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <div class="col-md-12">
                    <div class="p-3 py-5">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <div class="d-flex flex-row align-items-center back"><i class="fa fa-long-arrow-left mr-1 mb-1"></i>
                                <h6>Back to home</h6>
                            </div>
                            <h6 class="text-right">Edit User</h6>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-6 form-group">
                                <label for="username-edit"> UserName</label>
                                <input type="text" class="form-control" placeholder="Username" id="username-edit" value="exmaple">
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="password-edit"> Password</label>
                                <input type="password" class="form-control" value="example" placeholder="Password" id="password-edit">
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6 form-group">
                                <label for="emial-edit"> Email</label>
                                <input type="text" class="form-control" placeholder="Email" id="emial-edit" value="example@email.com">
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="rpassword-edit"> Repeat Password</label>
                                <input type="password" class="form-control" value="example" placeholder="Repeat Password" id="rpassword-edit">
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6 form-group">
                                <select class="form-select user-edit-select">
                                    <option selected value="0"> Select Rol</option>
                                    <option value="1">Admin</option>
                                    <option value="2">Owner</option>
                                    <option value="3">Nutritionist</option>
                                  </select>
                                <!-- <label for="rol-edit"> Rol</label> -->
                                <!-- <input type="text" class="form-control" placeholder="Rol" value="Rol" id="rol-edit"> -->
                            </div>
                        </div>                       
                        <div class="mt-5 text-right"><button class="btn btn-primary profile-button" type="button" (click)="SaveUser()">Save User</button></div>
                    </div>
                </div>
             </mat-card-content>
        </mat-card>
    </div>
</div>