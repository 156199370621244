import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { AnimationsService } from 'src/app/services/animations.service';
import { GeneralServicesService } from 'src/app/services/general-services.service';
import * as $ from 'jquery'
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas'
import htmlToPdfmake from 'html-to-pdfmake';
import { MatTableDataSource } from '@angular/material/table';
// import { answerResults } from 'src/app/components/admin/data/data.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CollectionService } from 'src/app/services/collection.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { List } from 'linqts';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css']
})
export class ResultsComponent implements OnInit {
  dataSource2: MatTableDataSource<answerResults>;
  superAnswer: Array<answerResults> = [];
  title = 'htmltopdf';
  @ViewChild('pdfTable') pdfTable: ElementRef;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['name', 'NumberRight', 'NumberWrong', 'DiffRW', 'Sscore', 'ScalePerc', 'description'];
  dataSource : any;
  datafoods: any;
  datanswers: any;
  devenv = environment.fakeData;
  ColAnswersResults : any[];
  displayedColumnsAnswers: string[] = [
    "ID",
    "FOODS",
    "Answer",
    "Allowed"
  ];
  pageSizes = [5,10];
  public typeTest = this.GService.GetLocalStorage("DataUser",true);
  constructor(private GService: GeneralServicesService, private animation: AnimationsService, private SCollection: CollectionService, private userAuth: AuthService) {

  }

  ngOnInit(): void {
    this.typeTest = this.GService.GetLocalStorage("DataUser",true);
  }

dataResult() {
  // $("#downloadpdf").css('pointer-events','auto');
  this.typeTest = this.GService.GetLocalStorage("DataUser",true);
   if(this.typeTest.SizeTest == 1){
     this.dataSource = this.GService.ProcessResult();
     this.animation.fadeOut("#container-btn-r");
      this.animation.fadeIn("#table-result");
      this.GData();
      document.getElementById("step-5").classList.remove('current');
      document.getElementById('step-5').classList.add('success');
      setTimeout(() => {
        this.animation.fadeInLine("#downloadpdf");
        this.animation.fadeInLine("#sendEmailpdf");
        this.animation.fadeInLine("#ViewAnswers");
      }, 1700);
   }
   else {
     this.dataSource = this.GService.ProcessResultExtend();
     this.animation.fadeOut("#container-btn-r");
      this.animation.fadeIn("#table-result");
      this.GDataExtend();
      document.getElementById("step-5").classList.remove('current');
      document.getElementById('step-5').classList.add('success');
      setTimeout(() => {
        this.animation.fadeInLine("#downloadpdf");
        this.animation.fadeInLine("#sendEmailpdf");
        this.animation.fadeInLine("#ViewAnswers");
      }, 1700);
   }

  }

  GData(){



            $('#no-more-tables').empty();
            let headTable =  `
                              <table class="table" style="border-top:0;">
                              <thead class="cf" style="border:0;">
                              <tr style="border:0;">
                              <th class="title-table text-center" style="border:0;" >
                              </th>
                                <th class="title-table text-center" colspan="2" style="border:0;font-family: 'ProximaNova_Semibold' !important;" >
                                RAW Score
                                </th>
                                <th class="title-table text-center" colspan="2" style="border:0;font-family: 'ProximaNova_Semibold' !important;" >
                                Assessment
                                </th>
                                </tr>
                                <tr>
                                  <th class="captions-table  caption1" style="background:#FFFFFF;color:trasparent;">name</th>
                                  <th  class="captions-table  caption2" style="font-family: 'ProximaNova_Semibold' !important;">Number of Right Items</th>
                                  <th  class="captions-table  caption2" style="font-family: 'ProximaNova_Semibold' !important;">Number of Wrong Items</th>
                                  <!--<th  class="captions-table  caption3" >Hits less mistakes</th>-->
                                  <th   class="captions-table  caption4" style="background:#FFFFFF;font-family: 'ProximaNova_Semibold' !important;">S Score</th>
                                  <!--<th   class="captions-table  caption5" style="background:#FFFFFF;font-family: 'ProximaNova_Semibold' !important;">Percentil Scale</th>-->
                                  <th   class="captions-table  caption6" style="background:#FFFFFF;font-family: 'ProximaNova_Semibold' !important;">Very Low / Low / Medium / High</th>
                                </tr>
                              </thead>
                              <tbody id="data-body">`
            $('#no-more-tables').append(headTable);

          for (let index = 0; index < this.dataSource.length; index++) {
          const element = this.dataSource[index];
          let dataBinding;
          if(index % 2 == 0){
            dataBinding = `
            <tr>
                <td data-label="name" style="background-color: #1E66AD;color:#FFFFFF;font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].name}</td>
                <td data-label="Number of Right Items" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].NumberRight}</td>
                <td data-label="Number of Wrong Items" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].NumberWrong}</td>
              <!-- <td data-label="Hits less mistakes" >${this.dataSource[index].DiffRW}</td>-->
                <td data-label="S Score" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].Sscore}</td>
                <!--<td data-label="Percentil Scale" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].percentilScale}</td>-->
                <td data-label="Assesment Very Low / Low / Medium / High" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].finalresult}</td>
            </tr>`;
          }else {

            dataBinding = `
                    <tr class="color-back-table">
                        <td data-label="name" style="background-color: #1E66AD;color:#FFFFFF;font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].name}</td>
                        <td data-label="Number of Right Items" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].NumberRight}</td>
                        <td data-label="Number of Wrong Items" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].NumberWrong}</td>
                        <!-- <td data-label="Hits less mistakes">${this.dataSource[index].DiffRW}</td>-->
                        <td data-label="S Score" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].Sscore}</td>
                        <!-- <td data-label="Percentil Scale" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].percentilScale}</td>-->
                        <td data-label="Assesment Very Low / Low / Medium / High" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].finalresult}</td>
                    </tr>`;
}



$('#data-body').append(dataBinding);
}

let footerTable = `</tbody></table>`;
$('#no-more-tables').append(footerTable);
console.log("THIS.TypeTest => ", this.typeTest.SizeTest);
// if(this.typeTest.SizeTest == 2){
    $("#charts").css('display','block');
// }
}
GDataExtend(){



  $('#no-more-tables').empty();
  let headTable =  `
                    <table class="table" style="border-top:0;">
                    <thead class="cf" style="border:0;">
                    <tr style="border:0;">
                    <th class="title-table text-center" style="border:0;" >
                    </th>
                      <th class="title-table text-center" colspan="2" style="border:0;font-family: 'ProximaNova_Semibold' !important;" >
                      RAW Score
                      </th>
                      <th class="title-table text-center" colspan="3" style="border:0;font-family: 'ProximaNova_Semibold' !important;" >
                      Assessment
                      </th>
                      </tr>
                      <tr>
                        <th class="captions-table  caption1" style="background:#FFFFFF;color:trasparent;">name</th>
                        <th  class="captions-table  caption2" style="font-family: 'ProximaNova_Semibold' !important;">Number of Right Items</th>
                        <th  class="captions-table  caption2" style="font-family: 'ProximaNova_Semibold' !important;">Number of Wrong Items</th>
                        <!--<th  class="captions-table  caption3">Hits less mistakes</th>-->
                        <th   class="captions-table  caption4" style="background:#FFFFFF;font-family: 'ProximaNova_Semibold' !important;">S Score</th>
                        <th   class="captions-table  caption5" style="background:#FFFFFF;font-family: 'ProximaNova_Semibold' !important;">Percentil Scale</th>
                        <th   class="captions-table  caption6" style="background:#FFFFFF;font-family: 'ProximaNova_Semibold' !important;">Very Low / Low / Medium / High</th>
                      </tr>
                    </thead>
                    <tbody id="data-body">`
  $('#no-more-tables').append(headTable);

for (let index = 0; index < this.dataSource.length; index++) {
const element = this.dataSource[index];
let dataBinding;
if(index % 2 == 0){
  dataBinding = `
  <tr>
      <td data-label="name" style="background-color: #1E66AD;color:#FFFFFF;font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].name}</td>
      <td data-label="Number of Right Items" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].NumberRight}</td>
      <td data-label="Number of Wrong Items " style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].NumberWrong}</td>
    <!-- <td data-label="Hits less mistakes " >${this.dataSource[index].DiffRW}</td>-->
      <td data-label="S Score" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].Sscore}</td>
      <td data-label="Percentil Scale" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].percentilScale}</td>
      <td data-label="Assesment Very Low / Low / Medium / High" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].finalresult}</td>
  </tr>`;
}else {

  dataBinding = `
          <tr class="color-back-table">
              <td data-label="name" style="background-color: #1E66AD;color:#FFFFFF;font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].name}</td>
              <td data-label="Number of Right Items" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].NumberRight}</td>
              <td data-label="Number of Wrong Items" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].NumberWrong}</td>
              <!-- <td data-label="Hits less mistakes">${this.dataSource[index].DiffRW}</td>-->
              <td data-label="S Score" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].Sscore}</td>
              <td data-label="Percentil Scale" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].percentilScale}</td>
              <td data-label="Assesment Very Low / Low / Medium / High" style="font-family: 'ProximaNova_Regular' !important;">${this.dataSource[index].finalresult}</td>
          </tr>`;
}



$('#data-body').append(dataBinding);
}

let footerTable = `</tbody></table>`;
$('#no-more-tables').append(footerTable);
console.log("THIS.TypeTest => ", this.typeTest.SizeTest);
// if(this.typeTest.SizeTest == 2){
$("#charts").css('display','block');
// }
}
public async downloadAsPDF() {
  // const pdfMake = (await import(/* webpackChunkName: "pdfMake" */ 'pdfmake/build/pdfmake.min')).default
  // const pdfFonts = (await import(/* webpackChunkName: "pdfFonts" */ 'pdfmake/build/vfs_fonts')).default
  // pdfMake.vfs = pdfFonts.pdfMake.vfs;
  // pdfMake.fonts = { 'Roboto':
  //                           {
  //                             normal: 'Roboto-Regular.ttf',
  //                             bold: 'Roboto-Medium.ttf',
  //                             italics: 'Roboto-Italic.ttf',
  //                             bolditalics: 'Roboto-Italic.ttf' },
  //                    'Proximanova_regular': {
  //                            normal: './assets/fonts/Proxima Nova Regular.ttf'
  //                           }
  //                   }
  // pdfMake.vfs = pdfFonts.pdfMake.vfs
  // const doc = new jsPDF();
  // const pdfTable = this.pdfTable.nativeElement;
  // var html = htmlToPdfmake(pdfTable.innerHTML);
  // const documentDefinition = { content: html };
  // pdfMake.createPdf(documentDefinition,).open();

  // html2canvas(document.getElementById('table-result'), {
  //   onrendered: function (canvas) {
  //       var data = canvas.toDataURL();
  //       var docDefinition = {
  //           content: [{
  //               image: data,
  //               width: 500,
  //           }]
  //       };
  //       pdfMake.createPdf(docDefinition).download("Score_Details.pdf");
  //   }
  // });



html2canvas(document.querySelector("#table-result")).then(canvas => {
  // $("#downloadpdf").css('pointer-events','none');
  var data =  canvas.toDataURL();
        var docDefinition = {
          pageSize: {
            width: 595.28,
            height: 'auto'
          },
            content: [
              { image: data, width: 500, }
            ]
        };
        pdfMake.createPdf(docDefinition).download("PKU_Knowledge_of_the_Low_Phe_Diet.pdf");
    })

}

public async SendEmailPDF() {



html2canvas(document.querySelector("#table-result")).then(canvas => {
  var w = $("#table-result").width();
  var h = $("#table-result").height();

  // Set the width of canvas to twice the width of the container
  //var canvas = document.createElement("canvas");
  canvas.width = w * 2;
  canvas.height = h * 2;
  canvas.style.width = w + "px";
  canvas.style.height = h + "px";
  var context = canvas.getContext("2d");
  // Then zoom the canvas and double the image onto the canvas.
  context.scale(2,2);

  var data =  canvas.toDataURL("image/png");
        var docDefinition = {
          pageSize: {
            width: 595.28,
            height: 'auto'
          },
            content: [
              { image: data, width: 500, }
            ]
        };
      var pdf =  pdfMake.createPdf(docDefinition);
        console.log("Send by email");
        this.animation.fadeIn("#form-email");
        this.animation.fadeOut(".branding21");

    })

}

public ViewAnswers(){
  // if(this.userAuth.isLoggedIn()){
    this.SCollection.GetFoods().then((results)=>{
      this.datafoods = JSON.parse(JSON.stringify(results));
      console.log("this.datafoodsTABLE: ", this.datafoods);
      this.GetAnswers(this.datafoods);
      // if(!this.devenv && this.userAuth.isLoggedIn()){
        this.SCollection.Ofodds.unsubscribe();
        // this.SCollection.FAuth.signOut();
        // }

  })
// }else {
// console.log("user is not authenticated")
// }
}
GetAnswers(foods: any[]){
  var idUser = this.GService.DatosSujeto.id;
  console.log("ASWER.ID.LOG: ", idUser)
  this.SCollection.GetAnswersResult(idUser).then((results) => {
    this.datanswers = JSON.parse(JSON.stringify(results));
    console.log("this.datanswers: ", this.datanswers);
  this.ColAnswersResults = this.datanswers;
  this.SCollection.AnswersR.unsubscribe();
  this.superAnswer = [];

  console.log("idUser> ", idUser);
  // var AnswerFilter = new List<any>(this.ColAnswersResults).Where(f=>f.id == idUser).ToArray();
  console.log("AnswerFilter> ", this.ColAnswersResults);
  const element = this.datanswers.Answer;
  const elementID = this.datanswers.id;
  let indexelement = 0;
    for (let index2 = 0; index2 < element.length; index2++) {
      indexelement = index2
        var objAnswerFake = new answerResultsEval();
        objAnswerFake.ID = element[index2].food;
        objAnswerFake.FOODS = new List<any>(foods)
        .Where(f=>  f.id == element[index2].food)
        .Select(f=> f.name).SingleOrDefault()
        objAnswerFake.Allow = element[index2].allow == true ? "Yes" : "No"
        objAnswerFake.Answer = element[index2].answer == true ? "Yes" : "No";
        console.log("objAnswerFake> ", objAnswerFake);
        this.superAnswer.push(objAnswerFake);
    }

    if(indexelement == element.length - 1){
       console.log("this.superAnswer: ", this.superAnswer);
      this.dataSource2 = new MatTableDataSource(this.superAnswer);
      this.dataSource2.paginator = this.paginator2;
      this.dataSource2.sort = this.sort;
      console.log("this.dataSource2-> ", this.dataSource2);
      $('.answer-results').css('display','block');
      this.animation.fadeIn("#answer-results");
      this.animation.fadeIn('#overlay');
    }

//  }



})

}
applyFilter2(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource2.filter = filterValue.trim().toLowerCase();

  if (this.dataSource2.paginator) {
    this.dataSource2.paginator.firstPage();
  }

}
closeAnswers(){
  this.animation.fadeOut("#answer-results");
  this.animation.fadeOut('#overlay');
}

}
export class answerResultsEval {
  ID: string
 FOODS: string
 Answer: string
 Allow: string
};

export class answerResults {
  ID: string
 FOODS: string
 Answer: string
};
