   <!--About Section-->
   <!-- <div class="page page-left" id="about-block"> -->
    <div class="page-inner full-height">
      <div class="container align-center detail-info" style="max-width: 85%;">
        <div class="row">
          <!--About PKU-->
          <div class="col-lg-3 col-md-3 col-sm-3 title-about-us">
            <h3 class="title-left">{{'About-section-title1' | translate}}</h3>
          </div>
          <div class="col-lg-9 col-md-8 col-sm-8 col-xs-9 content-about-us">
            <p class="tagline">{{'About-section-center-title' | translate}}</p>
            <span class="small-hr"></span>
            <p class="align-center text-about-desc">
              {{'About-section-ques-p1' | translate}}
            </p>
            <p class="align-center text-about-desc">
              {{'About-section-ques-p2' | translate}}
            </p>
            <p class="align-center text-about-desc">
              {{'About-section-ques-p3' | translate}}
            </p>
            <p class="align-center text-about-desc">
              {{'About-section-ques-p4' | translate}}
            </p>

          </div>
          <!--About us-->
          <div class="col-lg-3 col-md-3 col-sm-3 title-about-us">
            <h3 class="title-left">{{'About-section-title2' | translate}}</h3>
          </div>
          <div class="col-lg-9 col-md-8 col-sm-8 col-xs-9 content-about-us">
            <span class="small-hr"></span>
            <p class="align-center text-about-desc">
              {{'About-section-us-p1' | translate}}
            </p>
            <p class="align-center text-about-desc">
              {{'About-section-us-p2' | translate}}
            </p>
            <!--About us Slider-->
            <div id="qualities">
              <ngx-slick-carousel class="carousel qualities"
              #slickModal="slick-carousel"
              [config]="slideConfigTeam" >
                <div ngxSlickItem class="item">
                  <div class="fa-qual">
                    <!-- <img class="mark-thanks" src="/assets/img/sticker.svg"> -->
                    <img class="profile" src="/assets/img/authors/Rodolfo_Ramos_Alvarez.svg" alt="Rodolfo Ramos Alvarez profile">
                  </div>
                  <span class="qual-tag">Rodolfo Ramos Alvarez</span>
                  <span class="qual-desc">
                    {{'About-section-us-slide-desc1' | translate}}
                    <br><br><i class="fas fa-envelope"></i>&nbsp;&nbsp; {{'About-section-us-slide-mail1' | translate}}
                  </span>
                </div>
                <div  ngxSlickItem class="item">
                  <div class="fa-qual">
                    <!-- <img class="mark-thanks" src="/assets/img/sticker.svg"> -->
                    <img class="profile" src="/assets/img/authors/Rodolfo_Ramos_Susan_Waisbren.svg" alt="Susan Waisbren profile">
                  </div>
                  <span class="qual-tag">{{'About-section-us-slide-name2' | translate}}</span>
                  <span class="qual-desc">
                    {{'About-section-us-slide-desc2' | translate}}
                  </span>
                </div>
                <div ngxSlickItem class="item">
                  <div class="fa-qual">
                    <!-- <img class="mark-thanks" src="/assets/img/sticker.svg"> -->
                    <img class="profile" src="/assets/img/authors/Rodolfo_Ramos_Maili_Kapp.svg" alt="Maili Kapp profile">
                  </div>
                  <span class="qual-tag">{{'About-section-us-slide-name3' | translate}}</span>
                  <span class="qual-desc">
                    {{'About-section-us-slide-desc3' | translate}}
                    <br><br><i class="fas fa-envelope"></i>&nbsp;&nbsp; {{'About-section-us-slide-mail3' | translate}}
                  </span>
                </div>
                <div ngxSlickItem class="item">
                  <div class="fa-qual">
                    <!-- <img class="mark-thanks" src="/assets/img/sticker.svg"> -->
                    <img class="profile" src="/assets/img/authors/Rodolfo_Ramos_Kirsten_Ahring.svg" alt="Kirsten Ahring profile">
                  </div>
                  <span class="qual-tag">{{'About-section-us-slide-name4' | translate}}</span>
                  <span class="qual-desc">
                    {{'About-section-us-slide-desc4' | translate}}
                    <br><br><i class="fas fa-envelope"></i>&nbsp;&nbsp; {{'About-section-us-slide-mail4' | translate}}
                  </span>
                </div>
                <div ngxSlickItem class="item">
                  <div class="fa-qual">
                    <!-- <img class="mark-thanks" src="/assets/img/sticker.svg"> -->
                    <img class="profile" src="/assets/img/authors/Rodolfo_Ramos_Maria_Rodriguez.svg"  alt="Maria Rodriguez profile">
                  </div>
                  <span class="qual-tag">{{'About-section-us-slide-name5' | translate}}</span>
                  <span class="qual-desc">
                    {{'About-section-us-slide-desc5' | translate}}
                    <br><br><i class="fas fa-envelope"></i>&nbsp;&nbsp; {{'About-section-us-slide-mail5' | translate}}
                  </span>
                </div>
                <div ngxSlickItem class="item">
                  <div class="fa-qual">
                    <!-- <img class="mark-thanks" src="/assets/img/sticker.svg"> -->
                    <img class="profile" src="/assets/img/authors/Rodolfo_Ramos_Rocio_Fausor.svg" alt="Rocio Fausor profile">
                  </div>
                  <span class="qual-tag">{{'About-section-us-slide-name6' | translate}}</span>
                  <span class="qual-desc">
                    {{'About-section-us-slide-desc6' | translate}}
                    <br><br><i class="fas fa-envelope"></i>&nbsp;&nbsp; {{'About-section-us-slide-mail6' | translate}}
                  </span>
                </div>
                <div ngxSlickItem class="item">
                  <div class="fa-qual">
                    <!-- <img class="mark-thanks" src="/assets/img/sticker.svg"> -->
                    <img class="profile" src="/assets/img/authors/Rodolfo_Ramos_Maria_Bueno.svg" alt="Maria Bueno profile">
                  </div>
                  <span class="qual-tag">{{'About-section-us-slide-name7' | translate}}</span>
                  <span class="qual-desc">
                    {{'About-section-us-slide-desc7' | translate}}
                    <br><br><i class="fas fa-envelope"></i>&nbsp;&nbsp; {{'About-section-us-slide-mail7' | translate}}
                  </span>
                </div>
                <div ngxSlickItem class="item">
                  <div class="fa-qual">
                    <!-- <img class="mark-thanks" src="/assets/img/sticker.svg"> -->
                    <img class="profile" src="/assets/img/authors/Rodolfo_Ramos_Amaya_Belanger.svg" alt="Amaya Belanger profile">
                  </div>
                  <span class="qual-tag">{{'About-section-us-slide-name8' | translate}}</span>
                  <span class="qual-desc">
                    {{'About-section-us-slide-desc8' | translate}}
                  </span>
                </div>
                <div ngxSlickItem class="item">
                  <div class="fa-qual">
                    <!-- <img class="mark-thanks" src="/assets/img/sticker.svg"> -->
                    <img class="profile" src="/assets/img/authors/Rodolfo_Ramos_Fabricio_Galadi.svg" alt="Fabricio Galadi profile">
                  </div>
                  <span class="qual-tag">{{'About-section-us-slide-name9' | translate}}</span>
                  <span class="qual-desc">
                    {{'About-section-us-slide-desc9' | translate}}
                    <br><br><i class="fas fa-envelope"></i>&nbsp;&nbsp; {{'About-section-us-slide-mail9' | translate}}
                  </span>
                </div>

              </ngx-slick-carousel>
            </div>
            <!--/About us Slider-->
            <p class="aling-center text-about-desc" style="white-space: pre-line;">
              {{'About-section-us-p3' | translate}}
            </p>
            <ul class="align-left list-aboutus">
              <li><i class="fas fa-angle-double-right"></i>&nbsp;&nbsp;&nbsp;  {{'About-section-us-li1' | translate}}</li>
              <li><i class="fas fa-angle-double-right"></i>&nbsp;&nbsp;&nbsp; {{'About-section-us-li2' | translate}}</li>
              <li><i class="fas fa-angle-double-right"></i>&nbsp;&nbsp;&nbsp; {{'About-section-us-li3' | translate}}</li>
            </ul>
            <p class="align-left text-about-desc" style="margin-top: 3%;">
              {{'About-section-us-p4' | translate}}<br>
              <span class="link-pku">{{'About-section-us-p4-link' | translate}}</span>
            </p>
            <p class="align-left text-about-desc">
              {{'About-section-us-p5' | translate}} <a class="link-pku" href="pku.es" target="_blank">{{'About-section-us-p5-link' | translate}} </a>
            </p>
          </div>
          <!--Coming soon-->
          <div class="col-lg-3 col-md-3 col-sm-3 title-about-us">
            <h3 class="title-left">{{'About-section-title3' | translate}}</h3>
          </div>
          <div class="col-lg-9 col-md-8 col-sm-8 col-xs-9 mb-5 content-about-us">
            <!-- <p class="tagline" >This is the Questionnaire to evaluate the knowledge of low PHE diet</p> -->
            <span class="small-hr"></span>
            <p class="align-center text-about-desc">
              {{'About-section-cs-p' | translate}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="trans-overlay-dark-about"></div>
  <!-- </div> -->
  <!--/About Section-->
