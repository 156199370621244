import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';


import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { I18nModule } from './i18n/i18n.module';
import { SelectLanguageComponent } from './select-language/select-language.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AboutComponent } from './section/about/about.component';
import { ContactComponent } from './section/contact/contact.component';
import { LoaderComponent } from './section/loader/loader.component';
import { FoodComponent } from './section/food/food.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {  MatRadioModule} from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SideMenuComponent } from './section/side-menu/side-menu.component';
import { MDBBootstrapModule, DropdownModule } from 'angular-bootstrap-md';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import { ResultsComponent } from './section/results/results.component';
import {MatTableModule} from '@angular/material/table';
import { ProgressBarComponent } from './section/progress-bar/progress-bar.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCardModule} from '@angular/material/card'
import { ChartsModule } from 'ng2-charts';
import { ChartComponent } from './section/chart/chart.component';
import { CreditsComponent } from './section/credits/credits.component';
import { SendEmailComponent } from './section/send-email/send-email.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AlertComponent } from './components/alert/alert.component';
import { AnswerComponent } from './section/answer/answer.component';

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ThinkComponent } from './components/think/think.component';
import { ListComponent } from './components/user/list/list.component';
import { CreateComponent } from './components/user/create/create.component';
import { UpdateComponent } from './components/user/update/update.component';
import { PatientListComponent } from './components/patient/patient-list/patient-list.component';
import { CreatePatientComponent } from './components/patient/create-patient/create-patient.component';
import { ResultPatientsComponent } from './components/patient/result-patients/result-patients.component';
import { CutomFoodsComponent } from './components/admin/cutom-foods/cutom-foods.component';

@NgModule({
  declarations: [
    AppComponent,
    SelectLanguageComponent,
    AboutComponent,
    ContactComponent,
    LoaderComponent,
    FoodComponent,
    SideMenuComponent,
    ResultsComponent,
    ProgressBarComponent,
    ChartComponent,
    CreditsComponent,
    SendEmailComponent,
    routingComponents,
    NavbarComponent,
    AlertComponent,
    AnswerComponent,
    ThinkComponent,
    ListComponent,
    CreateComponent,
    UpdateComponent,
    PatientListComponent,
    CreatePatientComponent,
    ResultPatientsComponent,
    CutomFoodsComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    I18nModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule.forRoot(),
    DropdownModule.forRoot(),
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTableModule,
    MatCardModule,
    MatPaginatorModule,
    AngularFireModule.initializeApp(environment.firebase),
   AngularFireDatabaseModule,
   AngularFireAuthModule,
   AngularFirestoreModule,
   MatProgressBarModule,
   ChartsModule,
   MatTableExporterModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
 
 }
