<div class="container" style="max-width: 65% !important; padding-top: 7%;">
  
  <!-- <button id="downloadpdf-pat" mdbBtn color="info" outline="true" rounded="true" block="true"
        class="z-depth-0 my-4" mdbWavesEffect type="submit"
        style="font-family: 'ProximaNova_SemiBold';position: absolute; left: 1%; top: 48%;z-index: 9;display: none;border: 0 !important; 
         width: 30%; background: transparent;color: #1E66AD;box-shadow: none !important; transition: all .3s;" 
        (click)="downloadAsPDF()">Export to PDF &nbsp;&nbsp;&nbsp; <i class="fas fa-chevron-circle-down"></i></button> -->
  
        <!-- <button id="sendEmailpdf" mdbBtn color="info" outline="true" rounded="true" block="true"
        class="z-depth-0 my-4" mdbWavesEffect type="submit"
        style="font-family: 'ProximaNova_SemiBold';position: absolute; right: 1%; top: 41%;z-index: 9;display: none;border: 0 !important; 
         width: 30%; background: transparent;color: #1E66AD;box-shadow: none !important; transition: all .3s;" 
        (click)="SendEmailPDF()">Send by Email &nbsp;&nbsp;&nbsp; <i class="fas fa-chevron-circle-down"></i></button> -->
  
  <!--Emaill-->
  <!-- <app-send-email></app-send-email> -->
  <!--Table -->
  <div class="container table-result" id="table-result-pat" #pdfTable style="display: none;">
    <div class="row">
      <div class="col-md-12 principal-contianer-head-results" style="display: flex; flex-direction: row;background: #d8d8d8;padding: 1vw; border-radius: 1vw;">
        <div class="text-center title-results" style="display:flex; max-width:30%;padding: 1vw;">
          <img class="imagen-results" style="width: 100%; height: 100%;" src="/assets/img/pku-result.png">
          <!-- <span class="transform-title">Profile</span>
          <span class="transform-text"><strong class="title-normal">PKU</strong> Cuestionrie to evluate knowledge of the Low Phe Diet</span> -->
        </div>
        <div class="datos-results" style="display: flex;width: 60%;">
          <!-- Form -->
          <form class="text-center" style="color: #383838; width: 100%;">
            <!-- Genearls -->
            <div class="md-form md-form-data-results">           
              <!-- Default inline 1-->
              <div class="custom-control custom-radio custom-control-inline custom-control-inline-first custom-control-inline-item" style="margin-bottom: 1em;text-align: left;margin-left: 37%;">
                <label class="custom-control-label custom-control-label-input-text" for="defaultresult1-pat" style="margin-right: 2em;font-family: 'ProximaNova_Regular';">Age:</label>
                <input type="text" class="custom-control-input custom-control-input-text"  name="defaultresult1-pat" id="defaultresult1-pat" mdbInput style="border-radius: 0.5vw;text-align: center;padding: 0.2em; font-family: 'ProximaNova_Regular';border:0;color: #383838;" value="0">              
              </div>     
              <!-- Default inline 1-->
              <div class="custom-control custom-radio custom-control-inline custom-control-inline-item" style="margin-bottom: 1em;font-family: 'ProximaNova_Regular';">
                <label class="custom-control-label custom-control-label-input-text" for="defaultresult2-pat" style="margin-right: 2em;">Gender:</label>
                <input type="text" class="custom-control-input custom-control-input-text"  name="defaultresult2-pat" id="defaultresult2-pat" mdbInput style="border-radius: 0.5vw;text-align: center;padding: 0.2em;font-family: 'ProximaNova_Regular';border:0;color: #383838;" value="0">              
              </div>                        
              <!-- Default inline 3-->
              <div class="custom-control custom-radio custom-control-inline custom-control-inline-last custom-control-inline-item" style="margin-bottom: 1em;margin-left: 4%;font-family: 'ProximaNova_Regular';">
                <label class="custom-control-label custom-control-label-input-text" for="defaultresult3-pat" style="margin-right: 2em;">Date:</label>
                <input type="text" class="custom-control-input custom-control-input-text"  name="defaultresult3-pat" id="defaultresult3-pat" mdbInput style="border-radius: 0.5vw;text-align: center;padding: 0.2em;font-family: 'ProximaNova_Regular';border:0;color: #383838;" value="0">              
              </div> 
              
            </div>  
            <!-- Type PKU -->
           
            <div class="md-form md-form-data-results2">
              <h4 class="title-section-desc custom-control-label-input-text" style="font-size: 1.2vw;margin-left: 14%;text-align: left !important;font-family: 'ProximaNova_Regular'">Child has:</h4>
              <!-- Default inline 1-->
              <div class="custom-control custom-radio custom-control-inline" style="text-align: left;margin-left:37%;">
                <input type="radio" class="custom-control-input" id="defaultresult5-pat" name="defaultresult5-pat" mdbInput>
                <label class="custom-control-label custom-control-label-radios" for="defaultresult5-pat" style="margin-left: 1em;font-family: 'ProximaNova_Regular';">Classic PKU</label>
              </div>                        
              <!-- Default inline 2-->
              <div class="custom-control custom-radio custom-control-inline" style="text-align: left;margin-left:37%;">
                <input type="radio" class="custom-control-input" id="defaultresult6-pat" name="defaultresult5-pat" mdbInput>
                <label class="custom-control-label custom-control-label-radios" for="defaultresult6-pat" style="margin-left: 1em;font-family: 'ProximaNova_Regular';">Moderate PKU</label>
              </div>
              <!-- Default inline 3-->
              <div class="custom-control custom-radio custom-control-inline" style="text-align: left;margin-left:37%;">
                <input type="radio" class="custom-control-input" id="defaultresult7-pat" name="defaultresult5-pat" mdbInput>
                <label class="custom-control-label custom-control-label-radios" for="defaultresult7-pat" style="margin-left: 1em;font-family: 'ProximaNova_Regular';">Mild PKU</label>
              </div>
            </div>         
          </form>
          <!-- Form -->
        </div>
      </div>
      <div id="no-more-tables-pat" class="hscroll"></div>
      <div id="charts" style="display: block !important;">
        <ng-container>
          <div style="display: flex;flex-direction: column; margin-top: 1.5vw;padding-bottom: 12vw;">
            <div class="graph_container" id="chart-container-00">
             <canvas id="Chart11" style="height:20vh; width:45vw;"></canvas>
             <span class="text-results" id="text-results-pat"></span> 
           </div>
           <div class="graph_container" id="chart-container-11">
             <canvas id="Chart22" style="height:20vh; width:45vw;"></canvas>
             <span class="text-results" id="text-results2-pat"></span> 
           </div> 
           <div class="graph_container" id="chart-container-22">
             <canvas id="Chart33" style="height:20vh; width:45vw;"></canvas>
             <span class="text-results" id="text-results3-pat"></span> 
           </div> 
           <div class="graph_container" id="chart-container-33">
             <canvas id="Chart44" style="height:20vh; width:45vw;"></canvas>
             <span class="text-results" id="text-results4-pat"></span> 
           </div> 
           <app-credits id="credit-test-result" style="position: relative;display: block;width: 100%;margin-top:10%;"></app-credits>
         </div>
        </ng-container>
        
      </div>
    </div>
  
  </div>
  
</div>
