import { Component, OnInit } from '@angular/core';
import { CollectionService } from 'src/app/services/collection.service';
import * as $ from 'jquery';
import { List } from 'linqts';
import { AuthService, User } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css']
})
export class UpdateComponent implements OnInit {
  cresults: any;
  idUser: string
  dataResults: User[];
  devenv = environment.fakeData;
  constructor(private SCollection: CollectionService, private activatedRoute: ActivatedRoute, private userAuth: AuthService) { }

  ngOnInit(): void {
    console.log("USERS: " , this.SCollection.UserCollection);  
    
    this.activatedRoute.queryParams.subscribe(params => {       
       this.idUser = params['id']; 
      console.log("idUser: ", this.idUser);
      
      if(parseInt(sessionStorage.getItem("_894784yrtgsf52")) == 3){
        $(".user-edit-select").prop( "disabled", true );
      } else {
        $(".user-edit-select").prop( "disabled", false );
      }
      
      if(this.SCollection.UserCollection == undefined){
        this.SCollection.GetUsers.then((results) => {
          this.cresults = JSON.parse(JSON.stringify(results));
         this.dataResults = this.cresults.map(dta=> {
               const id = dta.id;
               const email = dta.email;
               const password = dta.password;
               var type = "";
               switch (parseInt(dta.type)) {
                      case 1:
                          type = "Admin"
                        break;
                      case 2:
                          type = "Owner"
                      break;
                      case 3:
                        type = "Nutritionist"
                        break;
                      
                      default:
                        break;
               }
               const username = dta.username;  
               return {id, email, password, type, username}
         });                   
         this.SCollection.UserCollection = this.cresults;
         var DataUser = new List<any>(this.SCollection.UserCollection)
                          .Where(f=> f.id == this.idUser)
                          .Select(f=> f).SingleOrDefault();
                          
          $("#emial-edit").val(DataUser.email);
          $("#username-edit").val(DataUser.username);
          $("#password-edit").val(DataUser.password);
          $("#rpassword-edit").val(DataUser.password);
          $("#id-user-head").text(DataUser.id);
          $("#rol-edit").val(DataUser.type);
          $(`.user-edit-select option[value="${DataUser.type}"]`).attr("selected", "selected");
            if(!this.devenv && this.userAuth.isLoggedIn()){
             this.SCollection.ColUsers.unsubscribe();  
            }         
       })
      } else {
        var DataUser = new List<any>(this.SCollection.UserCollection)
                          .Where(f=> f.id == this.idUser)
                          .Select(f=> f).SingleOrDefault();
            $("#emial-edit").val(DataUser.email);
            $("#username-edit").val(DataUser.username);
            $("#password-edit").val(DataUser.password);
            $("#rpassword-edit").val(DataUser.password);
            $("#id-user-head").text(DataUser.id);
            $("#rol-edit").val(DataUser.type);
            $(`.user-edit-select option[value="${DataUser.type}"]`).attr("selected", "selected");
      }
    });

  }
  SaveUser() {
    console.log("saving");
     let email = $("#emial-edit").val();
     let username = $("#username-edit").val();
     let pasw =  $("#password-edit").val();
     let pasw2 = $("#rpassword-edit").val();
     let id =    this.idUser;
     let rol = $(".user-edit-select").val();

     console.log("email, username, pasw, pasw2, id, rol: ", email, username, pasw, pasw2, id, rol);
      if(email == "" || email == undefined){
        alert("Email is required!");
        return 0;
      }
      if(username == "" || username == undefined){
        alert("Username is required!");
        return 0;
      }
      if(pasw == "" || pasw == undefined){
        alert("Password is required!");
        return 0;
      }
      if(pasw2 == "" || pasw2 == undefined){
        alert("Repeat Password is required!");
        return 0;
      }
      if(pasw != pasw2){
        alert("The passwords are not equal!");
        return 0;
      }
      if(id == "" || id == undefined){
        alert("ID is required!");
        return 0;
      }

      if(rol < 1 ){
        alert("Rol is required!");
        return 0;
      }

      this.SCollection.SaveUSerEx(id,{"email": email,"username": username, "password": pasw, "type": rol}).then(_ => {
        alert("User Updated!")
      }).catch((err)=> {
        console.log("ERR: ", err);
        alert("Something was wrong, Try later!")
      })

  }

}
