import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CollectionService, Patients, Profile } from 'src/app/services/collection.service';
import { environment } from 'src/environments/environment';
import { List } from 'linqts';
import { Answer, GeneralServicesService } from 'src/app/services/general-services.service';
import { AnimationsService } from 'src/app/services/animations.service';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent implements OnInit {
  displayedColums: string[] = [
    "Code",
    "FirstName",
    "LastName",
    "Email",
    "Parameter List",
    "Actions"
  ];
  dataSource: MatTableDataSource<Patients>;
  dataUsers: any[];
  answerxfood: Answer[];
  devenv = environment.fakeData;
  cresults: any;
  dataResults: Patients[];
  pageSizes = [5,10,15,20,40,50,100,200,500,1000];
  prof: any;
  public ObjScoreTableP: any;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private SCollection: CollectionService, private userAuth: AuthService, private router: Router, private GService: GeneralServicesService, private animation: AnimationsService) { }

  ngOnInit(): void {
    this. GetResults();
  }

  GetResults(){
    this.SCollection.GetPatients.then((results) => {
      this.cresults = JSON.parse(JSON.stringify(results));
     this.dataResults = this.cresults.map(dta=> {
           const id = dta.id;          
           const firstname = dta.firstname;
           const lastname = dta.lastname;
           const email = dta.email;
           const Gram = dta.Gram ? "Food list with " + dta.Gram + "% of phenylalanine" : "Food list with 100% of phenylalanine";
           return {id, email, firstname, lastname, Gram}
     });     
        console.log("this.dataResults: ", this.dataResults);
        let uidNutri = sessionStorage.getItem("_89imn7yrtgsf94");
        var resultsFilter = new List<Patients>(this.dataResults)
                                .Where(f=> f.id.toString().includes(uidNutri)).ToArray();
        console.log("resultsFilter: ", resultsFilter);
        this.SCollection.PatientCollection = resultsFilter;
        this.dataSource = new MatTableDataSource(resultsFilter);        
        console.log("this.dataSource: ", this.dataSource);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;      
       
        // if(!this.devenv && this.userAuth.isLoggedIn()){
         this.SCollection.ColPatents.unsubscribe();                
        //  this.SCollection.FAuth.signOut();  
        // }         
   })
  }
  ReloadList(){
    window.location.href= "/patients"
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }   
  }
  
  ResultsPatients(id){
    console.log("results, ", id);   
    this.SCollection.GetSAnswer(id).then((resultsanswerxfood)=> {
        var datos = JSON.parse(JSON.stringify(resultsanswerxfood));
        console.log("datos.Answer: ", datos.Answer);
        this.answerxfood = datos.Answer;
        console.log("answerxfood> ", this.answerxfood);
         
        this.SCollection.GetSProfiles(id).then((profile)=> {
          this.prof = profile;
          console.log("PROFILES> ", this.prof)
          this.GService.DatosSujeto.Agree = this.prof.Agree;
          this.GService.DatosSujeto.PKU = this.prof.PKU;
          this.GService.DatosSujeto.SizeTest = this.prof.SizeTest;
          this.GService.DatosSujeto.birthday = this.prof.birthday;
          this.GService.DatosSujeto.educationLevel = this.prof.educationLevel;
          this.GService.DatosSujeto.gender = this.prof.gender;
          this.GService.DatosSujeto.id = this.prof.id;
          this.GService.DatosSujeto.ocupation = this.prof.ocupation;
          this.GService.DatosSujeto.region = this.prof.region;
          this.GService.DatosSujeto.typeTest = this.prof.typeTest;
          this.GService.SaveLocalStorage("DataUser",this.GService.DatosSujeto, true);  
          var typeTest = this.GService.GetLocalStorage("DataUser",true);
            if(parseInt(typeTest.SizeTest) == 1){
            this.animation.fadeOut("#chart-container-00");
            this.animation.fadeOut("#chart-container-11");
            this.animation.fadeOut("#chart-container-22");
            this.animation.fadeOut("#chart-container-33");
            }else {
            this.animation.fadeIn("#chart-container-00");
            this.animation.fadeIn("#chart-container-11");
            this.animation.fadeIn("#chart-container-22");
            this.animation.fadeIn("#chart-container-33");
            }
          this.SCollection.GetParamScore.then(result => {       
            this.GService.ObjScoreTable = result;
            console.log("this.ObjScoreTableP.length > 0", this.GService.ObjScoreTable.length);              
            this.SCollection.Score.unsubscribe();
            if(this.GService.DatosSujeto.SizeTest == 1){
              this.animation.fadeIn("#results-nutri");
              this.GService.answerbyfood = this.answerxfood;
              // this.GService.ProcessAdminResult(this.answerxfood).then(_ => {        
                
                this.router.navigateByUrl("/patients/results");
                // this.SCollection.GSProfile.unsubscribe();
              // })
            }else {
              this.animation.fadeIn("#results-nutri");
              this.GService.answerbyfood = this.answerxfood;
              // this.GService.ProcessResultAdminExtend(this.answerxfood).then(_ => {
                this.router.navigateByUrl("/patients/results");
                // this.SCollection.GSProfile.unsubscribe();
              // })
              
            }
           
            
        }).catch(_ => {
            alert("Something is Wrong! Try Later.")
        }) 
         
        this.SCollection.ColSAnswers.unsubscribe();
    })
  })
}

  DeleteUser(id){
    console.log("deleted, ", id);
    var answer = window.confirm("Are you sure you want to Delete this User?");
    if (answer) {
      this.SCollection.DeleteUser(id).then(_ => {
        window.location.href = "/users";
      })
    }
    else {
        console.log("abort delete");
    }
   
  }

}
