<div class="row container-btn-r" id="container-btn-r">
  <div class="col-xs-12 ">
    <button mdbBtn color="info" outline="true" rounded="true" block="true"
      class="z-depth-0 my-4 waves-effect valitb-btn" mdbWavesEffect type="submit"
      style="font-family: 'ProximaNova_SemiBold';" (click)="dataResult()">Get Results</button>
  </div>
</div>
<div class="container" style="height: auto;">
  <div class="row">
      <button id="downloadpdf" mdbBtn color="info" outline="true" rounded="true" block="true"
      class="z-depth-0 my-4" mdbWavesEffect type="submit"
      style="font-family: 'ProximaNova_SemiBold';position: relative; display: none;border: 0 !important; 
       width: 30%; background: transparent;color: #1E66AD;box-shadow: none !important; transition: all .3s;" 
      (click)="downloadAsPDF()">Export to PDF &nbsp;&nbsp;&nbsp; <i class="fas fa-chevron-circle-down"></i></button>

      <button id="ViewAnswers" mdbBtn color="info" outline="true" rounded="true" block="true"
      class="z-depth-0 my-4" mdbWavesEffect type="submit"
      style="font-family: 'ProximaNova_SemiBold';position: relative; z-index: 9;display: none;border: 0 !important; 
       width: 30%; background: transparent;color: #1E66AD;box-shadow: none !important; transition: all .3s;" 
      (click)="ViewAnswers()">View Answers &nbsp;&nbsp;&nbsp; <i class="fas fa-chevron-circle-down"></i></button>

      <button id="sendEmailpdf" mdbBtn color="info" outline="true" rounded="true" block="true"
      class="z-depth-0 my-4" mdbWavesEffect type="submit"
      style="font-family: 'ProximaNova_SemiBold';position: relative; display: none;border: 0 !important; 
       width: 30%; background: transparent;color: #1E66AD;box-shadow: none !important; transition: all .3s;" 
      (click)="SendEmailPDF()">Send by Email &nbsp;&nbsp;&nbsp; <i class="fas fa-chevron-circle-down"></i></button>
  </div>
</div>
<!--Emaill-->
<app-send-email></app-send-email>
<!--ANswers-->
<div class="row answer-results" id="answer-results">
  <span class="closed" (click)="closeAnswers()">
    &#10006;
  </span>
  <div class="col-12">
    <mat-card>
        <mat-card-header>
            <mat-card-title style="color: #4a4a4a;">Answers</mat-card-title>
            <mat-card-subtitle style="color: #4a4a4a;">Data</mat-card-subtitle>
          </mat-card-header>
        <mat-card-content>
            <mat-form-field appearance="standard">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter2($event)" placeholder="Ex. pku" #input>
            </mat-form-field>
            <!-- <button  mat-button (click)="exporter2.exportTable('csv')" >Export Data</button> -->
            <div class="mat-elevation-z8">
            <table mat-table   [dataSource]="dataSource2" matSort matTableExporter  #exporter2="matTableExporter">
        
            <!-- ID Column -->
            <ng-container matColumnDef="ID">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-5'"> ID </th>
                <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-5'"> {{row.ID}} </td>
            </ng-container>
        
            <!-- Progress Column -->
            <ng-container matColumnDef="FOODS">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> FOODS </th>
                <td mat-cell *matCellDef="let row"> {{row.FOODS}} </td>
            </ng-container>
        
            <!-- Name Column -->
            <ng-container matColumnDef="Answer">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Answer </th>
                <td mat-cell *matCellDef="let row"> {{row.Answer}} </td>
            </ng-container> 
            
            <!-- Name Column -->
            <ng-container matColumnDef="Allowed">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Allowed </th>
              <td mat-cell *matCellDef="let row"> {{row.Allow}} </td>
          </ng-container> 
            
            
        
            <tr mat-header-row *matHeaderRowDef="displayedColumnsAnswers"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsAnswers;"></tr>
        
            <!-- Row shown when there is no matching data. -->
             <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
            
            </table>
        
            <mat-paginator #paginator2 [pageSizeOptions]="pageSizes"  showFirstLastButtons></mat-paginator>
            </div>
    </mat-card-content>
    </mat-card>
</div> 
</div>
<!--Table -->
<div class="container table-result" id="table-result" #pdfTable style="display: none;">
  <div class="row">
    <div class="col-md-12 principal-contianer-head-results" style="display: flex; flex-direction: row;background: #d8d8d8;padding: 1vw; border-radius: 1vw;">
      <div class="text-center title-results" style="display:flex; max-width:40%;padding: 1vw;">
        <img class="imagen-results" style="width: 100%; height: 100%;" src="/assets/img/pku-result.png">
        <!-- <span class="transform-title">Profile</span>
        <span class="transform-text"><strong class="title-normal">PKU</strong> Cuestionrie to evluate knowledge of the Low Phe Diet</span> -->
      </div>
      <div class="datos-results" style="display: flex;width: 60%;">
        <!-- Form -->
        <form class="text-center" style="color: #383838; width: 100%;">
          <!-- Genearls -->
          <div class="md-form md-form-data-results">           
            <!-- Default inline 1-->
            <div class="custom-control custom-radio custom-control-inline custom-control-inline-first custom-control-inline-item" style="margin-bottom: 1em;text-align: left;margin-left: 21%;">
              <label class="custom-control-label custom-control-label-input-text" for="defaultresult1" style="margin-right: 2em;font-family: 'ProximaNova_Regular';">Age:</label>
              <input type="text" class="custom-control-input custom-control-input-text"  name="defaultresult1" id="defaultresult1" mdbInput style="border-radius: 0.5vw;text-align: center;padding: 0.2em; font-family: 'ProximaNova_Regular';border:0;color: #383838;" value="0">              
            </div>     
            <!-- Default inline 1-->
            <div class="custom-control custom-radio custom-control-inline custom-control-inline-item" style="margin-bottom: 1em;font-family: 'ProximaNova_Regular';">
              <label class="custom-control-label custom-control-label-input-text" for="defaultresult2" style="margin-right: 2em;">Gender:</label>
              <input type="text" class="custom-control-input custom-control-input-text"  name="defaultresult2" id="defaultresult2" mdbInput style="border-radius: 0.5vw;text-align: center;padding: 0.2em;font-family: 'ProximaNova_Regular';border:0;color: #383838;" value="0">              
            </div>                        
            <!-- Default inline 3-->
            <div class="custom-control custom-radio custom-control-inline custom-control-inline-last custom-control-inline-item" style="margin-bottom: 1em;margin-left: 4%;font-family: 'ProximaNova_Regular';">
              <label class="custom-control-label custom-control-label-input-text" for="defaultresult3" style="margin-right: 2em;">Date:</label>
              <input type="text" class="custom-control-input custom-control-input-text"  name="defaultresult3" id="defaultresult3" mdbInput style="border-radius: 0.5vw;text-align: center;padding: 0.2em;font-family: 'ProximaNova_Regular';border:0;color: #383838;" value="0">              
            </div> 
            
          </div>  
          <!-- Type PKU -->
         
          <div class="md-form md-form-data-results2">
            <h4 class="title-section-desc custom-control-label-input-text" style="font-size: 1.2vw;margin-left: 14%;text-align: left !important;font-family: 'ProximaNova_Regular'">Child has:</h4>
            <!-- Default inline 1-->
            <div class="custom-control custom-radio custom-control-inline" style="text-align: left;margin-left:37%;">
              <input type="radio" class="custom-control-input" id="defaultresult5" name="defaultresult5" mdbInput>
              <label class="custom-control-label custom-control-label-radios" for="defaultresult5" style="margin-left: 1em;font-family: 'ProximaNova_Regular';">Classic PKU</label>
            </div>                        
            <!-- Default inline 2-->
            <div class="custom-control custom-radio custom-control-inline" style="text-align: left;margin-left:37%;">
              <input type="radio" class="custom-control-input" id="defaultresult6" name="defaultresult5" mdbInput>
              <label class="custom-control-label custom-control-label-radios" for="defaultresult6" style="margin-left: 1em;font-family: 'ProximaNova_Regular';">Moderate PKU</label>
            </div>
            <!-- Default inline 3-->
            <div class="custom-control custom-radio custom-control-inline" style="text-align: left;margin-left:37%;">
              <input type="radio" class="custom-control-input" id="defaultresult7" name="defaultresult5" mdbInput>
              <label class="custom-control-label custom-control-label-radios" for="defaultresult7" style="margin-left: 1em;font-family: 'ProximaNova_Regular';">Mild PKU</label>
            </div>
          </div>         
        </form>
        <!-- Form -->
      </div>
    </div>
    <div id="no-more-tables" class="hscroll"></div>
    <div id="charts" style="display: none;">
      <app-chart></app-chart>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12" style="text-align: center;padding: 3%;">
        <span style="font-size: 1vw; font-family: 'ProximaNova_Regular'; margin: 0 auto; align-self: center; color: #4a4a4a;">{{'title-results' | translate}}</span>
      </div>
    </div>
    <div class="row factor1" style="margin-bottom: 1%;">
      <div class="col-xs-12 col-md-12 col-lg-12 vlow">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-f1-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;border-left: 1px solid #4a4a4a;">{{'result-f1-1' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-f1-1-vl' | translate}}</div>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-12 low">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-f1-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;border-left: 1px solid #4a4a4a;">{{'result-f1-2' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-f1-1-l' | translate}}</div>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-12 average">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-f1-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;border-left: 1px solid #4a4a4a;">{{'result-f1-3' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-f1-1-av' | translate}}</div>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-12 high">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-f1-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;border-left: 1px solid #4a4a4a;">{{'result-f1-4' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-f1-1-hig' | translate}}</div>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-12 vhigh">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-f1-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;border-left: 1px solid #4a4a4a;">{{'result-f1-5' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-f1-1-vhig' | translate}}</div>
      </div>

      
    </div>
    <div class="row factor2" style="margin-bottom: 1%;">
      <div class="col-xs-12 col-md-12 col-lg-12 vlow">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-f2-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;border-left: 1px solid #4a4a4a;">{{'result-f2-1' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-f2-1-vl' | translate}}</div>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-12 low">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-f2-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;border-left: 1px solid #4a4a4a;">{{'result-f2-2' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-f2-1-l' | translate}}</div>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-12 average">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-f2-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;border-left: 1px solid #4a4a4a;">{{'result-f2-3' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-f2-1-av' | translate}}</div>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-12 high">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-f2-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;border-left: 1px solid #4a4a4a;">{{'result-f2-4' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-f2-1-hig' | translate}}</div>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-12 vhigh">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-f2-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;">{{'result-f2-5' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-f2-1-vhig' | translate}}</div>
      </div>
    </div>
    <div class="row factor3" style="margin-bottom: 1%;">
      <div class="col-xs-12 col-md-12 col-lg-12 vlow">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-f3-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;border-left: 1px solid #4a4a4a;">{{'result-f3-1' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-f3-1-vl' | translate}}</div>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-12 low">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-f3-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;border-left: 1px solid #4a4a4a;">{{'result-f3-2' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-f3-1-l' | translate}}</div>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-12 average">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-f3-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;">{{'result-f3-3' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-f3-1-av' | translate}}</div>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-12 high">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-f3-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;border-left: 1px solid #4a4a4a;">{{'result-f3-4' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-f3-1-hig' | translate}}</div>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-12 vhigh">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-f3-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;border-left: 1px solid #4a4a4a;">{{'result-f3-5' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-f3-1-vhig' | translate}}</div>
      </div>
    </div>
    <div class="row factorgeneral">
      <div class="col-xs-12 col-md-12 col-lg-12 vlow">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-gk-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;border-left: 1px solid #4a4a4a;">{{'result-gk-1' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-gk-1-vl' | translate}}</div>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-12 low">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-gk-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;border-left: 1px solid #4a4a4a;">{{'result-gk-2' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-gk-1-l' | translate}}</div>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-12 average">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-gk-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;border-left: 1px solid #4a4a4a;">{{'result-gk-3' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-gk-1-av' | translate}}</div>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-12 high">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-gk-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;border-left: 1px solid #4a4a4a;">{{'result-gk-4' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-gk-1-hig' | translate}}</div>
      </div>
      <div class="col-xs-12 col-md-12 col-lg-12 vhigh">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" style="align-self:center;">{{'result-gk-1-d' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="align-self:center;border-right: 1px solid #4a4a4a;border-left: 1px solid #4a4a4a;">{{'result-gk-5' | translate}}</div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2" style="align-self:center;">{{'result-gk-1-vhig' | translate}}</div>
      </div>
    </div>
    <app-credits id="credit-test-result" style="position: relative;display: none;width: 100%;margin-top:5%;"></app-credits>
  </div>

</div>