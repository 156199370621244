import { Injectable } from '@angular/core';
import { gsap } from 'gsap';
@Injectable({
  providedIn: 'root'
})
export class AnimationsService {

  constructor() { }

  fadeIn($el) {
    gsap.to( $el,{ css: { display: 'block',opacity:'1' }, duration: 2 });
  }
  fadeInLine($el) {
    gsap.to( $el,{ css: { display: 'inline-block',opacity:'1' }, duration: 2 });
  }
  fadeOut($el) {
    gsap.to($el, { css:{opacity: '0',display:'none'}, duration: 2 });
  }

  MovetoLeftOpacity($el, $Next, callback) {
    gsap.fromTo($el, { opacity: 1, x: 0 }, {
      opacity: 0, x: -200, duration: 1, onComplete: () => {
        this.HideBlock($el);
        this.ShowCard($Next);
        if($el=="#buttoms-type" || $el== "#GeneralData") {
          gsap.to($el,{x:0,duration:0});          
        }
        callback();
      }
    });
  }

  PutBlock($el) {
    gsap.to($el, { css: { display: 'block',opacity:'1' } });
  }
  HideBlock($el) {
    gsap.to($el, { css: { display: 'none',opacity:'0' } });
  }

  ShowCard(id) {
    gsap.fromTo(id, { css: { display: 'none' } }, {css: { display: 'block' }, onComplete: () => {
        gsap.fromTo(id, { css: { right: '-100%',opacity:'0' }}, { css: { right: '0%',opacity:'1' }, duration: 2.5 });
      }
    });

  }

  toHome(id) {
    gsap.fromTo(id, { opacity: 1 }, {
      opacity: 0, duration: 1, onComplete: () => {        
        gsap.fromTo(id, { css: { display: 'block' } }, { css: { display: 'none' },onComplete: ()=> {
          this.fadeOut("#buttoms-type");
          this.fadeIn("#buttoms-type");
          this.fadeOut("#disclaimer");
          this.fadeOut("#GeneralData");
          this.fadeOut("#Test-Card");
          this.fadeOut("#results");
          this.fadeIn("#formulario-options");
          this.fadeOut(".circle-bar");
        } });
      }
    });
  }

  NextFood (beforeId, afterId, first = false){
    beforeId = "#" + beforeId;
    afterId = "#" + afterId;
    gsap.to(".circle-bar",{css:{display:'none',opacity:"0"},duration:1,onComplete: ()=> {
      gsap.to(".circle-bar",{css:{display:'block',opacity:"1"},duration:1});
    }});
   
    if(!first){
    gsap.to(beforeId,{css:{display:'none', opacity: '0'},duration:1,onComplete: ()=> {  
      gsap.to(afterId,{css:{display:'block',opacity: '1'}, duration:1})
      
    } })
     } else {
             gsap.to("#title-explication",{css:{display:'block',opacity:'1'},duration:1});
             gsap.to(afterId,{css:{display:'block',opacity: '1'}, duration:1})
    }
  }



}
