import { Component, OnInit } from '@angular/core';
import { GeneralServicesService } from 'src/app/services/general-services.service';
import * as $ from 'jquery'
@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {
  progress = 0;
  constructor(private GService: GeneralServicesService) {
   this.ValueBar = this.GService.currentValueBar;
   }
  progressBar = document.querySelector('.progress-bar');
  intervalId;
  ValueBar: number;
  ngOnInit(): void {
    this.ValueBar = this.GService.currentValueBar;
    //this.GService.progress_Calculate();
  }
  
 
}
