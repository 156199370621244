import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import * as $ from 'jquery'
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  islogged = false;
  constructor(public authenticationService: AuthService, private router: Router) { }

  ngOnInit(): void {
  }
  SignOut(){
    this.islogged = false;
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('_894784yrtgsf52');
    sessionStorage.removeItem('_89imn7yrtgsf94');
     window.location.href="/";
  }

  profile(){
    let id = sessionStorage.getItem('_89imn7yrtgsf94');
    console.log("profile, ", id);
    this.router.navigate(['/users/edit'], {
      queryParams: {
         id: id
      }
   });
  }
  isNutritionist(){
    let isnutr = sessionStorage.getItem('_894784yrtgsf52');
    if(parseInt(isnutr) == 3){
      return true;
    }else {
      return false;
    }
  }
}
