<div id="popup1" class="overlay">
    <div class="popup">
      <h2>Oops!</h2>
      <a class="close" (click)="closepopup()">&times;</a>
      <div class="content">
       <p style="color: #4a4a4a;">Sorry, we are having trouble loading the questionnaire. Please reload this page or try again later.  </p>
       <button class="btn btn-large btn-warning" (click)="reload()" style="color: #FFFFFF; margin: 0 auto; display: block;font-family: 'ProximaNova_SemiBold';font-size: 1.4vw;margin-bottom: 1vw;
       width: 45%;
       padding: 0vw;">RESTART</button>
      </div>
    </div>
  </div>