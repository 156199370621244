<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark indigo" class="navbar-buttom">

    <!-- Navbar brand -->
    <mdb-navbar-brand>
        <a class="navbar-brand" href="/home" style="color: #000;">PKU</a></mdb-navbar-brand>

    <!-- Collapsible content -->
    <links >

       <!-- Links -->
       <ul class="navbar-nav mr-auto">
          <li class="nav-item active ">
             <a class="nav-link waves-light tippy" href="/home" title="{{'Menu-home' | translate}}" mdbWavesEffect style="color: #000;"><i class="fas fa-home"></i><span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item" routerLink="/login" *ngIf="authenticationService.isLoggedIn() == false">
             <a class="nav-link waves-light tippy" href="javascript:void(0);" title="{{'Menu-login' | translate}}" mdbWavesEffect style="color: #000;"><i class="fas fa-user-lock"></i></a>
          </li>
          <li class="nav-item" routerLink="/data" *ngIf="authenticationService.isLoggedIn() == true">
             <a class="nav-link waves-light tippy" title="{{'Menu-data' | translate}}" mdbWavesEffect style="color: #000;"><i class="fas fa-chart-pie"></i></a>
          </li>

          <!-- Dropdown -->
          <li class="nav-item dropdown" dropdown *ngIf="authenticationService.isAdmin() == true">
             <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light tippy" mdbWavesEffect style="color: #000;" title="{{'Users' | translate}}">
               <i class="fas fa-users"></i><span class="caret"></span></a>
             <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                <a class="dropdown-item waves-light" mdbWavesEffect routerLink="/users" style="color: #000;">Users List</a>
                <div class="divider dropdown-divider"></div>
                <a class="dropdown-item waves-light" mdbWavesEffect routerLink="users/new" style="color: #000;">New User</a>
             </div>
          </li>
           <!-- Dropdown -->
           <li class="nav-item dropdown" dropdown *ngIf="isNutritionist() == true">
            <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light tippy" mdbWavesEffect style="color: #000;" title="{{'Patients' | translate}}">
               <i class="fas fa-folder-open"></i><span class="caret"></span></a>
            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
               <a class="dropdown-item waves-light" mdbWavesEffect routerLink="/patients" style="color: #000;">Patient List</a>
               <div class="divider dropdown-divider"></div>
               <a class="dropdown-item waves-light" mdbWavesEffect routerLink="patients/new" style="color: #000;">Add Patient</a>
               <div class="divider dropdown-divider"></div>
               <a class="dropdown-item waves-light" mdbWavesEffect routerLink="data/customfoods" style="color: #000;">Customize Foods</a>
            </div>
         </li>
          <li class="nav-item"  (click)="profile()"  *ngIf="authenticationService.isLoggedIn() == true">
            <a class="nav-link waves-light tippy" title="{{'profie' | translate}}" mdbWavesEffect style="color: #000;"><i class="fas fa-user-circle"></i></a>
         </li>
         <li class="nav-item" (click)="SignOut()" *ngIf="authenticationService.isLoggedIn() == true">
            <a class="nav-link waves-light tippy" title="{{'logout-title' | translate}}" mdbWavesEffect style="color: #000;"><i class="fas fa-sign-out-alt"></i></a>
         </li>

       </ul>
       <!-- Links -->

       <!-- Search form -->
       <!-- <form class="form-inline waves-light" mdbWavesEffect>
          <div class="md-form my-0">
             <input class="form-control mr-sm-2" type="text" placeholder="Search">
          </div>
       </form> -->
    </links>
    <!-- Collapsible content -->

 </mdb-navbar>
 <!--/.Navbar-->
