import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  slideConfigTeam = {
    "slidesToShow": 3, 
    "slidesToScroll": 3,
    "infinite":true,
    "dots": false,
    "autoplay":true,
    "lazyLoad":'ondemand',
    "pauseOnHover":false,
    "arrows":true,
    "autoplaySpeed":3000,  
    "speed":3500,     
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          touchMove:true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          touchMove:true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          touchMove:true
        }
      }
    ]
  };
}
