import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'
import  '../../../assets/js/smtp.js' 
declare let Email: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  template: any;
  constructor() { }

  ngOnInit(): void {
  }

  submitContact(){
        console.log("contacForm");

        var Name = $("#inputName").val();
        var Email = $("#inputEmail").val();
        var Message = $("#inputMessage").val();
        var success = true;
        console.log("Name, Email, Message => ",Name, Email, Message );

        if(Name == ""){
            success = false;
            alert("Please add a valid Name");
        }
        if(Message == ""){
          success = false;
           alert("Please add a valid Message");
        }
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if(!Email.toString().match(regexEmail)){
          success = false;
          alert("Please add a valid email")
        }
        if(success){
          console.log("send email");
          this.sendEmail(Name,Email, Message);
        }


  }

 async sendEmail (name, mail, message) { 
    var body = await this.putTemplate(name, message, mail); 
   await   Email.send({
        Host : "smtp.gmail.com",
        Username : "pku.world.contact@gmail.com",
        Password : "rtbrsbxipfhmogwg",
        To : "pku.world@gmail.com",
        From : "pku.world.contact@gmail.com",
        Subject : "Contact Message",
        Body : body     
          }).then(message => {
                  alert(`${message}, Your email has been sent`);
            
        });
    }
  
    putTemplate(name,message, email) {
      this.template = `
      <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
      <head>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
      <meta name="format-detection" content="date=no">
      <meta name="format-detection" content="telephone=no"/>
      <meta name="x-apple-disable-message-reformatting">
      <title>PKU.world</title>
      
        <!--[if mso]>
      
        <style>
      
        * {font-family: Arial, Helvetica, sans-serif !important;}
      
        </style>
      
        <![endif]-->
      
         <!--[if !mso]><!-->
      
        <link href="https://fonts.googleapis.com/css?family=Roboto:400,300,700&subset=latin,cyrillic,greek" rel="stylesheet" type="text/css">
      
        <!--<![endif]-->
      
        <style type="text/css">
      
        
          /* cyrillic-ext */
          @font-face {
          font-family: 'Roboto';
          font-style: 300;
          font-weight: 100;
          src: local('Roboto Thin'), local('Roboto-Thin'), url(http://fonts.gstatic.com/s/roboto/v15/ty9dfvLAziwdqQ2dHoyjphkAz4rYn47Zy2rvigWQf6w.woff2) format('woff2');
          unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
          }
          /* cyrillic */
          @font-face {
          font-family: 'Roboto';
          font-style: 300;
          font-weight: 100;
          src: local('Roboto Thin'), local('Roboto-Thin'), url(http://fonts.gstatic.com/s/roboto/v15/frNV30OaYdlFRtH2VnZZdhkAz4rYn47Zy2rvigWQf6w.woff2) format('woff2');
          unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
          }
          /* greek-ext */
          @font-face {
          font-family: 'Roboto';
          font-style: 300;
          font-weight: 100;
          src: local('Roboto Thin'), local('Roboto-Thin'), url(http://fonts.gstatic.com/s/roboto/v15/gwVJDERN2Amz39wrSoZ7FxkAz4rYn47Zy2rvigWQf6w.woff2) format('woff2');
          unicode-range: U+1F00-1FFF;
          }
          /* greek */
          @font-face {
          font-family: 'Roboto';
          font-style: 300;
          font-weight: 100;
          src: local('Roboto Thin'), local('Roboto-Thin'), url(http://fonts.gstatic.com/s/roboto/v15/aZMswpodYeVhtRvuABJWvBkAz4rYn47Zy2rvigWQf6w.woff2) format('woff2');
          unicode-range: U+0370-03FF;
          }
          /* vietnamese */
          @font-face {
          font-family: 'Roboto';
          font-style: 300;
          font-weight: 100;
          src: local('Roboto Thin'), local('Roboto-Thin'), url(http://fonts.gstatic.com/s/roboto/v15/VvXUGKZXbHtX_S_VCTLpGhkAz4rYn47Zy2rvigWQf6w.woff2) format('woff2');
          unicode-range: U+0102-0103, U+1EA0-1EF1, U+20AB;
          }
          /* latin-ext */
          @font-face {
          font-family: 'Roboto';
          font-style: 300;
          font-weight: 100;
          src: local('Roboto Thin'), local('Roboto-Thin'), url(http://fonts.gstatic.com/s/roboto/v15/e7MeVAyvogMqFwwl61PKhBkAz4rYn47Zy2rvigWQf6w.woff2) format('woff2');
          unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
          }
          /* latin */
          @font-face {
          font-family: 'Roboto';
          font-style: 300;
          font-weight: 100;
          src: local('Roboto Thin'), local('Roboto-Thin'), url(http://fonts.gstatic.com/s/roboto/v15/2tsd397wLxj96qwHyNIkxHYhjbSpvc47ee6xR_80Hnw.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
          }
          /* Resets: see reset.css for details */
          .ReadMsgBody { width: 100%; background-color: #ffffff;}
          .ExternalClass {width: 100%; background-color: #ffffff;}
          .ExternalClass, .ExternalClass p, .ExternalClass span,
          .ExternalClass font, .ExternalClass td, .ExternalClass div {line-height:100%;}
          #outlook a{ padding:0;}
          body{width: 100%; height: 100%; background-color: #ffffff; margin:0; padding:0;}
          body{ -webkit-text-size-adjust:none; -ms-text-size-adjust:none; }
          html{width:100%;}
          table {mso-table-lspace:0pt; mso-table-rspace:0pt; border-spacing:0;}
          table td {border-collapse:collapse;}
          table p{margin:0;}
          br, strong br, b br, em br, i br { line-height:100%; }
          div, p, a, li, td { -webkit-text-size-adjust:none; -ms-text-size-adjust:none;}
          h1, h2, h3, h4, h5, h6 { line-height: 100% !important;  }
          span a { text-decoration: none !important;}
          a{ text-decoration: none !important; }
          ul{list-style: none; margin:0; padding:0;}
          img{height: auto !important; line-height: 100%; outline: none; text-decoration: none; display:block !important; }
          img{ -ms-interpolation-mode:bicubic;}
          .yshortcuts, .yshortcuts a, .yshortcuts a:link,.yshortcuts a:visited,
          .yshortcuts a:hover, .yshortcuts a span { text-decoration: none !important; border-bottom: none !important;}
          /*mailChimp class*/
          .default-edit-image{
          height:20px;
          }
          .tpl-repeatblock {
          padding: 0px !important;
          border: 1px dotted rgba(0,0,0,0.2);
          }
          @media only screen and (max-width: 640px){
          body{
          width:auto!important;
          }
          table[class="container"]{
          width: 100%!important;
          padding-left: 20px!important;
          padding-right: 20px!important;
          }
          td[class="image-270px"] img{
          width:100% !important;
          height:auto !important;
          max-width:100% !important;
          }
          td[class="image-170px"] img{
          width:100% !important;
          height:auto !important;
          max-width:100% !important;
          }
          td[class="image-185px"] img{
          width:185px !important;
          height:auto !important;
          max-width:185px !important;
          }
          td[class="image-100px"] img{
          width:100% !important;
          height:auto !important;
          max-width:100% !important;
          }
          td[class="image-100-percent"] img{
          width:100% !important;
          height:auto !important;
          max-width:100% !important;
          }
          td[class="small-image-100-percent"] img{
          width:100% !important;
          height:auto !important;
          }
          table[class="full-width"]{
          width:100% !important;
          }
          table[class="full-width-text"]{
          width:100% !important;
          background-color:#ffffff;
          padding-left:20px !important;
          padding-right:20px !important;
          }
          table[class="full-width-text2"]{
          width:100% !important;
          background-color:#ffffff;
          padding-left:20px !important;
          padding-right:20px !important;
          }
          table[class="col-2-3img"]{
          width:50% !important;
          margin-right: 20px !important;
          }
          table[class="col-2-3img-last"]{
          width:50% !important;
          }
          table[class="col-2-footer"]{
          width:55% !important;
          margin-right:20px !important;
          }
          table[class="col-2-footer-last"]{
          width:40% !important;
          }
          table[class="col-2-box-radius"]{
          width:47% !important;
          }
          table[class="col-2"]{
          width:47% !important;
          margin-right:20px !important;
          }
          table[class="col-2-last"]{
          width:47% !important;
          }
          table[class="col-3"]{
          width:29% !important;
          margin-right:20px !important;
          }
          table[class="col-3-last"]{
          width:29% !important;
          }
          table[class="row-2"]{
          width:50% !important;
          }
          td[class="text-center"]{
          text-align: center !important;
          }
          /* start clear and remove*/
          table[class="remove"]{
          display:none !important;
          }
          td[class="remove"]{
          display:none !important;
          }
          /* end clear and remove*/
          table[class="fix-box"]{
          padding-left:20px !important;
          padding-right:20px !important;
          }
          td[class="fix-box"]{
          padding-left:20px !important;
          padding-right:20px !important;
          }
          td[class="font-resize"]{
          font-size: 18px !important;
          line-height: 22px !important;
          }
          table[class="space-scale"]{
          width:19px !important;
          float:none !important;
          }
          table[class="clear-align-640"]{
          float:none !important;
          }
          }
          @media only screen and (max-width: 479px){
          body{
          font-size:10px !important;
          }
          table[class="container"]{
          width: 100%!important;
          padding-left: 10px!important;
          padding-right:10px!important;
          }
          table[class="container2"]{
          width: 100%!important;
          float:none !important;
          }
          td[class="full-width"] img{
          width:100% !important;
          height:auto !important;
          max-width:100% !important;
          min-width:124px !important;
          }
          td[class="image-270px"] img{
          width:100% !important;
          height:auto !important;
          max-width:100% !important;
          min-width:124px !important;
          }
          td[class="image-170px"] img{
          width:100% !important;
          height:auto !important;
          max-width:100% !important;
          min-width:124px !important;
          }
          td[class="image-185px"] img{
          width:185px !important;
          height:auto !important;
          max-width:185px !important;
          min-width:124px !important;
          }
          td[class="image-100px"] img{
          width:100px !important;
          height:auto !important;
          max-width:100% !important;
          min-width:100px !important;
          }
          
          td[class="image-100-percent"] img{
          width:100% !important;
          height:auto !important;
          max-width:100% !important;
          min-width:124px !important;
          }
          td[class="small-image-100-percent"] img{
          width:100% !important;
          height:auto !important;
          max-width:100% !important;
          min-width:124px !important;
          }
          table[class="full-width"]{
          width:100% !important;
          }
          table[class="full-width-text"]{
          width:100% !important;
          background-color:#ffffff;
          padding-left:20px !important;
          padding-right:20px !important;
          }
          table[class="full-width-text2"]{
          width:100% !important;
          background-color:#ffffff;
          padding-left:20px !important;
          padding-right:20px !important;
          }
          table[class="col-2-footer"]{
          width:100% !important;
          margin-right:0px !important;
          }
          table[class="col-2-footer-last"]{
          width:100% !important;
          }
          table[class="col-2-box-radius"]{
          width:100% !important;
          }
          table[class="col-2"]{
          width:100% !important;
          margin-right:0px !important;
          }
          table[class="col-2-last"]{
          width:100% !important;
          
          }
          table[class="col-3"]{
          width:100% !important;
          margin-right:0px !important;
          }
          table[class="col-3-last"]{
          width:100% !important;
          
          }
          table[class="row-2"]{
          width:100% !important;
          }
          table[id="col-underline"]{
          float: none !important;
          width: 100% !important;
          border-bottom: 1px solid #e4e4e4;
          }
          td[id="col-underline"]{
          float: none !important;
          width: 100% !important;
          border-bottom: 1px solid #e4e4e4;
          }
          td[class="col-underline"]{
          float: none !important;
          width: 100% !important;
          border-bottom: 1px solid #e4e4e4;
          }
          /*start text center*/
          td[class="text-center"]{
          text-align: center !important;
          }
          div[class="text-center"]{
          text-align: center !important;
          }
          /*end text center*/
          /* start  clear and remove */
          table[id="clear-padding"]{
          padding:0 !important;
          }
          td[id="clear-padding"]{
          padding:0 !important;
          }
          td[class="clear-padding"]{
          padding:0 !important;
          }
          table[class="remove-479"]{
          display:none !important;
          }
          td[class="remove-479"]{
          display:none !important;
          }
          table[class="clear-align"]{
          float:none !important;
          }
          /* end  clear and remove */
          table[class="width-small"]{
          width:100% !important;
          }
          table[class="fix-box"]{
          padding-left:0px !important;
          padding-right:0px !important;
          }
          td[class="fix-box"]{
          padding-left:0px !important;
          padding-right:0px !important;
          }
          td[class="font-resize"]{
          font-size: 14px !important;
          }
          td[class="increase-Height"]{
          height:10px !important;
          }
          td[class="increase-Height-20"]{
          height:20px !important;
          }
          td[class="remove-border"]{
          border:0 !important;
          }
          }
          @media only screen and (max-width: 320px){
          table[class="width-small"]{
          width:125px !important;
          }
          img[class="image-100-percent"]{
          width:100% !important;
          height:auto !important;
          max-width:100% !important;
          min-width:124px !important;
          }
          }
          td ul{list-style: initial; margin:0; padding-left:20px;}
      
        body{background-color:#ffffff; margin: 0 auto !important; height:auto!important;} #preview-template #mainStructure{padding:20px 0px 60px 0px!important;} .default-edit-image{height:20px;} tr.tpl-repeatblock , tr.tpl-repeatblock > td{ display:block !important;} .tpl-repeatblock {padding: 0px !important;border: 1px dotted rgba(0,0,0,0.2); }
      
        @media only screen and (max-width: 640px){ .full-block{display:table !important; padding-top:0px; padding-bottom:0px;} .row{display:table-row!important;} .image-100-percent img{ width:100%!important; height: auto !important; max-width: 100% !important; min-width: 124px !important;}}
      
        @media only screen and (max-width: 480px){ .full-block{display:table !important; padding-top:0px; padding-bottom:0px;} .row{display:table-row!important;}}
      
      
        *[x-apple-data-detectors], .unstyle-auto-detected-links *,
      
        .aBn{border-bottom: 0 !important; cursor: default !important;color: inherit !important; text-decoration: none !important;font-size: inherit !important; font-family: inherit !important; font-weight: inherit !important;line-height: inherit !important;}
      
        .im {color: inherit !important;}
      
        .a6S {display: none !important; opacity: 0.01 !important;}
      
        img.g-img + div {display: none !important;}
      
        img {height: auto !important; line-height: 100%; outline: none; text-decoration: none !important; -ms-interpolation-mode:bicubic;}
      
        a img{ border: 0 !important;}
      
        a:active,a:link,a:visited{color:inherit; text-decoration: none !important; }
      
        #outlook a, span a ,a {color:inherit; text-decoration: none !important;}
      
        u + #body a {color: inherit; text-decoration: none !important; font-size: inherit; font-family: inherit; font-weight: inherit; line-height: inherit;}
      
        .tpl-content{padding:0 !important;}
      
        table td {border-collapse:unset; -webkit-hyphens: none; -moz-hyphens: none; hyphens: none;}
      
        table p{margin:0; padding:0;}
      
        #mainStructure table,#mainStructure img{min-width:0!important;}
      
        #mainStructure{padding:0 !important;}
      
        .row th{display:table-cell;}
      
        .row{display:flex;}
      
        </style>
      </head>
      <body  style="font-size:12px; width:100%; height:100%; background-color:#f1f1f1;">
      <center  style="width:100%; background-color:#f1f1f1;">
      <table id="mainStructure" class="full-width" width="800" align="center" border="0" cellspacing="0" cellpadding="0" style="background-color: #ffffff; max-width: 800px; border: none; box-shadow: #e0e0e0 0px 0px 30px 5px; margin: 0px auto;">
        <!--START IMAGE HEADER LAYOUT-->
        <tr>
          <td align="center" valign="top" style="background-color: #ecebeb;" bgcolor="#ecebeb">
            <!-- start HEADER LAYOUT-container width 600px -->
            <table width="800" align="center" border="0" cellspacing="0" cellpadding="0" class="full-width" style="background-color: #ecebeb; margin: 0px auto; width: 800px; min-width: 320px; max-width: 100%;" role="presentation">
              <tr>
              <td valign="top" class="image-100-percent" width="800" style="width: 800px; line-height: 0px;background-color: #FFFFFF; text-align:center;"> <img src="https://firebasestorage.googleapis.com/v0/b/cuestionario-pku.appspot.com/o/pku.png?alt=media&token=94d72129-ab22-4742-ab55-1b5544e5c3b8" width="200" alt="header-image" style="height: auto; display: block !important; width: 200px; max-width: 200px; min-width: 100%;margin-left: 300px !important;" height="auto"></td>
              </tr>
            </table><!-- end HEADER LAYOUT-container width 600px -->
          </td>
        </tr>
        <!--END IMAGE HEADER LAYOUT-->
        <!-- START LAYOUT-1 -->
        <tr>
          <td align="center" valign="top" style="background-color: #fff;" bgcolor="#fff">
            <!-- start  container width 600px -->
            <table width="600" align="center" border="0" cellspacing="0" cellpadding="0" class="container" style="background-color: #ffffff; margin: 0px auto; width: 600px; min-width: 320px; max-width: 90%;" role="presentation">
              <tr>
                <td valign="top">
                  <!-- start container width 560px -->
                  <table width="540" align="center" border="0" cellspacing="0" cellpadding="0" class="full-width" style="margin: 0px auto; min-width: 100%;" role="presentation">
                    <!-- start text content -->
                    <tr>
                      <td valign="top">
                        <table width="100%" border="0" cellspacing="0" cellpadding="0" align="center" style="margin: 0px auto; min-width: 100%;" role="presentation">
                          <!-- start text content -->
                          <tr>
                            <td valign="top">
                              <table border="0" cellspacing="0" cellpadding="0" align="center" style="margin: 0px auto;mso-table-lspace:0pt; mso-table-rspace:0pt;" role="presentation">
                                <!--start space height -->
                                <tr>
                                  <td height="7" style="height: 7px; font-size: 0px; line-height: 0;" aria-hidden="true">&nbsp;</td>
                                </tr>
                                <!--end space height -->
                                <tr>
                                  <td style="font-size: 13px; font-family: Roboto, 'Open Sans', Arial, Tahoma, Helvetica, sans-serif; color: #a3a2a2; font-weight: 300; word-break: break-word; hyphens: none; line-height: 145%;" align="center"><span style="color: #a3a2a2; font-style: normal; line-height: 24px; font-size: 13px; font-weight: 300; font-family: Roboto, 'Open Sans', Arial, Tahoma, Helvetica, sans-serif;"><span style="color: #a3a2a2; font-style: normal; line-height: 24px; font-size: 13px; font-weight: 300; font-family: Roboto, 'Open Sans', Arial, Tahoma, Helvetica, sans-serif;"></span></span>
                                    <h3 class="null" style="font-size: 13px; font-weight: 700; font-family: Roboto, 'Open Sans', Arial, Tahoma, Helvetica, sans-serif;"><span style="color: #121c4e; font-style: normal; line-height: 38px; font-size: 32px; font-weight: 700; font-family: Roboto, 'Open Sans', Arial, Tahoma, Helvetica, sans-serif;"><span style="color: #121c4e; font-style: normal; line-height: 38px; font-size: 32px; font-weight: 700; font-family: Roboto, 'Open Sans', Arial, Tahoma, Helvetica, sans-serif;"><br>Hello I'm ${name}!&nbsp;<br><span style="color: #1E66AD; font-style: normal; line-height: 38px; font-size: 32px; font-weight: 700; font-family: Roboto, 'Open Sans', Arial, Tahoma, Helvetica, sans-serif;">—</span><br></span></span></h3>
                                    <h3 class="null" style="font-size: 13px; font-weight: 700; font-family: Roboto, 'Open Sans', Arial, Tahoma, Helvetica, sans-serif;"><span style="color: #222222; font-style: normal; line-height: 28px; font-size: 24px; font-weight: 700; font-family: Roboto, 'Open Sans', Arial, Tahoma, Helvetica, sans-serif;">Contact Email: ${email}</span></h3><span style="color: #222222; font-style: normal; line-height: 24px; font-size: 19px; font-weight: 300; font-family: Roboto, 'Open Sans', Arial, Tahoma, Helvetica, sans-serif;">${message}<br><span style="color: #222222; font-style: normal; line-height: 24px; font-size: 13px; font-weight: 300; font-family: arial, helvetica, sans-serif;"></span>
                                  </td>
                                </tr>
                                <!--start space height -->
                                <tr>
                                  <td height="5" style="height: 5px; font-size: 0px; line-height: 0;" aria-hidden="true">&nbsp;</td>
                                </tr>
                                <!--end space height -->
                              </table>
                            </td>
                          </tr><!-- end text content -->
                        </table>
                      </td>
                    </tr><!-- end text content -->
                  </table><!-- end  container width 560px -->
                </td>
              </tr>
            </table><!-- end  container width 600px -->
          </td>
        </tr><!-- END LAYOUT-1 -->
       
        <!-- START LAYOUT-1 -->
        <tr>
          <td align="center" valign="top" style="background-color: #fff;" bgcolor="#fff">
            <!-- start  container width 600px -->
            <table width="600" align="center" border="0" cellspacing="0" cellpadding="0" class="container" style="background-color: #ffffff; margin: 0px auto; width: 600px; min-width: 320px; max-width: 90%;" role="presentation">
              <tr>
                <td valign="top">
                  <!-- start container width 560px -->
                  <table width="540" align="center" border="0" cellspacing="0" cellpadding="0" class="full-width" style="margin: 0px auto; min-width: 100%;" role="presentation">
                    <!-- start text content -->
                    <tr>
                      <td valign="top">
                        <table width="100%" border="0" cellspacing="0" cellpadding="0" align="center" style="margin: 0px auto; min-width: 100%;" role="presentation">
                          <!-- start text content -->
                          <tr>
                            <td valign="top">
                              <table border="0" cellspacing="0" cellpadding="0" align="center" style="margin: 0px auto;mso-table-lspace:0pt; mso-table-rspace:0pt;" role="presentation">
                                <!--start space height -->
                                <tr>
                                  <td height="20" style="height: 20px; font-size: 0px; line-height: 0;" aria-hidden="true">&nbsp;</td>
                                </tr>
                                <!--end space height -->
                                <!-- <tr>
                                  <td style="font-size: 13px; font-family: Roboto, 'Open Sans', Arial, Tahoma, Helvetica, sans-serif; color: #a3a2a2; font-weight: 300; word-break: break-word; line-height: 145%;" align="center"><span style="color: #121c55; font-style: normal; line-height: 24px; font-size: 18px; font-weight: 700; font-family: Roboto, 'Open Sans', Arial, Tahoma, Helvetica, sans-serif;">ADP Workforce Now<a href="file:///C:/Users/Bryansoza585/Desktop/Templates/WFN%20TEMPLATE.html#" border="0" style="color: #121c55; border-style: none; text-decoration: none !impowrtant; font-size: 18px; font-weight: 700; font-family: Roboto, 'Open Sans', Arial, Tahoma, Helvetica, sans-serif;"></a><a border="0" style="color: #121c55; border-style: none; text-decoration: none !important; font-size: 18px; font-weight: 700; font-family: Roboto, 'Open Sans', Arial, Tahoma, Helvetica, sans-serif;"><sup style="font-size: 13.5px; font-weight: 700; font-family: Roboto, 'Open Sans', Arial, Tahoma, Helvetica, sans-serif;">®</sup></a><a border="0" style="color: #121c55; border-style: none; text-decoration: none !important; font-size: 18px; font-weight: 700; font-family: Roboto, 'Open Sans', Arial, Tahoma, Helvetica, sans-serif;"></a><a border="0" style="color: #121c55; border-style: none; text-decoration: none !important; font-size: 18px; font-weight: 700; font-family: Roboto, 'Open Sans', Arial, Tahoma, Helvetica, sans-serif;">&nbsp;Demo</a>&nbsp;&nbsp;</span><br></td>
                                </tr> -->
                                <!--start space height -->
                                <tr>
                                  <td height="20" style="height: 20px; font-size: 0px; line-height: 0;" aria-hidden="true">&nbsp;</td>
                                </tr>
                                <!--end space height -->
                              </table>
                            </td>
                          </tr><!-- end text content -->
                        </table>
                      </td>
                    </tr><!-- end text content -->
                  </table><!-- end  container width 560px -->
                </td>
              </tr>
            </table><!-- end  container width 600px -->
          </td>
        </tr><!-- END LAYOUT-1 -->
      
        <!--  START FOOTER COPY RIGHT -->
        <tr>
          <td align="center" valign="top" style="background-color: #1E66AD;" bgcolor="#1E66AD">
            <table width="600" align="center" border="0" cellspacing="0" cellpadding="0" class="container" style="background-color: #1E66AD; margin: 0px auto; width: 600px; min-width: 320px; max-width: 90%;" role="presentation">
              <tr>
                <td valign="top">
                  <table width="560" align="center" border="0" cellspacing="0" cellpadding="0" class="container" style="margin: 0px auto; width: 560px; min-width: 280px; max-width: 90%;" role="presentation">
                    <!--start space height -->
                    <tr>
                      <td height="10" style="height: 10px; font-size: 0px; line-height: 0;" aria-hidden="true"></td>
                    </tr>
                    <!--end space height -->
                    <tr>
                      <!-- start COPY RIGHT content -->
                      <td valign="top" style="font-size: 13px; font-family: Roboto, 'Open Sans', Arial, Tahoma, Helvetica, sans-serif; color: #ffffff; font-weight: 300; word-break: break-word; hyphens: none; line-height: 145%;" align="center">Questionnaire to evaluate knowledge of the low PHE Diet © 2021 by R.Ramos, J.Bieber, M.Kapp, M.A.Bueno, L.Gómez, M.Rüsz, M.M.Rodríguez, K.Ahring and S.Waisbren.
                          Designed by F.Galadí. This questionnaire is licensed under Attribution-Non Commercial 4.0 International. Can be downloaded for free from www.PKU.world.
                          To view a copy of this license, visit https://creativecommons.org/licenses/by-nc/4.0/</td> <!-- end COPY RIGHT content -->
                    </tr>
                    <!--start space height -->
                    <tr>
                      <td height="10" style="height: 10px; font-size: 0px; line-height: 0;" aria-hidden="true"></td>
                    </tr>
                    <!--end space height -->
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr><!--  END FOOTER COPY RIGHT -->
      </table></center>
      </body>
      </html>    
      `;
  
      return this.template
    }
}
