<div class="container" style="max-width: 90%;">
    <div class="row">
        <div class="col-12" style="padding: 4%;
        position: relative;">
            <button  mat-button (click)="Reload()" style="left: 1vw; right: auto; top: 7vw">Load Data</button>
        </div>
        
        <div class="col-12">
            <mat-card style="margin-top: 5%;overflow-x: scroll;">
                <mat-card-header>
                    <mat-card-title>Profile and Results</mat-card-title>
                    <mat-card-subtitle>Data</mat-card-subtitle>
                  </mat-card-header>
                <mat-card-content>
                    <mat-form-field appearance="standard">
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. pku" #input>
                    </mat-form-field>
                    <button  mat-button (click)="exporter.exportTable('csv')" >Export Data</button>
                    <div class="mat-elevation-z8">
                    <table mat-table   [dataSource]="dataSource" matSort matTableExporter  #exporter="matTableExporter">
                
                    <!-- ID Column -->
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-5'"> ID </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-5'"> {{row.id}} </td>
                    </ng-container>
                
                    <!-- Progress Column -->
                    <ng-container matColumnDef="Agreement">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-8'"> Agreement </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-8'"> {{row.Agree}} </td>
                    </ng-container>
                
                    <!-- Name Column -->
                    <ng-container matColumnDef="PKU Type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> PKU Type </th>
                        <td mat-cell *matCellDef="let row"> {{row.PKU}} </td>
                    </ng-container>
                
                    <!-- Fruit Column -->
                    <ng-container matColumnDef="Questionnarie">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Questionnarie </th>
                        <td mat-cell *matCellDef="let row"> {{row.SizeTest}} </td>
                    </ng-container>

                     <!-- Fruit Column -->
                     <ng-container matColumnDef="Age">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-8'"> Age </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-8'"> {{row.birthday}} </td>
                    </ng-container>

                     <!-- Fruit Column -->
                     <ng-container matColumnDef="Education Level">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Education Level </th>
                        <td mat-cell *matCellDef="let row"> {{row.educationLevel}} </td>
                    </ng-container>

                     <!-- Fruit Column -->
                     <ng-container matColumnDef="Gender">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-8'"> Gender </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-8'"> {{row.gender}} </td>
                    </ng-container>

                     <!-- Fruit Column -->
                     <ng-container matColumnDef="Ocupation">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ocupation </th>
                        <td mat-cell *matCellDef="let row"> {{row.ocupation}} </td>
                    </ng-container>

                     <!-- Fruit Column -->
                     <ng-container matColumnDef="Region">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Region </th>
                        <td mat-cell *matCellDef="let row"> {{row.region}} </td>
                    </ng-container>

                     <!-- Fruit Column -->
                     <ng-container matColumnDef="Start at">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Start at </th>
                        <td mat-cell *matCellDef="let row"> {{row.startAt}} </td>
                    </ng-container>

                    <!-- Fruit Column -->
                    <ng-container matColumnDef="Profile">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Profile </th>
                        <td mat-cell *matCellDef="let row"> {{row.typeTest}} </td>
                    </ng-container>
                     <!-- ----------------------------------------- -->
                    <ng-container matColumnDef="Raw Score">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-8'"> Raw Score </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-8'"> {{row.GRawScore}} </td>
                    </ng-container>
                    <ng-container matColumnDef="General Percentil">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-8'"> General Percentil </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-8'"> {{row.GenPercentil}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Percentil Factor 1">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-8'"> Percentil Factor 1 </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-8'"> {{row.PercentsF1}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Percentil Factor 2">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-8'"> Percentil Factor 2 </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-8'"> {{row.PercentsF2}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Percentil Factor 3">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-8'"> Percentil Factor 3 </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-8'"> {{row.PercentsF3}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Points Factor 1">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-8'"> Points Factor 1 </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-8'"> {{row.PointsF1}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Points Factor 2">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-8'"> Points Factor 2 </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-8'"> {{row.PointsF2}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Points Factor 3">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-8'"> Points Factor 3 </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-8'"> {{row.PointsF3}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Points Factor 4">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-8'"> Points Factor 4 </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-8'"> {{row.PointsF4}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Points Factor 5">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-8'"> Points Factor 5 </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-8'"> {{row.PointsF5}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Date Finish">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header > Date Finish </th>
                        <td mat-cell *matCellDef="let row" > {{row.date}} </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumnsProfile"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsProfile;"></tr>
            
                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                    </tr>
                    
                    </table>
                
                    <mat-paginator #paginator [pageSizeOptions]="pageSizes"  showFirstLastButtons></mat-paginator>
                    </div>
            </mat-card-content>
            </mat-card>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <mat-card style="margin-top: 5%;">
                <mat-card-header>
                    <mat-card-title>Answers</mat-card-title>
                    <mat-card-subtitle>Data</mat-card-subtitle>
                  </mat-card-header>
                <mat-card-content>
                    <mat-form-field appearance="standard">
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter2($event)" placeholder="Ex. pku" #input>
                    </mat-form-field>
                    <button  mat-button (click)="exporter2.exportTable('csv')" >Export Data</button>
                    <div class="mat-elevation-z8">
                    <table mat-table   [dataSource]="dataSource2" matSort matTableExporter  #exporter2="matTableExporter">
                
                    <!-- ID Column -->
                    <ng-container matColumnDef="ID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-td-colum-5'"> ID </th>
                        <td mat-cell *matCellDef="let row" [ngClass]="'w-td-colum-5'"> {{row.ID}} </td>
                    </ng-container>
                
                    <!-- Progress Column -->
                    <ng-container matColumnDef="FOODS">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> FOODS </th>
                        <td mat-cell *matCellDef="let row"> {{row.FOODS}} </td>
                    </ng-container>
                
                    <!-- Name Column -->
                    <ng-container matColumnDef="Answer">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Answer </th>
                        <td mat-cell *matCellDef="let row"> {{row.Answer}} </td>
                    </ng-container>  
                    
                     <!-- Name Column -->
                     <ng-container matColumnDef="Allowed">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Allowed </th>
                        <td mat-cell *matCellDef="let row"> {{row.Alloweds}} </td>
                    </ng-container>  
                    
                    
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsAnswers"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsAnswers;"></tr>
                
                    <!-- Row shown when there is no matching data. -->
                     <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                    </tr>
                    
                    </table>
                
                    <mat-paginator #paginator2 [pageSizeOptions]="pageSizes"  showFirstLastButtons></mat-paginator>
                    </div>
            </mat-card-content>
            </mat-card>
        </div> 
    </div> 
     <!-- <app-think id="loading-data"></app-think> -->
</div>


