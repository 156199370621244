import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-select-language',
  template: `
  <div class="select-box">    
        <select id="select-box1" #langSelect (change)="translate.use(langSelect.value)">
            <option
              *ngFor="let lang of translate.getLangs()"
              [value]="lang"
              [attr.selected]="lang === translate.currentLang ? '' : null"
            >{{ lang == "en" ? "English" : "Other" }} 
            </option>
      </select>
</div>
  `,
  styles: [
  ]
})
export class SelectLanguageComponent  {

  constructor(public translate: TranslateService) { }

}
