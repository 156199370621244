<div class="row  box-all-step">
    <div class="col-xs-12" style="margin-bottom: 25%;">
        <h3 class="title-test-steps">
            Progress PKU Questionnaire
        </h3>
    </div>
    <div class="col-xs-12  box-steps current"  id="step-1">
        <span class="align-middle">
            Select your Condition
        </span>
        <i class="far fa-check-circle"></i>
    </div>
    <div class="col-xs-12  box-steps pending"  id="step-2">
    <span class="align-middle">
        Descriptives Data
    </span>
    <i class="far fa-check-circle"></i>
   </div>
    <div class="col-xs-12  box-steps pending"  id="step-3">
        <span class="align-middle">
            Agreement
        </span>
        <i class="far fa-check-circle"></i>
    </div>
    <div class="col-xs-12  box-steps pending"  id="step-4">
      <span class="align-middle">
        Questionnaire
      </span>
      <i class="far fa-check-circle"></i>
    </div>
    <div class="col-xs-12  box-steps pending"  id="step-5">
        <span class="align-middle">
            Results
        </span>
        <i class="far fa-check-circle"></i>
    </div>
</div>