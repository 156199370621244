import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { gsap } from 'gsap';
import { GeneralServicesService } from '../../services/general-services.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private renderer: Renderer2, private elmRef: ElementRef, private Gservice: GeneralServicesService) { }

  ngOnInit() {
    this.InitBalloon();
  }
  title = 'PKU';
  timeout;
  targetEmail = document.getElementById("splitlayout");


  InitBalloon() {
    // Some random colors
    const colors = ["#0255C1", "#2AA7FF", "#1B1B1B", "#7979BD", "#1E007A"];

    const numBalls = 50;
    const balls = [];

    for (let i = 0; i < numBalls; i++) {
      let ball = document.createElement("div");
      ball.classList.add("ball");
      ball.style.background = colors[Math.floor(Math.random() * colors.length)];
      ball.style.left = `${Math.floor(Math.random() * 100)}vw`;
      ball.style.top = `${Math.floor(Math.random() * 100)}vh`;
      ball.style.transform = `scale(${Math.random()})`;
      ball.style.width = `${Math.random()}em`;
      ball.style.height = ball.style.width;

      balls.push(ball);
      var element = document.getElementById("intro-home");
      element.children[0].append(ball);
    }

    // Keyframes
    balls.forEach((el, i, ra) => {
      let to = {
        x: Math.random() * (i % 2 === 0 ? -11 : 11),
        y: Math.random() * 12
      };

      let anim = el.animate(
        [
          { transform: "translate(0, 0)" },
          { transform: `translate(${to.x}rem, ${to.y}rem)` }
        ],
        {
          duration: (Math.random() + 1) * 2000, // random duration
          direction: "alternate",
          fill: "both",
          iterations: Infinity,
          easing: "ease-in-out"
        }
      );
    });
  }
  DestroyBallon() {
    var elementToDelete = document.getElementsByClassName('ball');
    if (elementToDelete.length == 0) {
      this.InitBalloon();
    } else {
      while (elementToDelete.length > 0) {
        elementToDelete[0].parentNode.removeChild(elementToDelete[0]);
      }
    }
  }

  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": true,
    "dots": true,
    "autoplay": true,
    "lazyLoad": 'ondemand',
    "pauseOnHover": true,
    "arrows": false,
    "autoplaySpeed": 4000,
    "speed": 1500,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          touchMove: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          touchMove: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          touchMove: true
        }
      }
    ]
  };

  OpenFoods() {    
    localStorage.removeItem('DataUser');
    this.Gservice.DatosSujeto.id = "";
    document.getElementById('results').style.display = 'none';
    let foodSection = document.getElementById("food-block");
    let homeSection = document.getElementById("splitlayout");
    gsap.fromTo(homeSection, { css: { zIndex: 1 },duration:5 }, {css: { zIndex: 1 }, onComplete: () => {
        gsap.to(foodSection, { css:{display:'block',opacity:'1'}, duration: 1 });
        document.getElementById("step-1").classList.remove('current');
        document.getElementById("step-2").classList.remove('current');
        document.getElementById("step-3").classList.remove('current');
        document.getElementById("step-4").classList.remove('current');
        document.getElementById("step-5").classList.remove('current');

        document.getElementById("step-1").classList.remove('success');
        document.getElementById("step-2").classList.remove('success');
        document.getElementById("step-3").classList.remove('success');
        document.getElementById("step-4").classList.remove('success');
        document.getElementById("step-5").classList.remove('success');

        document.getElementById("step-1").classList.add('current');
        document.getElementById("step-2").classList.add('pending');
        document.getElementById("step-3").classList.add('pending');
        document.getElementById("step-4").classList.add('pending');
        document.getElementById("step-5").classList.add('pending');

      }
    });
  }

}
