<!--Preloader-->
<!-- <app-loader></app-loader> -->
<!--Container-->
<div class="pad-zero">
    <!--Splitlayout -->
    <div id="splitlayout" class="splitlayout reset-layout">
      <!--Intro-->
      <div class="intro" id="intro-home">

        <!--Left Content Intro-->
        <div class="side side-left background-scale-left">
          <div class="side-language side-language-mobile" style="display: none;">
            <h1 class="label-language">
              {{'Home-section-right-lang' | translate}}
            </h1>
            <app-select-language></app-select-language>
          </div>
          <!--BUTTON About Nav-->
          <div class="call-to-action about" (click)="this.DestroyBallon()">
            <i class="fa fa-info fa-2x"></i>
            <i class="fa fa-times fa-2x opacity-hide"></i>
            <span class="top"></span>
            <span class="right"></span>
            <span class="bottom"></span>
            <span class="left"></span>
          </div>
          <!--/BUTTON About Nav-->

          <div class="col-lg-8 col-md-9 col-sm-9 col-xs-9 align-center content-wrap">

            <!--Logo-->
            <div class="logo text-center">

                <img style="display: inline-block; margin: 0 auto; width: 12%;" src="/assets/img/logopku.png" alt="princess with yellow dress and crown on her head">
            </div>
            <!--/Logo-->

            <!--main content-->
            <div class="main">

              <!--Content with Typing Intro-->
              <section id="home" class="section active">
                <div class="preloader"></div>
                <div class="align-center text-left">
                  <span class="small-hr animated fadeInLeft" data-animation="fadeInLeft" data-out-animation="fadeOutLeft"
                    data-out-animation-delay="200"></span>
                  <div class="type-wrap text-center">
                    <h1 class="animated fadeInLeft" data-animation="fadeInLeft" data-out-animation="fadeOutLeft"
                      data-out-animation-delay="900"><span id="typed">{{'Home-section-left-title' | translate}}</span>
                      <sup><img style="max-width: 3%;" src="/assets/img/cc.svg" alt="creative commons logo"></sup>
                    </h1>
                  </div>

                  <div class="notify-btn main-menu" style="text-align: center;">
                    <a  class="btn btn-default animated fadeInLeft" href="#"
                      style="width: -moz-fit-content;width: fit-content;font-size: 1.5vw;height: 4.5vw;padding-top: 1vw;" (click)="OpenFoods();">
                        <span
                          class="button-label text-left" style="padding: 0 1vw 0 1vw;">{{'Home-section-button' | translate}}
                        </span>
                    </a>
                  </div>
                </div>
              </section>
              <!--/Content with Typing Intro-->

              <!--BUTTON Notify Form-->
              <section id="contact" class="section text-left">
                <div class="preloader"></div>
                <div class="main-menu">
                  <a class="close-notify black" href="#home" data-animation="fadeInDown" data-animation-delay="900"
                    data-out-animation="fadeOutUp" data-out-animation-delay="100">
                    <div class="call-to-action">
                      <i class="fa fa-times fa-2x"></i>
                      <span class="top"></span>
                      <span class="right"></span>
                      <span class="bottom"></span>
                      <span class="left"></span>
                    </div>
                  </a>
                </div>

                <!--CONTENT CHANGED & <br> ADDED-->
                <div class="notify-wrap">
                  <h3 class="wellcome-text" data-animation="fadeInLeft" data-animation-delay="900"
                    data-out-animation="fadeOutLeft" data-out-animation-delay="100" data-ng-bind="notify || 'hello..'">
                    Hello..</h3>
                  <form id="notifyMe" class="contact-form" action="notify-me.php" method="post">
                    <div class="input-wrap" data-animation="fadeInLeft" data-animation-delay="100"
                      data-out-animation="fadeOutLeft" data-out-animation-delay="900">
                      <input data-ng-model="notify"
                        class="col-lg-10  align-center form-control input input__field input__field--hoshi notify-input-box"
                        name="email" placeholder="email id" type="email">
                      <label class="input__label input__label--hoshi input__label--hoshi-color-1"></label>
                    </div>
                    <button type="submit" data-hover="Send" class="btn btn-default progress-button btn-submit "
                      data-animation="fadeInUp" data-animation-delay="900" data-out-animation="fadeOutUp"
                      data-out-animation-delay="1300">
                      <span><span class="arrow-wrap"><span class="arrow"></span></span></span>
                    </button>
                  </form>
                </div>
              </section>
              <!--/BUTTON Notify Form-->

              <div class="copyright">
                <ul class="social-icons">
                  <li>
                    <a style="color: #4a4a4a; font-family: 'ProximaNova-Light';" href="https://www.xdev-studio.com/"
                      target="_blank" class="social-icon">
                      <i class="fas fa-globe"></i>
                    </a>
                  </li>

                </ul>
                <p><a style="color: #4a4a4a; font-family: 'ProximaNova-Light';width: 100%;" href="https://www.xdev-studio.com/"
                    target="_blank" class="social-icon">
                    {{'Home-section-copyright1' | translate}} <i class="fas fa-heart"></i> {{'Home-section-copyright2' | translate}}
                  </a>
                </p>
              </div>
            </div>
            <!--/main content-->

          </div>
        </div>
        <!--/Left Content Intro-->

        <!--Right Content Intro-->
        <div class="side side-right background-scale">
          <div class="side-language">
          <h2 class="label-language">
            {{'Home-section-right-lang' | translate}}
          </h2>
          <app-select-language></app-select-language>
        </div>
          <!--Contect Nav-->
          <div class="call-to-action contact" (click)="this.DestroyBallon()">
            <i class="fa fa-envelope fa-2x"></i>
            <i class="fa fa-times fa-2x opacity-hide"></i>
            <span class="top"></span>
            <span class="right"></span>
            <span class="bottom"></span>
            <span class="left"></span>
          </div>
          <!--/Contect Nav-->

          <div class="counter-content">
            <div class="col-lg-8 col-md-9 col-sm-9 col-xs-9 align-center text-left">
              <p class="tagline" style="font-family: 'ProximaNova_SemiBold';color: #4a4a4a;">{{'Home-section-right-title' | translate}}<br> {{'Home-section-right-title2' | translate}}</p>

              <!--Counter-->
              <!-- <div id="countdown">
                <span class="days">00</span>
              </div>
              <span class="small-hr"></span>
              <span class="remain-days">Days</span> -->
              <!--/Counter-->

            </div>
          </div>
          <div class="supported-content">
            <div class="col-lg-8 col-md-9 col-sm-9 col-xs-9 align-center text-left">
              <!--Client Slider-->
              <div id="support">
                      <ngx-slick-carousel class="carousel"
                        #slickModal="slick-carousel"
                        [config]="slideConfig"
                        >
                    <div ngxSlickItem>
                      <span class="qual-desc" style="margin-bottom:6%;">
                        {{'Home-section-right-slide1' | translate}}
                      </span>
                      <img class="logo-sup" src="/assets/img/Logo Investigacion UGR 2.png" alt="Granda University Logo"/>
                    </div>
                    <div ngxSlickItem>
                      <span class="qual-desc" style="margin-bottom:6%;">
                        {{'Home-section-right-slide2' | translate}}
                      </span>
                      <img class="logo-sup" src="/assets/img/nutricia.svg" alt="Nutricia Spain Logo Research" />

                    </div>
                    <div ngxSlickItem>
                      <span class="qual-desc" style="margin-bottom:6%;">
                        {{'Home-section-right-slide3' | translate}}
                      </span>
                      <img class="logo-sup" src="/assets/img/UGR.svg" alt="Granada University Logo in Melilla Spain"/>
                    </div>
                    <div ngxSlickItem>
                      <span class="qual-desc" style="margin-bottom:6%;">
                        {{'Home-section-right-slide4' | translate}}
                      </span>
                      <img class="logo-sup" src="/assets/img/Logo-COP-Transparente.png" alt="general council of psychology Logo"/>
                    </div>
                    <div ngxSlickItem>
                      <span class="qual-desc" style="margin-bottom:25%;">
                        {{'Home-section-right-slide5' | translate}}
                      </span>
                      <button class="logo-sup btn btn-large btn-home-contact-us" (click)="this.DestroyBallon()">Contact us</button>
                    </div>
                  </ngx-slick-carousel>
              </div>
              <!--/Client Slider-->
            </div>
          </div>
          <div class="trans-overlay-dark"></div>
          <div class="section-footer-principal" style="position: absolute;width: 95%;bottom:0;top:auto;left: 2.5%; right: auto;">
            <div class="footer-authors-principal">
              <hr style="color: #4a4a4a !important;">
              <p class="footer-description-principal" style="color: #4a4a4a !important;">
                <strong>Questionnaire to  evaluate  knowledge  of  the  low  PHE  Diet ©  2021 by  Rodolfo Ramos,  J.Bieber,  M.Kapp,  M.A.Bueno,
                L.Gómez,  M.Rüsz,  M.M.Rodríguez,  K.Ahring  and  S.Waisbren.</strong> <br> Designed  by  F.Galadí.  This  questionnaire is  licensed
                under Attribution-Non Commercial 4.0 International. <br>Can be downloaded for free from <a href="https://pku.world" target="_blank" style="color: #1E66AD !important;">www.PKU.world</a>.<br>
                To view a copy of this license, visit <a href="https://creativecommons.org/licenses/by-nc/4.0/" target="_blank" style="color: #1E66AD !important;">https://creativecommons.org/licenses/by-nc/4.0/</a>
              </p>
            </div>
          </div>
        </div>
        <!--/Right Content Intro-->
      </div>
      <!--/Intro-->

      <!--About-->
      <app-about class="page page-left" id="about-block"></app-about>
      <!--Contact-->
      <app-contact class="page page-right" id="contact-block"></app-contact>
    </div>

    <app-food class="page page-bottom" id="food-block" style="opacity: 0;"></app-food>

  </div>
