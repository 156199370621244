    <main class="form-signin">
        <form>
            <span style="display: block;max-width: 50%;margin: 0 auto; text-align: center;"><i class="fas fa-user-lock"></i></span>            
            <h1 class="h3 mb-3 fw-normal">{{'login-title' | translate}}</h1>          
            
            <div class="form-floating">
                <input type="email" class="form-control" id="floatingInput" >
                <label for="floatingInput" class="label-for-login">{{'login-username' | translate}}</label>
                <!-- <div *ngIf="loginForm.invalid && usernameControl?.touched && usernameControl.errors">
                    <span *ngIf="usernameControl.errors['required']">Username is required</span>
                    <span *ngIf="usernameControl.errors['email']">Username must be an email</span>
                </div> -->
            </div>
            <div class="form-floating">
                <input type="password" class="form-control" id="floatingPassword"   >
                <label for="floatingPassword" class="label-for-login" >{{'login-passw' | translate}}</label>
                <!-- <div *ngIf="loginForm.invalid && passwordControl?.touched && passwordControl.errors">
                    <span *ngIf="passwordControl.errors['minlength']">Password must have at least 8 characters</span>
                </div> -->
            </div>
            <button class="w-100 btn btn-lg btn-primary" type="submit" (click) = "login()">{{'login-btn' | translate}}</button>
        </form>
    </main>
