import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { CutomFoodsComponent } from './components/admin/cutom-foods/cutom-foods.component';
import { DataComponent } from './components/admin/data/data.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { CreatePatientComponent } from './components/patient/create-patient/create-patient.component';
import { PatientListComponent } from './components/patient/patient-list/patient-list.component';
import { ResultPatientsComponent } from './components/patient/result-patients/result-patients.component';
import { CreateComponent } from './components/user/create/create.component';
import { ListComponent } from './components/user/list/list.component';
import { UpdateComponent } from './components/user/update/update.component';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  {path:'', component: HomeComponent},
  {path:'home', component: HomeComponent},
  {path: 'login', component: LoginComponent },
  {path: 'data', component: DataComponent, canActivate: [AuthGuard] },
  {path: 'users', component: ListComponent, canActivate: [AuthGuard] },
  {path: 'users/edit', component: UpdateComponent, canActivate: [AuthGuard] },
  {path: 'users/new', component: CreateComponent, canActivate: [AuthGuard] },
  {path: 'patients', component: PatientListComponent, canActivate: [AuthGuard] },
  {path: 'patients/new', component: CreatePatientComponent, canActivate: [AuthGuard]},
  {path: 'patients/results', component: ResultPatientsComponent, canActivate: [AuthGuard]},
  {path: 'data/customfoods', component: CutomFoodsComponent, canActivate: [AuthGuard]} 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [HomeComponent, LoginComponent, DataComponent]