import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { List } from 'linqts';
import { CollectionService } from 'src/app/services/collection.service';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery'

@Component({
  selector: 'app-cutom-foods',
  templateUrl: './cutom-foods.component.html',
  styleUrls: ['./cutom-foods.component.css']
})
export class CutomFoodsComponent implements OnInit {
  displayedColums: string[] = [
    "Code",
    "Food Name",
    "Classification",
    "Type of Food",
    "Actions"
  ];
  dataSource: MatTableDataSource<foods>;
  devenv = environment.fakeData;
  cresults: any;
  dataResults: foods[];
  pageSizes = [5,10,15,20,40,50,100,200,500,1000];
  valueAllow: boolean;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(private SCollection: CollectionService) { }

  ngOnInit(): void {
    this.GetResults();
  }

  SaveFood(){

    let newId = this.dataResults.length > 0 ? this.dataResults.length + 1 : 0;
    let FoodName = $("#foodname-new").val();
    let Allow = this.valueAllow;

    if(newId == undefined || newId == 0){
      alert("Something was wrong, reload and try again!");
      return 0;
    }
    if(FoodName == undefined || FoodName == ""){
      alert("Food Name is required!");
      return 0;
    }
    if($(".food-new-select").val() == 0){
      alert("Select a type of Food!");
      return 0;
    }

    var objFood = new foods();
    objFood.id = newId;
    objFood.allow = Allow;
    objFood.factor = 6;
    objFood._class = "number" + newId;
    objFood.idN = sessionStorage.getItem("_89imn7yrtgsf94");
    objFood.name = FoodName;
    objFood.type = "custom"

    console.log("ObjFood", objFood);

    console.log("Food Saved!", this.dataResults.length);
    console.log("Food Saved id new!", this.dataResults.length + 1);

    this.SCollection.SaveNewFood(objFood).then((res)=> {      
      alert("Food Saved!");
    }).catch((err)=> {
      console.log("Error save food: ", err);
      alert("Something is wrong, try later!");
    })

  }
  ReloadList(){
    window.location.href= "/data/customfoods"
  }
  selectChange(){
    console.log("changed");
    this.valueAllow =  $(".food-new-select").val() == 1 ? true : false;
  }
  DeleteFood(id){
    console.log("deleting: ", id);
    var answer = window.confirm("Are you sure you want to Delete this Food?");
    if (answer) {
      this.SCollection.DeleteFood(id).then(_ => {
        window.location.href = "/data/customfoods";
      })
    }
    else {
        console.log("abort delete");
    }
   
  }
  GetResults(){
    this.SCollection.GetFoods().then((results) => {
      this.cresults = JSON.parse(JSON.stringify(results));
      console.log("this.cresults: ", this.cresults);
     this.dataResults = this.cresults.map(dta=> {
           const id = dta.id;
           const idg = dta.idg;          
           const type = dta.type;
           const _class = dta.class;
           const allow = dta.allow;
           const name = dta.name;
           const factor = dta.factor;
           const idN = dta.idN ? dta.idN : "";
          
           return {idg,id, type, _class, allow,name, factor, idN}
     });     
        console.log("this.dataResults: ", this.dataResults);
        let uidNutri = sessionStorage.getItem("_89imn7yrtgsf94");
        var resultsFilter = new List<foods>(this.dataResults)
                                .Where(f=> f.idN.toString() == uidNutri).ToArray();
        // console.log("resultsFilter: ", resultsFilter);
        // this.SCollection.PatientCollection = resultsFilter;
        this.dataSource = new MatTableDataSource(resultsFilter);        
        console.log("this.dataSource: ", this.dataSource);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;      
       
        // if(!this.devenv && this.userAuth.isLoggedIn()){
         this.SCollection.Ofodds.unsubscribe();                
        //  this.SCollection.FAuth.signOut();  
        // }         
   })
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }   
  }
  
}

export class foods {
  id: number
  type: string
  _class: string
  allow: boolean
  name : string
  factor: number 
  idN: string
  idg: string
}
const listFake = [
  {
    "id": 80,
    "type": "custom",
    "class": "number80",
    "allow": true,
    "name": "Plum",
    "factor": 6
  },
  {
    "id": 81,
    "type": "custom",
    "class": "number81",
    "allow": true,
    "name": "quail eggs",
    "factor": 6,
    "idN": "vE88nqrJ1oBvuac3kYLe"
  },
  {
    "id": 82,
    "type": "custom",
    "class": "number82",
    "allow": false,
    "name": "quail eggs2",
    "factor": 6,
    "idN": "vE88nqrJ1oBvuac3kYLe"
  }
  
]